import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
@Component({
  selector: 'app-loader',
  templateUrl: './new-loader.component.html',
  styleUrls: ['./new-loader.component.scss'],
})
export class NewLoaderComponent implements OnInit {

  progressSpinnerStroke = 8;
  progressSpinnerMode: ProgressSpinnerMode = 'indeterminate';

  constructor() { }

  ngOnInit() {}

}
