import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { AuthService } from "../_services/auth.service";
import { LoginService } from "../_services/login.service";
import { UsersService } from "../_services/master-data/users.service";
import { SnackbarMsgService } from "../shared/Toast-service/snackbar-msg.service";
import { RtsService } from "../_services/rts.service";
import { MatDialog } from "@angular/material/dialog";
import { InfoPopUpComponent } from "../shared/component/info-pop-up/info-pop-up.component";
import { Subject } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  selectedLoginType: any = "citizen";
  RequestId = localStorage.getItem("reqId");
  userId = localStorage.getItem("userId");
  loginName: any = "";
  isSubmitting: boolean = false;
  services: any[] = [];
  hide: boolean = true;
  returnUrl: any;

  countdown: number = 180; // 180 seconds
  timer: any;
  isTimerActive: boolean = false;
  private unsubscribe$ = new Subject<void>();

  isPasswordMode: boolean = false;
  isOtpMode: boolean = false;
  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private loginService: LoginService,
    private userService: UsersService,
    private snackbar: SnackbarMsgService,
    private rtsService: RtsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      usrName: ["", Validators.required],
      usrPassword: ["", Validators.required],
      otp: [
        "",
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern("^[0-9]{6}$"),
        ],
      ],
      loginVia: ["", Validators.nullValidator],
    });

    this.route.queryParams.subscribe((params) => {
      this.returnUrl = params["returnUrl"]; // Fetch the returnUrl
    });

    const form1 = {
      requestID: this.RequestId,
      userId: this.userId,
    };
    const formData = new FormData();
    formData.append("requestDtoString", JSON.stringify(form1));
    if (this.RequestId) {
      this.userService.logindigi(formData).subscribe((res) => {});
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.authService.isAuthenticated()) {
          if (event.url === "/login") {
            // this.router.navigate(['/mapped-dashboard']); // Redirect authenticated users from login to dashboard
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
            }
          }
        }
      }
    });

    // Push initial state to prevent back navigation to login page
    history.pushState(null, "", location.href);

    window.onpopstate = () => {
      if (this.authService.isAuthenticated()) {
        // this.router.navigate(['/mapped-dashboard']);
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
        } else {
          this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
        }
      } else {
        history.go(1); // Move forward if not authenticated
      }
    };

    if (this.userId) {
      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
      } // this.router.navigate(['/mapped-dashboard']);
    }

    this.getAllServices();
    // this.openDialog();
  }

  ngOnDestroy() {
    // Clean up the timer when the component is destroyed
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDialog() {
    this.dialog.open(InfoPopUpComponent, {
      data: {
        title: "Ganapati Pandal Registration Closing Soon!",
        image: "/assets/ganpati-banner.jpg",
        // link: 'https://your-link.com',
        // linkText: 'Click here for more details'
      },
      width: "800px",
      height: "auto",
      disableClose: true,
    });
  }

  moveToNext(event: any, nextInput: any) {
    if (event.target.value.length === 1 && nextInput) {
      nextInput.focus();
    }
  }

  onLoginWithOtp() {
    if (this.loginForm.get("usrName").value) {
      const usrPasswordControl = this.loginForm.get("usrPassword");
      usrPasswordControl?.clearValidators();
      usrPasswordControl?.updateValueAndValidity();

      this.isLoading = true; // Set loading to true when API call starts

      this.loginService
        .generateLoginOtp(this.loginForm.get("usrName").value)
        .subscribe((res) => {
          this.isLoading = false; // Reset loading to false after response

          if (res) {
            if (res["status"] === "Success") {
              this.isOtpMode = true;
              this.isPasswordMode = false;
              this.snackbar.success("OTP sent successfully");
              this.startCountdown();
            } else if (res["status"] === "Failed") {
              this.snackbar.error(
                "Your mobile number is not registered. Please register it first."
              );
              this.router.navigate(["/register"]);
            }
          }
        });

      this.loginForm.get("loginVia").setValue("otp");
    } else {
      this.snackbar.error("Please Enter Mobile No First");
    }
  }

  startCountdown() {
    this.isTimerActive = true;
    this.timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.stopCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.timer);
    this.isTimerActive = false;
    this.countdown = 180; // Reset countdown if needed
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;

    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  onLoginWithPassword() {
    const usrNameControl = this.loginForm.get("usrName");

    if (usrNameControl?.value) {
      // this.isPasswordMode = true;
      // this.isOtpMode = false;

      this.loginService
        .isUserExist(this.loginForm.get("usrName").value)
        .subscribe((res) => {
          if (res === true) {
            this.isPasswordMode = true;
            this.isOtpMode = false;
          } else if (res === false) {
            this.snackbar.error(
              "Your mobile number is not registered. Please register it first."
            );
            this.router.navigate(["/register"]);
          }
        });

      const usrOTPControl = this.loginForm.get("otp");
      usrOTPControl?.clearValidators();
      usrOTPControl?.updateValueAndValidity();

      this.loginForm.get("loginVia").setValue("password");
    } else {
      this.snackbar.error("Please Enter Mobile No First");
    }
  }

  onOtpInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const numericValue = input.value.replace(/[^0-9]/g, ""); // Remove non-digit characters
    input.value = numericValue; // Set the cleaned value back

    // Manually update the form control value
    this.loginForm.get("otp")?.setValue(numericValue);
  }

  allowPaste(event: ClipboardEvent) {
    // const clipboardData = event.clipboardData;
    // const pastedData = clipboardData.getData("text");
    // // Check if the pasted data is not numeric
    // if (!/^\d*$/.test(pastedData)) {
    // event.preventDefault(); // Prevent paste if not numeric

    // }
    // alert("paste not allowed");
    event.preventDefault(); // Prevent paste if not numeric
  }

  onSubmit(): void {
    this.loginForm.markAllAsTouched();

    if (this.loginForm.valid) {
      if (!this.selectedLoginType) {
        this._snackBar.open("Please select a login type", "Close", {
          horizontalPosition: "right",
          verticalPosition: "top",
          duration: 3000,
        });
        return;
      }

      let formData = null;
      if (this.loginForm.get("loginVia")?.value == "password") {
        formData = {
          userName: this.loginForm.get("usrName")?.value,
          userPassword: this.loginForm.get("usrPassword")?.value,
          loginVia: "password",
        };

        console.log("formData", formData);
      }

      if (this.loginForm.get("loginVia")?.value == "otp") {
        formData = {
          userName: this.loginForm.get("usrName")?.value,
          otp: this.loginForm.get("otp")?.value,
          loginVia: "otp",
        };
        console.log("formData", formData);
      }

      this.loginService.loginAUthenticationV1(formData).subscribe(
        (data) => {
          console.log("Response:", data); // Check the structure of data

          const res = data as any;
          console.log(res);
          // if (res["status"] == "Success") {
          localStorage.setItem("ugpId", res.ugpId);
          localStorage.setItem("ugpPrevId", res.ugpPrevId);
          localStorage.setItem("token", res.token);
          localStorage.setItem("userId", res.id);
          localStorage.setItem("dptId", res.dptId);
          localStorage.setItem("wardId", res.wardId);
          localStorage.setItem("loginName", res.fullName);
          localStorage.setItem("previousDocument", res.previousDocument);
          localStorage.setItem("designationId", res.designationId);
          localStorage.setItem("firstName", res.firstName);
          localStorage.setItem("lastName", res.lastName);
          localStorage.setItem("mobile", res.mobile);
          localStorage.setItem("email", res.email);
          localStorage.setItem("pinCode", res.pinCode);
          localStorage.setItem("workflow", JSON.stringify(res.workflow));
          localStorage.setItem("activeFlag", res.activeFlag);
          localStorage.setItem("issuedAt", res.issuedAt);
          localStorage.setItem("expiration", res.expiration);
          localStorage.setItem("IsDepartmentHead", res.isDepartmentHead);
          localStorage.setItem("refrshToken", res.refreshToken);
          localStorage.setItem("griWardId",res.griWardId)
          //  added for mapping
          let roleAccess = res.roles;
          if (res.ugpPrevId == "3") {
            localStorage.setItem("showPandalService", "true");
          }
          localStorage.setItem("roles", JSON.stringify(res.roles));

          localStorage.setItem("authorities", JSON.stringify(res.authorities));
          localStorage.setItem("workflow", JSON.stringify(res.workflows));
          this.selectedLoginType = res.username;
          this.loginName = res.fullName;
          localStorage.setItem("login", this.selectedLoginType);
          this.authService.login();
          this.snackbar.success("Logged in");
          let modules = [];
          let dashboard = [];

          if (res.roles.length != 0) {
            let rolePrevId = "";
            res.roles.forEach((element) => {
              element.menus.forEach((menuEelement) => {
                rolePrevId = menuEelement.rolePrevId;
                if (menuEelement.menuFlag == "D") {
                }
                modules.push({
                  moduleId: menuEelement.moduleId,
                  moduleName: menuEelement.moduleName,
                  url: menuEelement.url,
                });
                if (menuEelement.menuFlag == "D") {
                  dashboard.push({
                    moduleId: menuEelement.moduleId,
                    moduleName: menuEelement.moduleName,
                    url: menuEelement.url,
                  });
                }

                localStorage.setItem("dashboard", JSON.stringify(dashboard));
              });
              modules = modules.filter(
                (module, index, self) =>
                  index ===
                  self.findIndex((m) => m.moduleId === module.moduleId)
              );
              localStorage.setItem("modules", JSON.stringify(modules));
            });
            localStorage.setItem("rolePrevId", rolePrevId);
          }

          if (res.ugpPrevId == "5") {
          } else if (res?.data?.isCFCUser == "true") {
            localStorage.setItem("isCFCUser", res?.data?.isCFCUser);
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigateByUrl("/cfc");
            }
          } else {
            console.log("res.Id", res);

            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else if (res.id === "0190161c-b26b-7eda-8401-685ce8b080f8") {
              this.router.navigate(["/grievance-management/handling"]);
            } else {
              this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
            } // this.router.navigate(['/mapped-dashboard']); //added for mapping
          }

          if (res.ugpPrevId == "15") {
            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigateByUrl("/qr-code");
            }
          }

          this.snackbar.success("Login success");
        },
        (error) => {
          // Check if the error is an instance of HttpErrorResponse
          if (error.status === 401) {
            const message =
              error.error || "An error occurred. Please try again.";
            if (message === "OTP Expired") {
              this.snackbar.error(
                "The OTP has expired. Please request a new OTP."
              );
            } else if (message === "Invalid OTP") {
              this.snackbar.error(
                "The OTP you entered is incorrect. Please check and try again."
              );
            } else {
              this.snackbar.error(message); // Display the error message directly
            }
          } else {
            // Fallback for non-HttpErrorResponse errors
            console.error("Unexpected error:", error);
            this.snackbar.error(
              "An unexpected error occurred. Please try again later."
            );
          }
        }
      );
    }
  }

  oddIndexData: any[] = [];
  evenIndexData: any[] = [];
  getAllServices() {
    this.rtsService.getServiceByActiveFlag().subscribe((res: any) => {
      if (res) {
        this.services = res.service; // Directly assign all services
      }
    });
  }

  togglePasswordVisibility() {
    this.hide = !this.hide;
  }
}
