<!-- <div class="flex flex-col md:flex-row h-screen overflow-hidden">
  <div class="hidden md:flex w-full md:w-3/5 bg-cover bg-center"
       style="background-image: url('assets/NMMC-Banner.jpg')">
  </div>

  <div class="flex flex-col items-center justify-center w-full md:w-2/5 h-full bg-gray-50">
    <div class="flex flex-col items-center mb-4">
      <img src="../../assets/logo.jpg" alt="NMMC Logo" class="w-96 h-auto max-w-full" />
    </div>

    <div class="w-full max-w-md px-4 overflow-y-auto">
      <h1 class="text-lg sm:text-xl md:text-xl text-center mb-4">Register</h1>

      <form [formGroup]="form" (ngSubmit)="onRegister()" class="space-y-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>First Name</mat-label>
            <input 
              matInput 
              formControlName="userFirstName" 
              placeholder="Enter First Name" 
              type="text" 
              required 
              maxlength="25" 
              minlength="2"
              pattern="[a-zA-Z]*">
            <mat-error *ngIf="form.get('userFirstName').hasError('required')">
              Please enter your first name.
            </mat-error>
            <mat-error *ngIf="form.get('userFirstName').hasError('pattern')">
              First name can only contain letters.
            </mat-error>
            <mat-error *ngIf="form.get('userFirstName').hasError('minlength')">
              First name must be at least 2 characters long.
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Last Name</mat-label>
            <input 
              matInput 
              formControlName="userLastName" 
              placeholder="Enter Last Name" 
              type="text" 
              required 
              maxlength="25" 
              minlength="2"
              pattern="[a-zA-Z]*">
            <mat-error *ngIf="form.get('userLastName').hasError('required')">
              Please enter your last name.
            </mat-error>
            <mat-error *ngIf="form.get('userLastName').hasError('pattern')">
              Last name can only contain letters.
            </mat-error>
            <mat-error *ngIf="form.get('userLastName').hasError('minlength')">
              Last name must be at least 2 characters long.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <mat-form-field appearance="fill" class="w-full relative">
            <mat-label>Email</mat-label>
            <input matInput formControlName="userEmail" placeholder="Enter Email" [readonly]="isEmailVerified" required>
            <mat-error *ngIf="form.get('userEmail').hasError('email')">Invalid email format.</mat-error>
            <mat-error *ngIf="form.get('userEmail').hasError('required')">Please enter your email.</mat-error>
            <span *ngIf="isEmailVerified"
                  class="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </mat-form-field>
          <mat-form-field appearance="fill" class="w-full relative">
            <mat-label>Mobile</mat-label>
            <input matInput formControlName="userMobile" placeholder="Enter Mobile" type="number" [readonly]="isMobileVerified" required>
            <mat-error *ngIf="form.get('userMobile').hasError('pattern')">Invalid mobile number.</mat-error>
            <mat-error *ngIf="form.get('userMobile').hasError('required')">Please enter your mobile number.</mat-error>
            <span *ngIf="isMobileVerified"
                  class="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
              </svg>
            </span>
          </mat-form-field>
        </div>

        <div *ngIf="!isOTPRequested && !isEmailVerified && !isMobileVerified">
          <button type="button" class="w-full text-white font-medium py-2 px-4 rounded mt-2"
                  [ngStyle]="{ 'background-color': hoverButton ? '#014f86' : '#015296', 'transform': hoverButton ? 'scale(1.05)' : 'scale(1)' }"
                  (click)="requestOTPs()" (mouseover)="hoverButton = true" (mouseleave)="hoverButton = false"
                  [disabled]="form.get('userEmail').invalid || form.get('userMobile').invalid">
            Request OTPs
          </button>
        </div>

        <div *ngIf="isOTPRequested && (!isEmailVerified || !isMobileVerified)" class="space-y-4 mt-2">
          <div *ngIf="!isEmailVerified">
            <div class="flex flex-col space-y-2">
              <div class="flex items-center space-x-2">
                <mat-form-field appearance="fill" class="flex-1">
                  <mat-label>Email OTP</mat-label>
                  <input matInput formControlName="emailOTP" placeholder="Enter Email OTP" class="py-1.5">
                </mat-form-field>
                <button type="button" class="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded transition-transform transform hover:scale-105"
                        (click)="verifyEmailOTP()">Verify</button>
                <button type="button" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded transition-transform transform hover:scale-105"
                        (click)="requestEmailOTP()">Resend</button>
              </div>
            </div>
          </div>

          <div *ngIf="!isMobileVerified">
            <div class="flex flex-col space-y-2">
              <div class="flex items-center space-x-2">
                <mat-form-field appearance="fill" class="flex-1">
                  <mat-label>Mobile OTP</mat-label>
                  <input matInput formControlName="mobileOTP" placeholder="Enter Mobile OTP" class="py-1.5">
                </mat-form-field>
                <button type="button" class="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded transition-transform transform hover:scale-105"
                        (click)="verifyMobileOTP()">Verify</button>
                <button type="button" class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded transition-transform transform hover:scale-105"
                        (click)="requestMobileOTP()">Resend</button>
              </div>
            </div>
          </div>
        </div>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Password</mat-label>
          <input matInput type="password" formControlName="usrPassword" placeholder="Enter Password" required>
          <mat-error *ngIf="form.get('usrPassword').hasError('required')">Password is required.</mat-error>
          <mat-error *ngIf="form.get('usrPassword').hasError('strongPassword')">Password must include uppercase, lowercase, number, and special character.</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Confirm Password</mat-label>
          <input matInput type="password" formControlName="usrConfirmPassword" placeholder="Confirm Password" required>
          <mat-error *ngIf="form.get('usrConfirmPassword').hasError('required')">Please confirm your password.</mat-error>
          <mat-error *ngIf="form.get('usrConfirmPassword').hasError('passwordMismatch')">Passwords do not match.</mat-error>
        </mat-form-field>

        <div class="flex items-start mt-4">
          <input id="termsCheckbox" 
                 type="checkbox" 
                 class="w-4 h-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500"
                 formControlName="agreeTerms" required>
          <label for="termsCheckbox" class="ml-2 text-sm font-light text-gray-500 dark:text-gray-400">
            I accept the <a href="#" class="font-medium text-blue-600 hover:underline dark:text-blue-500">Terms and Conditions</a>
          </label>
        </div>

        <button type="submit" class="w-full text-white font-medium py-2 px-4 rounded mt-2"
                [ngStyle]="{ 'background-color': hoverButton ? '#014f86' : '#015296', 'transform': hoverButton ? 'scale(1.05)' : 'scale(1)' }"
                (mouseover)="hoverButton = true" (mouseleave)="hoverButton = false"
                [disabled]="!isEmailVerified || !isMobileVerified">
          Register
        </button>
      </form>
      <br>
      <p class="text-sm font-light text-gray-500 dark:text-gray-400 mt-4">
        Already have an account? <a href="/login" class="font-medium text-blue-600 hover:underline dark:text-blue-500">Login here</a>
      </p>
    </div>
  </div>
</div> -->

<div class="flex flex-col md:flex-row h-screen overflow-hidden">
  <!-- Left Side with Image -->
  <div
    class="hidden md:flex w-full md:w-3/5 bg-cover bg-center"
    style="background-image: url('assets/NMMC-Banner.jpg')"
  ></div>

  <!-- Right Side with Registration Form -->
  <div
    class="flex flex-col items-center justify-center w-full md:w-2/5 h-full bg-gray-50"
  >
    <div class="flex flex-col items-center mb-4">
      <img
        src="../../assets/logo.jpg"
        alt="NMMC Logo"
        class="w-96 h-auto max-w-full"
      />
    </div>

    <div class="w-full max-w-md px-4 overflow-y-auto">
      <h1 class="text-lg sm:text-xl md:text-xl text-center mb-4">Register</h1>

      <form [formGroup]="form" (ngSubmit)="onRegister()" class="space-y-4">
        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <!-- First Name -->
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>First Name</mat-label>
            <input
              matInput
              formControlName="userFirstName"
              placeholder="Enter First Name"
              type="text"
              required
              maxlength="12"
              minlength="2"
              (copy)="allowPaste($event)"
              (paste)="allowPaste($event)"
              (cut)="allowPaste($event)"
              pattern="^[A-Za-z]{2,12}$"
            />

            <mat-error *ngIf="form.get('userFirstName').hasError('required')">
              Please enter your first name.
            </mat-error>
            <mat-error *ngIf="form.get('userFirstName').hasError('pattern')">
              First name can only contain letters.
            </mat-error>
            <mat-error *ngIf="form.get('userFirstName').hasError('minlength')">
              First name must be at least 2 characters long.
            </mat-error>
          </mat-form-field>
          <!-- Last Name -->
          <mat-form-field appearance="fill" class="w-full">
            <mat-label>Last Name</mat-label>
            <input
              matInput
              formControlName="userLastName"
              placeholder="Enter Last Name"
              type="text"
              required
              maxlength="12"
              minlength="2"
              (copy)="allowPaste($event)"
              (paste)="allowPaste($event)"
              (cut)="allowPaste($event)"
              pattern="^[A-Za-z]{2,12}$"
            />
            <mat-error *ngIf="form.get('userLastName').hasError('required')">
              Please enter your last name.
            </mat-error>
            <mat-error *ngIf="form.get('userLastName').hasError('pattern')">
              Last name can only contain letters.
            </mat-error>
            <mat-error *ngIf="form.get('userLastName').hasError('minlength')">
              Last name must be at least 2 characters long.
            </mat-error>
          </mat-form-field>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <!-- Email -->
          <mat-form-field appearance="fill" class="w-full relative">
            <mat-label>Email (Optional)</mat-label>
            <input
              matInput
              formControlName="userEmail"
              placeholder="Enter Email"
              (copy)="allowPaste($event)"
              (paste)="allowPaste($event)"
              (cut)="allowPaste($event)"
              type="email"
            />
            <mat-error *ngIf="form.get('userEmail').hasError('email')"
              >Invalid email format.</mat-error
            >
          </mat-form-field>
          <!-- Mobile -->
          <mat-form-field appearance="fill" class="w-full relative">
            <mat-label>Mobile</mat-label>
            <input
              matInput
              formControlName="userMobile"
              placeholder="Enter Mobile"
              type="text"
              [readonly]="isMobileVerified"
              required
              pattern="^[0-9]*$"
              maxlength="10"
              (keypress)="validateNumberInput($event)"
              (copy)="allowPaste($event)"
              (paste)="allowPaste($event)"
              (cut)="allowPaste($event)"
              autocomplete="off"
            />
            <mat-error *ngIf="form.get('userMobile').hasError('pattern')"
              >Please enter a valid 10-digit mobile number.</mat-error
            >
            <mat-error *ngIf="form.get('userMobile').hasError('required')"
              >Please enter your mobile number.</mat-error
            >
            <mat-error *ngIf="form.get('userMobile').hasError('maxlength')"
              >Mobile number cannot exceed 10 digits.</mat-error
            >
            <span
              *ngIf="isMobileVerified"
              class="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </span>
          </mat-form-field>
        </div>

        <div *ngIf="!isMobileVerified && !isOTPRequested">
          <button
            type="button"
            class="w-full text-white font-medium py-2 px-4 rounded mt-2"
            [ngStyle]="{
              'background-color': hoverButton ? '#014f86' : '#015296',
              transform: hoverButton ? 'scale(1.02)' : 'scale(1)'
            }"
            (click)="requestMobileOTP()"
            (mouseover)="hoverButton = true"
            (mouseleave)="hoverButton = false"
            [disabled]="form.get('userMobile').invalid"
          >
            Request Mobile OTP
          </button>
        </div>

        <div *ngIf="isOTPRequested && !isMobileVerified" class="space-y-4 mt-2">
          <div class="flex flex-col space-y-2">
            <div class="flex items-center space-x-2">
              <mat-form-field appearance="fill" class="flex-1">
                <mat-label>Mobile OTP</mat-label>
                <input
                  matInput
                  formControlName="mobileOTP"
                  placeholder="Enter Mobile OTP"
                  class="py-1.5"
                  type="text"
                  maxlength="6"
                  pattern="^[0-9]*$"
                  (copy)="allowPaste($event)"
                  (paste)="allowPaste($event)"
                  (cut)="allowPaste($event)"
                  (keypress)="validateNumberInput($event)"
                />
                <mat-error *ngIf="form.get('mobileOTP').hasError('pattern')"
                  >Invalid OTP. Only numbers are allowed.</mat-error
                >
                <mat-error *ngIf="form.get('mobileOTP').hasError('maxlength')"
                  >OTP must be 6 digits.</mat-error
                >
              </mat-form-field>

              <button
                type="button"
                class="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded"
                (click)="verifyMobileOTP()"
              >
                Verify
              </button>
              <button
                type="button"
                class="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-1 px-2 rounded"
                (click)="requestMobileOTP()"
                [disabled]="isCountingDown"
              >
                <span *ngIf="isCountingDown"> Resend in {{ countdown }}s </span>
                <span *ngIf="!isCountingDown"> Resend </span>
              </button>
            </div>
          </div>
        </div>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Password</mat-label>
          <input
            (copy)="allowPaste($event)"
            (paste)="allowPaste($event)"
            (cut)="allowPaste($event)"
            matInput
            type="password"
            formControlName="usrPassword"
            placeholder="Enter Password"
            maxlength="12"
            required
          />
          <mat-error *ngIf="form.get('usrPassword').hasError('required')"
            >Password is required.</mat-error
          >
          <mat-error *ngIf="form.get('usrPassword').hasError('strongPassword')"
            >Password must include uppercase, lowercase, number, and special
            character.</mat-error
          >
          <mat-hint
            >Include upper, lower, number, and special character.</mat-hint
          >
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Confirm Password</mat-label>
          <input
            matInput
            [type]="confirmPasswordVisible ? 'text' : 'password'"
            formControlName="usrConfirmPassword"
            placeholder="Confirm Password"
            (copy)="allowPaste($event)"
            (paste)="allowPaste($event)"
            (cut)="allowPaste($event)"
            maxlength="12"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="toggleConfirmPasswordVisibility()"
            aria-label="Toggle confirm password visibility"
            tabindex="-1"
          >
            <mat-icon>{{
              confirmPasswordVisible ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <mat-error *ngIf="form.get('usrConfirmPassword').hasError('required')"
            >Please confirm your password.</mat-error
          >
          <mat-error
            *ngIf="
              form.get('usrConfirmPassword').hasError('passwordMismatch') &&
              form.get('usrPassword').value &&
              form.get('usrConfirmPassword').value
            "
          >
            Passwords do not match.
          </mat-error>
          <mat-hint
            class="text-red-500"
            *ngIf="
              form.hasError('passwordMismatch') &&
              (!form.get('usrPassword').value ||
                !form.get('usrConfirmPassword').value)
            "
          >
            Passwords must match.
          </mat-hint>
          <mat-hint
            class="text-green-500"
            *ngIf="
              form.get('usrConfirmPassword').valid &&
              !form.hasError('passwordMismatch') &&
              form.get('usrPassword').value
            "
          >
            Passwords match!
          </mat-hint>
        </mat-form-field>

        <div class="flex items-start mt-4">
          <!-- Checkbox -->
          <input
            id="termsCheckbox"
            type="checkbox"
            class="w-4 h-4 border-gray-300 rounded text-blue-600 focus:ring-blue-500"
            formControlName="agreeTerms"
            required
          />
          <!-- <label for="termsCheckbox" class="ml-2 text-sm font-light text-gray-500 dark:text-gray-400">
            I accept the <a href="#" class="font-medium text-blue-600 hover:underline dark:text-blue-500">Terms and Conditions</a>
          </label> -->
          <div class="flex items-center">
            <label
              for="termsCheckbox"
              class="ml-2 text-sm font-light text-gray-500 dark:text-gray-400"
            >
              I accept the
              <a
                href="#"
                (click)="openTermsModal($event)"
                class="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >
                Terms and Conditions
              </a>
            </label>
          </div>
        </div>

        <!-- Submit Button -->
        <button
          type="submit"
          class="w-full text-white font-medium py-2 px-4 rounded mt-2"
          [ngStyle]="{
            'background-color': hoverButton ? '#014f86' : '#015296',
            transform: hoverButton ? 'scale(1.02)' : 'scale(1)'
          }"
          (mouseover)="hoverButton = true"
          (mouseleave)="hoverButton = false"
        >
          Register
        </button>
      </form>
      <br />
      <!-- Already have an accoagreeTermsunt? -->
      <p class="text-sm font-light text-gray-500 dark:text-gray-400 mt-4">
        Already have an account?
        <a
          href="/login"
          class="font-medium text-blue-600 hover:underline dark:text-blue-500"
          >Login here</a
        >
      </p>
    </div>
  </div>
</div>
