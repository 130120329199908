import { Component, ViewChild } from '@angular/core';
import { AddNewDocComponent } from 'src/app/DisasterManagementMaster/Transaction/add-document/add-new-doc/add-new-doc.component';
import { AddNewDocketentryComponent } from './add-new-docketentry/add-new-docketentry.component';
import { MatTableDataSource } from '@angular/material/table';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-new-docketentry',
  templateUrl: './new-docketentry.component.html',
  styleUrls: ['./new-docketentry.component.scss']
})
export class NewDocketentryComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  
columnNames: any[] = [
 
  {
    columnDef: 'sno',
    header: 'Sr.No.',
  },
  {
    columnDef: 'departmentName',
    header: 'Department Name',
  },
  {
    columnDef: 'subjectDate',
    header: 'Subject Date',
  },
  {
    columnDef: 'subject',
    header: 'Subject',
  },
  {
    columnDef: 'subjectSummary',
    header: 'Subject Summary',
  },
  {
    columnDef: 'committeeName',
    header: 'Committee Name',
  },
  {
    columnDef: 'meetingTypeName',
    header: 'Meeting Type Name',
  },
  {
    columnDef: 'financialYear',
    header: 'Financial Year',
  },
  {
    columnDef: 'ammount',
    header: 'Amount',
  },
  {
    columnDef: 'status',
    header: 'Status',
  },
  {
    columnDef: 'remark',
    header: 'Remark',
  },
  {
    columnDef: 'actions',
    header: 'Actions',
  },
];
departments = new MatTableDataSource<any>();
displayedColumns: any[] = []
contentLoaded = false;
constructor(private msmService: MsmamServiceService,
  private dialog: MatDialog,
  private _snackBar: SnackbarMsgService) {

}
ngOnInit() {
  this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
  this.getShedule()
}

getShedule() {
  this.msmService.getNewDocketentry().subscribe(
    data => {
      this.departments.data = data['newDocketEntry'];
      this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
      setTimeout(
        () => {
          this.departments.sort = this.sort;
          this.departments.paginator = this.paginator;
        });

      setTimeout(() => {
        this.contentLoaded = true;
      }, 2000);

    },
    err => {
      this.contentLoaded = true;

    }
  )
}

addEntry() {
  const dialogRef = this.dialog.open(AddNewDocketentryComponent, {
    width: "950px",
    data: {
      isEditMode: false,
    },
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result.data == "Success") {
      this.contentLoaded = false;
      this.getShedule()
    }
  });
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.departments.filter = filterValue.trim().toLowerCase();
}

onSlideToggleChange(row: any, event: any): void {

  row.activeFlag = event.checked ? 'Y' : 'N';
  if(event.checked) {
  this.msmService.activeInactiveNewDocketentry(row.id, row.activeFlag).subscribe(
    res => {
      this._snackBar.success('Docket Entry is Activated');
    },
    err => {
      this._snackBar.error('Activation Flag Failed to update')
    }
  )

} else {
  this.msmService.activeInactiveNewDocketentry(row.id, row.activeFlag).subscribe(
    res => {
      this._snackBar.error('Docket Entry is Deactivated');
      this.getShedule();
    },
    err => {
      this._snackBar.error('Activation Flag Failed to Delete')
    })
}
   
  }
onEdit(row: any){
  const dialogRef = this.dialog.open(AddNewDocketentryComponent, {
    width: "950px",
    data: {
      isEditMode: true,
      row: row
    },
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    if (result.data == "Success") {
      this.contentLoaded = false;
      this.getShedule()
    }
  });
}

}
