<div class="bg-white p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
        close
    </mat-icon>

    <h1 class="text-2xl font-bold mb-8">{{ addedit ? 'Edit' : 'Add' }} Meeting Agenda & Finalization</h1>

    <form (ngSubmit)="saveMeeting()" #docketForm="ngForm">
        <div class="flex gap-20">
            
            <div class="w-full">
                <div class="relative z-0 w-full mb-5">
                    <mat-checkbox [(ngModel)]="isSubject" name="isSubject" color="primary">
                        Is subject
                    </mat-checkbox>
                </div>
                <div *ngIf="isSubject">
                <mat-form-field class="w-full">
                    <mat-label for="officeSelect">Select Office</mat-label>
                    <mat-select [(ngModel)]="officeId" name="officeId" id="officeSelect"
                        (ngModelChange)="departmentChange($event)" required>
                        <mat-option *ngFor="let office of offices" [value]="office.id">
                            {{ office.officeName }}
                        </mat-option>
                        <mat-option *ngIf="offices?.length === 0" [value]="'noOptions'">No options
                            available</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="w-full">
                    <mat-label for="departmentSelect">Select Department</mat-label>
                    <mat-select [(ngModel)]="departmentId" name="departmentId" id="departmentSelect"
                        (ngModelChange)="departmentChange($event)" required>
                        <mat-option *ngFor="let department of departments" [value]="department.id">
                            {{ department.department }}
                        </mat-option>
                        <mat-option *ngIf="departments?.length === 0" [value]="'noOptions'">No options
                            available</mat-option>
                    </mat-select>
                </mat-form-field>

                

                <mat-form-field class="w-full">
                    <mat-label for="committeeSelect">Select Committee</mat-label>
                    <mat-select [(ngModel)]="committeeId" name="committeeId" id="committeeSelect" required>
                        <mat-option *ngFor="let committee of committees" [value]="committee.id">
                            {{ committee.committeeName }}
                        </mat-option>
                        <mat-option *ngIf="committees?.length === 0" [value]="'noOptions'">No options
                            available</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="relative z-0 w-full mb-5">
                    <label for="docketDate" class="block text-gray-600">Docket Date</label>
                    <input [(ngModel)]="docketDate" type="date" name="docketDate" id="docketDate"
                        class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required />
                </div>

                <div class="relative z-0 w-full mb-5">
                    <label for="fromDate" class="block text-gray-600">From Date</label>
                    <input [(ngModel)]="fromDate" type="date" name="fromDate" id="fromDate"
                        class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required />
                </div>

                <div class="relative z-0 w-full mb-5">
                    <label for="toDate" class="block text-gray-600">To Date</label>
                    <input [(ngModel)]="toDate" type="date" name="toDate" id="toDate"
                        class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required />
                </div>

                <div class="relative z-0 w-full mb-5">
                    <label for="verifyUploadedDocuments" class="block text-gray-600">Verify Uploaded Documents</label>
                    <mat-icon>image</mat-icon>
                </div>
</div>
            </div>

            <div class="w-full" *ngIf="isSubject">


                <mat-form-field class="w-full">
                    <mat-label for="docketNo">Docket No</mat-label>
                    <input matInput [(ngModel)]="docketNo" type="number" id="docketNo" name="docketNo" required />
                </mat-form-field>

                

                
                    <!-- Your HTML code here -->
                    <div class="relative z-0 w-full mb-5">
                      <mat-form-field class="w-full">
                        <mat-label for="subject">Subject</mat-label>
                        <input matInput [(ngModel)]="subject" id="subject" name="subject" required />
                      </mat-form-field>
                    </div>
                  
                    <div class="relative z-0 w-full mb-5">
                      <mat-form-field class="w-full">
                        <mat-label for="summery">Subject Summary</mat-label>
                        <textarea matInput [(ngModel)]="subjectSummary" id="summery" name="subjectSummary" required></textarea>
                      </mat-form-field>
                    </div>
                  


                <mat-form-field class="w-full">
                    <mat-label for="remark">Remark</mat-label>
                    <input matInput [(ngModel)]="remark" name="remark" id="remark" required />
                </mat-form-field>


                <div class="relative z-0 w-full mb-5">
                    <mat-checkbox [(ngModel)]="isApprovedAndSubmittedForAgenda" name="isApprovedAndSubmittedForAgenda"
                        color="primary">
                        Is Approved and Submitted for Agenda
                    </mat-checkbox>
                </div>

                <div class="relative z-0 w-full mb-5">
                    <mat-checkbox [(ngModel)]="isRevert" name="isRevert" color="primary">
                        Is Revert
                    </mat-checkbox>
                </div>

                <div  class="relative z-0 w-full mb-5">
                    <mat-form-field class="w-full">
                        <mat-label for="reasonForRevert">Reason for Revert</mat-label>
                        <textarea matInput [disabled]="!isRevert" [(ngModel)]="reasonForRevert" name="reasonForRevert" id="reasonForRevert"
                            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400"
                            required></textarea>
                    </mat-form-field>
                </div>

            </div>
        </div>
    
        <button type="submit"
            class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
            [disabled]="!docketForm.valid || !officeId">
            Save
        </button>
    </form>
</div>