import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AssetServiceService {
  constructor(private http: HttpClient) {}

  getAssetType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assettype/getAllDashboard`
    );
  }

  getActiveAssetType() {
    return this.http.get(`${environment.assetUrl}/api/master/assettype/getAll`);
  }

  activateInactivateAssetType(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assettype/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetType(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assettype/save`,
      data
      
    );
  }

  saveAssetInspectionChecklistForm(data: any){
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetInspectionForm/save`,
      data
      
    );
  }
  saveAssetInspectionChecklistFromGetBy(transactionId: any) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetInspectionForm/getById?transactionId=${transactionId}`
    );
  }
  
  
  getAssetSubType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubtype/getAllDashboard`
    );
    
  }

  activateInactivateAssetSubType(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubtype/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetSubType(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubtype/save`,
      data
    );
  }

  getAssetCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetcategory/getAllDashboard`
    );
  }

  activateInactivateAssetCategory(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetcategory/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetCategory(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetcategory/save`,
      data
    );
  }

  getAssetSubCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubcategory/getAllDashboard`
    );
  }
  getActviveAssetSubCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubcategory/getAll`
    );
  }

  activateInactivateAssetSubCategory(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubcategory/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetSubCategory(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetsubcategory/save`,
      data
    );
  }

  getActiveAssetCategory() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetcategory/getAll`
    );
  }

  getAssetEvaluator() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetevaluator/getAllDashboard`
    );
  }

  activateInactivateAssetEvaluator(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetevaluator/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAssetEvaluator(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/assetevaluator/save`,
      data
    );
  }

  //asset master
  getAsset() {
    return this.http.get(
      `${environment.assetUrl}/api/master/asset/getAllDashboard`
    );
  }

  getActiveAsset() {
    return this.http.get(`${environment.assetUrl}/api/master/asset/getAll`);
  }

  activateInactivateAsset(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/asset/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveAsset(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/asset/save`,
      data
    );
  }

  //equipment breakdown type
  getEquipmentBreakdownType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/equipmentbreakdowntype/getAllDashboard`
    );
  }

  getActiveEquipmentBreakdownType() {
    return this.http.get(`${environment.assetUrl}/api/master/equipmentbreakdowntype/getAll`);
  }

  activateInactivateEquipmentBreakdownType(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipmentbreakdowntype/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  saveEquipmentBreakdownType(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipmentbreakdowntype/save`,
      data
    );
  }

  // Asset status
  getAssetStatus() {
    return this.http.get(
      `${environment.assetUrl}/api/master/status/getAll`
    );
  }
  getActiveAssetStatus() {
    return this.http.get(
      `${environment.assetUrl}/api/master/status/getAll?activeFlag=Y`
    );
  }
  
  activeInactiveAssetStatus(id,flag) {
    return this.http.post(
      `${environment.assetUrl}/api/master/status/activeInActive?id=${id}&activeFlag=${flag}`,{}
    );
  }

  saveAssetStatus(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/status/save`,
      data
    );
  }

  

  getAllAssetEvaluator() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetevaluator/getAll`
    );
  }


  getAssetSubTypeById(data) {
    return this.http.get(`${environment.assetUrl}/api/master/assetsubtype/getByAssetTypeId?assetTypeId=${data}`)
  }
 getAssetSubCategoryBy(data) {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetsubcategory/getByAssetCategoryId?assetCategoryId=${data}`,
    );
  }

  getAllAsset() {
    return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAllActiveList`);
  }
  getActiveAssetEvaluator() {
    return this.http.get(
      `${environment.assetUrl}/api/master/assetevaluator/getAllActiveList`
    );
  }
  saveAssetValuation(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/requestValuation/save`,
      data
    );
  }


    //First Form In Asset Request Valuation
  scrutinyForRequestValuationRegistrationApprove(data: any, id: string, remark: string) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetReqValuation/scrutinyForApprove?id=${id}&remark=${remark}`,
      data
    );
  }
  
  scrutinyForRequestValuationRegistrationReject(data: any, id: string, remark: string) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetReqValuation/scrutinyForReject?id=${id}&remark=${remark}`,
      data
    );
  }


    saveAssetRequestValuation(data: any) {
      return this.http.post(
        `${environment.assetUrl}/api/transaction/assetReqValuation/save`,
        data
      );
    }
    // onDepartmentAndWardIdSelection(deptId:any,wardId:any){
    //   return this.http.get(
    //     `${environment.assetUrl}/api/transaction/assetCreateUpdate/getByDeptAndWardIdWise?deptId=${deptId}&wardId=${wardId}`
    //   );
    // }


    documentList(assetTypeId: any, assetSubTypeId: any) {
      const requestBody = {
        assetTypeId: assetTypeId,
        assetSubTypeId: assetSubTypeId,
      };
      return this.http.post(`${environment.assetUrl}/api/master/mstDocumentList/getByAssetTypeIdSubTypeId`, requestBody);
    }
    

    
    onDepartmentAndWardIdSelection(deptId: any, wardId: any | null) {
      let url = `${environment.assetUrl}/api/transaction/assetCreateUpdate/getByDeptAndWardIdWise?deptId=${deptId}`;
      if (wardId) {
        url += `&wardId=${wardId}`;
      }
      return this.http.get(url);
    }

    getAllRequestValuation() {
      return this.http.get(
        `${environment.assetUrl}/api/transaction/assetReqValuation/getAll`
      );
    }
  
    getAssetRequestValuationById(id) {
      return this.http.get(
        `${environment.assetUrl}/api/transaction/assetReqValuation/getById?id=${id}`
      );
    }

  // getAllAssetValuator() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/requestValuation/getAll`
  //   );
  // }

    getAllAssetValuator() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/requestValuation/getAllV3`
    );
  }

  getAssetValuatorById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/requestValuation/getById?id=${id}`
    );
  }
  
  getAssetInsuranceById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetInsurance/getById?id=${id}`
    );
  }


  ///api/transaction/equipmentBreakdownMaintenance/getAllIdentificationNoAndMaintainanceTypeWise


  getAllIdentificationNoAndMaintainanceTypeWise(identificationNo:any) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/getAllIdentificationNoAndMaintainanceTypeWise?identificationNo=${identificationNo}`
    );
  }

  // saveEBreakDown(data: any) {
  //   return this.http.post(
  //     `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/save`,
  //     data
  //   );
  // }
  saveEBreakDown(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/saveV1`,
      data
    );

  }
  assetMaintenanceApproved(data: any) {
   const remark =data.remark
   const params = {
    id: data.id,
    maintainanceTypePb: data.maintenanceTypePb,
     remark: remark 
  };

  return this.http.post(
    `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForApproveV1`,{}, { params });
  }

  //      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForApproveV1?id=${id},&maintenanceTypePb=${maintenanceTypePb},&remark=${remark}`

  assetMaintenanceReject(data: any) {
    const remark =data.remark
   const params = {
    id: data.id,
    maintainanceTypePb: data.maintenanceTypePb,
     remark: remark 
  };

    return this.http.post(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/scrutinyForRejectV1/`,{}, { params });
  }

  // getAllEBreakdownDetails() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/getAll`
  //   );
  // } 

  getAllEBreakdownDetails() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/getAllV1`
    );
  } 

  getAllEBreakdownById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/equipmentBreakdownMaintenance/getById?id=${id}`
    );
  }
  // getAllAssetInsurance() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/assetInsurance/getAll`
  //   );
  // }


  scrutinyForInsuranceRegistrationApprove(data: any, id: string, remark: string) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetInsurance/scrutinyForApproveV1?id=${id}&remark=${remark}`,
      data
    );
  }
  
  scrutinyForInsuranceRegistrationReject(data: any, id: string, remark: string) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetInsurance/scrutinyForRejectV1?id=${id}&remark=${remark}`,
      data
    );
  }
  

    getAllAssetInsurance() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetInsurance/getAllV1`
    );
  }

  saveAssetInsurance(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/assetInsurance/save`,
      data
    );
  } 
  saveInsuranceIncident(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/insuranceIncident/save`,
      data
    );
  }
  // getAllInsuranceIncident() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/insuranceIncident/getAll`
  //   );
  // }
  // getAllInsuranceIncident() {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/insuranceIncident/getAllV1`
  //   );
  // }

  scrutinyForInsuranceIncidentRegistrationApprove(data: any, id: string, remark: string) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/insuranceIncident/scrutinyForApproveV1?id=${id}&remark=${remark}`,
      data
    );
  }
  
  scrutinyForInsuranceIncidentRegistrationReject(data: any, id: string, remark: string) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/insuranceIncident/scrutinyForRejectV1?id=${id}&remark=${remark}`,
      data
    );
  }
   getAllInsuranceIncident() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/insuranceIncident/getAllV5`
    );
  }
  // getInsuranceIncidentById(id) {
  //   return this.http.get(
  //     `${environment.assetUrl}/api/transaction/insuranceIncident/getById?id=${id}`
  //   );
  // }

    getInsuranceIncidentById(id) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/insuranceIncident/getByIdV1?id=${id}`
    );
  }

  //asset-record
  getAllUsagetype(){
    return this.http.get(
      `${environment.assetUrl}/api/master/usageType/getAll`,
    );
  }
// createOrUpdateAssetRecord(data: any)  {
//     return this.http.post(`${environment.assetUrl}/api/transaction/assetCreateUpdate/save`,data);
//   }
createOrUpdateAssetRecord(data: any)  {
    return this.http.post(`${environment.assetUrl}/api/transaction/assetCreateUpdate/saveV1`,data);
  }

  // getAllAssetRecords(){
  //   return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAll `);
  // }

    getAllAssetRecords(){
    return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAllV2 `);
  }
  getAllAssetRecordsIdentification(){
    return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getAllIdentificationNo `);
  }


  // getById(id) {
  //   return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getById?id=${id}`);
  // }

  getById(id) {
     return this.http.get(`${environment.assetUrl}/api/transaction/assetCreateUpdate/getByIdV1?id=${id}`);
  }

  // Maintenance checklist

  getCheckList() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/getAll`
    );
  }
  getActiveCheckList() {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/getAll?activeFlag=Y`
    );
  }

  saveCheckList(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/save`,
      data
    );
  }

  activeInactiveCheckListStatus(data) {
    return this.http.post(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  getByIdCheckList(id)
  {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/maintenanceChecklist/getById?id=${id}`
    );
  }

  //CheckList Item
  
  getCheckListItem() {
    return this.http.get(
      `${environment.assetUrl}/api/master/mstMaintenanceChecklistItem/getAll`
    );
  }
  saveCheckListItem(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/mstMaintenanceChecklistItem/save`,
      data
    );
  }

  activeInactiveCheckListStatusItem(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/mstMaintenanceChecklistItem/save`,
      data
    );
  }

  //Equipment Part master

  getEquipmentPart() {
    return this.http.get(
      `${environment.assetUrl}/api/master/equipment/getAll`
    );
  }
  saveEquipmentPart(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipment/save`,
      data
    );
  }

  //Document List

  getDocumentList() {
    return this.http.get(
      `${environment.assetUrl}/api/master/mstDocumentList/getAllV1
      `
    );
  }
  saveDocumentList(data: any) {
    return this.http.post(
      `${environment.assetUrl}/api/master/mstDocumentList/saveV1`,
      data
    );
  }

  activeInactiveDocumentList(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/mstDocumentList/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      data
    );
  }


  activeInactiveEquipmentPart(data) {
    return this.http.post(
      `${environment.assetUrl}/api/master/equipment/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      data
    );
  }
  getAllActiveEquipmentPart() {
    return this.http.get(
      `${environment.assetUrl}/api/master/equipment/getAllActiveList`
    );
  }




  //Asset create-update

  getPlotType() {
    return this.http.get(
      `${environment.assetUrl}/api/master/plotType/getAll`
    );
  }


  getPlotNumber() {
    return this.http.get(
      `${environment.assetUrl}/api/master/plotNumber/getAll`
    );
  }
  getByIdentificationNumber(identificationNo: string) {
    return this.http.get(
      `${environment.assetUrl}/api/transaction/assetCreateUpdate/getByIdentificationNo?identificationNo=${identificationNo}`
    );
  }
getByIdentificationNoAndFinancialYear(identificationNo: string, financialYearId: string) {
  return this.http.get(
    `${environment.assetUrl}/api/transaction/requestValuation/getByIdentificationNoAndFinancialYear?IdentificationNo=${identificationNo}&financialYearId=${financialYearId}`
  );
}

getAllByIdentificationNo(identificationNo : string) {
  return this.http.get(
    `${environment.assetUrl}/api/transaction/requestValuation/getAllByIdentificationNo?identificationNo=${identificationNo}`
  );
}
// getInsuranceDetailsV1(identificationNo : string){
//   return this.http.get(
//     `${environment.assetUrl}/api/transaction/assetInsurance/getByIdentificationNo?identificationNo=${identificationNo}`
//   );
// }

getInsuranceDetails(identificationNo : string){
  return this.http.get(
    `${environment.assetUrl}/api/transaction/assetInsurance/getAllByIdentificationNoV1?identificationNo=${identificationNo}`
  );
}

// getInsuranceIncidentDetails(identificationNo : string){
//   return this.http.get(
//     `${environment.assetUrl}/api/transaction/insuranceIncident/getByIdentificationNo?identificationNo=${identificationNo}`
//   );
// }

getInsuranceIncidentDetails(identificationNo : string){
  return this.http.get(
    `${environment.assetUrl}/api/transaction/insuranceIncident/getByIdentificationNoV1?identificationNo=${identificationNo}`
  );
}






scrutinyForCreateAndUpdateRegistrationApprove(data: any, id: string, remark: string) {
  return this.http.post(
    `${environment.assetUrl}/api/transaction/assetCreateUpdate/scrutinyForApproveV1?id=${id}&remark=${remark}`,
    data
  );
}

scrutinyForCreateAndUpdateRegistrationReject(data: any, id: string, remark: string) {
  return this.http.post(
    `${environment.assetUrl}/api/transaction/assetCreateUpdate/scrutinyForRejectV1?id=${id}&remark=${remark}`,
    data
  );
}
}

