import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  public transform(value: any, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      
       case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      
       case 'url': return this.sanitizer.bypassSecurityTrustUrl(`${environment.commonUrl}/api/file/preview?filePath=${value}`);
 
       case 'resourceUrl':console.log(value); return this.sanitizer.bypassSecurityTrustResourceUrl(value);
 
       default: throw new Error(`Invalid safe type specified: ${type}`);
 
        }
  }
}
