<h2 mat-dialog-title>Document View</h2>
<mat-dialog-content>
  <div class="zoom-controls">
    <button mat-button (click)="zoomOut()">-</button>
    <span>{{ zoomLevel }}%</span>
    <button mat-button (click)="zoomIn()">+</button>
  </div>
  
  <div class="iframe-container">
    <iframe 
      *ngIf="documentUrl"
      [src]="documentUrl" 
      [ngStyle]="{'transform': 'scale(' + zoomScale + ')', 'transform-origin': 'top left'}"
      width="1200px" 
      height="600px" 
      style="border: none;"
      title="PDF Document"
    ></iframe>
  </div>
  
  <p *ngIf="!documentUrl">Loading document...</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onClose()">Close</button>
</mat-dialog-actions>
