import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-viewer-modal',
  templateUrl: './document-viewer-modal.component.html',
  styleUrls: ['./document-viewer-modal.component.scss']
})
export class DocumentViewerModalComponent {

  safeUrl: SafeResourceUrl;

  constructor(
    public dialogRef: MatDialogRef<DocumentViewerModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { base64Data: string , docName: string},
    private sanitizer: DomSanitizer
  ) {
    this.safeUrl = this.getSafeUrl(data.base64Data);
  }

  close(): void {
    this.dialogRef.close();
  }

  getSafeUrl(base64Data: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(base64Data);
  }


  downloadDocument(): void {
    const link = document.createElement('a');
    link.href = this.data.base64Data;

    // Set the download attribute with a default or provided filename
    link.download = this.data.docName || 'document';

    // Trigger the download
    link.click();
  }

}
