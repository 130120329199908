import { Overlay } from '@angular/cdk/overlay';
import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ScrutinyActionsConfirmationComponent } from 'src/app/CWM/cwm-dashboard/cwm-app-scrutiny/scrutiny-actions-confirmation/scrutiny-actions-confirmation.component';
import { HrmsServiceService } from 'src/app/_services/HRMS/hrms-service.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-life-certificate-transaction',
  templateUrl: './life-certificate-transaction.component.html',
  styleUrls: ['./life-certificate-transaction.component.scss']
})
export class LifeCertificateTransactionComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  workflowData = localStorage.getItem("workflow") ? JSON.parse(localStorage.getItem("workflow")) : null;
  loginName : any  = ''
  data: any;

   columnNames: any[] = [
    {
      columnDef: 'sno',
      header: 'Sr.No.',
    },
    {
      columnDef: 'fullName',
      header: "Full Name",
    },
    {
      columnDef: 'mobile',
      header: 'Mobile',
    },
    
    {
      columnDef: 'email',
      header: 'Email',
    },
    // {
    //   columnDef: 'adharNo',
    //   header: 'Aadhar No.',
    // },
    // {
    //   columnDef: 'panNo',
    //   header: 'PAN No.',
    // },
    // {
    //   columnDef: 'bank',
    //   header: 'Bank',
    // },
    // {
    //   columnDef: 'branch',
    //   header: 'Branch',
    // },
    // {
    //   columnDef: 'accountType',
    //   header: 'Account Type',
    // },
    // {
    //   columnDef: 'accountNo',
    //   header: 'Account No.',
    // },
    {
      columnDef: 'status',
      header: 'Status',
    },
   
    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ];
  
  lifeCert = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  applicationApprovedForm : FormGroup
  assignStage = [];

  pageSizeOptions: number[] = [10, 25, 100];
  isLoading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;


  constructor(private hrmsService: HrmsServiceService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router :Router,
    private _snackBar: MatSnackBar,
    private location : Location,
    private snackbar : MatSnackBar,
    private overlay : Overlay) {

      // try {
      //   this.workflowData = localStorage.getItem("workflow") ? JSON.parse(localStorage.getItem("workflow")) : null;
      // } catch (e) {
      //   console.error('Error parsing JSON:', e);
      //   this.workflowData = null;
      // }

  }

  ngOnInit() {
    this.loginName = localStorage.getItem('loginName')
   console.log(this.loginName)
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
    this.getLifeCertificates()
    // this.getWorkflowData()
    // if (this.workflowData && this.workflowData.length > 0) {
    //   this.assignStage = [];
    
    //   this.workflowData.forEach((workflow) => {
    //     if (workflow["Life Certificate"]) {
    //       workflow["Life Certificate"].forEach(detail => {
    //         const stages = detail.stage.split(',').map(stage => stage.trim()).filter(stage => stage); 
    //         this.assignStage.push(...stages);
    //       });
    //     }
    //   });
    
    //   this.assignStage = Array.from(new Set(this.assignStage)); 
    //   console.log(this.assignStage);
    // } else {
    //   console.error("workflowData or worfFlowDetails is undefined");
    // }

    // this.getLifeCertificates(this.assignStage);

   
  }

  getLifeCertificates(){
    const searchParam = ''; 
    this.hrmsService.getlifeCertificate(this.currentPage, this.pageSize, searchParam).subscribe(
      data => {
        console.log(data)
        this.lifeCert.data = data['trnLifeCertificateOfPensionerListDao']
        this.lifeCert.data = this.lifeCert.data.sort((a, b) => a.id - b.id);
        this.paginator.length = data['totalElements'];

        setTimeout(
          () => {
            this.lifeCert.sort = this.sort;
            // this.lifeCert.paginator = this.paginator;
          });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);

      },
      err => {
        this.contentLoaded = true;

      }
    )
  }

  // getWorkflowData(){
  //     if (this.workflowData && this.workflowData.length > 0) {
  //     this.assignStage = [];
    
  //     this.workflowData.forEach((workflow) => {
  //       if (workflow["Life Certificate"]) {
  //         workflow["Life Certificate"].forEach(detail => {
  //           const stages = detail.stage.split(',').map(stage => stage.trim()).filter(stage => stage); 
  //           this.assignStage.push(...stages);
  //         });
  //       }
  //     });
    
  //     this.assignStage = Array.from(new Set(this.assignStage)); 
  //     console.log(this.assignStage);
  //   } else {
  //     console.error("workflowData or worfFlowDetails is undefined");
  //   }
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lifeCert.filter = filterValue.trim().toLowerCase();
  }

  add() {
    this.router.navigate(['/hrms-module/life-certificate-actions'],{ state: { applicationData: [],isEditMode: false }})

   
  }

  onEdit(row: any) {
    this.router.navigate(['/hrms-module/life-certificate-actions'],{ state: { applicationData: [],isEditMode: false }})

    
  }

  applyScrutiny(element : any){
    this.router.navigate(['/hrms-module/life-certificate-scrutiny'],{ state: { applicationData: element}})

  }

  downloadForm(row : any){
    console.log(row)
    this.hrmsService.generateLifeCertificatePdf(row.id).subscribe((response : any) => {
      if(response.status == 'SUCCESS'){
        const  base64Report=response.base64;

        console.log("response base64Report",base64Report);
  
  
        const binaryString = window.atob(base64Report);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
  
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([bytes], { type: 'application/pdf' });
  
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
    
        const filename = 'Life_Certificate.pdf';
        anchor.download = filename;
  
        document.body.appendChild(anchor);
    
        anchor.click();
    
        document.body.removeChild(anchor);
    
        window.URL.revokeObjectURL(url);
        this.showSnackBar('Life Certificate downloaded successfully.', 'success');

      }else {
        this.showSnackBar('Error: Unable to download life certificate.', 'error');

      }
     
  
     
    })

   
  }

  showSnackBar(message: string, type: 'success' | 'error') {
    this.snackbar.open(message, 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
    });
    }


    pageChanged(event: PageEvent) {
      console.log({ event });
      this.pageSize = event.pageSize;
      this.currentPage = event.pageIndex;
    
      this.getLifeCertificates();
    }

 
}


