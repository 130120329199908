import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ActivationInactivationConfirmationComponent } from "src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { AddAssetEvaluatorComponent } from "./add-asset-evaluator/add-asset-evaluator.component";

@Component({
  selector: "app-asset-evaluator",
  templateUrl: "./asset-evaluator.component.html",
  styleUrls: ["./asset-evaluator.component.scss"],
})
export class AssetEvaluatorComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.No",
    },
    {
      columnDef: "assetEvaluatorName",
      header: "Asset Valuator Name",
    },
    {
      columnDef: "organizationName",
      header: "Organization Name",
    },
    {
      columnDef: "actions",
      header: "Actions",
    },
  ];
  assetssubcategory = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  contentLoaded = false;
  statusAction: any = "";
  checked: boolean = false;
  activeFlag: any;
  row: any;

  constructor(
    private assetService: AssetServiceService,
    private dialog: MatDialog,
    private _snackbar: SnackbarMsgService
  ) {}

  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );
    this.getAssetEvaluator() ;
  }
  organizationName:any;
  getAssetEvaluator() {
    this.assetService.getAssetEvaluator().subscribe(
        (data) => {
            const assetEvaluatorMasterList = data['assetEvaluatorMasterList'];
            this.organizationName = assetEvaluatorMasterList.map(item => item.organizationName);
            this.assetssubcategory.data = assetEvaluatorMasterList.sort((a, b) => {
                if (a.activeFlag === b.activeFlag) {
                    return a.organizationName.localeCompare(b.organizationName);
                }
                return a.activeFlag === "Y" ? -1 : 1; 
            })

            setTimeout(() => {
                this.assetssubcategory.paginator = this.paginator;
            });

            setTimeout(() => {
                this.contentLoaded = true;
            }, 2000);
        },
        (err) => {
            console.error('Error fetching asset evaluators:', err); 
            this.contentLoaded = true;
        }
    );
}



  addAssetSubCategory() {
    const dialogRef = this.dialog.open(AddAssetEvaluatorComponent, {
      width: "950px",
      data: {
        isEditMode: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetEvaluator();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetssubcategory.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    if (event.checked == true) {
      this.statusAction = "Activate";
      row.activeFlag = "Y";
    } else {
      this.statusAction = "Deactivate";
      row.activeFlag = "N";
    }
    this.organizationName = row.organizationName
    var formData = {
      id: row.id,
      activeFlag: row.activeFlag,
    };

    const dialogRef = this.dialog.open(
      ActivationInactivationConfirmationComponent,
      {
        width: "550px",
        data: {
          mode: this.statusAction,
          component: `${ this.organizationName }`,
        },
        autoFocus: false,
        restoreFocus: false,
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        if (this.statusAction == "Activate") {
          this.assetService
            .activateInactivateAssetEvaluator(formData)
            .subscribe(
              (res) => {
                this._snackbar.success(
                  ` Record ${this.statusAction}d Successfully`
                );
                this.getAssetEvaluator() ;
              },
              (err) => {
                this._snackbar.error("Activation Failed");
                this.getAssetEvaluator() ;
              }
            );
        } else {
          this.assetService
            .activateInactivateAssetEvaluator(formData)
            .subscribe(
              (res) => {
                this._snackbar.success(
                  ` Record ${this.statusAction}d Successfully`
                );
                this.checked = !this.checked;
                this.getAssetEvaluator() ;
              },
              (err) => {
                this._snackbar.error("Deactivation Failed");
                this.getAssetEvaluator() ;
              }
            );
        }
      } else {
        this.getAssetEvaluator() ;
      }
    });
  }

  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddAssetEvaluatorComponent, {
      width: "950px",
      data: {
        isEditMode: true,
        row: row,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = true;
        this.getAssetEvaluator() ;
      }
    });
  }
}
