<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
        close
    </mat-icon>

    <div class="mx-auto max-w-md">
        <h1 class="text-2xl font-bold mb-8">{{addedit?'Edit':'Add'}} Asset Type</h1>
        <form [formGroup]="assetTypeForm" (ngSubmit)="saveAsset()" #incidentForm="ngForm">
            <mat-form-field class="w-full">
                <mat-label>Enter Asset Type</mat-label>
                <input matInput [(ngModel)]="assetTypeName" formControlName="assetTypeName" maxlength="50" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$"
                >
                <mat-error *ngIf="assetTypeForm.get('assetTypeName').hasError('required')">
                    Asset Type Name is required
                  </mat-error>
                  <mat-error *ngIf="assetTypeForm.get('assetTypeName').hasError('pattern')">
                    Enter Asset Type Name must start with an alphanumeric character and can include only letters, numbers.
                  </mat-error>
            </mat-form-field>

            <div class="button-container">
                <button type="submit" class="btn-save" [disabled]="!assetTypeForm.valid">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>