<mat-card [class.mat-elevation-z8]="true" style="margin: 1.4rem;">

    <mat-card-content>
      <div class=" w-full  p-4">
        <div class="lg:flex lg:items-center lg:justify-between">
          <div class="min-w-0 flex-1">
            <h2 class="text-2xl font-bold">Life Certificate</h2>
  
          </div>
          <!-- <div class="mt-5 flex lg:ml-4 lg:mt-0" *ngIf="loginName == 'Admin Admin'"> -->
            <div class="mt-5 flex lg:ml-4 lg:mt-0" *ngIf="loginName == 'Admin Admin' || loginName == 'NMMC ADMIN' || loginName == 'NMMC SUPERADMIN'">
        
            <span class="sm:ml-3">
                <button type="button" (click)="add()"
                class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-lg px-2 py-2.5 text-center inline-flex items-center mr-2  dark:bg-gray-800 dark:hover:bg-gray-700 dark:focus:ring-gray-700 dark:border-gray-700">
                
                    <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1 mb-2" fill="currentColor" stroke="currentColor" stroke-width="1.5" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>
                        Add new life certificate
                    </button>
            </span>
        
            </div>
        </div>
  
      </div>
      <div class="w-full  p-4">
        <mat-form-field class="searchBox" appearance="outline">
          <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
        </mat-form-field>
        <table mat-table [dataSource]="lifeCert" *ngIf="lifeCert.data.length > 0 && contentLoaded" matSort>
          <ng-container *ngFor="let column of columnNames" [matColumnDef]="column.columnDef">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>{{ column.header }}</th>
            <td mat-cell *matCellDef="let row,let i = index">
              <ng-container *ngIf="column.columnDef !== 'actions' && column.Def !== 'sno' && column.Def !== 'fullName' && column.columnDef !== 'status'; else slideToggleTemplate">
                {{ row[column.columnDef] }}
              </ng-container>
              <div *ngIf="column.columnDef==='sno'">
                {{ (currentPage * pageSize) + i + 1 }}
              </div>
              <div *ngIf="column.columnDef === 'fullName'">
                {{ row.firstName }} {{ row.lastName }}
            </div>
            <div *ngIf="column.columnDef === 'status'">
              <div *ngIf="row.status != 'HOD_APPROVED'">
                {{ row.status }} 

              </div>
              <div *ngIf="row.status == 'HOD_APPROVED'">
                APPLICATION_APPROVED 

              </div>
          </div>
              <ng-template #slideToggleTemplate >
  
                <div class="action" *ngIf="column.columnDef === 'actions'">
               
                
                  <button 
                  mat-icon-button 
                  (click)="applyScrutiny(row)"  
                  matTooltip="Scrutiny" 
                  [disabled]="row.activeFlag === 'N'"
                 >
                  <mat-icon>remove_red_eye</mat-icon>
                </button>

                <button 
                mat-icon-button 
                (click)="downloadForm(row)"  
                matTooltip="download" 
                [disabled]="row.status != 'ADMINISTRATIVE_OFFICER_APPROVED'"
               >
                <mat-icon>download</mat-icon>
              </button>

                 
                 
                </div>
              
              </ng-template>
            </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
  
        <!-- <mat-paginator [pageSizeOptions]="[20,40,60]" showFirstLastButtons></mat-paginator> -->
        <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" showFirstLastButtons aria-label="Select page">
        </mat-paginator>
        <ngx-skeleton-loader *ngIf="!contentLoaded" count="4" appearance="line"
          animation="progress-dark"></ngx-skeleton-loader>
        <h3 *ngIf="lifeCert.data.length == 0 && contentLoaded">No data available.</h3>
  
  
      </div>
  
  
    </mat-card-content>
  
  </mat-card>



