import { Component } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { CommonService } from "src/app/_services/Common-Services/common.service";
import { CustomValidatorService } from "src/app/_services/custom-validator.service";
import { UsersService } from "src/app/_services/master-data/users.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent {
  form: FormGroup;
  currentStep: number = 0;
  isLoading = false;

  showPassword: boolean = false;

  countdown: number = 180; // 180 seconds
  timer: any;
  isTimerActive: boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private regService: UsersService,
    private _snackBar: SnackbarMsgService,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    if (localStorage.getItem("userId")) {
      this.router.navigate(["/dashboard"]); // Stay on dashboard if authenticated
    }

    this.form = this.fb.group(
      {
        username: ["", Validators.required],
        otp: ["", Validators.required],
        // currentPassword: ['', Validators.required],
        newPassword: [
          "",
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(12),
            this.passwordComplexityValidator,
          ],
        ],
        confirmNewPassword: ["", Validators.required],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  ngOnDestroy() {
    // Clean up the timer when the component is destroyed
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  requestOTP() {
    const username = this.form.get("username")?.value;
    if (username) {
      this.isLoading = true;
      this.regService.requestOTP({ userName: username }).subscribe({
        next: (res: any) => {
          if (res["status"] === "Success") {
            this.currentStep = 1;
            this.startCountdown();
            console.log("OTP request successful");
            this._snackBar.success(
              "OTP has been sent successfully. Please check your phone."
            );
          } else if (res["status"] === "No") {
            this._snackBar.error(
              "Your mobile number is not registered. Please verify the number you entered."
            );
          } else {
            this._snackBar.error(
              "There was an error sending the OTP. Please try again."
            );
          }
        },
        error: () => {
          this._snackBar.error(
            "There was an error sending the OTP. Please try again."
          );
        },
        complete: () => {
          // Set loading to false once request is complete
          this.isLoading = false;
        },
      });
    }
  }

  validateNumberInput(event: KeyboardEvent) {
    const allowedKeys = [
      "Backspace",
      "Tab",
      "Enter",
      "ArrowLeft",
      "ArrowRight",
    ];
    if (
      !allowedKeys.includes(event.key) &&
      (event.key < "0" || event.key > "9")
    ) {
      event.preventDefault(); // Prevent the input if it's not a number or allowed key
    }
  }

  validateNumericInput(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  }

  allowPaste(event: ClipboardEvent) {
    // const clipboardData = event.clipboardData;
    // const pastedData = clipboardData.getData('text');

    // if (!/^\d*$/.test(pastedData)) {
    //   event.preventDefault();
    // }
    event.preventDefault();
  }

  passwordComplexityValidator(control: any) {
    const password = control.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumeric = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const valid = hasUpperCase && hasNumeric && hasSpecialChar;

    return !valid ? { complexity: true } : null;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  startCountdown() {
    this.isTimerActive = true;
    this.countdown = 180; // Reset countdown to 180 seconds
    this.timer = setInterval(() => {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.stopCountdown();
      }
    }, 1000);
  }

  stopCountdown() {
    clearInterval(this.timer);
    this.isTimerActive = false;
  }

  verifyOTP() {
    const userName = this.form.get("username")?.value;
    const otp = this.form.get("otp")?.value;

    const payload = {
      userMobile: userName,
      otp: otp,
    };

    this.regService.verifyMobileOTP(payload).subscribe((res: any) => {
      if (res["status"] === "Success") {
        this.currentStep = 2;
        console.log("OTP verification successful");
        this._snackBar.success("OTP verified successfully.");
      } else if (
        res["status"] === "Failed" &&
        res["message"] === "Invalid OTP"
      ) {
        this._snackBar.error(
          "The OTP you entered is incorrect. Please check and try again."
        );
      } else if (
        res["status"] === "Failed" &&
        res["message"] === "OTP not found"
      ) {
        this._snackBar.error("The OTP has expired. Please request a new OTP.");
      } else {
        this._snackBar.error("Invalid OTP.");
      }
    });
  }

  handleSubmit() {
    const userName = this.form.get("username")?.value;
    const newPassword = this.form.get("newPassword")?.value;
    const confirmNewPassword = this.form.get("confirmNewPassword")?.value;

    console.log(userName, newPassword, confirmNewPassword);

    const payload = {
      userName: userName,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };

    this.regService.updateCitizenPassword(payload).subscribe((res: any) => {
      if (res["status"] === "Success") {
        this.currentStep = 3;
        console.log("Password updated successfully");
        this._snackBar.success("Password updated successfully.");
        this.redirectToLogin();
      } else {
        this._snackBar.error(res["message"]);
      }
    });
  }

  goBack() {
    if (this.currentStep === 0) {
      this.redirectToLogin();
    } else {
      this.currentStep--;
    }
  }

  redirectToLogin() {
    this.router.navigate(["/login"]);
  }

  passwordMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const newPassword = control.get("newPassword");
    const confirmNewPassword = control.get("confirmNewPassword");

    if (
      newPassword &&
      confirmNewPassword &&
      newPassword.value !== confirmNewPassword.value
    ) {
      control.get("confirmNewPassword")?.setErrors({ mismatch: true });
      return { mismatch: true };
    } else {
      control.get("confirmNewPassword")?.setErrors(null);
      return null;
    }
  }

  savePassword() {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        component: "Update Password",
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === "success") {
        const userName = this.form.get("userName")?.value;
        const usrPassword = this.form.get("usrPassword")?.value;
        this.commonService
          .savePassword(userName, usrPassword)
          .subscribe((res) => {
            if (res["status"] === "success") {
              this._snackBar.success("Password Updated Successfully");

              setTimeout(() => {
                this.router.navigate(["/login"]);
              }, 1000);
            } else if (res["status"] === "Failure") {
              this._snackBar.error(res["message"]);
            } else {
              this._snackBar.error("Password Updation Failed");
            }
          });
      }
    });
  }
}
