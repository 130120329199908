import { Component, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { RtsService } from 'src/app/_services/rts.service';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WardService } from 'src/app/_services/ward.service';
import { ZoneService } from 'src/app/_services/zone.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-transfer-ward',
  templateUrl: './transfer-ward.component.html',
  styleUrls: ['./transfer-ward.component.scss']
})
export class TransferWardComponent implements OnInit{
  applications:any;
  dataSource:any;
  deptId=localStorage.getItem('dptId');
  zones:any;
  selectedZone:any;
  wards:any;
  selectedValues:any;
  selectionForm!: FormGroup;
  constructor(public dialogRef: MatDialogRef<TransferWardComponent>,@Inject(MAT_DIALOG_DATA) public item: any,private RtsService:RtsService,
  private fb: FormBuilder,private wardService : WardService,
  private zoneService : ZoneService,
  private dialog : MatDialog){
  }

  ngOnInit(){
    this.selectionForm = this.fb.group({
      id:[""],
      // zoneId : ["",Validators.required],
      transferHistory:["",Validators.required],
      wardId : ["",Validators.required]
    });
    this.getZone()
  }
 
  
  getZone(){
    this.zoneService.getZone().then((data : any) => {
      this.zones = data['zoneMasterList']
    })
  }

  getWard(event : any){
    this.selectedZone = event
    this.wardService.getWardByZone(this.selectedZone).then((data : any) => {
      this.wards = data
    })
  }

  onWardSelection(){
    this.selectedValues = true
  }
  

  selectionSave(){
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode : 'Add',
        component : 'transfer'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.selectionForm.get('id').setValue(this.item.id);
      if(this.selectionForm.valid){
        this.RtsService.transferward(this.selectionForm.value).subscribe(res=>{
          if(res['status']=='SUCCESS'){
            this.dialogRef.close();
          }
        })
    }
        this.selectionForm.disable()
      }
    })

    
  }
  

  cancelSelection(){
    this.dialogRef.close();
  }
}
