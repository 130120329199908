<div class="bg-white p-6">
  <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
    close
  </mat-icon>

  <h1 class="text-2xl font-bold mb-8">{{ addedit ? 'Edit' : 'Add' }} Docket Entry</h1>

  <form (ngSubmit)="saveMeeting()" #commiteeForm="ngForm">
    <div class="flex gap-20 ">
      <div class="w-full">
        <mat-form-field class="w-full">
          <mat-label for="departmentSelect">Select Department</mat-label>
          <mat-select [(ngModel)]="departmentId" name="departmentId" id="departmentSelect"
            (ngModelChange)="departmentChange($event)" >
            <mat-option *ngFor="let department of departments" [value]="department.id">
              {{ department.committeeName }}
            </mat-option>
            <mat-option *ngIf="departments?.length === 0" [value]="'noOptions'">No options available</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="relative z-0 w-full mb-5">
          <label for="subjectDate" class="block text-gray-600"> Subject Date</label>
          <input [(ngModel)]="subjectDate" type="date" name="subjectDate" id="subjectDate"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" />
        </div>

        <mat-form-field class="w-full">
          <mat-label for="summery">Subject Summary</mat-label>
          <textarea matInput [(ngModel)]="subjectSummary" id="summery" name="subjectSummary" required=""></textarea>
        </mat-form-field>

        <div class="relative z-0 w-full mb-5">
          <!-- <input [(ngModel)]="financialYear" type="year" name="financialYear" id="financialYear"
            class="w-full border rounded-md px-4 py-2 focus:outline-none focus:border-blue-400" required="" /> -->
            <app-year-picker (selectYear)="onSelectYear($event)" required ></app-year-picker>

        </div>

        <mat-form-field class="w-full">
          <mat-label for="amount">Amount</mat-label>
          <input matInput [(ngModel)]="ammount" type="number" id="docketNo" name="ammount" required />
        </mat-form-field>

    
        <div>
          <label for="rctUpload" class="block text-sm font-medium text-gray-900 dark:text-white">Incident Documents</label>
          <button (click)="fileInput.click()" type="button"
                  class="w-full border rounded-md px-4 py-2 bg-blue-500 text-gray-100 focus:outline-none focus:border-blue-400">
            Upload
          </button>
          <input [hidden]="true" [(ngModel)]="uploadDocument" #fileInput type="file" (change)="onFileChange($event)" name="my_file" id="my-file" multiple
       accept="image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">

          <span *ngFor="let document of documents">&nbsp;{{ document.fileName }}</span>
        </div>
        <!-- <span *ngIf="addedit">
          
        <span  *ngFor="let document of prvdocuments">&nbsp;{{ document.fileName }}</span> 
      </span> -->
       
      </div>

      <div class="w-full">
        <mat-form-field class="w-full">
          <mat-label for="docketNo">Docket No</mat-label>
          <input matInput [(ngModel)]="docketNo" type="number" id="docketNo" name="docketNo" required />
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="subject">Subject</mat-label>
          <input matInput [(ngModel)]="subject" id="subject" name="subject" required="">
        </mat-form-field>

        <mat-form-field class="w-full">
          <mat-label for="committeeSelect">Select Committee</mat-label>
          <mat-select [(ngModel)]="committeeId" name="committeeId" id="committeeSelect"
            (ngModelChange)="committeeChange($event)" required="">
            <mat-option *ngFor="let committee of committees" [value]="committee.id">
              {{ committee.committeeName }}
            </mat-option>
            <mat-option *ngIf="committees?.length === 0" [value]="'noOptions'">No options available</mat-option>
          </mat-select>
        </mat-form-field>

        
        <mat-form-field class="w-full">
          <mat-label for="meetingSelect">Select Meeting Type</mat-label>
          <mat-select [(ngModel)]="meetingTypeId" name="meetingTypeId" id="meetingSelect"
            (ngModelChange)="meetingTypeChange($event)" required="">
            <mat-option *ngFor="let meetingType of meetingTypes" [value]="meetingType.id">
              {{ meetingType.meetingTypeName }}
            </mat-option>
            <mat-option *ngIf="meetingTypes?.length === 0" [value]="'noOptions'">No options available</mat-option>
          </mat-select>
        </mat-form-field>

     
        <mat-form-field class="w-full">
          <mat-label for="status">Status</mat-label>
          <input matInput [(ngModel)]="status" name="status" id="status" required="" />
        </mat-form-field> 

        <mat-form-field class="w-full">
          <mat-label for="remark">Remark</mat-label>
          <input matInput [(ngModel)]="remark" name="remark" id="remark" required="" />
        </mat-form-field>

      </div>
    </div>
    <button type="submit"
      class="w-full disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none"
      [disabled]="!commiteeForm.valid">
      Save
    </button>
  </form>
</div>
