import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddFieldDialogComponent } from './add-field-dialog/add-field-dialog.component';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss'],
})
export class FormBuilderComponent {
  @Output() formArray = new EventEmitter<string>();
  formFields: any[] = [];
  showSequence:boolean=true;
  newField: any = {
    field_name: '',
    field_label:'',
    field_type: 'text',
    field_options:{
      required: false,
      choices: '',
      placeholder:''
    },
    field_sequence: 0,
  };

  // Define MatTableDataSource to work with mat-table
  dataSource = new MatTableDataSource(this.formFields);
  displayedColumns: string[] = ['field_name',
  // 'field_label', 
  'field_type', 'field_options.required', 'field_sequence','field_options.placeholder', 'field_options.choices', 'action'];
  formConfig: any = {};

  constructor(private dialog: MatDialog) {}

  openAddFieldDialog(): void {
    const dialogRef = this.dialog.open(AddFieldDialogComponent, {
      width: '400px',
      data: { newField: this.newField },
    });
  
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        
        // console.log(result)

        // Check if field_options.choices is present and a string before splitting
        if (result.newField.field_options && typeof result.newField.field_options.choices === 'string') {
          result.newField.field_options.choices = result.newField.field_options.choices.split(',');
        } else {
          result.newField.field_options.choices = [];
        }

        if (result.newField.field_label === "") {
          result.newField.field_label = result.newField.field_name;
        }        
        this.formFields.push(result.newField);
        this.newField = {
          field_name: '',
          field_label: '',
          field_type: 'text',
          field_options: {
            required: false,
            choices: [],
            placeholder: '',
          },
          field_sequence: 0,
        };
        this.dataSource.data = this.formFields;
        this.generateJSON();
      }
    });
  }
  

  removeField(index: number): void {
    this.formFields.splice(index, 1);
    this.dataSource.data = this.formFields;
  }

  generateJSON(): void {
    const formConfig = {
      form_name: '',
      form_description: '',
      fields: this.formFields,
    };

    this.formConfig = formConfig;

    this.formArray.emit(this.formConfig)
  }
}
