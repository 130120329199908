<div class="flex h-screen overflow-hidden">
  <!-- Left Side with Image -->
  <div
    class="hidden md:flex w-0 md:w-3/5 bg-cover bg-center"
    style="background-image: url('assets/NMMC-Banner.jpg')"
  ></div>

  <!-- Right Side with Registration Form -->
  <div
    class="flex items-center justify-center w-full md:w-2/5 h-screen bg-gray-50 relative"
  >
    <div class="absolute inset-0 flex items-center justify-center">
      <div class="w-full max-w-md p-6 bg-white shadow-lg rounded-lg">
        <img
          src="../../assets/nmmc.jpg"
          alt="NMMC Logo"
          class="mx-auto w-32 h-32 sm:w-40 sm:h-40 md:w-44 md:h-44 mb-4"
        />
        <div class="text-2xl font-semibold mb-4 text-center">
          Navi Mumbai Municipal Corporation
        </div>

        <h1 class="text-lg sm:text-xl md:text-2xl text-center mb-4">
          {{
            currentStep === 0
              ? "Enter Mobile No"
              : currentStep === 1
              ? "Verify OTP"
              : "Reset Password"
          }}
        </h1>

        <form [formGroup]="form" (ngSubmit)="handleSubmit()" class="space-y-4">
          <!-- Username Step -->
          <div *ngIf="currentStep === 0">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Mobile No</mat-label>
              <input
                matInput
                formControlName="username"
                placeholder="Enter Mobile No"
                maxlength="10"
                (input)="validateNumericInput($event)"
                (copy)="allowPaste($event)"
                (paste)="allowPaste($event)"
                (cut)="allowPaste($event)"
                autocomplete="off"
              />
              <mat-error *ngIf="form.controls['username'].hasError('pattern')">
                Please enter a valid mobile number.
              </mat-error>
            </mat-form-field>

            <button
              mat-raised-button
              type="button"
              (click)="requestOTP()"
              [disabled]="isLoading"
              class="custom-button mt-4"
            >
              <ng-container *ngIf="!isLoading; else loading">
                Request OTP
              </ng-container>
              <ng-template #loading>
                <mat-spinner
                  diameter="20"
                  class="text-white"
                  style="color: white"
                ></mat-spinner>
              </ng-template>
            </button>

            <button
              mat-raised-button
              color="accent"
              type="button"
              (click)="redirectToLogin()"
              class="w-full mt-2"
            >
              Go Back to Login
            </button>
          </div>

          <!-- OTP Verification Step -->
          <div *ngIf="currentStep === 1">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Enter OTP</mat-label>
              <input
                matInput
                formControlName="otp"
                placeholder="Enter OTP"
                type="text"
                maxlength="6"
                (keypress)="validateNumberInput($event)"
                inputmode="numeric"
                required
              />
              <mat-error *ngIf="form.get('otp').hasError('pattern')"
                >Only numbers are allowed.</mat-error
              >
              <mat-error *ngIf="form.get('otp').hasError('maxlength')"
                >OTP cannot exceed 6 digits.</mat-error
              >
            </mat-form-field>

            <button
              mat-raised-button
              type="button"
              (click)="verifyOTP()"
              class="custom-button mt-4"
            >
              Verify OTP
            </button>

            <!-- Resend OTP Button, hidden until countdown ends -->
            <button
              mat-raised-button
              type="button"
              (click)="requestOTP()"
              class="custom-button mt-2"
              *ngIf="!isTimerActive"
            >
              Resend OTP
            </button>

            <!-- Countdown message -->
            <div *ngIf="isTimerActive" class="mt-2 flex justify-center">
              <span class="text-gray-600"
                >You can resend OTP in {{ countdown }} seconds</span
              >
            </div>

            <button
              mat-raised-button
              color="accent"
              type="button"
              (click)="goBack()"
              class="w-full mt-2"
            >
              Go Back
            </button>
          </div>

          <!-- Password Reset Step -->
          <div *ngIf="currentStep === 2">
            <mat-form-field appearance="fill" class="w-full">
              <mat-label>New Password</mat-label>
              <input
                matInput
                formControlName="newPassword"
                [type]="showPassword ? 'text' : 'password'"
                placeholder="New Password"
                required
              />
              <mat-error *ngIf="form.get('newPassword')?.hasError('required')">
                Password is required.
              </mat-error>
              <mat-error *ngIf="form.get('newPassword')?.hasError('minlength')">
                Password must be at least 6 characters long.
              </mat-error>
              <mat-error *ngIf="form.get('newPassword')?.hasError('maxlength')">
                Password cannot exceed 12 characters.
              </mat-error>
              <mat-error
                *ngIf="form.get('newPassword')?.hasError('complexity')"
              >
                Password must include at least one uppercase letter, one number,
                and one special character.
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-full">
              <mat-label>Confirm New Password</mat-label>
              <input
                matInput
                formControlName="confirmNewPassword"
                [type]="showPassword ? 'text' : 'password'"
                placeholder="Confirm New Password"
                required
              />
              <mat-error
                *ngIf="form.get('confirmNewPassword')?.hasError('required')"
              >
                Confirmation is required.
              </mat-error>
              <mat-error *ngIf="form.hasError('mismatch')">
                Passwords do not match.
              </mat-error>
              <mat-hint
                class="text-green-600"
                *ngIf="
                  !form.hasError('mismatch') &&
                  form.get('confirmNewPassword')?.value &&
                  form.get('newPassword')?.value
                "
              >
                Passwords match!
              </mat-hint>
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="toggleShowPassword()"
              >
                <mat-icon>{{
                  showPassword ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
            </mat-form-field>

            <button
              mat-raised-button
              style="background-color: #015296; color: white"
              class="w-full mt-4"
              type="submit"
              [disabled]="form.invalid"
            >
              Reset Password
            </button>

            <button
              mat-raised-button
              color="accent"
              type="button"
              (click)="goBack()"
              class="w-full mt-2"
            >
              Go Back
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
