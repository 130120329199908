import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenserviceService {

  constructor(private httpClient: HttpClient) { }

  // Method to get the token
  getToken(): string | null {
    return localStorage.getItem('token'); // Assuming the token is stored in local storage
  }

  refreshToken(refreshToken: string): Observable<any> {

    const body = {
      refreshToken: refreshToken
    };

    return this.httpClient
      .post(`${environment.commonUrl}/auth/refreshToken`, body);
  }

  storeToken(token){
    return localStorage.getItem('token');
  }

  storeRefreshToken(refreshToken){
    return localStorage.getItem('refreshToken');
  }

  // Method to save the token
  // setToken(token: string): void {
  //   localStorage.setItem('token', token);
  // }

  // // Method to remove the token
  // removeToken(): void {
  //   localStorage.removeItem('token');
  // }
}
