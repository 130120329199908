import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-asset-type",
  templateUrl: "./add-asset-type.component.html",
  styleUrls: ["./add-asset-type.component.scss"],
})
export class AddAssetTypeComponent {
  assetTypeForm:FormGroup
  assetTypeName: string = "";
  activeFlag: any = "Y";
  addedit: boolean;
  id: any;
  progressSpinnerDialog: any = null;

  constructor(
    private fb: FormBuilder,
    private assetService: AssetServiceService,
    private _snackbar: SnackbarMsgService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddAssetTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {
    this.addedit = data1["isEditMode"];
    this.assetTypeForm = this.fb.group({
      id: [" "],
      assetTypeName: ['', [Validators.required, Validators.maxLength(50),Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
      activeFlag: ['Y'],
      createdUserId: [" "],
      updateUserId: [" "]
    });
    if (this.addedit) {
      const data12 = data1["row"];
      this.assetTypeName = data12["assetTypeName"];
      this.id = data12["id"];
    }
  }

  saveAsset() {
    if(this.assetTypeForm.valid){
    const data = {
      id: this.id,
      assetTypeName: this.assetTypeName.trim(),
      activeFlag: this.activeFlag,
    };

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit ? "Edit" : "Add",
        Component: "Asset Type",
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.displayProgressSpinner(); 
        this.assetService.saveAssetType(data).subscribe(          
          (res) => {
            if ((res["status"].toLowerCase() == "success")) {
              const message = this.addedit ? "Record Updated Successfully" : "Record Added Successfully";
              
              this._snackbar.success(message);
              setTimeout(() => {
                this.closeProgressSpinner();
                this.dialogRef.close({ data: "Success" });
              }, 1000);
            }
            else{   
              this.closeProgressSpinner();   
              this._snackbar.error(res['message']);
            }
          },
        );
      }
    });
  }
}


async displayProgressSpinner() {
  this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
      disableClose: true,
      panelClass: "progressSpinner",
  });
}

async closeProgressSpinner() {
if(this.progressSpinnerDialog) {
  this.progressSpinnerDialog.close();
}
}
}
