import { Component } from '@angular/core';
import { DatePipe, Location} from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ScrutinyActionsConfirmationComponent } from 'src/app/CWM/cwm-dashboard/cwm-app-scrutiny/scrutiny-actions-confirmation/scrutiny-actions-confirmation.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { HrmsServiceService } from 'src/app/_services/HRMS/hrms-service.service';
import { CustomValidatorService } from 'src/app/_services/custom-validator.service';
import { DocumentViewerModalComponent } from 'src/app/document-viewer-modal/document-viewer-modal.component';


@Component({
  selector: 'app-life-certificate-transaction-scrutiny',
  templateUrl: './life-certificate-transaction-scrutiny.component.html',
  styleUrls: ['./life-certificate-transaction-scrutiny.component.scss'],
  providers : [DatePipe]
})
export class LifeCertificateTransactionScrutinyComponent {
  applicationData : any[] = []
  applicationForm! : FormGroup
  appId : any = ''
  appStatus: any = ''
  state : any[] = []
  action : any = ''
  remark : any = ''
  applicationDataInfo : any[] = []
  loanApplicationData : any[] = []
  id : any = ''
  createdUserId : any = ''
  updateUserId : any = ''
  userId = localStorage.getItem('userId')
  departments : any[] = []
  designations : any[] = []
  userActions : any = ''
  scrutinyProcessForm! : FormGroup
  showLifeCertificate : boolean = false
  showMedicalCertificate : boolean = false
  showMarriageCertificate : boolean = false
  documentList : any[] = []

  payScales : any[] = []
  contentLoaded = true;
  assignStage : any[] = [];
  workflowData : any




  totalUnpaidYears : any = 0
  totalUnpaidMonths : any = 0
  totalUnpaidDays : any = 0
  familyPensionReleaseDate : any = ''
  dateOfDeath : any = ''
  familyPensionAmmount : any = ''
  scrutinyProcessFormApproved! : FormGroup
  scrutinyProcessFormRejected! : FormGroup

  accTypes : any[] = [
    {
      id : 1,
      accType : 'Saving'
    },
    {
      id : 1,
      accType : 'Current'
    }
  ]
  branchData : any[] = []
  bankData : any[] = []

 
  constructor(
    private fb: FormBuilder,
    private hrmsService: HrmsServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location : Location,
    private router: Router,
    private snackbar : MatSnackBar,
    private customValidator : CustomValidatorService,
    private designationService : CommonService,
    private datePipe : DatePipe,
    private commonService : CommonService
  ) {

  }

  address : any = ''

  ngOnInit(): void {
   
    this.userId = localStorage.getItem("userId");
    this.createdUserId = this.userId;
    this.updateUserId = this.userId;
    this.state = history.state;
    this.workflowData = localStorage.getItem("workflow") ? JSON.parse(localStorage.getItem("workflow")) : null;
    this.getBank()
    this.getBranc()

    this.applicationForm = this.fb.group({
      firstName: ['', Validators.required],
      middleName: ['', Validators.required],
      pensionerName: ['', Validators.required],
      lastName : ['', Validators.required],
      gender: ['', Validators.required],
      mobile :  ["",[Validators.required, Validators.pattern('[0-9]{10}')]],
      email: ["", [Validators.required, Validators.email,this.customValidator.customEmailValidator()]],
      adharNo : ['',Validators.required],
      panNo : ['',[Validators.required, Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]],
      address: ['', Validators.required],
      bankName: ['', Validators.required],
      branchName: ['', Validators.required],
      accountType: ['', Validators.required],
      accountNo: ['', Validators.required],
      ifscCode: ['', Validators.required],
      date: ['', Validators.required],
      place: ['', Validators.required],
      currentDateTime:[''],
      currentLocationLongitude:[''],
      currentLocationLatitude:[''],
      photo: [''],
      video: [''],
      createdUserId: [''],
      updateUserId: [''],
      activeFlag: ['Y'],
      id:[""],
      remark : [""],


     
    });

    this.state = history.state;
    this.applicationData.push(this.state['applicationData'])
    this.appStatus = this.applicationData[0]['status']
    this.appId = this.applicationData[0]['id']
    this.getDetails(this.appId)
    this.getWorkflowData()
    this.applicationForm.disable();

    this.scrutinyProcessFormRejected = this.fb.group({
      id : [""],
      rejectRemark : [""],
      rejectRemarkMr : [""]
    })

    this.scrutinyProcessFormApproved = this.fb.group({
      id : [""],
      approveRemark : [""],
      approveRemarkMr : [""],
    })


    this.scrutinyProcessForm = this.fb.group({
      id : [""],
      status : [""],
      approveRemark : [""],
      approveRemarkMr : [""],
      rejectRemark : [""],
      rejectRemarkMr : [""]
    })
   
  
    if(this.appStatus == 'APPLICATION_CREATED'){
      this.action = "application created"
      this.userActions = "(CLERK APPROVAL)"
      this.scrutinyProcessFormApproved.patchValue({'id':this.appId})
      this.scrutinyProcessFormRejected.patchValue({'id':this.appId})

    }
    if(this.appStatus == 'ADMINISTRATIVE_OFFICER_REJECTED'){
      this.action = "rejected by administrative officer"
      this.userActions =  "(CLERK APPROVAL)"
      this.scrutinyProcessFormApproved.patchValue({'id':this.appId})
      this.scrutinyProcessFormRejected.patchValue({'id':this.appId})
    }
    if(this.appStatus == 'APPROVED_BY_CLEARK'){
      this.action = "approved by clerk"
      this.userActions = "(Administrative Officer APPROVAL)"
      this.scrutinyProcessFormApproved.patchValue({'id':this.appId})
      this.scrutinyProcessFormRejected.patchValue({'id':this.appId})
    
    }

    if(this.appStatus == 'REJECTED_BY_CLEARK'){
      this.action = "rejected by clerk"
      // this.userActions = "(Administrative Officer APPROVAL)"
      this.scrutinyProcessFormApproved.patchValue({'id':this.appId})
      this.scrutinyProcessFormRejected.patchValue({'id':this.appId})
    
    }
    if(this.appStatus == 'ADMINISTRATIVE_OFFICER_APPROVED'){
      this.action = "approved by administrative officer"
      this.userActions = "(APPLICATION APPROVED)"
      this.scrutinyProcessFormApproved.patchValue({'id':this.appId})
      this.scrutinyProcessFormRejected.patchValue({'id':this.appId})
    }
        
   
  

  }

  getBank() {
    this.commonService.getBank().subscribe(
      res => {
        this.bankData = res as any;
      },
      err => {
      }
    );
  }
 
  getBranc() {
    this.commonService.getBankBranch().subscribe(
      res => {
        this.branchData = res as any;
      },
      err => {
      }
    );
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  
  getDetails(id) {
    this.hrmsService.getByIdlifeCertificate(id).subscribe(
      res => {
        this.loanApplicationData = res  as any[]
        this.patchForm()
      });
  }

  patchForm() {
    this.id = this.loanApplicationData['id'];
    this.createdUserId = this.loanApplicationData['createdUserId'];
    this.updateUserId = this.userId;
    this.applicationForm.patchValue(this.loanApplicationData);
      this.applicationForm.patchValue(
        {
          'date' : this.datePipe.transform(this.loanApplicationData['date'],'yyyy-MM-dd')

        }
      )
      this.documentList = this.loanApplicationData['documentList']

    this.applicationForm.disable();

  }

  viewDocument(filePath : any){
    var fullFilePath = 'https://15-206-219-76.nip.io:8090' + '/api/file/preview?filePath=' + filePath
    window.open(fullFilePath, '_blank');
   
  }
  
  approve() {
    const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
      width: "550px",
      data: {
        text: 'Are you sure you want to approve Life Certificate.',
        status: 'Approval for Life Certificate' + this.userActions,
        component: 'Life Certificate'
      },
      autoFocus: false,
      restoreFocus: false,
    });

    
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result['status'] == "success") {

          this.scrutinyProcessFormApproved.patchValue(
            {'approveRemark' : result.remark,
            'approveRemarkMr' : result.remark,
            }
          )
        
 


      


       

        this.hrmsService.lifeCertificateScrutinyApproval(this.scrutinyProcessFormApproved.value).subscribe((res) => {
          if (res['status'] == "success") {
            this._snackBar.success("Approved Successfully.");
            this.location.back()
          } else {
            this._snackBar.error("Approval Failed");
          }
        },
        (err : any) => {
          this._snackBar.error("Approval Failed");

        });
      }
    });
  }

  reject() {
    const dialogRef = this.dialog.open(ScrutinyActionsConfirmationComponent, {
      width: "550px",
      data: {
        text: 'Are you sure you want to reject Life Certificate.',
        status: 'Rejection for Life Certificate' + this.userActions,
        component: 'Life Certificate'
      },
      autoFocus: false,
      restoreFocus: false,
    });


    dialogRef.afterClosed().subscribe((result: any) => {
      if (result['status'] == "success") {

          this.scrutinyProcessFormRejected.patchValue(
            {'rejectRemark' : result.remark,
            'rejectRemarkMr' : result.remark,
            }
          )
        

     

        this.hrmsService.lifeCertificateScrutinyReject(this.scrutinyProcessFormRejected.value).subscribe((res) => {
          if (res['status'] == "success") {
            this._snackBar.success("Rejected");
            this.location.back()
          } else {
            this._snackBar.error("Rejection Failed");
          }
        },
        (err : any) => {
          this._snackBar.error("Rejection Failed");

        });
      }
    });
  }




cancel(){
  this.location.back()
}
  
showSnackBar(message: string, type: 'success' | 'error') {
  this.snackbar.open(message, 'Close', {
      duration: 3000,
      panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
  });
  }


  viewDocuments(base64Data: string, docName: string): void {
    const byteString = atob(base64Data.split(',')[1]);
    const mimeType = base64Data.split(',')[0].split(':')[1].split(';')[0];
  
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([ab], { type: mimeType });
  
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
    setTimeout(() => URL.revokeObjectURL(blobUrl), 100);
  }


  getWorkflowData(){
    if (this.workflowData && this.workflowData.length > 0) {
    this.assignStage = [];
  
    this.workflowData.forEach((workflow) => {
      if (workflow["Life Certificate"]) {
        workflow["Life Certificate"].forEach(detail => {
          const stages = detail.stage.split(',').map(stage => stage.trim()).filter(stage => stage); 
          this.assignStage.push(...stages);
        });
      }
    });
  
    this.assignStage = Array.from(new Set(this.assignStage)); 
    console.log(this.assignStage);
  } else {
    console.error("workflowData or worfFlowDetails is undefined");
  }
}
}



