import { ChangeDetectorRef, Component } from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { HrmsServiceService } from 'src/app/_services/HRMS/hrms-service.service';
import { CustomValidatorService } from 'src/app/_services/custom-validator.service';
import { WebcamImage } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LifeCertificateDownloadCopyComponent } from '../life-certificate-download-copy/life-certificate-download-copy.component';
import { Overlay } from '@angular/cdk/overlay';
import { MarriageCertificateDownloadCopyComponent } from '../marriage-certificate-download-copy/marriage-certificate-download-copy.component';
import { ServiceCertificateDownloadCopyComponent } from '../service-certificate-download-copy/service-certificate-download-copy.component';
import { ViewNoticeComponent } from './view-notice/view-notice.component';
import { DocumentViewMobileComponent } from 'src/app/shared/component/document-view-mobile/document-view-mobile.component';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-edit-life-certificate-transaction',
  templateUrl: './add-edit-life-certificate-transaction.component.html',
  styleUrls: ['./add-edit-life-certificate-transaction.component.scss'],
  providers : [DatePipe]
})
export class AddEditLifeCertificateTransactionComponent {
  applicationForm: FormGroup;
  pensionerSearchForm : FormGroup;
  pensionerDataFound : boolean = false
  addedit: boolean;
  userId: string;
  id: any;
 
  state : any[] = []
  action : any = ''
  userActions : any = ''
  bankData : any[] = []
  branchData : any[] = []
  accTypes : any[] = [
    {
      id : 1,
      accType : 'Saving'
    },
    {
      id : 2,
      accType : 'Current'
    }
  ]
  uploadClicked : boolean = false
 

  showWebcam: boolean = false;
  capturedImage: string | ArrayBuffer = null;
  trigger: Subject<void> = new Subject<void>();
  showLifeCertificate : boolean = false
  showMedicalCertificate : boolean = false
  showMarriageCertificate : boolean = false
  showMedical : boolean = false
  showServiceCerificate : boolean = false
  showNOC : boolean = false
  docList : any[] = []
  uploadedDocs : any[] = []

   // Media Constraints to use front camera only
   public videoOptions: MediaTrackConstraints = { facingMode: 'user'};
   title = 'निवृत्तीवेतन / कुटूंब निवृत्तीवेतनधारकास महत्वाच्या सूचना';
   points = [
     'दरवर्षी 1 नोव्हेंबर ते 30 नोव्हेंबरपर्यंत सर्व निवृत्तीवेतनधारकास / कुटूंब निवृत्तीवेतन धारक यांनी हयात असल्याबाबत नवी मुंबई महानगरपालिका मुख्यालय सामान्य़ प्रशासन विभागाने आपल्या मोबाईल क्रमांकावर पाठविलेल्या लिंक व्दारे परीपूर्ण स्वरूपातील हयातीचा दाखला पाठविणे बंधनकारक आहे. अन्यथा हयातीचा दाखला मुदतीमध्ये प्राप्त़ न झाल्यास अथवा अपूर्ण स्वरूपात असल्यास डिसेंबर महिन्यापासून (December Paid in January) निवृत्तीवेतन तात्पुरते स्थ़गित करण्यात येईल याची कृपया नोंद घ्यावी.',
     'सदर हयातीच्या कोऱ्या दाखल्याची मूळ प्रत समजून, छायांकित प्रत काढून बँक शाख व्य़वस्थापकाची अथवा नोंदणीकृत डॉक्ट़र अथवा सक्षम अधिकाऱ्याची सही, शिक्का व तारखेसह साक्षांकित केलेला हयातीचा दाखला वरील नमूद कालावधीत सादर करावा.',
     'बँक शाखा व्य़वस्थापक – ज्या बँकेतून निवृत्तीवेतनाचे अधिदान होत आहे त्या बँकेच्या कोणत्याही शाखेचा व्य़वस्थापक.',
     'नोंदणीकृत डॉक्ट़र (MBBS) – बँकेत जाणास असमर्थ असणाऱ्या निवृत्तीवेतनधारकांनी डॉक्टरांचे साक्षांकन आणल्यास सेाबत त्याच डॉक्टरांचे लेटर हेडवरील बँकेत जाण्यास असमर्थ असण्याबाबत वैद्यकीय प्रमाणपत्र जोडणे आवश्य़क असेल.',
     'हयातीचा दाखला सादर करण्याच्या कालावधीमध्ये निवृत्तीवेतनधारक/कुटूंब निवृत्तीवेतनधारक  भारताबाहेर असल्यास कॉन्स्युलेट जनरल ऑफ इंडिया (Constable General of India) हे त्या देशातील भारतीय राजपत्रित अधिकारी असल्याने त्यांच्या कार्यालयाकडून हयातीचा दाखला साक्षांकित करून पाठविण्यात यावा किंवा भारतातील ज्या बँकेतून निवृत्तीवेतनाचे अधिदान होत आहे त्या बँकेच्या त्या देशातील शाखेच्या व्यवस्थापकाकडून साक्षांकित करून पाठविण्यात यावा.',
     'हयातीचा दाखला साक्षांकित करताना अधिकाऱ्याने स्वाक्षरी सोबत दिनांक नमूद करणे अनिवार्य आहे, साक्षांकनाच्या दिनांकापासून हयातीचा दाखला 30 दिवसांत सादर करणे बंधनकारक आहे.'
   ];

   showMore = false;
   selectedLanguage = 'mr';

  

  constructor(
    private fb: FormBuilder,
    private hrmsService: HrmsServiceService, 
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    private snackbar : MatSnackBar,
    private customValidator : CustomValidatorService,
    private uploadService :FileUploadService,
    private commonService : CommonService,
    private location : Location,
    private router : Router,
    private overlay : Overlay,
    private datePipe : DatePipe,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService

  ) {
    this.translate.setDefaultLang('en'); // Default language

  }

  ngOnInit() {
    
    this.userId = localStorage.getItem("userId");
    this.state = history.state;
    this.addedit = this.state['isEditMode']
    this.getBank()
    this.getDocuments();

    // this.getBranc()
    this.pensionerSearchForm = this.fb.group({
      panNo: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]]
    })

    this.applicationForm = this.fb.group({
      firstName: ['',[Validators.required]],
      middleName:[null],
      pensionerName: ['', [Validators.required]],
      pensionerId : [null],
      lastName : ['', [Validators.required]],
      gender: ['', Validators.required],
      mobile :  ['',[Validators.required, Validators.pattern('^[6-9][0-9]{9}$')]],
      email: [null, [ Validators.email]],
      adharNo : ['', [Validators.required,Validators.pattern(/^(?:\d{12}|\d{4} \d{4} \d{4}|\d{4}-\d{4}-\d{4})$/)]],
      panNo : ['',[Validators.required, Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]],
      address: ['', Validators.required],
      bankName: ['',[Validators.required]],
      branchName: ['', [Validators.required]],
      accountType: ['', Validators.required],
      accountNo: ['', Validators.required],
      ifscCode: ['', [Validators.required,Validators.pattern(/^[A-Z]{4}0[A-Z0-9]{6}$/)]],
      date: [''],
      place: [''],
      currentDateTime:[''],
      currentLocationLongitude:[''],
      currentLocationLatitude:[''],
      createdUserId: [''],
      updateUserId: [''],
      activeFlag: ['Y'],
      marathiOrEnglish : ['en',Validators.required]
      // id:[""]


     
    });

    this.setLanguageValidators(this.selectedLanguage); // Set validators based on language
    this.changeLanguage('mr')

    this.applicationForm.patchValue(
      {
        'accountType' : 'Saving'
      }
    )

    if (this.addedit) {
      this.action = 'Edit'
      this.userActions = 'Update'
      // const leaveTypeData = this.data['row'];
      const confReportData = []
      confReportData.push(this.state['applicationData'])
      this.id = confReportData['id'];
   
      this.getDetails(this.id)

      
    }else {
      this.action = 'Add'
      this.userActions = 'Add new'
      this.applicationForm.patchValue({"createdUserId":this.userId})

    }
    this.applicationForm.patchValue(
      {
        'updateUserId' : this.userId
      }
    )
    
    this.getCurrentLocationDateTimeStamp()
    this.restoreFormData(); // Call to restore form data on component initialization

  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.setLanguageValidators(lang); // Update validators based on the selected language

    if(lang == 'mr'){
      this.applicationForm.patchValue(
        {
          'marathiOrEnglish' : 'mr'
        }
      )
    }else {
      this.applicationForm.patchValue(
        {
          'marathiOrEnglish' : 'en'
        }
      )
    }
   
    this.translate.use(lang);
  }

  setLanguageValidators(language: string): void {
    if (language === 'en') {
      // Apply English validators
      this.applicationForm.get('firstName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
      this.applicationForm.get('middleName').setValidators([Validators.pattern("^[a-zA-Z ]*$")]);
      this.applicationForm.get('pensionerName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
      this.applicationForm.get('lastName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
      this.applicationForm.get('bankName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
      this.applicationForm.get('branchName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z ]*$")]);
    } else if (language === 'mr') {
      this.applicationForm.get('firstName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z\u0900-\u097F ]*$")]);
    this.applicationForm.get('middleName').setValidators([Validators.pattern("^[a-zA-Z\u0900-\u097F ]*$")]);
    this.applicationForm.get('pensionerName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z\u0900-\u097F ]*$")]);
    this.applicationForm.get('lastName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z\u0900-\u097F ]*$")]);
    this.applicationForm.get('bankName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z\u0900-\u097F ]*$")]);
    this.applicationForm.get('branchName').setValidators([Validators.required, Validators.pattern("^[a-zA-Z\u0900-\u097F ]*$")]);
  }

    

    // Revalidate after setting validators
    this.applicationForm.updateValueAndValidity();
  }

  formData : any
  getDetails(id) {
    this.hrmsService.getByIdlifeCertificate(id).subscribe(
      res => {
        this.formData = res
        this.patchForm()
      }
    )

  }

  patchForm() {
    this.applicationForm.patchValue(this.formData)
    this.applicationForm.patchValue(
      {
        'updateUserId' : this.userId,
        'accountType' : 'Saving',
     
      }
    )
  
  }

  isValidEmp : boolean = false
  getEmpDetails() {
    this.hrmsService.getDetailsByEmployeeId(this.applicationForm.get('pensionerId').value).subscribe(
      (res) => {

        if(res['employeeId'] == null){
          this.showSnackBar('Error: Employee not found.Please enter correct Employee Id and fetch details', 'error');
          this.resetForm() // Reset the form
          this.isValidEmp = false

        }else {
          let formData = res;
          this.applicationForm.patchValue(formData);
          if(formData['accountType'] === 'Savings'){
            this.applicationForm.patchValue(
              {
                'accountType' : 'Saving'
              }
            )
          }
          this.applicationForm.patchValue(
            {
              'emailId' : formData['email'],
              'mobile' : formData['mobileNo'],
              'dateOfBirth' :  formData['birthDate'],
              'adharNo' : formData['aadharNo'],
              'departmentId' : formData['depId'],
              'lastDateOfService' : formData['retirementDate'],
              'activeFlag' : 'Y',
              'pensionerName' : formData['firstName'] + ' ' + formData['lastName'],
              'pensionerId' : formData['employeeId']
  
            }
          )
          

          if(this.applicationForm.get('bankId').value != null){
            this.getBranc(this.applicationForm.get('bankId').value)

          }

          this.isValidEmp = true
        }
      


      },
      (err) => {

      }
    )

  }

  resetForm() {
    this.applicationForm.reset(); // Reset the form
    this.applicationForm.markAsPristine(); // Mark the form as pristine
    this.applicationForm.markAsUntouched(); // Mark the form as untouched
    Object.keys(this.applicationForm.controls).forEach(key => {
      this.applicationForm.get(key)?.setErrors(null); // Clear errors for each form control
    });
  }

  getBank() {
    this.commonService.getByActiveFlagBank().subscribe(
      res => {
        this.bankData = res as any;
      },
      err => {
      }
    );
  }

  getBranc(bankId : any) {
    this.commonService.getBankBranchByBankId(bankId).subscribe(
      res => {
        this.branchData = res as any;
      },
      err => {
      }
    );
  }


  uploadLifeCrtificate(event : any){
    for(var i = 0; i < event.target.files.length;i++){
    //   this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          
    //     this.applicationForm.patchValue({'digitalSignature':JSON.parse(data).filePath})

    // })
    this.uploadedDocs.push(event.target.files[i])
    this.showLifeCertificate = true
    }
    this.docList.push('lifeCertificate')

  }

  uploadServiceCrtificate(event : any){
    for(var i = 0; i < event.target.files.length;i++){
    //   this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          
    //     this.applicationForm.patchValue({'digitalSignature':JSON.parse(data).filePath})

    // })
    this.uploadedDocs.push(event.target.files[i])
    this.showServiceCerificate = true
    this.uploadClicked = true

    }
    this.docList.push('serviceCertificate')

  }

  uploadMarriageCrtificate(event : any){
    for(var i = 0; i < event.target.files.length;i++){
    //   this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          
    //     this.applicationForm.patchValue({'digitalSignature':JSON.parse(data).filePath})

    // })
    this.uploadedDocs.push(event.target.files[i])
    this.showMarriageCertificate = true
    this.uploadClicked = true

    }
    this.docList.push('marriageCertificate')
  }

  uploadNOC(event : any){
    for(var i = 0; i < event.target.files.length;i++){
    //   this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          
    //     this.applicationForm.patchValue({'digitalSignature':JSON.parse(data).filePath})

    // })
    this.uploadedDocs.push(event.target.files[i])
    this.showNOC = true

    }
    this.docList.push('nocDoc')

  }

  uploadMedical(event : any){
    for(var i = 0; i < event.target.files.length;i++){
    //   this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          
    //     this.applicationForm.patchValue({'digitalSignature':JSON.parse(data).filePath})

    // })
    this.uploadedDocs.push(event.target.files[i])
    this.showMedical = true

    }
    this.docList.push('medicalCertificate')

  }

  toggleWebcam() {
    this.showWebcam = !this.showWebcam;
    if (!this.showWebcam) {
      // Reset captured image when hiding webcam
      this.capturedImage = null;
    }
  }

  // handleImage(webcamImage: WebcamImage) {
  //   // Handle the captured image here
    
  //   this.capturedImage = webcamImage.imageAsDataUrl;
  //   this.showWebcam = false; // Hide the webcam feed after capturing the image
     
  // }

  handleImage(webcamImage: WebcamImage) {
    // Create a canvas element to flip the image
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Set the canvas size to match the captured image size
    const img = new Image();
    img.src = webcamImage.imageAsDataUrl;
    
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;

        // Flip the context horizontally
        context?.scale(-1, 1);
        // Draw the image with the flipped context
        context?.drawImage(img, -img.width, 0);

        // Get the flipped image data URL
        this.capturedImage = canvas.toDataURL();

        // Hide the webcam feed after capturing the flipped image
        this.showWebcam = false;
    };
}




  captureImage() {
    this.trigger.next(); // Trigger capturing image
    this.saveAndSendImage()
  }

  uploadedLivePhoto : File
  showPhoto : boolean = false
  // saveAndSendImage() {

  //   if (this.capturedImage) {
  //     const blob = this.dataURItoBlob(this.capturedImage as string); // Cast to string
  //     const file = new File([blob], 'webcam_image.jpg');
  //     this.uploadedLivePhoto = file
       
  //       this.uploadedDocs.push(this.uploadedLivePhoto)
  //       this.docsUploaded.push(this.uploadedLivePhoto)
  //       this.docList.push('photo')
  //       this.showPhoto = true

  //         // this.uploadService.uploadFile(file).then((data : any) => {
          
  //       // this.applicationForm.patchValue({'photo':JSON.parse(data).filePath})

  //   // })
    
  //     // this.applicationForm.patchValue(
  //     //   {
  //     //     'photo' : this.uploadedLivePhoto
  //     //   }
  //     // )
  //     // You can now use 'file' like any other file input
  //     // Send the 'file' through your API using HttpClient
  //   }
  // }

  saveAndSendImage() {
    if (this.capturedImage) {
      const blob = this.dataURItoBlob(this.capturedImage as string); // Cast to string
      const file = new File([blob], 'webcam_image.jpg');
      this.uploadedLivePhoto = file;
  
      // const reader = new FileReader();
      // reader.onload = (e: any) => {
      //   const base64String = e.target.result.split(',')[1];
        
      //   this.uploadedDocs.push(base64String);
      //   this.docsUploaded.push(base64String);
      //   this.docList.push('webcam_image');
      //   this.showPhoto = true;
      // };
      
      // reader.readAsDataURL(file);
       // Convert the base64 data to a Blob
     
      
      // Call readImage to handle the captured file
      this.readImage(file);
    }
  }

  readImage(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = async () => {
        await this.addLocationToImage(img, file.name); // Pass the original file name
      };
    };
    reader.readAsDataURL(file);
  }
  

  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  
  save() {
    this.uploadClicked = true


    if (this.applicationForm.valid) {
      if(this.docList.includes('Declaration Certificate') && this.docList.includes('Bank NOC')){
        const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
          width: '550px',
          data: {
            mode: this.addedit ? 'Edit' : 'Add',
            component: 'Life Certificate'
          },
          autoFocus: false,
          restoreFocus: false
        });
    
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result === 'success') {
            this.applicationForm.patchValue(
              {
                'date' : this.datePipe.transform(new Date(this.applicationForm.get('date').value),'yyyy-MM-dd HH:mm:ss'),
                'currentDateTime' : this.datePipe.transform(new Date(),'yyyy-MM-dd HH:mm:ss'),
        
              }
            )
            const mergedData = {
              ...this.applicationForm.value,
              docList : this.docList,
              documents: this.docsUploaded
          };
            this.hrmsService.savelifeCertificate(mergedData)
            .subscribe(
              (res) => {
                if (res['status'] === 'success') {
                  if(this.addedit == false){
                    this.showSnackBar('Life Certificate added successfully', 'success');
      
                  }else {
                    this.showSnackBar('Life Certificate updated successfully', 'success');
      
                  }
                  setTimeout(() => {
                    this.location.back()
                  }, 1000);
                } else if(res['message'] == "Pan Already exits !!!"){
                  this.showSnackBar('Life Certificate added successfully', 'success');
    
                }else {
                  if(this.addedit == false){
                    this.showSnackBar('Error: Life Certificate addition failed', 'error');
      
                  }else {
                    this.showSnackBar('Error: Life Certificate updation failed', 'error');
      
                  }                  }
              },
              (err) => {
                if(this.addedit == false){
                  this.showSnackBar('Error: Life Certificate addition failed', 'error');
    
                }else {
                  this.showSnackBar('Error: Life Certificate updation failed', 'error');
    
                }                }
            );
          }
        });
      }else {
        if(!this.docList.includes('Declaration Certificate')){
          this.showSnackBar('Error:Please upload declaration certificate', 'error');
        }else if(!this.docList.includes('Bank NOC')){
          this.showSnackBar('Error:Please upload Bank NOC document', 'error');
        }else {
          this.showSnackBar('Error:Please upload mandatory documents', 'error');

        }
      }
   
     
    
    }else {
      this.showSnackBar('Error: Invalid details in Life Certificate form', 'error');

    }
   
  }

  showSnackBar(message: string, type: 'success' | 'error') {
    this.snackbar.open(message, 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
    });
    }

    cancel(){
      this.location.back()
    }

    keyPress(event: any) {
      const pattern = /[0-9\+\-\ ]/;
      let inputChar = String.fromCharCode(event.charCode);
      if (event.keyCode != 8 && !pattern.test(inputChar)) {
        event.preventDefault();
      }

      const input = event.target as HTMLInputElement;
      const value = input.value;



      // Restrict the first digit to be 6 or higher
      if (value.length === 0 && parseInt(event.key) < 6) {
        event.preventDefault();
      }
    }



    // openLife(){
    //   this.router.navigate(['/hrms-module/life-certificate-form'],{ state: { applicationData: [],isEditMode: false }})

    // }

  

    openLife() {
      const dialogRef = this.dialog.open(LifeCertificateDownloadCopyComponent, {
        width: "98%",
        height:"90%",
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: {
          isEditMode: false,
        },
      });
    
      dialogRef.afterClosed().subscribe((result: any) => {
       
      });
    }

    openService(){
      const dialogRef = this.dialog.open(ServiceCertificateDownloadCopyComponent, {
        width: "98%",
        height:"90%",
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: {
          isEditMode: false,
        },
      });
    
      dialogRef.afterClosed().subscribe((result: any) => {
       
      });
    }

    openMarriage(){
      const dialogRef = this.dialog.open(MarriageCertificateDownloadCopyComponent, {
        width: "98%",
        height:"90%",
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: {
          isEditMode: false,
        },
      });
    
      dialogRef.afterClosed().subscribe((result: any) => {
       
      });
    }
  
    initForm(): void {
      this.applicationForm = this.fb.group({
        firstName: ['', Validators.required],
        middleName: ['', Validators.required],
        pensionerName: ['', Validators.required],
        lastName : ['', Validators.required],
        gender: ['', Validators.required],
        mobile :  ["",[Validators.required, Validators.pattern('[0-9]{10}')]],
        email: ["", [Validators.required, Validators.email,this.customValidator.customEmailValidator()]],
        adharNo : ['',Validators.required],
        panNo : ['',[Validators.required, Validators.pattern(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/)]],
        address: ['', Validators.required],
        bank: ['', Validators.required],
        branch: ['', Validators.required],
        accountType: ['', Validators.required],
        accountNo: ['', Validators.required],
        ifscCode: ['', Validators.required],
        date: ['', Validators.required],
        place: [''],
        createdUserId: [''],
        updateUserId: [''],
        activeFlag: ['Y'],
        // id:[""]
  
  
       
      });
    }

    restoreFormData(): void {
      const savedFormData = sessionStorage.getItem('yourFormKey');
      if (savedFormData) {
        const parsedFormData = JSON.parse(savedFormData);
        this.applicationForm.patchValue(parsedFormData);
      }
    }

    address : any = ''
    latitude : any = ''
    longitude : any = ''
    getCurrentLocationDateTimeStamp(){
       this.hrmsService.getCurrentPosition().subscribe(
      (position: GeolocationPosition) => {
        this.applicationForm.patchValue(
          {
            'currentLocationLatitude' :  position.coords.latitude,
            'currentLocationLongitude' : position.coords.longitude,
          }
        )

        this.latitude = position.coords.latitude
        this.longitude = position.coords.longitude
     
        this.hrmsService.getAddress(position.coords.latitude, position.coords.longitude).subscribe(
          (res: any) => {
        
          if(res.status == 'success'){
            this.address = res.address
            this.applicationForm.patchValue(
              {
                'place' :  this.address,
              }
            )
          }
        },
        (err: any) => {
        })
       
      },
      (error: any) => {
      }
    );
    this.applicationForm.patchValue(
      {
        'currentDateTime' : this.datePipe.transform(new Date(),'yyyy-MM-ddTHH:mm:ss'),
        'date' : this.datePipe.transform(new Date(),'yyyy-MM-dd'),
        
      
      }
    )

    


    }
  
    saveFormData(): void {
      const formData = this.applicationForm.value;
      sessionStorage.setItem('yourFormKey', JSON.stringify(formData));
    }

    documentsData : any[] = []
    showDocument: { [key: string]: boolean } = {};
    docsUploaded : any[] = []

    // getDocuments(): void {
    //   let tranName = "Life Certificate";
  
    //   let moduleName = "HR and Payroll Management";
    //   this.commonService
    //     .getDocsByModuleAndTransactionName(moduleName, tranName)
    //     .subscribe(
    //       (data: any) => {
    //         if (data["status"] === "Failed") {
    //           this.documentsData = [];
    //           // this._snackBar.error("No data found");
    //         } else {
    //           this.documentsData = data;
    //           this.documentsData.forEach((doc) => {
    //             if (!this.uploadedDocs[doc.id]) {
    //               this.uploadedDocs[doc.id] = [];
    //             }
    //             if(doc.docName == 'Marriage Certificate' || doc.docName == 'Service Certificate' ||  doc.docName == 'NOC'){
    //               doc.mandatory = true

    //             }else {
    //               doc.mandatory = false

    //             }
    //             this.showDocument[doc.id] = false;

    //           });
    //           console.log(this.documentsData)
    //         }

    //       },
    //       (err) => {
    //       }
    //     );
    // }
  
    // uploadDocument(event: any, docName: string) {
    //   const files = event.target.files;
  
    //   if (!this.uploadedDocs[docName]) {
    //     this.uploadedDocs[docName] = [];
    //   }
  
    //   for (let i = 0; i < files.length; i++) {
    //     this.uploadedDocs[docName].push(files[i]);
    //     this.docsUploaded.push(files[i])
    //   }
  
    //   this.showDocument[docName] = true;
  
    //   if (!this.docList.includes(docName)) {
    //     this.docList.push(docName);
    //   }
  
      
    // }

    getDocuments(): void {
      const tranName = "Life Certificate";
      const moduleName = "HR and Payroll Management";
      const mandatoryDocs = ["Marriage Certificate", "Service Certificate", "Bank NOC", "Declaration Certificate"]; // Centralize document names
    
      this.commonService
        .getDocsByModuleAndTransactionName(moduleName, tranName)
        .subscribe(
          (data: any) => {
            if (data.status === "Failed") {
              this.documentsData = [];
              // this._snackBar.error("No data found");
            } else {
              this.documentsData = data.map((doc) => {
                // Set mandatory flag based on document name
                doc.mandatory = mandatoryDocs.includes(doc.docName);
                if (!this.uploadedDocs[doc.id]) {
                  this.uploadedDocs[doc.id] = [];
                }
                this.showDocument[doc.id] = false;
                return doc;
              });
              this.documentsData.push(
                {
                  activeFlag : 'Y',
                  docId : null,
                  docName : 'Declaration Certificate',
                  docNameMr : '',
                  id : null,
                  mandatory : true,
                  moduleName : "HR and Payroll Management",
                  moduleNameMr : "HR and Payroll Management",
                  transactionName : "Life Certificate",
                  transactionNameMr : "Life Certificate"                
                }
              )
              // Sort documents: mandatory ones on top
              this.documentsData.sort((a, b) => {
                return b.mandatory - a.mandatory; // True (1) will come before false (0)
              });
    
            }
          },
          (err) => {
            console.error("Error fetching documents:", err);
          }
        );
    }
    

    uploadDocument(event: any, docName: string) {
      const files = event.target.files;
      
      // Initialize the array if it doesn't exist
      if (!this.uploadedDocs[docName]) {
          this.uploadedDocs[docName] = [];
      }
      
      for (let i = 0; i < files.length; i++) {
          const file = files[i];
          
          const reader = new FileReader();
          reader.onload = (e: any) => {
              const base64String = e.target.result.split(',')[1];
              this.docsUploaded.push(base64String);
          };
          reader.readAsDataURL(file);
      }
      
      this.showDocument[docName] = true;
      
      if (!this.docList.includes(docName)) {
          this.docList.push(docName);
      }
      
  
  }
  
  
    viewDocument(docName: string) {
      console.log(this.uploadedDocs)
      console.log(this.uploadedDocs[docName])
      const uploadedFiles = this.uploadedDocs[docName];
      if (uploadedFiles && uploadedFiles.length > 0) {
        const file = uploadedFiles[0];
        console.log(file)
        if (file instanceof Blob) {
          const fileUrl = URL.createObjectURL(file);
          window.open(fileUrl);
        } else {
        }
      } else {
      }
    }
  
    removeDocument(docName: string) {
      delete this.uploadedDocs[docName];
  
      const index = this.docList.indexOf(docName);
      this.showDocument[docName] = false;

      if (index !== -1) {
        this.docList.splice(index, 1);
      } else {
      }
    }

    async addLocationToImage(image: HTMLImageElement, originalFileName: string): Promise < void> {
      const latLongDetails = `Latitude: ${this.applicationForm.get('currentLocationLatitude').value.toFixed(5)}, Longitude: ${this.applicationForm.get('currentLocationLatitude').value.toFixed(5)}`;
      this.drawImageWithLocation(image, this.address,latLongDetails,originalFileName);
    //   if(navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(
    //     async (position) => {
    //       const latitude = position.coords.latitude;
    //       const longitude = position.coords.longitude;

    //       try {
    //         const address = this.address
    //         const latLongDetails = `Latitude: ${latitude.toFixed(5)}, Longitude: ${longitude.toFixed(5)}`;

    //         // Pass the address, lat/long details, and file name to the draw function
    //         await this.drawImageWithLocation(image, address, latLongDetails, originalFileName); // Await drawing to complete
    //       } catch (error) {
    //         console.error('Error fetching address:', error);
    //         await this.drawImageWithLocation(image, 'Location unavailable', 'Location unavailable', originalFileName);
    //       }
    //     },
    //     (error) => {
    //       console.error('Error getting location', error);
    //       this.drawImageWithLocation(image, 'Location unavailable', 'Location unavailable', originalFileName);
    //     }
    //   );
    // } else {
    //   console.error('Geolocation not supported');
    //   await this.drawImageWithLocation(image, 'Location unavailable', 'Location unavailable', originalFileName);
    // }
  }

  async drawImageWithLocation(image: HTMLImageElement, address: string, latLongDetails: string, originalFileName: string): Promise<void> {
   
  
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return;

    // Set canvas size to the size of the image + space for text (adjusted based on image height)
    const textAreaHeight = image.height * 0.25; // Reserve 20% of the image height for the text area
    canvas.width = image.width;
    canvas.height = image.height + textAreaHeight;

    // Draw the image on the canvas
    ctx.drawImage(image, 0, 0);

    // Create gradient background for the text area
    const gradient = ctx.createLinearGradient(0, image.height, canvas.width, canvas.height);
    gradient.addColorStop(0, 'rgba(0, 0, 0, 0.8)'); // Start with a darker color
    gradient.addColorStop(1, 'rgba(0, 0, 0, 0.5)'); // Fade into a lighter color

    // Draw the gradient background for the text
    ctx.fillStyle = gradient;
    ctx.fillRect(0, image.height, canvas.width, textAreaHeight);

    // Calculate dynamic font size based on both image width and height
    const baseFontSize = Math.max(Math.min(image.width * 0.04, image.height * 0.04), 14); // Font size between 14px and a dynamic value based on image size
    const lineHeight = baseFontSize * 1.3; // Ensure line height is 1.5x the font size for better spacing between lines

    ctx.font = `${baseFontSize}px Arial`;
    ctx.fillStyle = 'white';
    ctx.textAlign = 'left'; // Align text to the left

    // Load and draw the logo image (dynamic logo size based on image height)
    const locationIcon = new Image();
    locationIcon.src = 'assets/map_log.png'; // Path relative to assets folder

    locationIcon.onload = () => {
      const logoSize = Math.max(image.height * 0.1, 30); // Dynamic logo size with a minimum of 30px
      const logoYPosition = image.height + (textAreaHeight - logoSize) / 2;

      // Draw the logo on the left side of the text area
      ctx.drawImage(locationIcon, 20, logoYPosition, logoSize, logoSize);

      // Adjust the starting position of the text with more space after the logo
      const textStartX = logoSize + 60; // Add more padding after the logo
      const maxTextWidth = canvas.width - textStartX - 20; // Maximum text width after leaving space for the logo

      // Starting Y position for text
      let textYPosition = image.height + logoSize / 2 + 10; // Start drawing text slightly below the logo

      // Function to handle word wrapping
      const wrapText = (text: string, maxWidth: number, x: number, y: number) => {
        const words = text.split(' ');
        let line = '';
        for (let i = 0; i < words.length; i++) {
          const testLine = line + words[i] + ' ';
          const metrics = ctx.measureText(testLine);
          const testWidth = metrics.width;
          if (testWidth > maxWidth && i > 0) {
            ctx.fillText(line, x, y);
            line = words[i] + ' ';
            y += lineHeight; // Move down by the line height after each line
          } else {
            line = testLine;
          }
        }
        ctx.fillText(line, x, y);
        return y + lineHeight; // Return the updated y-position after the text is drawn
      };

      // Word wrap and draw the main address
      textYPosition = wrapText(address, maxTextWidth, textStartX, textYPosition);

      // Word wrap and draw the latitude and longitude on the next line
      textYPosition = wrapText(latLongDetails, maxTextWidth, textStartX, textYPosition);

      // Get the current date and time
      const now = new Date();
      const formattedDate = now.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
      const formattedTime = now.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });

      // Create the date and time string in the required format
      const dateTimeText = `As On ${formattedDate} at ${formattedTime}`;

      // Draw the current date and time on the canvas
      wrapText(dateTimeText, maxTextWidth, textStartX, textYPosition); // Draw date and time below the address and lat/long

      // Convert the canvas back to a Blob (binary file)
      canvas.toBlob((blob) => {
        if (blob) {
          const processedFileName = `${originalFileName.split('.').slice(0, -1).join('.')}-processed.png`; // Use the original file name with a suffix

          const file = new File([blob], processedFileName, { type: 'image/png' }); // Create a File from the Blob

        
          this.convertToBase64(file); // Convert to Base64



          this.cdr.detectChanges(); // Ensure change detection

        }
      }, 'image/png');
    };
  }

  base64String : any = ''
  convertToBase64(file: File): void {
    const reader = new FileReader();
    
    reader.onload = () => {
      this.base64String = (reader.result as string).split(',')[1]; // Base64 without metadata
      this.uploadedDocs.push(this.base64String);
      this.docsUploaded.push(this.base64String);
     
      this.docList.push('webcam_image');
      this.showPhoto = true;
    };

    reader.onerror = (error) => {
      console.error('Error: ', error);
    };

  
   
    reader.readAsDataURL(file); // Read file as Data URL (Base64 encoded string)
  }

  searchEmpByPanNo(){
    this.hrmsService.getByPanNoPensionerDetails(this.pensionerSearchForm.get('panNo').value).subscribe((res : any) => {
      if(res.message == null){
        this.patchPensionerDetails(res)
        this.pensionerDataFound = true
      }else {
        this.showSnackBar('Error: ' + res.message, 'error');
        this.pensionerDataFound = true

      }
    })
  }

  patchPensionerDetails(res : any){
    this.applicationForm.patchValue(res)
    this.applicationForm.patchValue(
      {
        pensionerName : res.fullName,
        mobile : res.mobileNumber
      }
    )
  }

  restrictInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    
    // Allow only numbers, spaces, and dashes
    if (!/[0-9\s-]/.test(event.key)) {
      event.preventDefault();  // Prevent invalid characters
    }
    
    // Disallow typing more than 12 digits (ignoring spaces and dashes)
    const digitsOnly = currentValue.replace(/\s|-/g, '').length;
    if (digitsOnly >= 12 && /[0-9]/.test(event.key)) {
      event.preventDefault();  // Prevent additional digit input if 12 digits are reached
    }
  }

  declarationDownloaded : boolean = false
  downloadForm(doc){
    this.hrmsService.downloadDeclarationCertificate().subscribe((res : any) => {
      this.viewDocuments(res.base64Data,res.fileName)
    })
  }

  downloadNocForm(doc){
    if(this.applicationForm.get('panNo').value == null || this.applicationForm.get('panNo').value == ''){
      this.showSnackBar('Please enter Pan No. in pensioner details form.', 'error');

    }else {
      this.hrmsService.downloadNocCertificate(this.applicationForm.get('panNo').value).subscribe((res : any) => {
        if(res.status == 'FAILURE'){
          this.showSnackBar(res.message, 'error');

        }else {
          this.viewDocuments(res.base64Data,res.fileName)

        }
      })
    }

  }

  viewDocuments(base64Data: string, docName: string): void {
    const byteString = atob(base64Data.split(',')[1]);
    const mimeType = base64Data.split(',')[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([ab], { type: mimeType });
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = docName; // Set the file name for download

    // Append to the body
    document.body.appendChild(link);
    link.click(); // Simulate click to start download

    // Cleanup
    setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl); // Release the blob URL

    }, 100);

    setTimeout(() => {
     
      this.showSnackBar(docName + ' downloaded successfully', 'success');

  }, 3000);



  }

  restrictPanInput(event : any){
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    
// Disallow typing more than 12 digits (ignoring spaces and dashes)
const digitsOnly = currentValue.length;
if (digitsOnly >= 10) {
  event.preventDefault();  // Prevent additional digit input if 12 digits are reached
}
  }

  // openInstructionsDialog(): void {
  //   this.dialog.open(ViewNoticeComponent, {
  //     width: '50%',
  //     height : '50%'
  //   });
  // }

  fetchDocumentContent() {
    const fileName =
      "/var/docs/hrms-manuals/User_Manual_Life_Certificate.pdf";

    const imageUrl =
      environment.commonUrl + "/api/file/preview?filePath=" + fileName;
    this.dialog.open(DocumentViewMobileComponent, {
      data: imageUrl,
      maxWidth: "100%",
    });
  }

  toggleShowMore(event: Event): void {
    event.preventDefault();
    this.showMore = !this.showMore;
  }


}

