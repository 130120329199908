import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, filter, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Breadcrumb } from "../property-tax/property-tax.service";
import { dE } from "@fullcalendar/core/internal-common";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private breadcrumbs = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbs.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: Breadcrumb[] = [];
        this.addBreadcrumbs(root, "", breadcrumbs);
        this.breadcrumbs.next(breadcrumbs);
      });
  }

  private addBreadcrumbs(
    route: ActivatedRouteSnapshot,
    url: string,
    breadcrumbs: Breadcrumb[]
  ) {
    if (
      route.routeConfig &&
      route.routeConfig.data &&
      route.routeConfig.data["breadcrumb"]
    ) {
      const breadcrumb = {
        label: route.routeConfig.data["breadcrumb"],
        url: `${url}/${route.routeConfig.path}`,
      };
      breadcrumbs.push(breadcrumb);
    }
    if (route.firstChild) {
      this.addBreadcrumbs(
        route.firstChild,
        url + "/" + route.routeConfig?.path,
        breadcrumbs
      );
    }
  }

  makeAPaymentUser(requestData) {
    return this.http.post(
      `${environment.commonUrl}/api/eazyPay/pay/makePayment1`,
      requestData
    );
  }
  // Area
  getArea() {
    return this.http.get(
      `${environment.commonUrl}/api/master/area/getAllDashboard`
    );
  }
  getByActiveFlagArea() {
    return this.http.get(`${environment.commonUrl}/api/master/area/getAll`);
  }
  activeInactiveArea(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/area/active/${id}`,
      flag
    );
  }
  saveArea(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/area/save`,
      data
    );
  }

  paymentIntegration(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/eazyPay/pay/makePayment`,
      data
    );
  }

  // Bank
  getBank() {
    return this.http.get(`${environment.commonUrl}/api/master/mstBank/getAll`);
  }
  getBankById(id: string) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBank/getById?id=${id}`
    );
  }
  getByActiveFlagBank() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBank/getAllActiveList`
    );
  }
  activeInactiveBank(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstBank/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveBank(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstBank/save`,
      data
    );
  }

  // common bank
  getmstBankAccount() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getAll`
    );
  }
  getByActiveFlagmstBankAccount() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getAllByActiveFlag`
    );
  }
  activeInactivemstBankAccount(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstBankAccount/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  savemstBankAccount(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstBankAccount/save`,
      data
    );
  }

  getById(id) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getById?id=${id}`,
      {}
    );
  }

  // Branch
  getBankBranch() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankBranch/getAll`
    );
  }
  getByActiveFlagBankBranch() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankBranch/getAllActiveList`
    );
  }
  activeInactiveBankBranch(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstBankBranch/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  getBankBranchByBankId(bankId) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankBranch/getByBankId?bankId=${bankId}`,
      {}
    );
  }

  getBranchByBankListIds(bankIds) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankBranch/getBankBranchByBankId?bankIds=${bankIds}`,
      {}
    );
  }

  saveBankBranch(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstBankBranch/save`,
      data
    );
  }

  // Caste
  getCaste() {
    return this.http.get(
      `${environment.commonUrl}/api/master/caste/getAllDashboard`
    );
  }
  getByActiveFlagCaste() {
    return this.http.get(`${environment.commonUrl}/api/master/caste/getAll`);
  }
  activeInactiveCaste(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/caste/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveCaste(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/caste/save`,
      data
    );
  }

  // Category
  getCategory() {
    return this.http.get(
      `${environment.commonUrl}/api/master/category/getAllDashboard`
    );
  }
  getByActiveFlagCategory() {
    return this.http.get(`${environment.commonUrl}/api/master/category/getAll`);
  }
  activeInactiveCategory(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/category/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveCategory(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/category/save`,
      data
    );
  }

  // CFC
  getCFC() {
    return this.http.get(
      `${environment.commonUrl}/api/master/cfccenter/getAllDashBoard`
    );
  }
  getByActiveFlagCFC() {
    return this.http.get(
      `${environment.commonUrl}/api/master/cfccenter/getAll`
    );
  }
  activeInactiveCFC(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/cfccenter/active?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }

  activeInactiveCFCNew(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/cfccenter/active/${id}`,
      flag
    );
  }
  saveCFC(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/cfccenter/save`,
      data
    );
  }

  // City
  getCity() {
    return this.http.get(
      `${environment.commonUrl}/api/master/city/getAllDashboard`
    );
  }
  getByActiveFlagCity() {
    return this.http.get(`${environment.commonUrl}/api/master/city/getAll`);
  }
  activeInactiveCity(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/city/activeInactive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveCity(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/city/save`,
      data
    );
  }

  // Department
  getDepartment() {
    return this.http.get(
      `${environment.commonUrl}/api/master/department/getAllDashboard`
    );
  }
  getByActiveFlagDepartment() {
    return this.http.get(
      `${environment.commonUrl}/api/master/department/getAll`
    );
  }

  getWorksDepartment() {
    return this.http.get(
      `${environment.commonUrl}/api/master/department/getAllWorksDepartment`
    );
  }
  getDepartmentById(id: any) {
    var url =
      `${environment.commonUrl}` + "/api/master/department/getById?id=" + id;
    return this.http.get(url);
  }

  getByFilterActiveFlagDepartment(keyword: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/department/getAll/?keyword=${keyword}`
    );
  }

  activeInactiveDepartment(id, data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/department/active/${id}`,
      data
    );
  }
  saveDepartment(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/department/save`,
      data
    );
  }

  // Designation
  getDesignation() {
    return this.http.get(
      `${environment.commonUrl}/api/master/designation/getAllDashboard`
    );
  }
  getByActiveFlagDesignation() {
    return this.http.get(
      `${environment.commonUrl}/api/master/designation/getAll`
    );
  }
  activeInactiveDesignation(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/designation/active/${id}`,
      flag
    );
  }
  saveDesignation(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/designation/save`,
      data
    );
  }

  // District
  getDistrict() {
    return this.http.get(
      `${environment.commonUrl}/api/master/district/getAllDashboard`
    );
  }
  getDistrictByStateId(stateId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/district/getDistrictByStateId?stateId=${stateId}`
    );
  }
  getByActiveFlagDistrict() {
    return this.http.get(`${environment.commonUrl}/api/master/district/getAll`);
  }
  activeInactiveDistrict(id, data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/district/active/${id}`,
      data
    );
  }
  saveDistrict(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/district/save`,
      data
    );
  }

  // Electoral Ward
  getElectoralWard() {
    return this.http.get(
      `${environment.commonUrl}/api/master/electoralWard/getAllDashboard`
    );
  }
  getByActiveFlagElectoralWard() {
    return this.http.get(
      `${environment.commonUrl}/api/master/electoralWard/getAll`
    );
  }
  activeInactiveElectoralWard(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/electoralWard/activeInactive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveElectoralWard(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/electoralWard/save`,
      data
    );
  }

  // Financial Year
  getFinancialYear() {
    return this.http.get(
      `${environment.commonUrl}/api/master/financialyear/getAllDashboard`
    );
  }
  getByActiveFlagFinancialYear() {
    return this.http.get(
      `${environment.commonUrl}/api/master/financialyear/getAll`
    );
  }
  activeInactiveFinancialYear(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/financialyear/activeInActive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveFinancialYear(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/financialyear/save`,
      data
    );
  }

  // Location
  getLocation() {
    return this.http.get(`${environment.commonUrl}/api/master/location/getAll`);
  }
  getByActiveFlagLocation() {
    return this.http.get(
      `${environment.commonUrl}/api/master/location/getAllByActiveFlag `
    );
  }
  activeInactiveLocation(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/location/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveLocation(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/location/save`,
      data
    );
  }

  // Node
  getNode() {
    return this.http.get(
      `${environment.commonUrl}/api/master/node/getAllDashboard`
    );
  }
  getNodeByZoneId(zoneId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/node/getNodeByZoneId?zoneId=${zoneId}`
    );
  }
  getByActiveFlagNode() {
    return this.http.get(`${environment.commonUrl}/api/master/node/getAll`);
  }
  activeInactiveNode(id, data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/node/active/${id}`,
      data
    );
  }
  saveNode(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/node/save`,
      data
    );
  }

  // Religion
  getReligion() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstReligion/getAll`
    );
  }
  getByActiveFlagReligion() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstReligion/getAllActiveList`
    );
  }
  activeInactiveReligion(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstReligion/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveReligion(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstReligion/save`,
      data
    );
  }

  // Sector
  getSector() {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getAllDashboard`
    );
  }
  getSectorByWard(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByWardId?wardId=${data}`
    );
  }
  getSectorByNode(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByNodeId?nodeId=${data}`
    );
  }
  getByActiveFlagSector() {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getAllSector`
    );
  }
  activeInactiveSector(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/sector/active/${id}`,
      flag
    );
  }
  saveSector(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/sector/save`,
      data
    );
  }

  // State
  getState() {
    return this.http.get(
      `${environment.commonUrl}/api/master/state/getAllDashboard`
    );
  }
  getByActiveFlagState() {
    return this.http.get(`${environment.commonUrl}/api/master/state/getAll`);
  }
  activeInactiveState(id, data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/state/active/${id}`,
      data
    );
  }
  saveState(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/state/save`,
      data
    );
  }

  // Sub Caste
  getSubCaste() {
    return this.http.get(
      `${environment.commonUrl}/api/master/subcaste/getAllDashboard`
    );
  }
  getByActiveFlagSubCaste() {
    return this.http.get(`${environment.commonUrl}/api/master/subcaste/getAll`);
  }
  activeInactiveSubCaste(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/subcaste/activeInActive?activeFlag=${data.activeFlag}&id=${data.id}`,
      null
    );
  }
  saveSubCaste(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/subcaste/save`,
      data
    );
  }

  // Sub Department
  getSubDepartment() {
    var url =
      `${environment.commonUrl}` + "/api/master/subDepartment/getAllDashboard";
    return this.http.get(url);
  }

  getSubDepartmentByDepartment(deptId: any) {
    var url =
      `${environment.commonUrl}` +
      "/api/master/subDepartment/getByDepartment?dptId=" +
      deptId;
    return this.http.get(url);
  }
  getByActiveFlagSubDepartment() {
    return this.http.get(
      `${environment.commonUrl}/api/master/subDepartment/getAll`
    );
  }
  bookSubDepartmentMapping() {
    return this.http.get(
      `${environment.commonUrl}/api/master/bookSubDepartmentMapping/activeInactive`
    );
  }
  activeInactiveSubDepartment(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/subDepartment/active/${id}`,
      flag
    );
  }
  saveSubDepartment(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/subDepartment/save`,
      data
    );
  }

  // Taluka
  getTaluka() {
    return this.http.get(
      `${environment.commonUrl}/api/master/taluka/getAllDashboard`
    );
  }
  getTalukaByDistrictId(districtId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/taluka/getTalukaByDistrictId?districtId=${districtId}`
    );
  }
  getByActiveFlagTaluka() {
    return this.http.get(`${environment.commonUrl}/api/master/taluka/getAll`);
  }
  activeInactiveTaluka(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/taluka/active/${id}`,
      flag
    );
  }
  saveTaluka(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/taluka/save`,
      data
    );
  }

  // User Groups
  getUserGroups() {
    return this.http.get(
      `${environment.commonUrl}/api/master/userGroup/getAllDashboard`
    );
  }
  getByActiveFlagUserGroups() {
    return this.http.get(
      `${environment.commonUrl}/api/master/userGroup/getAll`
    );
  }
  activeInactiveUserGroups(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/userGroup/changeFlag?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveUserGroups(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/userGroup/save`,
      data
    );
  }

  // Users
  getUsers() {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getAllDashboard`
    );
  }

  getCitizenUsers() {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/citizenUsers`
    );
  }

  getDepartmentUsers() {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/departmentUsers`
    );
  }

  getUsersByDeptDesg(dptId: any, designationId: any) {
    var url =
      `${environment.commonUrl}` +
      "/api/master/user/getAllEmployeebyDptIdAndDesId?dptId=" +
      dptId +
      "&designationId=" +
      designationId;

    return new Promise((resolve) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  getUsersByDepartmentId(dptId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getUsersByDepartment?departmentId=${dptId}`
    );
  }

  getuserbydeptdesignation(dptId: any, designationId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getAllEmployeebyDptIdAndDesId?dptId=${dptId}&designationId=${designationId}`
    );
  }
  getEmpForForwardingBySector(
    wardId: any,
    dptId: any,
    subDepartmentId: any,
    designationId: any,
    sectorId: any
  ) {
    return this.http.get(
      `${environment.gmUrl}/api/mst/mstGrievanceEmployeeMapping/getEmpForForwardingBySector?wardId=${wardId}&departmentId=${dptId}&subDepartmentId=${subDepartmentId}&desgId=${designationId}&sectorId=${sectorId}`
    );
  }

  getUserByWardDeptDesg(wardId: any, dptId: any, designationId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getUserByWardDeptDesg?wardId=${wardId}&departmentId=${dptId}&designationId=${designationId}`
    );
  }

  // updateUserStatus(username: string, activeFlag: string) {
  //   const params = new HttpParams()
  //     .set('username', username)
  //     .set('activeFlag', activeFlag);  // Set query params

  //   return this.http.post(`${environment.commonUrl}/auth/toggle-status`, null, { params });
  // }

  updateUserStatus(username: string, activeFlag: "Y" | "N"): Observable<any> {
    const url = `${environment.commonUrl}/auth/toggle-status`;
    const params = {
      username: username,
      activeFlag: activeFlag,
    };

    return this.http.post<any>(url, null, { params });
  }

  getUsersById(id: any) {
    var url = `${environment.commonUrl}` + "/api/master/user/getById?id=" + id;

    return new Promise((resolve) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  getUserById(id: any) {
    var url = `${environment.commonUrl}` + "/api/master/user/getById?id=" + id;
    return this.http.get(url);
  }

  getActiveUsers(desigId: any, wardId: any, deptName: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/designationAndDepartment?designationId=${desigId}&wardId=${wardId}&departmentName=${deptName}`
    );
  }

  getByActiveFlagCfcUsers() {
    var url =
      `${environment.commonUrl}` +
      "/api/master/user/getAllCfcUsers?ugpPrevId=4";
    return this.http.get(url);
  }

  getCfcUsers() {
    var url =
      `${environment.commonUrl}` +
      "/api/master/user/getAllCfcUsers?ugpPrevId=4";

    return new Promise((resolve) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }
  getAllDepartmentUser() {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getAllDepartmentUser`
    );
  }

  getAllCitizenUsers() {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getAllCitizenUsers`
    );
  }

  getDeptUsers() {
    var url =
      `${environment.commonUrl}` + "/api/master/user/getAllDepartmentUser";

    return new Promise((resolve) => {
      this.http
        .get(url)
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err: any) => {
          resolve(err);
        });
    });
  }

  getByActiveFlagUsers() {
    return this.http.get(`${environment.commonUrl}/api/master/user/getAll`);
  }
  activeInactiveUsers(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/user/changeFlag?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveUsers(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/user/save`,
      data
    );
  }

  // Ward
  getWard() {
    var url = `${environment.commonUrl}` + "/api/master/ward/getAllDashboard";
    return this.http.get(url);
  }
  getByActiveFlagWard() {
    var url = `${environment.commonUrl}` + "/api/master/ward/getAll";
    return this.http.get(url);
  }
  getWardByZoneId(zoneId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/ward/getWardByZoneId?zoneId=${zoneId}`
    );
  }
  activateInactivateWard(id, data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/ward/active/${id}`,
      data
    );
  }
  saveWard(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/ward/save`,
      data
    );
  }

  // Zone
  getZone() {
    return this.http.get(
      `${environment.commonUrl}/api/master/zone/getAllDashboard`
    );
  }
  getZoneByTalukaId(talukaId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/zone/getZoneByTalukaId?talukaId=${talukaId}`
    );
  }
  getByActiveFlagZone() {
    return this.http.get(`${environment.commonUrl}/api/master/zone/getAll`);
  }
  activeInactiveZone(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/zone/active/${id}`,
      flag
    );
  }
  saveZone(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/zone/save`,
      data
    );
  }

  getUserByWardLocDeptDesg(params: HttpParams) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getUserByWardLocDeptDesg`,
      { params }
    );
  }

  getUsersByDesignation(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/getUsersByDesignation?designationId=${data}`
    );
  }

  // Document Master
  getDocMaster() {
    return this.http.get(`${environment.commonUrl}/api/master/document/getAll`);
  }

  getDocsByModuleAndTransactionName(modName: any, tranName: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/documentMapping/getDocsByModuleAndTransactionName?modName=${modName}&tranName=${tranName}`
    );
  }

  // getModules() {
  //   return this.http.get(
  //     `${environment.commonUrl}/api/master/moduleTransactionMappingMaster/getModuleNames`
  //   );
  // }

  getTransactions(name: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/moduleTransactionMappingMaster/getByModuleName?name=${name}`
    );
  }
  getByActiveFlagDocMaster() {
    return this.http.get(
      `${environment.commonUrl}/api/master/document/getAllByActiveFlag`
    );
  }
  activeInactiveDocMaster(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/document/activeInactive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveDocMaster(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/document/save`,
      data
    );
  }

  getDocMasterByTalukaId(talukaId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/document/getById?id=${talukaId}`
    );
  }

  // Document Mapping
  getDocMapping() {
    return this.http.get(
      `${environment.commonUrl}/api/master/documentMapping/getAll`
    );
  }
  getByActiveFlagDocMapping() {
    return this.http.get(
      `${environment.commonUrl}/api/master/documentMapping/getAllByActiveFlag`
    );
  }

  getDocMapsById(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/documentMapping/getById?id=${id}`
    );
  }

  activeInactiveDocMapping(data) {
    return this.http.post(
      `${environment.commonUrl}/api/master/documentMapping/activeInactive?id=${data.id}&activeFlag=${data.activeFlag}`,
      null
    );
  }
  saveDocMapping(data: any) {
    console.log(JSON.stringify(data));
    return this.http.post(
      `${environment.commonUrl}/api/master/documentMapping/save`,
      data
    );
    // return this.http.post(`${environment.commonUrl}/api/master/documentMapping/save`,JSON.stringify(data))
  }

  getDocMappingByTalukaId(talukaId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/documentMapping/getById?id=${talukaId}`
    );
  }

  // title
  getTitleAll() {
    var url = `${environment.commonUrl}` + "/api/master/title/getAll";
    return this.http.get(url);
  }

  getTitle() {
    var url = `${environment.commonUrl}` + "/api/master/title/getAllDashboard";
    return this.http.get(url);
  }
  getByActiveFlagTitle() {
    var url = `${environment.commonUrl}` + "/api/master/title/getAll";
    return this.http.get(url);
  }

  getByIdTitle(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/title/getById?id=${id}`
    );
  }

  activateInactivateTitle(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/title/activeInActive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveTitle(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/title/save`,
      data
    );
  }

  // common node
  getCommonNode() {
    return this.http.get(`${environment.commonUrl}/api/master/mstNode/getAll`);
  }
  getByActiveFlagCommonNode() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstNode/getAllActiveList`
    );
  }
  activeInactiveCommonNode(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstNode/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveCommonNode(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstNode/save`,
      data
    );
  }

  getByIdCommonNode(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstNode/getById?id=${id}`
    );
  }

  // common sector
  getCommonSector() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstSector/getAll`
    );
  }
  getByActiveFlagCommonSector() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstSector/getAllActiveList`
    );
  }
  activeInactiveCommonSector(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstSector/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveCommonSector(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstSector/save`,
      data
    );
  }

  getByIdCommonSector(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstSector/getById?id=${id}`
    );
  }

  // common ward
  getCommonWard() {
    return this.http.get(`${environment.commonUrl}/api/master/mstWard/getAll`);
  }
  getByActiveFlagCommonWard() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstWard/getAllActiveList`
    );
  }
  activeInactiveCommonWard(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstWard/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveCommonWard(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstWard/save`,
      data
    );
  }

  getByIdCommonWard(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstWard/getById?id=${id}`
    );
  }

  // common zone
  getCommonZone() {
    return this.http.get(`${environment.commonUrl}/api/master/mstZone/getAll`);
  }
  getByActiveFlagCommonZone() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstZone/getAllActiveList`
    );
  }
  activeInactiveCommonZone(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstZone/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }
  saveCommonZone(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstZone/save`,
      data
    );
  }

  getByIdCommonZone(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstZone/getById?id=${id}`
    );
  }

  // common zone-ward mapping
  // getCommonZoneWardMapping() {
  //   return this.http.get(
  //     `${environment.commonUrl}/api/master/mstZoneWardMapping/getAllDashboard`
  //   );
  // }
  // getByActiveFlagCommonZoneWardMapping() {
  //   return this.http.get(`${environment.commonUrl}/api/master/mstZoneWardMapping/getAll`);
  // }
  // activeInactiveCommonZoneWardMapping(id, flag) {
  //   return this.http.post(
  //     `${environment.commonUrl}/api/master/mstZoneWardMapping/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
  //     null
  //   );
  // }
  saveCommonZoneWardMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstZoneWardMapping/save`,
      data
    );
  }

  getByIdCommonZoneWardMapping(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstZoneWardMapping/getByZoneId?zoneId=${id}`
    );
  }

  // common ward-node mapping
  // getCommonWardNodeMapping() {
  //   return this.http.get(
  //     `${environment.commonUrl}/api/master/mstWardNodeMapping/getAllDashboard`
  //   );
  // }
  // getByActiveFlagCommonWardNodeMapping() {
  //   return this.http.get(`${environment.commonUrl}/api/master/mstWardNodeMapping/getAll`);
  // }
  // activeInactiveCommonWardNodeMapping(id, flag) {
  //   return this.http.post(
  //     `${environment.commonUrl}/api/master/mstWardNodeMapping/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
  //     null
  //   );
  // }
  saveCommonWardNodeMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstWardNodeMapping/save`,
      data
    );
  }

  //get node by ward id
  getByIdCommonWardNodeMapping(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstWardNodeMapping/getByWardId?wardId=${id}`
    );
  }

  // common node-sector mapping
  // getCommonNodeSectorMapping() {
  //   return this.http.get(
  //     `${environment.commonUrl}/api/master/mstNodeSectorMapping/getAllDashboard`
  //   );
  // }
  // getByActiveFlagCommonNodeSectorMapping() {
  //   return this.http.get(`${environment.commonUrl}/api/master/mstNodeSectorMapping/getAll`);
  // }
  // activeInactiveCommonNodeSectorMapping(id, flag) {
  //   return this.http.post(
  //     `${environment.commonUrl}/api/master/mstNodeSectorMapping/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
  //     null
  //   );
  // }

  saveCommonNodeSectorMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstNodeSectorMapping/save`,
      data
    );
  }

  saveCommonDepartmentDesignationMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstDepartmentDesignationMapping/save`,
      data
    );
  }
  //Get Sector by node id
  getByIdCommonNodeSectorMapping(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstNodeSectorMapping/getByNodeId?nodeId=${id}`
    );
  }

  //Get designation by department id
  getByIdCommonDepartmentDesignationMapping(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstDepartmentDesignationMapping/getByDepartmentId?departmentId=${id}`
    );
  }
  // https://15-206-219-76.nip.io/backend/common/api/master/mstDepartmentDesignationMapping/getByDepartmentIdByActiveFlag?departmentId=018ee68f-db51-75bc-9a64-ace798eccf53
  getByIdCommonDepartmentDesignationMappingByActiveFlag(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstDepartmentDesignationMapping/getByDepartmentIdByActiveFlag?departmentId=${id}`
    );
  }

  //search user by mobile NO
  getUserByMobileNumber(userName: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/user/findUsersByUsername?userName=${userName}`
    );
  }

  //save user module mapping
  saveUserModuleMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstUserModuleMapping/save`,
      data
    );
  }

  getModulesByUserId(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstUserModuleMapping/getByUserId?userId=${id}`
    );
  }

  //save role menu mapping
  saveRoleMenuMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstRoleMenuMapping/save`,
      data
    );
  }

  getMenusByDepartmentIdRoleId(departmentId: any, roleId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstRoleMenuMapping/getByRoleIdAndDepartmentId?roleId=${roleId}&departmentId=${departmentId}`
    );
  }

  getMenusByRoleId(roleId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstRoleMenuMapping/getByRoleId?roleId=${roleId}`
    );
    // return this.http.get<any[]>(`/api/menusByRoleId/${roleId}`);
  }
  //save user role mapping
  saveUserRoleMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstUserRoleMapping/save`,
      data
    );
  }

  getRolesByUserId(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstUserRoleMapping/getByUserId?userId=${id}`
    );
  }

  getBankAccount() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getAll`
    );
  }

  //department module mapping
  saveDepartmentModuleMapping(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstModuleDepartmentMapping/save`,
      data
    );
  }

  getModulesByDeptId(deptId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstModuleDepartmentMapping/getByDepartmentId?departmentId=${deptId}`
    );
    // return this.http.get<any[]>(`/api/menusByRoleId/${roleId}`);
  }

  getByActiveFlagBankAccount() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getAllByActiveFlag`
    );
  }

  getAccountDetailsByBankAndBranchName(bank: any, branch: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getByBankAndBranchName?bankName=${bank}&branchName=${branch}`
    );
  }
  getAccountDetailsByBankAndBranchId(bank: any, branch: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstBankAccount/getByBankAndBranchId?bankId=${bank}&branchId=${branch}`
    );
  }
  //get area by ward
  getAreaByWardId(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/area/getAreaByWard?wardId=${id}`
    );
  }
  //get location by ward and sector
  getLocationByWardAndSector(ward: any, sector: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/location/getLocationByWardAndSector?wardId=${ward}&sectorId=${sector}`
    );
  }

  getLocationByWardNodeAndSector(ward: any, node: any, sector: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/location/getLocationByWardAndNodeAndSector?wardId=${ward}&nodeId=${node}&sectorId=${sector}`
    );
  }

  // ----------------------ROLES AND PERMISSIONS-----------------------------------------------------------------------------------//

  //role master

  getRole() {
    return this.http.get(
      `${environment.commonUrl}/api/master/role/getAllDashboard`
    );
  }

  getRoleWithoutDEptId() {
    return this.http.get(
      `${environment.commonUrl}/api/master/role/getAllRoleWithoutDept`
    );
  }

  getByActiveFlagRole() {
    return this.http.get(`${environment.commonUrl}/api/master/role/getAll`);
  }

  getAllRolesByDepartmentId(departmentId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/role/getAllRolesByDepartmentId?departmentId=${departmentId}`
    );
  }

  activeInactiveRole(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/role/changeFlag?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveRole(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/role/save`,
      data
    );
  }

  //Module master

  getModules() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstModule/getAll`
    );
  }

  getByActiveFlagModules() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstModule/getAllActiveList`
    );
  }

  activeInactiveModules(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstModule/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveModules(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstModule/save`,
      data
    );
  }

  //Menu master

  getMenus() {
    return this.http.get(
      `${environment.commonUrl}/api/master/menu/getAllDashboard`
    );
  }

  getByActiveFlagMenu() {
    return this.http.get(`${environment.commonUrl}/api/master/menu/getAll`);
  }

  getAllMenusByModuleId(moduleId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/menu/getAllMenuListByModuleId?moduleId=${moduleId}`
    );
  }
  activeInactiveMenu(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/menu/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveMenu(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/menu/saveV1`,
      data
    );
  }

  savePassword(userName: any, userPassword: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/user/forgotPassword?userName=${userName} &userPassword=${userPassword}`,
      null
    );
  }

  // navigations and redirections required apis

  getVillageData() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstVillage/getAll`
    );
  }

  getActiveVillageData() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstVillage/getAllByActiveFlag`
    );
  }

  activeInactiveVillage(id, flag) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstVillage/activeInactive?id=${id}&activeFlag=${flag.activeFlag}`,
      null
    );
  }

  saveVillage(data: any) {
    return this.http.post(
      `${environment.commonUrl}/api/master/mstVillage/save`,
      data
    );
  }
  profileApplicationWater(data: any) {
    return this.http.post(
      `${environment.wtUrl}/api/mappingUserWater/save`,
      data
    );
  }
  getWaterByUserId(id) {
    return this.http.get(
      `${environment.wtUrl}/api/mappingUserWater/getAll?userId=${id}`
    );
  }
  //Fetch Bank Details
  getBankBranchDetailsByIFSC(ifscCode: string): Observable<any> {
    return this.http.get(
      `${environment.wtUrl}/api/transactions/common/ifsc/${ifscCode}`
    );
  }

  //Fetch Bank Book And Cash Book Code

  BookCode() {
    return this.http.get(`${environment.faUrl}/api/master/book/getBankBook`);
  }
  CashCode() {
    return this.http.get(`${environment.faUrl}/api/master/book/getCashBook`);
  }

  getByIdCommonWardZoneMapping(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstZoneWardMapping/getByWardId?wardId=${id}`
    );
  }

  getAccountByDepartment(id) {
    return this.http.get(
      `${environment.faUrl}/api/master/account/getAccountByDepartment?departmentId=${id}`
    );
  }

  makeAPayment1(
    applicationId: string,
    paymentAmount: number
  ) {
    const requestData = {
      applicationId: applicationId,
      paymentAmount: paymentAmount  
    };

    return this.http.post(
      `${environment.commonUrl}/api/common-payment-service/create-transaction`,
      requestData
    );
  }

  proceedPayment(paymentData) {
    
    return this.http.post(
      `${environment.commonUrl}/api/common-payment-service/create-transaction`,
      paymentData
    );
  }

  proceedPaymentEazyPay(paymentData) {
    const requestData = {
      applicationId: paymentData.applicationId,
      transactionAmount: paymentData.transactionAmount,
      returnUrl: paymentData.returnUrl,
      moduleId: paymentData.moduleId,
      paymentDescription: paymentData.paymentDescription,
      paymentAmount: 0, // Number
      pgAmount: 0, // BigDecimal as Number
      firstName: localStorage.getItem('loginName'), // String
      lastName: "x", // String
      emailId:"test@gmail.com",
      address: "x", // String
      mobileNo: localStorage.getItem('mobile'), // String
      ward: "x", // String
      department: "x", // String
      serviceName: "x", // String
      billType: "x", // String
      chargesType: "x", // String
      amount: 0, // BigDecimal as Number
      taxes: 0, // BigDecimal as Number
      demandId: '018fc8ce-44ac-737c-832c-7f4bdc4acd5a', // UUID
      propertyNumber: "x",
      waterConsumerNumber: "x",
      transactionName: "x",
      paymentType: paymentData.paymentDescription,
      transaction:paymentData.serviceId,
      extraParam1: paymentData.paymentDescription,
      extraParam2: "x",
      extraParam3: "x"
    };
    return this.http.post(
      `${environment.commonUrl}/api/eazyPay/pay/makePayment1`,
      requestData
    );
  }
  getPaymentUrl(res: any): string {
    return `${environment.commonUrl}/api/common-payment-service/payment-request/${res.transactionId}`;
  }

  showPaymentGateway() {
    return this.http.get(
      `${environment.commonUrl}/api/common-payment-service/getPg`
    );
  }
}
