import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-asset-evaluator",
  templateUrl: "./add-asset-evaluator.component.html",
  styleUrls: ["./add-asset-evaluator.component.scss"],
})
export class AddAssetEvaluatorComponent {
  assetEvaluatorForm: FormGroup;
  effectiveFromDate: any ;
  organizationName:  any ;
  organizationAddress:  any ;
  organizationPhone: any ;
  firstName:  any ;
  middleName:  any ;
  lastName:  any ;
  gender:  any ;
  mobile:  any ;
  email:  any ;
  totalExperience:  any ;
  relevantExperience:  any ; 
  reference:  any ;
  pastProjects:  any ;
  remarks:  any ;
  activeFlag:  any ;
  addedit: any;
  id = null;
  currentDate:any = new Date();
  progressSpinnerDialog: any = null;

  constructor(
    private assetService: AssetServiceService,
    private _snackbar: SnackbarMsgService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddAssetEvaluatorComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {
    this.addedit = data1["isEditMode"];

        this.assetEvaluatorForm = this.fb.group({
          effectiveFromDate: ['', Validators.required],
          organizationName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          organizationAddress: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(/^[a-zA-Z0-9\s]+$/)]],
          organizationPhone: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9\s]+$/)]],
          firstName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          middleName: ['', [Validators.required,Validators.maxLength(30), Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          lastName: ['', [Validators.required, Validators.maxLength(30),Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          mobile: ['', [Validators.required,Validators.maxLength(10), Validators.pattern(/^\d{10}$/)]],
          gender:['',Validators.required],
          email: ['', [Validators.required, Validators.email]],
          totalExperience: ['', [ Validators.maxLength(50)]],
          relevantExperience: ['', [ Validators.maxLength(100)]],
          reference: ['', [Validators.maxLength(100), Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          pastProjects: ['', [Validators.maxLength(100), Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          remarks: ['', [Validators.maxLength(100), Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
          activeFlag: ["Y"],
        });
        
  if (this.addedit) {
    const data12 = data1["row"];
    this.assetEvaluatorForm.patchValue(data12); 
    this.id = data12["id"];
}
}
  

  saveAssetSubCategory() {
    if (this.assetEvaluatorForm.valid) {
    const data = {
      id: this.id,
      effectiveFromDate: this.assetEvaluatorForm.value.effectiveFromDate,
      organizationName: this.assetEvaluatorForm.value.organizationName.trim(),
      organizationAddress: this.assetEvaluatorForm.value.organizationAddress.trim(),
      organizationPhone: this.assetEvaluatorForm.value.organizationPhone,
      firstName: this.assetEvaluatorForm.value.firstName.trim(),
      middleName: this.assetEvaluatorForm.value.middleName.trim(),
      lastName: this.assetEvaluatorForm.value.lastName.trim(),
      gender: this.assetEvaluatorForm.value.gender,
      mobile: this.assetEvaluatorForm.value.mobile,
      email: this.assetEvaluatorForm.value.email,
      totalExperience: this.assetEvaluatorForm.value.totalExperience.trim(),
      relevantExperience: this.assetEvaluatorForm.value.relevantExperience.trim(),
      reference: this.assetEvaluatorForm.value.reference.trim(),
      pastProjects: this.assetEvaluatorForm.value.pastProjects.trim(),
      remarks: this.assetEvaluatorForm.value.remarks.trim(),
      activeFlag: this.assetEvaluatorForm.value.activeFlag, 
    };

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: this.addedit ? "Edit" : "Add",
        Component: "Asset Evaluator",
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.displayProgressSpinner(); 
        this.assetService.saveAssetEvaluator(data).subscribe(
          (res) => {
            if ((res["status"].toLowerCase() == "success")) {
              const message = this.addedit ? "Record Updated Successfully" : "Record Added Successfully";
              this._snackbar.success(message);
              setTimeout(() => {
                this.closeProgressSpinner();
                this.dialogRef.close({ data: "Success" });
              }, 1000);
            }else{   
              this.closeProgressSpinner();   
              this._snackbar.error(res['message']);
            }
          },
        );
      }
    });
  }
}

async displayProgressSpinner() {
  this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
      disableClose: true,
      panelClass: "progressSpinner",
  });
}

async closeProgressSpinner() {
if(this.progressSpinnerDialog) {
  this.progressSpinnerDialog.close();
}
}
}
