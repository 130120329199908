import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-pending-dues-dialog',
  templateUrl: './show-pending-dues-dialog.component.html',
  styleUrls: ['./show-pending-dues-dialog.component.scss']
})
export class ShowPendingDuesDialogComponent {
  action : any = ''
  component : any = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ShowPendingDuesDialogComponent>,) { }

  ngOnInit(): void {
    this.action = this.data.mode
    this.component = this.data.component
  }

  onConfirm() {
    this.dialogRef.close('success');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

