import { Component, ViewChild } from "@angular/core";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GrievanceManagementServiceService } from "src/app/_services/Grievance/grievance-management-service.service";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-grievance-application",
  templateUrl: "./grievance-application.component.html",
  styleUrls: ["./grievance-application.component.scss"],
})
export class GrievanceApplicationComponent {
  @ViewChild(MatSort) sortOpen: MatSort;
  @ViewChild(MatPaginator) paginatorOpen: MatPaginator;
  @ViewChild(MatSort) sortClosed: MatSort;
  @ViewChild(MatPaginator) paginatorClosed: MatPaginator;
  data: any;

  pageNo: number = 0;
  pageSize: number = 10;
  sortBy: string = "id";
  sortDir: string = "asc";

  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.no",
    },
    {
      columnDef: "grievanceCode",
      header: "Grievance Id",
    },
    {
      columnDef: "usrName",
      header: "Complainant Name",
    },
    {
      columnDef: "grievanceDate",
      header: "Grievance Date",
    },
    // {
    //   columnDef: 'grievanceType',
    //   header: 'Complaint Type',
    // },

    // {
    //   columnDef: 'grievanceSubType',
    //   header: 'Complaint SubType',
    // },
    {
      columnDef: "department",
      header: "Department",
    },

    // {
    //   columnDef: 'location',
    //   header: 'Location',
    // },

    // {
    //   columnDef: 'sector',
    //   header: 'Sector',
    // },
    {
      columnDef: "status",
      header: "Status",
    },
  ];

  departments = new MatTableDataSource<any>();
  openGrievances = new MatTableDataSource<any>();
  closedGrievances = new MatTableDataSource<any>();

  displayedColumns: any[] = [];
  contentLoadedOpen = false;
  contentLoadedClosed = false;
  activeTab: any = 0;

  userId: any = "";
  dptId: any = "";
  ugpPrevId: any = "";
  totalOpenGrievances: number = 0;
  totalClosedGrievances: number = 0;

  constructor(
    private _snackBar: SnackbarMsgService,
    private grievanceService: GrievanceManagementServiceService
  ) {}
  ngOnInit() {
    this.userId = localStorage.getItem("userId");
    this.dptId = localStorage.getItem("dptId");
    this.ugpPrevId = localStorage.getItem("ugpPrevId");
    if (this.ugpPrevId == 2) {
      this.columnNames.push({
        columnDef: "actions",
        header: "Actions",
      });
    }
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );

    // this.getData()
    this.getOpenGrievances();
    this.getClosedGrievances();
  }

  // getData() {
  //   this.grievanceService.getAllDptUserGrievances(this.userId).subscribe(
  //     data => {
  //       console.log(data)
  //       const transformedData = data['trnRegisterGrievanceDaoLst'].map(item => {
  //         if (item.grievanceDate) {
  //           item.grievanceDate = this.formatDate(item.grievanceDate);
  //         }
  //         return item;
  //       });

  //       this.departments.data = transformedData;
  //       setTimeout(() => {
  //         // this.departments.sort = this.sort;
  //         // this.departments.paginator = this.paginator;
  //       });

  //       setTimeout(() => {
  //         this.contentLoaded = true;
  //       }, 2000);
  //     },
  //     err => {
  //       this.contentLoaded = true;
  //     }
  //   );
  // }

  getOpenGrievances(pageNo: number = 0, pageSize: number = 10) {
    // Add sortBy and sortDir if your service requires them
    this.grievanceService
      .getMyOpenGrievances(
        this.userId,
        pageNo,
        pageSize,
        this.sortBy,
        this.sortDir
      )
      .subscribe(
        (res) => {
          const transformedData = res["trnRegisterGrievanceDaoLst"].map(
            (item) => {
              if (item.grievanceDate) {
                item.grievanceDate = this.formatDate(item.grievanceDate);
              }
              return item;
            }
          );
          this.openGrievances.data = transformedData;
          this.totalOpenGrievances = res["totalElements"];
          this.openGrievances.data = this.openGrievances.data.sort(
            (a, b) => a.id - b.id
          );
          this.contentLoadedOpen = true;

          setTimeout(() => {
            this.openGrievances.sort = this.sortOpen;
            this.openGrievances.paginator = this.paginatorOpen;
          });
        },
        (err) => {
          console.log(err);
          this.contentLoadedOpen = true;
        }
      );
  }

  onPageChangeOpen(event: PageEvent) {
    this.getOpenGrievances(event.pageIndex, event.pageSize);
  }

  getClosedGrievances(pageNo: number = 0, pageSize: number = 10) {
    // Add sortBy and sortDir if your service requires them
    this.grievanceService
      .getMyClosedGrievances(
        this.userId,
        pageNo,
        pageSize,
        this.sortBy,
        this.sortDir
      )
      .subscribe(
        (res) => {
          const transformedData = res["trnRegisterGrievanceDaoLst"].map(
            (item) => {
              if (item.grievanceDate) {
                item.grievanceDate = this.formatDate(item.grievanceDate);
              }
              return item;
            }
          );
          this.closedGrievances.data = transformedData;
          this.totalClosedGrievances = res["totalElements"];
          this.closedGrievances.data = this.closedGrievances.data.sort(
            (a, b) => a.id - b.id
          );
          this.contentLoadedClosed = true;

          setTimeout(() => {
            this.closedGrievances.sort = this.sortClosed;
            this.closedGrievances.paginator = this.paginatorClosed;
          });
        },
        (err) => {
          console.log(err);
          this.contentLoadedClosed = true;
        }
      );
  }

  onPageChangeClosed(event: PageEvent) {
    this.getClosedGrievances(event.pageIndex, event.pageSize);
  }

  formatDate(date: string): string {
    const d = new Date(date);
    const day = `0${d.getDate()}`.slice(-2);
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.openGrievances.filter = filterValue.trim().toLowerCase();
  }

  applyFilterClosed(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.closedGrievances.filter = filterValue.trim().toLowerCase();
  }
}
