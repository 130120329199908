<div class="py-4 text-center">
  <h1 class="text-3xl md:text-5xl lg:text-6xl font-bold">Welcome to Property Tax Management</h1>
</div>


<!-- <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-5 px-4">
  <a href="/property-tax/add-new-property-registration"
     class="block bg-purple-300 shadow-lg rounded-lg p-4 transform transition-transform hover:scale-102 hover:bg-purple-400">
    <div class="flex items-center">
      <span class="material-icons text-purple-700 text-4xl">home</span>
      <div class="ml-4 flex flex-col justify-center">
        <h2 class="text-xl font-bold text-gray-800 mb-1">New Property Registration</h2>
        <p class="text-gray-600">Click here to register a new property with the municipal corporation.</p>
      </div>
    </div>
  </a>

  <a href="/property-tax/check-application-status"
     class="block bg-teal-300 shadow-lg rounded-lg p-4 transform transition-transform hover:scale-102 hover:bg-teal-400">
    <div class="flex items-center">
      <span class="material-icons text-teal-700 text-4xl">assignment</span>
      <div class="ml-4 flex flex-col justify-center">
        <h2 class="text-xl font-bold text-gray-800 mb-1">Check Application Status</h2>
        <p class="text-gray-600">Click here to check the status of your submitted application.</p>
      </div>
    </div>
  </a>

  <a href="/property-tax/search-property-bill"
     class="block bg-yellow-300 shadow-lg rounded-lg p-4 transform transition-transform hover:scale-102 hover:bg-yellow-400">
    <div class="flex items-center">
      <span class="material-icons text-yellow-700 text-4xl">list</span>
      <div class="ml-4 flex flex-col justify-center">
        <h2 class="text-xl font-bold text-gray-800 mb-1">Search Property Bill</h2>
        <p class="text-gray-600">Click here to search your property bill by entering the property number.</p>
      </div>
    </div>
  </a>

  <a href="/property-tax/ledger-report"
     class="block bg-blue-300 shadow-lg rounded-lg p-4 transform transition-transform hover:scale-102 hover:bg-blue-400">
    <div class="flex items-center">
      <span class="material-icons text-blue-700 text-4xl">account_balance</span>
      <div class="ml-4 flex flex-col justify-center">
        <h2 class="text-xl font-bold text-gray-800 mb-1">Search Property Ledger</h2>
        <p class="text-gray-600">Click here to view your property ledger details.</p>
      </div>
    </div>
  </a>
</div> -->

<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mt-5 px-4">
  <!-- New Property Registration -->
  <a href="/property-tax/add-new-property-registration"
     class="block bg-purple-300 shadow-lg rounded-lg p-3 transform transition-transform hover:scale-102 hover:bg-purple-400">
    <div class="flex items-center">
      <span class="material-icons text-purple-700 text-3xl">home</span>
      <div class="ml-3 flex flex-col justify-center">
        <h2 class="text-lg font-bold text-gray-800 mb-1">New Property Registration</h2>
        <p class="text-sm text-gray-600">Register a new property with the municipal corporation.</p>
      </div>
    </div>
  </a>

  <!-- Check Application Status -->
  <a href="/property-tax/check-application-status"
     class="block bg-teal-300 shadow-lg rounded-lg p-3 transform transition-transform hover:scale-102 hover:bg-teal-400">
    <div class="flex items-center">
      <span class="material-icons text-teal-700 text-3xl">assignment</span>
      <div class="ml-3 flex flex-col justify-center">
        <h2 class="text-lg font-bold text-gray-800 mb-1">Check Application Status</h2>
        <p class="text-sm text-gray-600">Check the status of your submitted application.</p>
      </div>
    </div>
  </a>

  <!-- Search Property Bill -->
  <a href="/property-tax/search-property-bill"
     class="block bg-yellow-300 shadow-lg rounded-lg p-3 transform transition-transform hover:scale-102 hover:bg-yellow-400">
    <div class="flex items-center">
      <span class="material-icons text-yellow-700 text-3xl">list</span>
      <div class="ml-3 flex flex-col justify-center">
        <h2 class="text-lg font-bold text-gray-800 mb-1">Search Property Bill</h2>
        <p class="text-sm text-gray-600">Search your property bill by entering the property number.</p>
      </div>
    </div>
  </a>

  <!-- Search Property Ledger -->
  <a href="/property-tax/ledger-report"
     class="block bg-blue-300 shadow-lg rounded-lg p-3 transform transition-transform hover:scale-102 hover:bg-blue-400">
    <div class="flex items-center">
      <span class="material-icons text-blue-700 text-3xl">account_balance</span>
      <div class="ml-3 flex flex-col justify-center">
        <h2 class="text-lg font-bold text-gray-800 mb-1">Search Property Ledger</h2>
        <p class="text-sm text-gray-600">View your property ledger details.</p>
      </div>
    </div>
  </a>
  

  <!-- My Task -->
  <a href="/property-tax/my-tasks"
     class="block bg-green-300 shadow-lg rounded-lg p-3 transform transition-transform hover:scale-102 hover:bg-green-400">
    <div class="flex items-center">
      <span class="material-icons text-green-700 text-3xl">task</span>
      <div class="ml-3 flex flex-col justify-center">
        <h2 class="text-lg font-bold text-gray-800 mb-1">My Task</h2>
        <p class="text-sm text-gray-600">View and manage your assigned tasks.</p>
      </div>
    </div>
  </a>
</div>

<div class=" p-6">
  <!-- Main Grid Layout -->
  <div class="grid grid-cols-1 md:grid-cols-2 gap-8">

    <!-- Property Status Counts Section -->
    <!-- <div class="bg-white shadow-lg rounded-lg p-8 h-fit">
      <h2 class="text-center text-2xl font-semibold mb-6 text-gray-700">Property Status Overview</h2>
      
      <div class="grid grid-cols-2 md:grid-cols-4 gap-6">
        <div *ngFor="let item of propertyStatusItems" class="bg-blue-50 shadow-md rounded-lg py-4 px-2 text-center">
          <div class="text-3xl font-bold text-blue-600">{{ item.count }}</div>
          <div class="text-gray-600 font-medium text-sm mt-1">{{ item.label }}</div>
        </div>
      </div>
    </div> -->
    <div class="bg-white shadow-md rounded-xl p-8 md:p-12 lg:p-14 max-w-6xl mx-auto h-fit">
      <h2 class="text-center text-3xl lg:text-4xl font-semibold mb-10 text-gray-800">Property Status Overview</h2>
      
      <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
        <div 
          *ngFor="let item of propertyStatusItems" 
          class="bg-white shadow-sm rounded-lg py-6 px-4 text-center transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg"
        >
          <div class="text-4xl font-extrabold text-indigo-600">{{ item.count }}</div>
          <div class="text-gray-700 font-medium text-sm mt-2">{{ item.label }}</div>
        </div>
      </div>
    </div>
    
    

    <!-- Filter and Chart Section -->
    <div class="bg-white shadow-lg rounded-lg p-8">
      <h2 class="text-center text-2xl font-semibold mb-6 text-gray-700">Property Filter and Chart</h2>
      
      <!-- Filter Selection -->
      <select [(ngModel)]="selectedFilter" 
              (ngModelChange)="onFilterChange()" 
              class="w-full border border-gray-300 rounded-md p-2 mb-6 focus:outline-none focus:border-blue-500 transition ease-in-out">
        <option value="wardWise">Ward Wise</option>
        <option value="nodeWise">Node Wise</option>
        <option value="sectorWise">Sector Wise</option>
        <option value="propertyType">Property Type</option>
        <option value="propertySubType">Property Sub Type</option>
      </select>
      
      <!-- Displaying the Chart -->
      <div class="mt-4">
        <apx-chart [chart]="chartOptions.chart" 
                   [series]="chartOptions.series" 
                   [xaxis]="chartOptions.xaxis" 
                   [dataLabels]="chartOptions.dataLabels"
                   [plotOptions]="chartOptions.plotOptions"
                   [yaxis]="chartOptions.yaxis"
                   [title]="chartOptions.title"
                   [labels]="chartOptions.labels">
        </apx-chart>
      </div>
    </div>
  </div>
</div>
