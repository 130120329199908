import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NewLoaderComponent } from 'src/app/WATER-TAX/REPORT/loader-new/new-loader.component';
import { AssetServiceService } from 'src/app/_services/assetService/asset-service.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-asset-category',
  templateUrl: './add-asset-category.component.html',
  styleUrls: ['./add-asset-category.component.scss']
})
export class AddAssetCategoryComponent {
  assetCategoryName : string = '';
  activeFlag : any = 'Y'
  addedit:any;
  id : any;
  progressSpinnerDialog: any = null;

  constructor(private assetService:AssetServiceService, private _snackbar:SnackbarMsgService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddAssetCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any){
    
    this.addedit = data1['isEditMode']




    if(this.addedit){
      const data12 = data1['row']
      this.assetCategoryName = data12['assetCategoryName'] 
      this.id = data12['id']
    }
  }

  saveAssetCategory(){
    const data = {
      id: this.id,
      assetCategoryName: this.assetCategoryName.trim(),
      activeFlag: this.activeFlag
    };

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode : this.addedit?'Edit':'Add',
        component: 'Asset Category'
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result == "success"){
        this.displayProgressSpinner(); 
        this.assetService.saveAssetCategory(data).subscribe(
          (res) =>{
            if((res['status'].toLowerCase() == 'success')){
              const message = this.addedit ? "Record Updated Successfully" : "Record Added Successfully";
              
              this._snackbar.success(message);
              setTimeout(() => {
                this.closeProgressSpinner();
                this.dialogRef.close({data: 'Success'});
              }, 1000)
            }
            else{  
              this.closeProgressSpinner();         
              this._snackbar.error(res['message']);

            }
          },
          err => {
            console.log(err);
            
            this._snackbar.error(err.message);
          }
        );
      }
    })
  }

  async displayProgressSpinner() {
    this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
        disableClose: true,
        panelClass: "progressSpinner",
    });
}

async closeProgressSpinner() {
  if(this.progressSpinnerDialog) {
    this.progressSpinnerDialog.close();
  }
}




}
