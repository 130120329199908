import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import {Location} from '@angular/common';
import * as html2pdf from 'html2pdf.js';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddEditEmployeeMasterComponent } from 'src/app/HRMS/Masters/employee-master/add-edit-employee-master/add-edit-employee-master.component';




@Component({
  selector: 'app-life-certificate-download-copy',
  templateUrl: './life-certificate-download-copy.component.html',
  styleUrls: ['./life-certificate-download-copy.component.scss']
})
export class LifeCertificateDownloadCopyComponent {

  @ViewChild('content', { static: false }) content: ElementRef;

  constructor(
    private location : Location,
    public dialogRef: MatDialogRef<LifeCertificateDownloadCopyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async downloadPDF() {
    const element = document.getElementById('yourStaticPageContent');

    // Set the width of the content container to accommodate any potential overflow
    element.style.width = '76%'; // You can adjust this value as needed

    // Capture the entire content of the page
    const canvas = await html2canvas(element, {
      scrollY: -window.scrollY, // Ensure capturing the content from the top of the page
      windowHeight: document.documentElement.scrollHeight, // Capture content up to the full height of the page
      windowWidth: document.documentElement.scrollWidth, // Capture content up to the full width of the page

    });

    // Convert the captured content to PDF
    html2pdf()
      .from(canvas)
      .save('life-certificate.pdf');

    // Reset the width of the content container after capturing
    element.style.width = 'auto';
  }

  cancel(){
    this.dialogRef.close()
  }
}
