import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Payment } from "../classes/payment";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private amountData: any = {};
  constructor(private http: HttpClient) {}

  initiatePayment(paymentInfo: Payment) {
    return this.http.post(environment.rtsUrl + "/api/payment/initiatePayment", paymentInfo, { responseType: "text" });
  }

  setAmountData(data: any) {
    this.amountData = data;
  }

  getAmountData() {
    return this.amountData;
  }

}
