import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ActivationInactivationConfirmationComponent } from "src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { AddAssetCategoryComponent } from "./add-asset-category/add-asset-category.component";

@Component({
  selector: "app-asset-category",
  templateUrl: "./asset-category.component.html",
  styleUrls: ["./asset-category.component.scss"],
})
export class AssetCategoryComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.No",
    },
    {
      columnDef: "assetCategoryName",
      header: "Asset Category Name",
    },
    {
      columnDef: "actions",
      header: "Actions",
    },
  ];
  assetsCategory = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  statusAction: any = "";
  checked: boolean = false;
  contentLoaded = false;
  activeFlag:any;
  assetCategoryName:any;
  constructor(
    private assetService: AssetServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService
  ) {}
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );
    this.getAssetCategory();
  }

  getAssetCategory() {
    this.assetService.getAssetCategory().subscribe(
      (data) => {
        const assetCategoryList = data["assetCategoryMasterList"];
        this.assetCategoryName = assetCategoryList.map(item => item.assetCategoryName);
        this.assetsCategory.data = assetCategoryList.sort((a, b) => {

            if (a.activeFlag === b.activeFlag) {
              
                const nameA = a.assetCategoryName?.toLowerCase() || '';
                const nameB = b.assetCategoryName?.toLowerCase() || '';
                return nameA.localeCompare(nameB);
            }
            return a.activeFlag === "Y" ? -1 : 1; 
        });

        setTimeout(() => {
          this.assetsCategory.sort = this.sort;
          this.assetsCategory.paginator = this.paginator;
        });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      (err) => {
        this.contentLoaded = true;
      }
    );
  }

  addAssetCategory() {
    const dialogRef = this.dialog.open(AddAssetCategoryComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetCategory();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetsCategory.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    if (event.checked == true) {
      this.statusAction = "Activate";
      row.activeFlag = "Y";
    } else {
      this.statusAction = "Deactivate";
      row.activeFlag = "N";
    }
    this.assetCategoryName = row.assetCategoryName
    var formData = {
      id: row.id,
      activeFlag: row.activeFlag,
    };

    const dialogRef = this.dialog.open(
      ActivationInactivationConfirmationComponent,
      {
        width: "550px",
        data: {
          mode: this.statusAction,
          component: `${ this.assetCategoryName }`
        },
        disableClose:true,
        autoFocus: false,
        restoreFocus: false,
      }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        if (this.statusAction == "Activate") {
          this.assetService.activateInactivateAssetCategory(formData).subscribe(
            (res) => {
              this._snackBar.success(
                ` Record ${this.statusAction}d Successfully`
              );
              this.getAssetCategory();
            },
            (err) => {
              this._snackBar.error("Activation Failed");
              this.getAssetCategory();
            }
          );
        } else {
          this.assetService.activateInactivateAssetCategory(formData).subscribe(
            (res) => {
              this._snackBar.success(
                ` Record ${this.statusAction}d Successfully`
              );
              this.checked = !this.checked;
              this.getAssetCategory();
            },
            (err) => {
              this._snackBar.error("Deactivation Failed");
              this.getAssetCategory();
            }
          );
        }
      } else {
        this.getAssetCategory();
      }
    });
  }

  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddAssetCategoryComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row: row,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetCategory();
      }
    });
  }
}
