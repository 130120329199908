import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-water-confirmation-dialog',
  templateUrl: './water-confirmation-dialog.component.html',
  styleUrls: ['./water-confirmation-dialog.component.scss']
})
export class WaterConfirmationDialogComponent {
  action : any = ''
  component : any = ''
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<WaterConfirmationDialogComponent>,) { }

  ngOnInit(): void {
    this.action = this.data.mode
    this.component = this.data.component
  }

  onConfirm() {
    this.dialogRef.close('success');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

