<div class="w-full p-4">
  <div class="lg:flex lg:items-center lg:justify-between"></div>
</div>
<div class="w-full p-4">
  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="center"
    [selectedIndex]="activeTab"
  >
    <mat-tab label="Open Grievances">
      <mat-form-field class="searchBox" appearance="outline">
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="Search"
          #input
        />
      </mat-form-field>
      <table
        mat-table
        [dataSource]="openGrievances"
        *ngIf="openGrievances.data.length > 0 && contentLoadedOpen"
        matSort
      >
        <ng-container
          *ngFor="let column of columnNames"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <!-- column.columnDef !== 'actions' && -->
            <ng-container
              *ngIf="
                column.columnDef !== 'actions' && column.Def !== 'sno';
                else slideToggleTemplate
              "
            >
              {{ row[column.columnDef] }}
            </ng-container>
            <div *ngIf="column.columnDef === 'sno'">
              {{ i + 1 }}
            </div>
            <ng-template #slideToggleTemplate>
              <div class="action" *ngIf="column.columnDef === 'actions'">
                <button
                  mat-icon-button
                  [routerLink]="'/grievance-management/add-handling/' + row.id"
                  [queryParams]="{ assignTo: row.assignTo }"
                  matTooltip="View"
                >
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
              </div>
            </ng-template>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [length]="totalOpenGrievances"
        [pageSize]="10"
        [pageSizeOptions]="[10, 30, 50]"
        (page)="onPageChangeOpen($event)"
        showFirstLastButtons
      ></mat-paginator>
      <ngx-skeleton-loader
        *ngIf="!contentLoadedOpen"
        count="4"
        appearance="line"
        animation="progress-dark"
      ></ngx-skeleton-loader>
      <h3 *ngIf="openGrievances.data.length == 0 && contentLoadedOpen">
        No data available.
      </h3>
    </mat-tab>
    <mat-tab label="Closed Grievances">
      <mat-form-field class="searchBox" appearance="outline">
        <input
          matInput
          (keyup)="applyFilterClosed($event)"
          placeholder="Search"
          #input
        />
      </mat-form-field>
      <table
        mat-table
        [dataSource]="closedGrievances"
        *ngIf="closedGrievances.data.length > 0 && contentLoadedClosed"
        matSort
      >
        <ng-container
          *ngFor="let column of columnNames"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            {{ column.header }}
          </th>
          <td mat-cell *matCellDef="let row; let i = index">
            <!-- column.columnDef !== 'actions' && -->
            <ng-container
              *ngIf="
                column.columnDef !== 'actions' && column.Def !== 'sno';
                else slideToggleTemplate
              "
            >
              {{ row[column.columnDef] }}
            </ng-container>
            <div *ngIf="column.columnDef === 'sno'">
              {{ i + 1 }}
            </div>
            <ng-template #slideToggleTemplate>
              <div class="action" *ngIf="column.columnDef === 'actions'">
                <button
                  mat-icon-button
                  [routerLink]="'/grievance-management/add-handling/' + row.id"
                  [queryParams]="{ assignTo: row.assignTo }"
                  matTooltip="View"
                >
                  <mat-icon>remove_red_eye</mat-icon>
                </button>
              </div>
            </ng-template>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [length]="totalClosedGrievances"
        [pageSize]="10"
        [pageSizeOptions]="[10, 30, 50]"
        (page)="onPageChangeClosed($event)"
        showFirstLastButtons
      ></mat-paginator>
      <ngx-skeleton-loader
        *ngIf="!contentLoadedClosed"
        count="4"
        appearance="line"
        animation="progress-dark"
      ></ngx-skeleton-loader>
      <h3 *ngIf="closedGrievances.data.length == 0 && contentLoadedClosed">
        No data available.
      </h3>
    </mat-tab>
  </mat-tab-group>
</div>
