<div>
  <h2>Form Builder</h2>

  <!-- Add Field Button -->
  <button mat-raised-button color="primary" (click)="openAddFieldDialog()">Add Field</button>

  <!-- Form Fields Table -->
  <table mat-table [dataSource]="dataSource">
    <!-- Column Definitions -->
    <ng-container matColumnDef="field_name">
      <th mat-header-cell *matHeaderCellDef>Field Name</th>
      <td mat-cell *matCellDef="let field">{{ field.field_name }}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="field_label">
      <th mat-header-cell *matHeaderCellDef>Field Label</th>
      <td mat-cell *matCellDef="let field">{{ field.field_label }}</td>
    </ng-container> -->
    <ng-container matColumnDef="field_options.placeholder">
      <th mat-header-cell *matHeaderCellDef>Field placeholder</th>
      <td mat-cell *matCellDef="let field">{{ field.field_options.placeholder }}</td>
    </ng-container>
    <ng-container matColumnDef="field_type">
      <th mat-header-cell *matHeaderCellDef>Field Type</th>
      <td mat-cell *matCellDef="let field">{{ field.field_type }}</td>
    </ng-container>
    <ng-container matColumnDef="field_options.required">
      <th mat-header-cell *matHeaderCellDef>Required</th>
      <td mat-cell *matCellDef="let field">{{ field.field_options.required ? 'Yes' : 'No' }}</td>
    </ng-container>
    <ng-container matColumnDef="field_sequence">
      <th mat-header-cell *matHeaderCellDef [hidden]="showSequence">Sequence</th>
      <td mat-cell *matCellDef="let field" [hidden]="showSequence">{{ field.field_sequence }}</td>
    </ng-container>
    <ng-container matColumnDef="field_options.choices">
      <th mat-header-cell *matHeaderCellDef>Choices</th>
      <td mat-cell *matCellDef="let field">{{ field.field_options.choices }}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>Action</th>
      <td mat-cell *matCellDef="let i = index">
        <button mat-icon-button color="warn" (click)="removeField(i)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Table Header Row -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <!-- Table Row -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <!-- JSON Output -->
  <!-- <button mat-raised-button color="primary" (click)="generateJSON()">Update JSON</button>
  <pre>{{ formConfig | json }}</pre> -->
</div>
