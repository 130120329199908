<h2 mat-dialog-title>Add Field</h2>
<div mat-dialog-content>
  <form #addFieldForm="ngForm" style="text-align: center;">
    <!-- Field Name -->
    <mat-form-field>
      <input matInput [(ngModel)]="data.newField.field_name" name="name" placeholder="Field Name" required />
    </mat-form-field>
    <!-- <mat-form-field>
      <input matInput [(ngModel)]="data.newField.field_label" name="name" placeholder="Field Label" required />
    </mat-form-field> -->
    
    <!-- Field Type -->
    <mat-form-field >
      <mat-label>Field Type</mat-label>
      <mat-select [(ngModel)]="data.newField.field_type" (selectionChange)="typeField(data.newField.field_type)" name="type" required>
        <mat-option value="text">Text</mat-option>
         <!-- textarea -->
        <mat-option value="textarea">Textarea</mat-option>
        <mat-option value="radio">Radio</mat-option>
        <mat-option value="file">File</mat-option>
        <mat-option value="select">Dropdown</mat-option>
        <mat-option value="checkbox">Checkbox</mat-option>
        <!-- date -->
        <mat-option value="date">Date</mat-option>
        <!-- time -->
        <mat-option value="time">Time</mat-option>
        <!-- datetime -->
        <mat-option value="datetime">Datetime</mat-option>
        <!-- email -->
        <mat-option value="email">Email</mat-option>
        <!-- number -->
        <mat-option value="number">Numeric</mat-option>
        <!-- tel -->
        <mat-option value="tel">Tel</mat-option>
  

      </mat-select>
    </mat-form-field>

    <!-- Field Sequence -->
    <!-- <mat-form-field>
      <input matInput type="number" [(ngModel)]="data.newField.field_sequence" name="sequence" placeholder="Sequence" required />
    </mat-form-field> -->

    <!-- Field Options -->
    <mat-form-field *ngIf="showChoices">
      <input matInput [(ngModel)]="data.newField.field_options.choices" name="choices" placeholder="Choices" />
    </mat-form-field>
    <p  *ngIf="showChoices" class="text-sm">Add Options Separated by comma(,)</p>
    <mat-form-field>
      <input matInput [(ngModel)]="data.newField.field_options.placeholder" name="placeholder" placeholder="placeholder" />
    </mat-form-field>
    <!-- Field Required (Radio Buttons) -->
    <mat-form-field>
      <mat-label>Required</mat-label>
      <mat-select [(ngModel)]="data.newField.field_options.required" name="required" required>
        <mat-option value="true">Yes</mat-option>
        <mat-option value="false">No</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions style="float: right;">
  <button mat-button (click)="onCancelClick()">Cancel</button>
  <button mat-button (click)="onSaveClick()" [disabled]="!addFieldForm.valid">Save</button>
</div>
