import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ShowPendingDuesDialogComponent } from "src/app/WATER-TAX/dialogs/show-pending-dues-dialog/show-pending-dues-dialog.component";
import { WaterConfirmationDialogComponent } from "src/app/WATER-TAX/dialogs/water-confirmation-dialog/water-confirmation-dialog.component";
import { FileResponseModel } from "src/app/WATER-TAX/model/file-response.model";
import { Page } from "src/app/WATER-TAX/model/Page";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { ApiResponse } from "src/app/WATER-TAX/response-models/api-response.model";
import { DepartmentalDisconnectionListResponse } from "src/app/WATER-TAX/response-models/departmental-disconnection-list-response.model";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class WaterTaxService {
  [x: string]: any;
  private sharedData: any;
  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private router: Router,
    private _snackBar: MatSnackBar
  ) {}

  private dataSubject = new BehaviorSubject<any>("Initial Data");
  data$ = this.dataSubject.asObservable();

  setData(data: any) {
    this.dataSubject.next(data);
  }
  private loaderSubject = new Subject<boolean>();
  loaderState = this.loaderSubject.asObservable();

  show() {
    this.loaderSubject.next(true);
  }

  hide() {
    this.loaderSubject.next(false);
  }

  validateEmail(email: string): Observable<boolean> {
    const url = `${environment.wtUrl}/api/emailValidation/checkMail?email=${email}`;
    return this.http.get<boolean>(url);
  }
  saveWaterMaster(data) {
    return this.http.post(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/save`,
      data
    );
  }

  // saveWaterConnectionComplaint(data) {
  //   return this.http.post(
  //     `${environment.wtUrl}/api/transaction/waterConnectionComplaint/save`,
  //     data
  //   );
  // }
  saveWaterConnnectionComplaint(data, task: any) {
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/transaction/waterConnectionComplaint/save`,
      data,
      { headers }
    );
  }
  saveNoDueCertificate(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    // return this.http.post(`${environment.wtUrl}/api/trn/noDueCertificate/save`, data)
    return this.http.post(
      `${environment.wtUrl}/api/trn/noDueCertificate/save`,
      sanitizedData,
      { headers }
    );
  }
  saveBillReassesment(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    // return this.http.post(`${environment.wtUrl}/api/trn/noDueCertificate/save`, data), { headers }
    return this.http.post(
      `${environment.wtUrl}/api/billReassessment/saveReassementBill`,
      sanitizedData,
      { headers }
    );
  }
  saveJaldjarPayment(data) {
    return this.http.post(
      `${environment.wtUrl}/api/trn/jaldarPayment/save`,
      data
    );
  }
  savePlumberLicense(data) {
    return this.http.post(
      `${environment.wtUrl}/api/trn/plumberLicense/save`,
      data
    );
  }

  getErrorReportCount(data) {
    return this.http.post(
      `${environment.wtUrl}/api/bill/viewReportError`,
      data
    );
  }

  saveRefunDeposit(data) {
    return this.http.post(
      `${environment.wtUrl}/api/transaction/depositeRefund/save`,
      data
    );
  }

  getDocumentListTrnWise(modelName: any, transactionName: any) {
    return this.http.get(
      `${environment.wtUrl}/api/master/common/getDocsByModuleAndTransactionName?modName=${modelName}&tranName=${transactionName}`
    );
  }
  getNoDueCertificateByConsumerNumber(consumerNumber: any) {
    return this.http.get(
      `${environment.wtUrl}/api/trn/noDueCertificate/getByConsumerNumber?consumerNumber=${consumerNumber}`
    );
  }
  deleteDocument(id: any): any {
    return this.http.get(
      `${environment.wtUrl}/api/transactions/common/discardFile?documentChecklistId=${id}`
    );
  }
  getBillReassesmentByConsumerNumber(
    consumerNumber: any,
    billingCycle: any,
    financialYear: any
  ) {
    return this.http.get(
      `${environment.wtUrl}/api/billReassessment/getConsumerDetails?consumerNumber=${consumerNumber}&billingCycle=${billingCycle}&financialYear=${financialYear}`
    );
  }

  getDocumentList() {
    return this.http.get(
      `${environment.wtUrl}/api/master/common/getDocsByModuleAndTransactionName?modName`
    );
  }

  uploadAllTypeOfFile(formData: any) {
    return this.http.post(
      `${environment.wtUrl}/api/transactions/common/uploadTransactionDocuments`,
      formData
    );
  }

  uploadAllTypeOfFileWT(formData: any) {
    return this.http.post(`${environment.wtUrl}/api/file/upload`, formData);
  }

  getAllGESCharges() {
    return this.http.get(
      `${environment.wtUrl + "/api/master/gesCharge/getAll"}`
    );
  }

  getWardwiseNode(wardId: any) {
    return this.http.get(
      `${
        environment.commonUrl +
        "/api/master/mstWardNodeMapping/getByWardId?wardId=" +
        wardId
      }`
    );
  }
  getAreaTypeWiseData(areaType: any) {
    return this.http.get(
      `${environment.wtUrl + "/api/getConnectionType?areaTypeName=" + areaType}`
    );
  }
  getBillTypeAgainsAreaTypeConnectionTypeConsumerGroupType(
    areaType: any,
    connectionType: any,
    consumerGroupType: any
  ) {
    return this.http.get(
      `${
        environment.wtUrl +
        "/api/getBillType?areaTypeName=" +
        areaType +
        "&connectionTypeId=" +
        connectionType +
        "&ConsumerGroupTypeId=" +
        consumerGroupType
      }`
    );
  }
  getWardwiseVillage(wardId: any, type: any) {
    return this.http.get(
      `${
        environment.commonUrl +
        "/api/master/mstVillage/getAllVillageByVillageTypeAndWardId?villageType=" +
        type +
        "&wardId=" +
        wardId
      }`
    );
    // return this.http.get(`${environment.commonUrl+'/api/master/mstVillage/getByWardId?wardId='+wardId}`)
  }
  getByIdBillPayment(id: any) {
    return this.http.get(
      `${environment.wtUrl + "/api/billNew/getBillForCollection?id=" + id}`
      // `${environment.wtUrl + "/api/bill/getBillById?id=" + id}`
    );
  }

  getNodeWiseSector(nodeId: any) {
    return this.http.get(
      `${
        environment.commonUrl +
        "/api/master/mstNodeSectorMapping/getByNodeId?nodeId=" +
        nodeId
      }`
    );
  }
  getBillGeneration(data: any) {
    return this.http.post(
      `${environment.wtUrl}/api/billNew/generateMeterReadingRequest`,
      data
    );
    // return this.http.post(`${environment.wtUrl}/api/bill/generateMeterReadingRequest`,data)
  }
  getFilterMeterReading(data: any) {
    return this.http.post(
      `${environment.wtUrl}/api/bill/getFilterWiseMeterReadings`,
      data
    );
  }

  getFinancialYear() {
    return this.http.get(
      `${environment.commonUrl + "/api/master/financialyear/getAll"}`
    );
    // return this.http.get(`${environment.commonUrl + "/api/master/financialyear/getAllDashboard"}`);
  }

  getDepartmentList() {
    return this.http.get(
      `${environment.commonUrl + "/api/master/department/getAllDashboard"}`
    );
  }
  getDepartmentWiseUserList(did: any) {
    return this.http.get(
      `${environment.commonUrl + "/api/master/user/getAllDashboard"}`
    );
  }

  getMeterReaderUsers() {
    // return this.http.get(`${environment.commonUrl + "/api/master/user/getAllDashboard"}`);
    return this.http.get(
      `${
        environment.commonUrl +
        "/api/master/user/getUsersByDesignation?designationId=01931efa-e7d0-7a83-8fa4-e43726feda69"
      }`
    );
  }

  getWaterMaster(api: string) {
    return this.http.get(`${environment.wtUrl + "/" + api}`);
  }

  getByIdWithTask(task: string, api: any) {
    // const sanitizedData = this.removeCircularReferences(data);
    console.log(api);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId")); // { headers }
    return this.http.get(`${environment.wtUrl + api}`, { headers });
  }

  getConsumerTypeByConsumerGroupType(consumerGroupType: string) {
    // return this.http.get(`${environment.wtUrl+'/api/master/consumerType/getByConsumerGroupType?consumerGroupType='+consumerGroupType}`)
    return null;
  }

  getConsumerTypeByConsumerGroupTypeAndBillType(
    consumerGroupType: string,
    billTyepe: any
  ) {
    return this.http.get(
      `${
        environment.wtUrl +
        "/api/getConsumerType?consumerGroupTypeId=" +
        consumerGroupType +
        "&billTypeId=" +
        billTyepe
      }`
    );
    // return this.http.get(`${environment.wtUrl+"/api/master/consumerType/getByConsumerGroupType?consumerGroupType="+consumerGroupType +"&billType="+billTyepe}`);
  }

  getConsumerTypeByConsumerGroupTypeV2(consumerGroupType: string) {
    return this.http.get(
      `${
        environment.wtUrl +
        "/api/master/consumerType/getByConsumerGroupTypeV2?consumerGroupType=" +
        consumerGroupType
      }`
    );
  }

  getBillTypeByConsumerType(consumerType: string) {
    return this.http.get(
      `${
        environment.wtUrl +
        "/api/master/billTypeConsumerTypeMapping/getByConsumerType?consumerType=" +
        consumerType
      }`
    );
  }

  getWaterAndCommonMaster(api: string, type: string): any {
    if (type == "common") {
      return this.http.get(`${environment.commonUrl + "/" + api}`);
    } else if (type == "wt") {
      return this.http.get(`${environment.wtUrl + "/" + api}`);
    } else if (type == "commWithRespontType") {
      return this.http.get(`${environment.commonUrl + "/" + api}`);
    }
  }

  getSetviceWiseCharge(serviceId: any, id: any) {
    // return this.http.get(`${environment.wtUrl}/api/master/charge/getAll`);
    return this.http.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/getServiceCharges?serviceId=${serviceId}&id=${id}`
    );
  }

  getAllChargesList() {
    // return this.http.get(`${environment.wtUrl}/api/master/charge/getAll`);
    // return this.http.get(`${environment.wtUrl}/api/master/charge/getAllNew`);
    return this.http.get(
      `${environment.wtUrl}/api/master/charge/getFixedCharges`
    );
  }

  getBackendPDF(url: any) {
    return this.http.get(`${environment.wtUrl + url}`, {
      responseType: "text",
    });
  }
  getLedgerReport(consumerNumber: any) {
    return this.http.get(
      `${environment.wtUrl}/api/report/ledgerReport/getLedgerReportDetails?consumerNumber=${consumerNumber}`
    );
    // return this.http.get(`${environment.wtUrl}/api/transaction/trnNewWaterConnection/getConsumerDetailsForLedgerReport?consumerNumber=${consumerNumber}`);
  }

  getConsumerTypeListUsingConsumerGroupTypeId(id: any) {
    return this.http.get(
      `${environment.wtUrl}/api/master/consumerType/getConsumerType?consumerGroupType=` +
        id
    );
  }

  getByPropertyNumber(propertyNumber: any) {
    return this.http.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/fetchConsumerDetailsByPropertyNo?propertyTaxNumber=${propertyNumber}`
    );
    // return this.http.get(`${environment.wtUrl}/backend/pt/api/transaction/trnnewpropertyregistration/getByPropertyNo?propertyNo=${propertyNumber}`);
  }

  comparisionDetails(id: any) {
    return this.http.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/getServiceHistoryById?id=${id}`
    );
  }

  getWaterConnectionList(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any
  ) {
    const headers = new HttpHeaders()
      // .set('task', task)
      // .set('userId', localStorage.getItem('userId') || '')
      .set("Authorization", `Bearer ${localStorage.getItem("token")}`);
    return this.http.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`,
      { headers }
    );
    // return this.http.get(`${environment.wtUrl}/api/transaction/trnNewWaterConnection/getAll`);
  }

  //------------
  getDashboardUserList(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any,
    toggle: any,
    serviceName: any
  ) {
    console.log("service toggle service->", toggle);
    console.log("serviceName  service->", serviceName);

    const headers = new HttpHeaders()
      // .set('task', task)
      // .set('userId', localStorage.getItem('userId') || '')
      .set("Authorization", `Bearer ${localStorage.getItem("token")}`);
    return this.http.get(
      `${environment.wtUrl}/api/dashboard/getServiceWiseRecords?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}&serviceName=${serviceName}`,
      { headers }
    );

    // return this.http.get(`${environment.wtUrl}/api/transaction/trnNewWaterConnection/getAll`);
  }

  //http://localhost:9017/api/dashboard/getServiceWiseRecords
  getWaterMasterPagination(
    api: any,
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any
  ) {
    const headers = new HttpHeaders()
      // .set('task', task)
      // .set('userId', localStorage.getItem('userId') || '')
      .set("Authorization", `Bearer ${localStorage.getItem("token")}`);
    return this.http.get(
      `${
        environment.wtUrl + "/" + api
      }?pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`,
      { headers }
    );
    // return this.http.get(`${environment.wtUrl}/api/transaction/trnNewWaterConnection/getAll`);
  }
  getWaterDepartmentalDisconnection(body: any) {
    return this.http.post(
      `${environment.wtUrl}/api/departmentalDisconnection/fetchRecordsForDisconnection`,
      body
    );
  }
  getWaterBill(body: any) {
    return this.http.post(
      `${environment.wtUrl}/api/billNew/getAllWaterBills`,
      // `${environment.wtUrl}/api/bill/getAllWaterBills`,
      body
    );
  }
  //getAllBills() {
  getAllBills(event: any) {
    //&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}
    return this.http.get(
      `${environment.wtUrl}/api/billNew/getAllReadingRequest?whichOne=${event.toggle}&pageNo=${event.pageIndex}&pageSize=${event.pageSize}&sortDir=${event.sortDir}&sortBy=${event.sortBy}&searchParam=${event.searchParam}`
    );
    //  return this.http.get(`${environment.wtUrl}/api/billNew/getAllReadingRequest?`);
    // return this.http.get(`${environment.wtUrl}/api/bill/getAllReadingRequest`)
  }
  getWaterBillNew(body: any) {
    return this.http.post(
      `${environment.wtUrl}/api/billNew/getAllWaterBills`,
      body
    );
  }

  billgetById(id: any, task: any) {
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    // return this.http.get(`${environment.wtUrl}/api/billNew/getByIdBillApproval?id=`+id,{headers})
    return this.http.get(`${environment.wtUrl}/api/billNew/getById?id=` + id, {
      headers,
    });
    // return this.http.get(`${environment.wtUrl}/api/bill/getById?id=`+id,{headers})
  }
  getByIdBillApproval(id: any, task: any) {
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.get(
      `${environment.wtUrl}/api/billNew/getByIdBillApproval?id=` + id,
      { headers }
    );
    // return this.http.get(`${environment.wtUrl}/api/billNew/getById?id=`+id,{headers})
    // return this.http.get(`${environment.wtUrl}/api/bill/getById?id=`+id,{headers})
  }
  getDepositRefundListOld() {
    return this.http.get(
      `${environment.wtUrl}/api/transaction/depositeRefund/getAll`
    );
  }

  // getDepositRefundList(pageNo: number, pageSize: number, sortDir: any, sortBy: any, searchParam: any) {
  getDepositRefundList(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any,
    toggle: any
  ) {
    return this.http.get(
      `${environment.wtUrl}/api/transaction/depositeRefund/getAll`
      // `${environment.wtUrl}/api/transaction/depositeRefund/getAll'?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`
    );
  }

  getBillByConsumerNumber(consumerNumber: any) {
    return this.http.get(
      `${environment.wtUrl}/api/bill/billDetailsByConsumerNo?consumerNumber=${consumerNumber}`
    );
  }

  getConnectionComplaintList(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any,
    toggle: any
  ) {
    const headers = new HttpHeaders();
    return this.http.get(
      // `${environment.wtUrl}/api/transaction/waterConnectionComplaint/getAll`
      `${environment.wtUrl}/api/transaction/waterConnectionComplaint/getAll?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`,
      { headers }
    );
  }
  getConnectionComplaintListOld() {
    return this.http.get(
      `${environment.wtUrl}/api/transaction/waterConnectionComplaint/getAll`
      // `${environment.wtUrl}/api/transaction/waterConnectionComplaint/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`
    );
  }
  getJaldharPaymentListOld() {
    return this.http.get(`${environment.wtUrl}/api/trn/jaldarPayment/getAll`);
  }
  getJaldharPaymentList(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any,
    createDtTm: any,
    toggle: any,
    serviceNameUuid: any
  ) {
    return this.http.get(
      // `${environment.wtUrl}/api/trn/jaldarPayment/getAll?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`,
      `${environment.wtUrl}/api/trn/jaldarPayment/getAll?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}&serviceName=${serviceNameUuid}`
    );
  }

  getPlumberLicenseList(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any
  ) {
    return this.http.get(
      `${environment.wtUrl}/api/trn/plumberLicense/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`
    );
  }
  getPlumberLicenseListOld() {
    return this.http.get(`${environment.wtUrl}/api/trn/plumberLicense/getAll`);
  }
  getNoDueCertificateOld() {
    return this.http.get(
      `${environment.wtUrl}/api/trn/noDueCertificate/getAll`
    );
  }
  getNoDueCertificate(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any
  ) {
    return this.http.get(
      `${environment.wtUrl}/api/trn/noDueCertificate/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`
    );
  }

  getNoDueCertificateNew(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any,
    toggle: any,
    serviceName: any
  ) {
    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    return this.http.get(
      `${environment.wtUrl}/api/trn/noDueCertificate/getServiceWiseRecords?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}&serviceName=${serviceName}`,
      { headers }
    );
  }

  getAllReassesment(event: any) {
    console.log("event----->", event);
    //return this.http.get(`${environment.wtUrl}/api/billReassessment/getAll?whichOne=${event.toggle}&pageNo=${event.pageIndex}&pageSize=${event.pageSize}&sortDir=${event.sortDir}&sortBy=${event.sortBy}&searchParam=${event.searchParam}`);
    return this.http.get(`${environment.wtUrl}/api/billReassessment/getAll`);
  }

  //?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}&serviceName=${serviceName}`,
  // { headers }
  getWaterConsumerList(data: any) {
    return this.http.post(
      `${environment.wtUrl}/api/transaction/connectionServices/scrutiny/getAllConsumer`,
      data
    );
  }
  getWaterBillDemand(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/billNew/generateDemand`,
      sanitizedData,
      { headers }
    );
    // return this.http.post(`${environment.wtUrl}/api/bill/generateDemand`, sanitizedData, { headers });
  }

  // getDocPreview(filePath:any){
  //   return `${environment.wtUrl}/api/file/previewNew?filePath=${filePath}`;
  // }

  getDocPreview(filePath: any): any {
    return this.http.get<string>(
      `${environment.wtUrl}/api/file/previewNew?filePath=${filePath}`
    );
  }

  getZone(): any {
    return this.http.get(`${environment.commonUrl}/api/master/zone/getAll`);
  }

  getTitle(pageNo: any, pageSize: any, sortBy: any, sortDir: any): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/title/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(`${environment.wtUrl}/api/master/title/getAll`);
    }
  }
  getGender(pageNo: any, pageSize: any, sortBy: any, sortDir: any): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/gender/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(`${environment.wtUrl}/api/master/gender/getAll`);
    }
  }
  getApplicantType(pageNo: any, pageSize: any, sortBy: any, sortDir: any): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/applicantType/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(
        `${environment.wtUrl}/api/master/applicantType/getAll`
      );
    }
  }
  getUsageType(pageNo: any, pageSize: any, sortBy: any, sortDir: any): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/usageType/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(`${environment.wtUrl}/api/master/usageType/getAll`);
    }
  }
  getConnectionType(
    pageNo: any,
    pageSize: any,
    sortBy: any,
    sortDir: any
  ): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/connectionType/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(
        `${environment.wtUrl}/api/master/connectionType/getAll`
      );
    }
  }
  getConnectionSize(
    pageNo: any,
    pageSize: any,
    sortBy: any,
    sortDir: any
  ): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/connectionsize/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(
        `${environment.wtUrl}/api/master/connectionsize/getAll`
      );
    }
  }
  getLandmark(pageNo: any, pageSize: any, sortBy: any, sortDir: any): any {
    if (pageNo != null) {
      return this.http.get(
        `${environment.wtUrl}/api/master/landmark/getAll?pageNo=${pageNo}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`
      );
    } else {
      return this.http.get(`${environment.wtUrl}/api/master/landmark/getAll`);
    }
  }

  saveApplicantType(data) {
    return this.http.post(
      `${environment.wtUrl}/api/master/applicantType/save`,
      data
    );
  }

  saveTitle(data) {
    return this.http.post(`${environment.wtUrl}/api/master/title/save`, data);
  }

  saveGender(data) {
    return this.http.post(`${environment.wtUrl}/api/master/gender/save`, data);
  }

  saveConnectionType(data) {
    return this.http.post(
      `${environment.wtUrl}/api/master/connectionType/save`,
      data
    );
  }

  saveConnectionSize(data) {
    return this.http.post(
      `${environment.wtUrl}/api/master/connectionsize/save`,
      data
    );
  }
  saveLandmark(data) {
    return this.http.post(
      `${environment.wtUrl}/api/master/landmark/save`,
      data
    );
  }

  addEditDelete(api, data) {
    return this.http.post(`${environment.wtUrl + api}`, data);
  }

  saveWaterConnectionComplaint2(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/transaction/waterConnectionComplaint/save`,
      sanitizedData,
      {
        headers,
        observe: "response",
      }
    );
  }

  saveJaldharPayment(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/trn/jaldarPayment/save`,
      sanitizedData,
      { headers }
    );
  }
  savePlumberLicense2(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId") || "")
      .set("Authorization", `Bearer ${localStorage.getItem("token")}`);

    return this.http.post(
      `${environment.wtUrl}/api/trn/plumberLicense/save`,
      sanitizedData,
      {
        headers,
        observe: "response",
      }
    );
    //   const sanitizedData = this.removeCircularReferences(data);
    //   const headers = new HttpHeaders()
    // .set('task', task)
    // .set('userId',localStorage.getItem('userId'));
    //   return this.http.post(`${environment.wtUrl}/api/trn/plumberLicense/save`, sanitizedData, { headers });
  }

  saveDepartmentalNotice(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/departmentalDisconnection/persistRecordsForDisconnection`,
      sanitizedData,
      { headers }
    );
  }

  saveWaterBill(task: any, data: any, api: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    })
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(`${environment.wtUrl + api}`, sanitizedData, {
      headers: headers,
      observe: "response",
    });
  }

  saveUsageType(data) {
    return this.http.post(
      `${environment.wtUrl}/api/master/usageType/save`,
      data
    );
  }

  // saveScrutinize(task:any,data:any){
  //   return this.http.post(`${environment.wtUrl}/api/transaction/trnNewWaterConnection/saveScrutinize?task=${task}`, data)
  // }

  // saveScrutinize(task: any, data: any) {
  //   const sanitizedData = this.removeCircularReferences(data);
  //   const headers = new HttpHeaders()
  // .set('task', task)
  // .set('userId',localStorage.getItem('userId'))
  // .set('Authorization',`Bearer ${localStorage.getItem('token')}`);

  //   return this.http.post(`${environment.wtUrl}/api/transaction/connectionServices/scrutiny/saveScrutinize`, sanitizedData, { headers });
  // }

  saveScrutinize(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId") || "")
      .set("Authorization", `Bearer ${localStorage.getItem("token")}`);

    return this.http.post(
      `${environment.wtUrl}/api/transaction/connectionServices/scrutiny/saveScrutinize`,
      sanitizedData,
      {
        headers,
        observe: "response",
      }
    );
  }

  getPendingDue(id: any) {
    return this.http.get(
      `${environment.wtUrl}/api/bill/checkPendingDuesAgainstConsumerNumber?consumerNumber=${id}`,
      {
        observe: "response", // Ensure that the entire response is observed here
      }
    );
  }

  async onDialogChange(row: any, event: any, heading: any): Promise<boolean> {
    console.log("--> onDialogChange", event);
    const dialogRef = this.dialog.open(WaterConfirmationDialogComponent, {
      width: "550px",
      data: {
        mode: event,
        component: heading,
      },
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });

    const result: any = await dialogRef.afterClosed().toPromise();

    if (result === "success") {
      // row.activeFlag = event.checked ? 'Y' : 'N';
      return true;
    } else {
      return false;
    }
  }
  async showPendingDuesDialog(
    path: any,
    event: any,
    heading: any
  ): Promise<boolean> {
    const dialogRef = this.dialog.open(ShowPendingDuesDialogComponent, {
      width: "550px",
      data: {
        mode: event,
        component: { ...heading, path: path },
      },
      autoFocus: false,
      restoreFocus: false,
    });

    const result: any = await dialogRef.afterClosed().toPromise();

    if (result === "success") {
      console.log("True", heading);

      const mydata = {
        action: null,
        id: heading.id,
        consumerNumber: heading.consumerNumber,
        task: "",
        cardAction: [],
      };
      // const mydata={action:data.action.action,id:row.consumerNumber,task:data.action.task,cardAction:data.cardAction};

      this.router.navigateByUrl("/water-tax/CollectPaymentComponent", {
        state: mydata,
      });

      //  this.router.navigateByUrl("/water-tax/CollectPaymentComponent");
      // row.activeFlag = event.checked ? 'Y' : 'N';
      return true;
    } else {
      this.router.navigateByUrl("/water-tax/WaterTaxDashboardNewComponent");
      return false;
    }
  }

  saveDepositRefundScrutiny(task: any, data: any) {
    // const headers = new HttpHeaders().set('task', task);
    // headers.set('userId':'')
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/transaction/depositeRefund/save`,
      sanitizedData,
      { headers }
    );
  }
  removeCircularReferences(obj: any) {
    const seen = new WeakSet();
    return JSON.parse(
      JSON.stringify(obj, (key, value) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      })
    );
  }

  setSharedData(data: any): void {
    this.sharedData = data;
  }

  getSharedData(): any {
    return this.sharedData;
  }

  getWorkflowArray(): any[] {
    const workflowString = localStorage.getItem("workflow");
    if (workflowString) {
      return JSON.parse(workflowString);
    }
    return [];
  }

  // getStagesForKey(key: string): any[] {
  //   const workflowArray = this.getWorkflowArray();
  //   for (let i = 0; i < workflowArray.length; i++) {
  //     if (workflowArray[i].hasOwnProperty(key)) {
  //       console.log(workflowArray[i]);
  //       return workflowArray[i][key];
  //     }
  //   }
  //   return [];
  // }

  getStagesForKey(key: string): string[] {
    const workflowArray = this.getWorkflowArray();
    const uniqueStagesSet = new Set<string>();

    for (let i = 0; i < workflowArray.length; i++) {
      if (workflowArray[i].hasOwnProperty(key)) {
        const stagesArray = workflowArray[i][key];
        stagesArray.forEach((stageObj: any) => {
          stageObj.stage.split(" , ").forEach((stage: string) => {
            const trimmedStage = stage.trim();
            if (trimmedStage && trimmedStage !== "null") {
              uniqueStagesSet.add(trimmedStage);
            }
          });
        });
      }
    }

    return Array.from(uniqueStagesSet);
  }

  getOrigenelKey(dummyKey: string): string {
    if (dummyKey == "serviceNameEn") {
      return "serviceName";
    }
    if (dummyKey == "applicantName") {
      return "firstName";
    } else {
      return dummyKey;
    }
  }

  //reports api
  getAllWard() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstWard/getAllActiveList`
    );
  }

  getAllBillingCycle() {
    return this.http.get(`${environment.wtUrl}/api/master/billingCycle/getAll`);
  }
  getAllSector() {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstSector/getAllActiveList`
    );
  }
  getAllBillType() {
    return this.http.get(`${environment.wtUrl}/api/master/billType/getAll`);
  }
  getAllcollectionCenter() {
    return this.http.get(
      `${environment.wtUrl}/api/master/collectionCenter/getAll`
    );
  }
  getActivecollectionCenter() {
    return this.http.get(
      `${environment.wtUrl}/api/master/collectionCenter/getCollectionCenters`
    );
  }

  getAllConsumertype() {
    return this.http.get(`${environment.wtUrl}/api/master/consumerType/getAll`);
  }
  getAllConnectionType() {
    return this.http.get(
      `${environment.wtUrl}/api/master/connectionType/getAll`
    );
  }
  getAllConnectionSubGroup() {
    return this.http.get(
      `${environment.wtUrl}/api/master/consumerGroupTypeName/getAll`
    );
  }
  getAllConnectionStatus() {
    return this.http.get(
      `${environment.wtUrl}/api/master/connectionStatus/getAll`
    );
  }
  getAllConnectionSize() {
    return this.http.get(
      `${environment.wtUrl}/api/master/connectionsize/getAll`
    );
  }

  payBill(data: any) {
    return this.http.post(
      `${environment.wtUrl}/api/billCollection/makePayment`,
      data
    );
  }
  getReceiptPayment(
    consumerNumber: string,
    paymentMode: any,
    format: any
  ): Observable<any> {
    const url = `${environment.wtUrl}/api/reports/getPaymentReceipt?consumerNumber=${consumerNumber}&whichOne=${paymentMode}&format=${format}`;

    return this.http.get<string>(url, {
      responseType: "text" as "json",
    });
    // return this.http.get(url);
  }

  getBankDetails(ifscCode: any): Observable<any> {
    const url = `${environment.wtUrl}/api/transactions/common/ifsc/${ifscCode}`;
    return this.http.get(url, { observe: "response" });
  }

  //   getChequeRealization(event: any) {
  //     console.log("service toggle service---------->", event.toggle);
  //     const headers = new HttpHeaders()
  //       .set("Authorization", `Bearer ${localStorage.getItem("token")}`);
  //     return this.http.get(
  //       `${environment.wtUrl}/api/transaction/trnChequeRealizationEntry/getChequeRealizationEntry?whichOne=${event.toggle}&pageNo=${event.pageIndex}&pageSize=${event.pageSize}&sortBy=${event.sortBy}&sortDir=${event.sortDir}&searchParam=${event.searchParam}`

  //     );
  // }

  getChequeRealization(
    pageNo: number,
    pageSize: number,
    sortDir: any,
    sortBy: any,
    searchParam: any,
    toggle: any
  ) {
    console.log("service toggle service->", toggle);
    // console.log("serviceName  service->", serviceName);

    const headers = new HttpHeaders().set(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );
    return this.http.get(
      // `${environment.wtUrl}/api/transaction/trnChequeRealizationEntry/getChequeRealizationEntry?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}&serviceName=${serviceName}`,{ headers }
      `${environment.wtUrl}/api/transaction/trnChequeRealizationEntry/getChequeRealizationEntry?whichOne=${toggle}&pageNo=${pageNo}&pageSize=${pageSize}&sortDir=${sortDir}&sortBy=${sortBy}&searchParam=${searchParam}`,
      { headers }
    );
  }

  getConsumerGroupTypeListByAreaTypeAndConnectionType(
    areaType: any,
    connectionType: any
  ) {
    return this.http.get(
      `${environment.wtUrl}/api/getConsumerGroupType?areaTypeName=${areaType}&&connectionTypeId=${connectionType}`
    );
    // return this.http.get(`${environment.wtUrl}/api/getConsumerGroupType?areaTypeName=${areaType}&connectionTypeId=${connectionType}`);
  }

  saveChequeRealization(data) {
    return this.http.post(
      `${environment.wtUrl}/api/transaction/trnChequeRealizationEntry/saveChequeRealizationEntry`,
      data
    );
  }

  getDemandNotePaymentDetailsByIdAndPaymentType(id, paymentType) {
    console.log("service id: ", id);
    return this.http.get(
      `${environment.commonUrl}/api/eazyPay/pay/getPaymentByApplicationIdAndPaymentType?id=${id}&paymentType=${paymentType}`
    );
  }

  getAllApplicationDetailByNumberCollectionOnliePayment(
    applicationNumber: any
  ) {
    return this.http.get(
      `${environment.commonUrl}/api/citizen/getApplicationDetailByNumber?applicationNumber=${applicationNumber}`
    );
  }

  getAllCollectionPaymentDetailsId(id: any) {
    return this.http.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/getById?id=${id}`
    );
    // return this.http.get(`${environment.wtUrl}/backend/pt/api/transaction/trnnewpropertyregistration/getByPropertyNo?propertyNo=${propertyNumber}`);
  }

  makeTransferApplicationPayment(
    applicationId: string,
    transactionAmount: number,
    returnUrl: string,
    moduleId: any,
    paymentDescription,
    transaction: string
  ) {
    const requestData = {
      applicationId: applicationId,
      transactionAmount: transactionAmount,
      // returnUrl: environment.wtUrl + returnUrl,
      returnUrl: environment.frontEndUrl + returnUrl,
      moduleId: moduleId,
      paymentDescription: paymentDescription,
      paymentAmount: 0, // Number
      pgAmount: 0, // BigDecimal as Number
      firstName: "x", // String
      lastName: "x", // String
      emailId: "test@gmail.com", // String
      address: "x", // String
      mobileNo: "7788776565", // String
      ward: "x", // String
      department: "x", // String
      serviceName: "x", // String
      billType: "x", // String
      chargesType: "x", // String
      amount: 0, // BigDecimal as Number
      taxes: 0, // BigDecimal as Number
      demandId: "018fc8ce-44ac-737c-832c-7f4bdc4acd5a", // UUID
      propertyNumber: "x",
      waterConsumerNumber: "x",
      transactionName: "x",
      extraParam1: paymentDescription,
      extraParam2: "x",
      extraParam3: "x",
      transaction: transaction,
      paymentType: "SERVICE_CHARGES",
    };

    return this.http.post(
      `${environment.commonUrl}/api/eazyPay/pay/makePayment1`,
      requestData
    );
  }

  formatStatus(status: string): string {
    let formattedStatus = status.replace(/_/g, " ");
    if (formattedStatus == "DE VERIFIED") {
      return "DE Verified";
    } else {
      formattedStatus = formattedStatus
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      return formattedStatus;
    }
  }

  getRoleList(): any[] {
    let roles = JSON.parse(localStorage.getItem("roles"));
    let list = [];
    roles.map((r) => {
      list.push(r.role);
    });
    return list;
  }

  progressSpinnerDialog: any = null;

  async displayProgressSpinner() {
    console.log("display Progress Spinner call");
    // if(!this.progressSpinnerDialog) {
    this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
      disableClose: true,
      panelClass: "progressSpinner",
    });
    // }
  }

  async closeProgressSpinner() {
    if (this.progressSpinnerDialog) {
      this.progressSpinnerDialog.close();
    }
  }

  saveDepartmentalNoticeNew(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(
      `${environment.wtUrl}/api/departmental-disconnection/persistRecordsForDisconnection`,
      sanitizedData,
      { headers }
    );
  }

  saveInitDepartmentalNotice(task: any, data: any) {
    const sanitizedData = this.removeCircularReferences(data);
    const headers = new HttpHeaders()
      .set("task", task)
      .set("userId", localStorage.getItem("userId"));
    return this.http.post<{ status: string; errorMessage: string }>(
      `${environment.wtUrl}/api/departmental-disconnection/save`,
      sanitizedData,
      { headers }
    );
  }

  fetchDepartmentalDisconnectionList(
    page: number,
    size: number,
    toggle: string,
    body: any
  ) {
    let reqParams = `?page=${page}&size=${size}`;
    return this.http.post<
      ApiResponse<Page<DepartmentalDisconnectionListResponse>>
    >(
      `${environment.wtUrl}` +
        "/api/departmental-disconnection/list/" +
        toggle +
        reqParams,
      body
    );
  }

  fetchDepartmentalDisconnectionNotice(id: string, type?: string) {
    return this.http.post<ApiResponse<FileResponseModel[]>>(
      `${environment.wtUrl}/api/departmental-disconnection/generate-notice?requestId=${id}` +
        (type ? `&type=${type}` : ""),
      null
    );
  }

  errorToast(message: string) {
    return this._snackBar.open(message, undefined, {
      panelClass: ["snackbar-error"],
      horizontalPosition: "end",
      verticalPosition: "top",
      duration: 5000,
    });
  }
}
