<!-- <div #content class="header">
    <h1>नवी मुंबई महानगरपालिका</h1>
    <p>नवी मुंबई महानगरपालिका कार्यालय, नवीन इमारत, तिसरा मंजला, भुक्र1 व 2 किल्ले गावठाण जवळ, पामबीच जंक्शन सेक्टर-
        15ए, सीबीडी बेलापूर नवी मुंबई - 400614</p>
</div> -->


<div  class="bg-gray-100 p-6 scroll-m-0" style="max-height: 90vh; overflow-y: auto;">
    <div id="yourStaticPageContent">

    <div class="flex items-center">

        <button mat-icon-button (click)="cancel()">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>

       
    </div>

    <div class="flex justify-center">
        <h1 class="text-2xl mb-8">नवी मुंबई महानगरपालिका
        </h1>
      
    </div>
    <div class="flex justify-center">
       
        <h2 class="text-2xl mb-8">नवी मुंबई महानगरपालिका कार्यालय, नवीन इमारत, तिसरा मंजला, भुक्र1 व 2 किल्ले गावठाण जवळ, पामबीच जंक्शन सेक्टर-
            15ए, सीबीडी बेलापूर नवी मुंबई - 400614
        </h2>
    </div>
    <hr>

        <div class="flex justify-center">
            <h2 class="text-2xl mb-8">Marriage Certificate</h2>
          </div>
        <fieldset class='p-3 border border-black rounded-md'>
            <!-- <legend class="text-orange-500 text-lg 	">  विवाह/पुनर्विवाहाबाबत प्रतिज्ञापत्र 
                Declaration of Marriage / Remarriage 
                 </legend> -->
               

<!-- <h3>
    निवृत्तीवेतन धारकाचे नाव / Name <span class="blank-line"></span>
   
    पत्ता / Address <span class="blank-line"></span>
   
    बैंक /Bank Name <span class="blank-line"></span>
   
    हयातीचा दाखला / Life Certificate <span class="blank-line"></span>
   
    शाखा / Branch <span class="blank-line"></span>
   
    IFSC Code <span class="blank-line"></span>
   
    खाते क्रमांक / Account No <span class="blank-line"></span>
   
    Mobile No <span class="blank-line"></span>
</h3> -->

<div class="flex justify-center">
    <h2 class="text-lg mb-8">3. विवाह/पुनर्विवाहाबाबत प्रतिज्ञापत्र (फक्त कुटूंब निवृत्ती वेतन धारकांकरीता)
        Declaration of Marriage / Remarriage (Only for family Pensioners)
        </h2>
</div>
              <h3 style="padding-bottom: 3rem;">
                1.  मी असे जाहिर करतो/करते की, मी विवाह/पुनर्विवाह केलेला
                <span class="blank-line"></span> (कृपया आहे किंवा नाही असे स्पष्ट लिहावे) <br>
                2. मी असे जाहीर करतो/करते की, मी विवाह/पुनर्विवाह दिनांक  <span class="blank-line"></span> रोजी केलेला आहे.
              
              </h3>

              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2">

                <div>
                    ठिकाण Place. <span class="blank-line"></span> निवृत्तीवेतनधारकाची सही / अंगठा
                </div>
                <div>
                    प्रशासकीय अधिकारी (प्रशासन)
                    नवी मुंबई महानगरपालिका
                </div>
                <div>
                    दिनांक Date: <span class="blank-line"></span> Signature / Thumb Impression
                    of Pensioner
                    
                </div>
                <div>
                    Signature of Administrative officer
                    Navi Mumbai Municipal Corporation
                    
                </div>
              </div>

              <h5 class="flex justify-center" style="padding-top: 3rem;">
                निवृत्तीवेतन / कुटूंब निवृत्तीवेतन धारकास महत्त्वाच्या सूचना <br>
                1. निवृत्ती वेतन विभागाशी कोणताही पत्रव्यवहार करताना निवृत्ती वेतनाच्या मंजूरी आदेशाची छायांकित प्रत
                जोडण्यात यावी. <br>
                2. निवृत्ती वेतन धारकानी, त्यांच्या निवासस्थानात पत्ता बदल झाल्यास त्वरीत या विभागास कळवावे.
                
              </h5>

        
        </fieldset>

    </div>

    <div class="float-right p-4" >
        <button type="button"  (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
              Go back
            </button>
            <button type="button" (click)="downloadPDF()" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Download</button>
    </div>
       
</div>


