<div class="py-10 px-10">
  <div class="text-lg mb-2">Transfer Ward</div>
  <form [formGroup]="selectionForm" (ngSubmit)="selectionSave()">
    <div class="grid grid-cols-2 gap-4">
      <div>
        <mat-form-field class="w-full">
          <mat-label>Select Zone</mat-label>
          <mat-select (selectionChange)="getWard($event.value)">
            <mat-option *ngFor="let zone of zones" [value]="zone.id">
              {{ zone.zoneName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div>
        <mat-form-field class="w-full">
          <mat-label>Select Ward</mat-label>
          <mat-select formControlName="wardId" (selectionChange)="onWardSelection()">
            <mat-option *ngFor="let ward of wards" [value]="ward.id">
              {{ ward.wardName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="selectionForm.get('wardId').hasError('required')">
            Please select ward
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field class="w-full">
        <mat-label>Remark</mat-label>
        <input matInput formControlName="transferHistory" />
        <mat-error *ngIf="selectionForm.get('transferHistory').hasError('required')">
          Remark is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="flex justify-center">
      <button
        id="cancel-button"
        type="button"
        class="px-4 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none"
        (click)="cancelSelection()"
      >
        Cancel
      </button>

      <button
        id="submit-button"
        type="submit"
        [disabled]="!selectionForm.valid"
        [ngClass]="{'bg-green-500': selectionForm.valid, 'bg-slate-400': !selectionForm.valid}"
        class="px-4 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none hover:shadow-lg focus:outline-none ml-3"
      >
        Save
      </button>
    </div>
  </form>
</div>
