import { Component } from '@angular/core';

@Component({
  selector: 'app-radio-field-options-dialog',
  templateUrl: './radio-field-options-dialog.component.html',
  styleUrls: ['./radio-field-options-dialog.component.scss']
})
export class RadioFieldOptionsDialogComponent {

}
