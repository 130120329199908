import { Component, OnInit } from "@angular/core";
import { TestService } from "../_services/test.service";
import { ApplicationModalComponent } from "./application-modal/application-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../_services/auth.service";
import { NavigationEnd, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { CommonService } from "../_services/Common-Services/common.service";
import { InfoPopUpComponent } from "../shared/component/info-pop-up/info-pop-up.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  userId = localStorage.getItem("userId");
  userLogin: string = "";
  loginName: string = "";
  ugpId: any;
  ugpPrevId = localStorage.getItem("ugpPrevId");
  //   menuItems = [
  //     { title: "Assets Managemenent", routerLink: "/asset-dashboard", ugpPrevId: "2" },
  //     { title: "Content Management System", routerLink: "/content-management/cms-dashboard", ugpPrevId: "2" },
  //     { title: "Central Workshop Management", routerLink: "/central-workshop", ugpPrevId: "2" },
  //     { title: "Core Module", routerLink: "/masterDashboard", ugpPrevId: "2" },
  //     { title: "Disaster Management", routerLink: "/disaster-management/disaster-dashboard", ugpPrevId: "2" },
  //     { title: "Enterprise Workflow Management", routerLink: "/EnterpriceWorkflowManagement-dashboard", ugpPrevId: "2" },
  //     { title: "Financial Accounting", routerLink: ["/financial-accounting"], ugpPrevId: "2" },
  //     { title: "Financial Accounting", routerLink: ["/financial-accounting"], ugpPrevId: "7" },
  //     { title: "Grievance Management", routerLink: ["/grievance-management"], ugpPrevId: "2" },
  //     { title: "Grievance Management", routerLink: ["/grievance-management"], ugpPrevId: "3" },
  //     { title: "HR & Payroll Management", routerLink: "/hrms-module/hrms-dashboard", ugpPrevId: "2" },
  //     { title: "Land And Estate", routerLink: "/land-Estate", ugpPrevId: "2" },
  //     { title: "Material Managemenent", routerLink: "/mm-dashboard", ugpPrevId: "2" },
  //     { title: "Municipal Secretary & Agenda Management", routerLink: "/msmam-dashboard", ugpPrevId: "2" },
  //     { title: "Online Services(RTS)", routerLink: "/online-rts" },
  //     { title: "Property Tax Management", routerLink: ["/property-tax/propertyTax-scrutiny"], ugpPrevId: "2" },
  //     { title: "Solid Waste Management", routerLink: ["/solid-waste-management"], ugpPrevId: "2" },
  //     { title: "Survey Database Creation", routerLink: "/survey-database/survey-dashboard" },
  //     { title: "Water Tax Management", routerLink: ["/water-tax/water-tax-dashboard"], ugpPrevId: "2" },
  //     { title: "Work Management", routerLink: ["/work-management"], ugpPrevId: "2" }
  // ];

  //   menuItems = [
  //     { title: "Assets Managemenent", routerLink: "/asset-dashboard", ugpPrevId: "2" },
  //     { title: "Content Management System", routerLink: "/content-management/cms-dashboard", ugpPrevId: "2" },
  //     { title: "Central Workshop Management", routerLink: "/central-workshop", ugpPrevId: "2" },
  //     { title: "Core Module", routerLink: "/masterDashboard", ugpPrevId: "2" },
  //     { title: "Disaster Management", routerLink: "/disaster-management/disaster-dashboard", ugpPrevId: "2" },
  //     { title: "Enterprise Workflow Management", routerLink: "/EnterpriceWorkflowManagement-dashboard", ugpPrevId: "2" },
  //     { title: "Financial Accounting", routerLink: ["/financial-accounting"], ugpPrevId: "2" },
  //     { title: "Financial Accounting", routerLink: ["/financial-accounting"], ugpPrevId: "7" },
  //     { title: "Grievance Management", routerLink: ["/grievance-management"], ugpPrevId: "2" },
  //     { title: "Grievance Management", routerLink: ["/grievance-management"], ugpPrevId: "3" },
  //     { title: "HR & Payroll Management", routerLink: "/hrms-module/hrms-dashboard", ugpPrevId: "2" },
  //     { title: "Land And Estate", routerLink: "/land-Estate", ugpPrevId: "2" },
  //  { title: "Material Managemenent", routerLink: "/mm-dashboard", ugpPrevId: "2" },
  //     { title: "Municipal Secretary & Agenda Management", routerLink: "/msmam-dashboard", ugpPrevId: "2" },
  //     { title: "Online Services(RTS)", routerLink: "/online-rts" },
  //     { title: "Property Tax Management", routerLink: ["/property-tax/propertyTax-scrutiny"], ugpPrevId: "2" },
  //     { title: "Solid Waste Management", routerLink: ["/solid-waste-management"], ugpPrevId: "2" },
  //     { title: "Survey Database Creation", routerLink: "/survey-database/survey-dashboard" },
  //     { title: "Water Tax Management", routerLink: ["/water-tax/water-tax-dashboard"], ugpPrevId: "2" },
  //     { title: "Work Management", routerLink: ["/work-management"], ugpPrevId: "2" }
  // ];


  menuItems = [
    // {
    //   title: "Assets Management",
    //   routerLink: "/asset-management/asset-dashboard",
    //   ugpPrevId: "2",
    //   icon: "perm_data_setting",
    // },
    {
      title: "Assets Management",
      routerLink: "/asset-management/asset-dashboard",
      ugpPrevId: "2",
      icon: "perm_data_setting",
    },
    {
      title: "Content Management System",
      routerLink: "/content-management/cms-dashboard",
      ugpPrevId: "2",
      userId: "0191bb5d-d225-7a08-9ff4-a1c8f0947906",
      icon: "content_paste",
    },
    {
      title: "Content Management System",
      routerLink: "/content-management/cms-dashboard",
      ugpPrevId: "2",
      userId: "018d8776-b566-7d3c-9904-47166ad9dc0a",
      icon: "content_paste",
    },
    {
      title: "Central Workshop Management",
      routerLink: "/central-workshop",
      ugpPrevId: "2",
      icon: "directions_car",
    },
    {
      title: "Central Workshop Management",
      routerLink: "/central-workshop",
      ugpPrevId: "4",
      icon: "directions_car",
    },
    {
      title: "Core Module",
      routerLink: "/core-module/masterDashboard",
      ugpPrevId: "2",
      icon: "settings",
    },
    {
      title: "Disaster Management",
      routerLink: "/disaster-management/disaster-report-complaint",
      ugpPrevId: "2",
      icon: "warning",
    },
    {
      title: "Disaster Management",
      routerLink: "/disaster-management/disaster-report-complaint",
      ugpPrevId: "3",
      icon: "warning",
    },
    {
      title: "Enterprise Workflow Management",
      routerLink: "/enterprise-workflow-management",
      ugpPrevId: "2",
      icon: "compare_arrows",
    },
    {
      title: "Financial Accounting",
      routerLink: ["/financial-accounting"],
      ugpPrevId: "2",
      icon: "account_balance",
    },
    {
      title: "Financial Accounting",
      routerLink: ["/financial-accounting"],
      ugpPrevId: "7",
      icon: "account_balance",
    },
    {
      title: "Grievance Management",
      routerLink: ["/grievance-management/handling"],
      ugpPrevId: "2",
      icon: "transfer_within_a_station",
    },
    {
      title: "Grievance Management",
      routerLink: ["/grievance-management/register"],
      ugpPrevId: "3",
      icon: "transfer_within_a_station",
    },
    {
      title: "HR & Payroll Management",
      routerLink: "/hrms-module/hrms-dashboard",
      ugpPrevId: "2",
      icon: "people",
    },
    {
      title: "Land And Estate",
      routerLink: "/land-Estate",
      ugpPrevId: "2",
      icon: "assignment",
    },
    {
      title: "Material Management",
      routerLink: "/material-management",
      ugpPrevId: "2",
      icon: "file_copy",
    },
    {
      title: "Municipal Secretary & Agenda Management",
      routerLink: "/msmam-dashboard",
      ugpPrevId: "2",
      icon: "settings_voice",
    },

    {
      title: "Online Services(RTS)",
      routerLink: "/online-rts/action-dashboard",
      ugpPrevId: "2",
      icon: "online_prediction",
    },
    {
      title: "Online Services(RTS)",
      routerLink: "/online-rts/onlinerts",
      ugpPrevId: "2",
      userId: "018d8776-b566-7d3c-9904-47166ad9dc0a",
      icon: "online_prediction",
    },
    {
      title: "Online Services(RTS)",
      routerLink: "/online-rts/rts-services",
      ugpPrevId: "3",
      icon: "online_prediction",
    },

    {
      title: "Property Tax Management",
      routerLink: ["/property-tax/propertyTax-scrutiny"],
      ugpPrevId: "2",
      icon: "home",
    },
    {
      title: "Property Tax Management",
      routerLink: ["/property-tax/propertyTax-scrutiny"],
      ugpPrevId: "3",
      icon: "home",
    },
    {
      title: "Property Tax Management",
      routerLink: ["/property-tax/propertyTax-scrutiny"],
      userId: "0192b2e5-3105-72da-b007-28c0a3e55f1f",
      icon: "home",
    },
    {
      title: "Solid Waste Management",
      routerLink: ["/solid-waste-management"],
      ugpPrevId: "2",
      icon: "recycling",
    },
    {
      title: "Survey / Opinion",
      routerLink: "/survey-database/survey-service-list",
      ugpPrevId: "3",
      // userId: "018d8776-b566-7d3c-9904-47166ad9dc0a",
      icon: "map",
    },
    {
      title: "Survey / Opinion",
      routerLink: "/survey-database/survey-dashboard",
      ugpPrevId: "2",
      // userId: "0191b872-dfe6-7d46-946b-ca206c1c8c53",
      icon: "map",
    },
    {
      title: "Water Tax Management",
      routerLink: ["/water-tax/WaterTaxDashboardNewComponent"],
      ugpPrevId: "2",
      icon: "opacity",
    },
    {
      title: "Water Tax Management",
      routerLink: ["/water-tax/WaterTaxDashboardNewComponent"],
      ugpPrevId: "3",
      icon: "opacity",
    },
    {
      title: "Water Tax Ledger Report",
      routerLink: ["/water-tax/RepoLedgerComponent"],
      ugpPrevId: "2",
      userId: "0192b2e5-3105-72da-b007-28c0a3e55f1f",
      icon: "opacity",
    },
    {
      title: "Works Management",
      routerLink: ["/work-management"],
      ugpPrevId: "2",
      icon: "work_outline",
    },
    {
      title: "CFC",
      routerLink: ["/cfc"],
      ugpPrevId: "2",
      icon: "work_outline",
    },
    // {
    //   title: "CFC",
    //   routerLink: ["/cfc"],
    //   ugpPrevId: "3",
    //   icon: "work_outline",
    // },
    // {
    //   title: "Ganpati Pandal Permission",
    //   routerLink: ["/online-rts/application-service-form/01904e12-fd01-73ec-af71-2afe6b0a2ffb"],
    //   // ugpPrevId: "2",
    //   icon: "src\assets\ganesh.svg",
    // },
    {
      title: "Life Certificate / हयातीचा दाखला",
      routerLink: ["/hrms-module/life-certificate-actions"],
      ugpPrevId: "3",
      icon: "workspace_premium",
    },
  ];

  amount: any = {
    amount: "100",
    applicationId: "1234",
  };
  constructor(
    private testService: TestService,
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private commonService: CommonService
  ) {
    // this.getDataUsingFormData();
    // this.getApplicationData()

    iconRegistry.addSvgIcon(
      "ganpati-pandal",
      sanitizer.bypassSecurityTrustResourceUrl("assets/ganesh.svg")
    );

    iconRegistry.addSvgIcon(
      "navratri-pandal",
      sanitizer.bypassSecurityTrustResourceUrl("assets/navratri.svg")
    );

    iconRegistry.addSvgIcon(
      "certificate",
      sanitizer.bypassSecurityTrustResourceUrl("assets/lifeCertificates.svg")
    );
  }
  ngOnInit(): void {
    // this.testService.getdepartment().subscribe(res=>{
    //   console.log('getdepartmentservice',res)
    // }),err=>{
    //   console.log(err)
    // }
    this.userLogin = localStorage.getItem("login");
    this.loginName = localStorage.getItem("loginName");
    this.ugpId = localStorage.getItem("ugpId");
    this.getModules();
    // if (localStorage.getItem("ugpPrevId") != '2') {
    //   this.openDialog()
    // }

  }

  openDialog() {
    this.dialog.open(InfoPopUpComponent, {
      data: {
        title: "Ganapati Pandal Registration Closing Soon!",
        image: "/assets/ganpati-banner.jpg",
        // link: 'https://your-link.com',
        // linkText: 'Click here for more details'
      },
      width: "800px",
      height: "auto",
      disableClose: true,
    });
  }

  // async getDataUsingFormData() {
  //   await this.testService.serviceWithFormData("param1", "param2").subscribe(
  //     (res: any) => {
  //       console.log("Response:", res);
  //     },
  //     (err: any) => {
  //       console.log("Error:", err);
  //     }
  //   );
  // }

  // async getDataUsingBody() {
  //   await this.testService.serviceWithBody("param1", "param2").subscribe(
  //     (res: any) => {
  //       console.log("Response:", res);
  //     },
  //     (err: any) => {
  //       console.log("Error:", err);
  //     }
  //   );
  // }

  modules: any[] = [];
  getModules() {
    this.commonService.getModules().subscribe((res: any) => {
      // console.log(res)
      this.modules = res.filter((mod) => mod.isVisibleToDashboard == "Y");
      // console.log(this.modules)
    });
  }
  shouldDisplayItem(item: any): boolean {
    if (item.routerLink == '/online-rts/action-dashboard' && localStorage.getItem('userId') == '018d8776-b566-7d3c-9904-47166ad9dc0a') {
      return false
    } else if (localStorage.getItem("userId") == '0192b2e5-3105-72da-b007-28c0a3e55f1f') {
      if (item.userId) {
        return (localStorage.getItem("userId") == item.userId)
      } else {
        return false;
      }
    } else if (item.userId) {

      return (localStorage.getItem("userId") === item.userId);
    }
    else {
      return !item.ugpPrevId || this.ugpPrevId === item.ugpPrevId;
    }
  }

  applications: any;
  getApplicationData() {
    this.testService.getApplications(this.userId).subscribe((res) => {
      this.applications = res["application"];
    });
  }

  show(data: any) {
    // Check if formDetails is a valid JSON string
    try {
      const appli = JSON.parse(data.formDetails);

      // Open the modal with the parsed data
      this.dialog.open(ApplicationModalComponent, {
        // width: '400px', // Adjust the width as needed
        data: appli, // Pass the data to the modal component
      });
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  }
}
