import { Platform } from "@angular/cdk/platform";
import { Component, inject, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ViewImgDocComponent } from "src/app/RTS/view-rts-application/view-img-doc/view-img-doc.component";
@Component({
  selector: "app-document-view-mobile",
  templateUrl: "./document-view-mobile.component.html",
  styleUrls: ["./document-view-mobile.component.scss"],
})
export class DocumentViewMobileComponent {
  platform = inject(Platform);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<DocumentViewMobileComponent> // Inject MatDialogRef for controlling the dialog
  ) {}

  ngOnInit(): void {
    // Fallback: If the file type is PDF, disable the loader after a timeout.
    if (this.isPdfType(this.data)) {
      // Add a fallback in case `ngx-doc-viewer` doesn't trigger load events.
      // setTimeout(() => {
      //   this.loading = false;
      // }, 2000); // Adjust the timeout as needed
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
  isImageType(data: string): boolean {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp"];
    const fileExtension = data.substring(data.lastIndexOf(".")).toLowerCase();
    return imageExtensions.includes(fileExtension);
  }

  isPdfType(data: string): boolean {
    const pdfExtensions = [".pdf", ".xls", ".xlsx", ".csv"];
    const fileExtension = data.substring(data.lastIndexOf(".")).toLowerCase();
    return pdfExtensions.includes(fileExtension);
  }

  // Handle the content load event for images
  // onContentLoad(): void {
  //   this.loading = false;
  // }

  // // Handle errors during content load
  // onContentError(): void {
  //   this.loading = false;
  //   // Optionally display a fallback or error message
  // }
}
