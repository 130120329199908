import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ActivationInactivationConfirmationComponent } from "src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { AddEditEqpBreakdownTypeComponent } from "./add-edit-eqp-breakdown-type/add-edit-eqp-breakdown-type.component";

@Component({
  selector: "app-asset-eqp-breakdown-type",
  templateUrl: "./asset-eqp-breakdown-type.component.html",
  styleUrls: ["./asset-eqp-breakdown-type.component.scss"],
})
export class AssetEqpBreakdownTypeComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  equipmentBreakdownTypeName: any;
  progressSpinnerDialog: any = null;
  equipmentName: any;

  columnNames: any[] = [
    { columnDef: "sno", header: "Sr.No." },
    { columnDef: "equipmentBreakdownTypeName", header: "Equipment Breakdown" },
    { columnDef: "actions", header: "Actions" },
  ];
  assetEqpBreakdownTypes = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  contentLoaded = false;
  statusAction: string = "";
  checked: boolean = false;
  isLoading: Observable<boolean>;

  constructor(
    private assetService: AssetServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,
  ) {}

  ngOnInit() {
    this.displayedColumns = this.columnNames.map((x) => x.columnDef);
    this.getAssetEqpBreakdownTypeData();
  }

  ngAfterViewInit() {
    this.assetEqpBreakdownTypes.sort = this.sort;
  }

  getAssetEqpBreakdownTypeData() {
    this.assetService.getEquipmentBreakdownType().subscribe((res) => {
      const equipmentBreakdownTypeMasterList = res["equipmentBreakdownTypeMasterList"];
      this.equipmentBreakdownTypeName = equipmentBreakdownTypeMasterList.map((item) => item.equipmentBreakdownTypeName);
      this.assetEqpBreakdownTypes.data = equipmentBreakdownTypeMasterList.sort((a, b) =>
        a.activeFlag === b.activeFlag
          ? a.equipmentBreakdownTypeName.localeCompare(b.equipmentBreakdownTypeName)
          : a.activeFlag === "Y"
          ? -1
          : 1
      );

      setTimeout(() => {
        this.assetEqpBreakdownTypes.sort = this.sort;
        this.assetEqpBreakdownTypes.paginator = this.paginator;
        this.contentLoaded = true;
      }, 2000);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetEqpBreakdownTypes.filter = filterValue.trim().toLowerCase();
  }

  addAsset() {
    const dialogRef = this.dialog.open(AddEditEqpBreakdownTypeComponent, {
      width: "550px",
      data: { isEditMode: false },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.data === "Success") {
        this.contentLoaded = false;
        this.getAssetEqpBreakdownTypeData();
      }
    });
  }

  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddEditEqpBreakdownTypeComponent, {
      width: "550px",
      data: { isEditMode: true, row },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.data === "Success") {
        this.contentLoaded = false;
        this.getAssetEqpBreakdownTypeData();
      }
    });
  }

  onSlideToggleChange(row: any, event: any): void {
    this.statusAction = event.checked ? "Activate" : "Deactivate";
    this.equipmentName = row.equipmentBreakdownTypeName;

    row.activeFlag = event.checked ? "Y" : "N";

    const formData = { id: row.id, activeFlag: row.activeFlag };

    const dialogRef = this.dialog.open(ActivationInactivationConfirmationComponent, {
      width: "550px",
      data: {
        mode: this.statusAction,
        component: `${this.equipmentName}`,
      },
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === "success") {
        this.assetService.activateInactivateEquipmentBreakdownType(formData).subscribe(
          () => {
            this._snackBar.success(` Record ${this.statusAction}d Successfully`);
            this.getAssetEqpBreakdownTypeData();
          },
          () => {
            this._snackBar.error(`Record ${this.statusAction}d Successfully`);
            this.getAssetEqpBreakdownTypeData();
          }
        );
      }
    });
  }

  async displayProgressSpinner() {
    this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
      disableClose: true,
      panelClass: "progressSpinner",
    });
  }

  async closeProgressSpinner() {
    if (this.progressSpinnerDialog) {
      this.progressSpinnerDialog.close();
    }
  }
}
