import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-field-dialog',
  templateUrl: './add-field-dialog.component.html',
  styleUrls: ['./add-field-dialog.component.scss'],
})
export class AddFieldDialogComponent {
  showChoices:boolean=false;
  constructor(
    public dialogRef: MatDialogRef<AddFieldDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    this.dialogRef.close({ newField: this.data.newField });
  }

  typeField(item) {
    // Check if the item is one of the specified values
    if (item === 'checkbox' || item === 'select' || item === 'radio') {
      this.showChoices = true;
    } else {
      this.showChoices = false;
    }
    // console.log(item);
  }
  
  
}
