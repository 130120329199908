import { Component, ViewChild } from '@angular/core';
import { AddMeetingAgendaComponent } from './add-meeting-agenda/add-meeting-agenda.component';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MatDialog } from '@angular/material/dialog';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-prepare-meeting-agenda-and-finalization',
  templateUrl: './prepare-meeting-agenda-and-finalization.component.html',
  styleUrls: ['./prepare-meeting-agenda-and-finalization.component.scss']
})
export class PrepareMeetingAgendaAndFinalizationComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;

  columnNames: any[] = [
    { columnDef: 'sno',header: 'Sr.No.'},
    { columnDef: 'isSubject', header: 'Is Subject' },
    // { columnDef: 'fromDate', header: 'From Date' },
    // { columnDef: 'toDate', header: 'To Date' },
    { columnDef: 'officeName', header: 'Office Name' },
    // { columnDef: 'departmentId', header: 'Department ID' },
    { columnDef: 'departmentName', header: 'Department Name' },
    { columnDef: 'committeeName', header: 'Committee Name' },
    { columnDef: 'docketNo', header: 'Docket No' },
    // { columnDef: 'docketDate', header: 'Docket Date' },
    { columnDef: 'subject', header: 'Subject' },
    { columnDef: 'subjectSummary', header: 'Subject Summary' },
    { columnDef: 'isApprovedAndSubmittedForAgenda', header: 'Is Approved And Submitted For Agenda' },
    { columnDef: 'isRevert', header: 'Is Revert' },
    { columnDef: 'reasonForRevert', header: 'Reason For Revert' },
    { columnDef: 'remark', header: 'Remark' },
    {
      columnDef: 'actions', header: 'Actions',
    },
  ];

  departments = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  constructor(private msmService: MsmamServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService) {

  }
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
    this.getShedule()
  }

  getShedule() {
    this.msmService.getPrepareMeetingAgenda().subscribe(
      data => {
        this.departments.data = data['prepareMeetingAgendaAndFinalization'];
        this.departments.data = this.departments.data.sort((a, b) => a.id - b.id);
        setTimeout(
          () => {
            this.departments.sort = this.sort;
            this.departments.paginator = this.paginator;
          });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);

      },
      err => {
        this.contentLoaded = true;

      }
    )
  }

  addAgenda() {
    const dialogRef = this.dialog.open(AddMeetingAgendaComponent, {
      width: "950px",
      data: {
        isEditMode: false,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getShedule()
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.departments.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {

    row.activeFlag = event.checked ? 'Y' : 'N';
    if (event.checked) {
      this.msmService.activeInactivePrepareMeetingAgenda(row.id, row.activeFlag).subscribe(
        res => {
          this._snackBar.success('Meeting Agenda is Activated');
        },
        err => {
          this._snackBar.error('Activation Flag Failed to update')
        }
      )

    } else {
      this.msmService.activeInactivePrepareMeetingAgenda(row.id, row.activeFlag).subscribe(
        res => {
          this._snackBar.error('Meeting Agenda is Deactivated');
          this.getShedule();
        },
        err => {
          this._snackBar.error('Activation Flag Failed to Delete')
        })
    }

  }
  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddMeetingAgendaComponent, {
      width: "950px",
      data: {
        isEditMode: true,
        row: row
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getShedule()
      }
    });
  }

}
