import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AssetServiceService } from 'src/app/_services/assetService/asset-service.service';
import { ActivationInactivationConfirmationComponent } from 'src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { AddAssetTypeComponent } from './add-asset-type/add-asset-type.component';


@Component({
  selector: 'app-asset-type',
  templateUrl: './asset-type.component.html',
  styleUrls: ['./asset-type.component.scss']
})
export class AssetTypeComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  columnNames: any[] = [
    {
      columnDef : "sno",
      header : 'Sr.No'
    },
    {
      columnDef: 'assetTypeName',
      header: 'Asset Type Name',
    },
    {
      columnDef: 'actions',
      header: 'Actions',
    },
  ];
  assetstype = new MatTableDataSource<any>();
  displayedColumns: any[] = []
  contentLoaded = false;
  statusAction: any = ''
  checked: boolean = false;
  assetTypeName:any;
  
  constructor(private assetService: AssetServiceService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,) {

  }
  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));
    this.getAssetType();
  }

  getAssetType() {
    this.assetService.getAssetType().subscribe(
      data => {
        const assetTypes = data['assetTypeMasterList'];
        this.assetTypeName = assetTypes.map(item => item.assetTypeName);

        const sortedAssetTypes = assetTypes
          .sort((a, b) => a.assetTypeName.localeCompare(b.assetTypeName)) 
          .sort((a, b) => (b.activeFlag === 'Y' ? 1 : -1));  
  
        this.assetstype.data = sortedAssetTypes;
        setTimeout(
          () => {
            this.assetstype.sort = this.sort;
            this.assetstype.paginator = this.paginator;
          });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      err => {
        this.contentLoaded = true;
      }
    )
  }

  addAssetType() {
    const dialogRef = this.dialog.open(AddAssetTypeComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetType()
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetstype.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {

    if (event.checked == true) {
      this.statusAction = 'Activate'
      row.activeFlag = 'Y'
    } else {
      this.statusAction = 'Deactivate';
      row.activeFlag = 'N'
    }
    this.assetTypeName = row.assetTypeName
    var formData = {
      id: row.id,
      activeFlag: row.activeFlag
    }

    const dialogRef = this.dialog.open(ActivationInactivationConfirmationComponent, {
      width: "550px",
      data: {
        mode: this.statusAction,
        component: `${ this.assetTypeName }`,
      },
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        if (this.statusAction == 'Activate') {
          this.assetService.activateInactivateAssetType(formData).subscribe(
            res => {
                this._snackBar.success(
                        ` Record ${this.statusAction}d Successfully`
                      );
              this.getAssetType();

              },
            err => {
              this._snackBar.error('Activation Failed')
              this.getAssetType();

            }
          )
        } else {
          this.assetService.activateInactivateAssetType(formData).subscribe(
            res => {
              this._snackBar.success(
                ` Record ${this.statusAction}d Successfully`
              );
              this.checked = !this.checked;
              this.getAssetType();

            },
            err => {
              this._snackBar.error('Deactivation Failed')
              this.getAssetType();

            })
        }
      } else {
        this.getAssetType();
      }
    })

  }

  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddAssetTypeComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row: row
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetType();
      }
    });
  }
}
