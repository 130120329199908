import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { MasterService } from 'src/app/_services/master.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-meeting-agenda',
  templateUrl: './add-meeting-agenda.component.html',
  styleUrls: ['./add-meeting-agenda.component.scss']
})
export class AddMeetingAgendaComponent {
  fromDate: string;
  toDate: string;
  officeId: number;
  offices:any;
  departmentId: number;
  departmentName: string;
  departments:any;
  committeeId: number;
  committees:any;
  docketNo: number;
  docketDate: string;
  subject: string;
  subjectSummary: string;
  verifyUploadedDocuments: string;
  isSubject:boolean=false;
  isApprovedAndSubmittedForAgenda: boolean=false;
  isRevert: boolean=false;
  reasonForRevert: string;
  remark: string;
  addedit: any;
  id = null;
  createdUserId:any;
  updateUserId:any;
  userId: string;
  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddMeetingAgendaComponent>,
    private masterService: MasterService,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {}

  ngOnInit() {
    this.getOffices();
    this.getCommittees();
    this.getDepartment();
    this.initializeFormData();
  }
 
  initializeFormData() {
    this.addedit = this.data1['isEditMode'];
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    if (this.addedit) {
      const data12 = this.data1['row'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      Object.assign(this, data12);
      this.departmentName = data12['departmentName'];
      // this.meetingTypeName = data12['meetingTypeName'];
    }
  }
  getOffices(){
    this.msmService.getByActiveFlagOffices().subscribe(
      (data) => (this.offices = data['officesMasterList']),
      () => this._snackBar.error('Office List is Empty')
    );
  }

  

  

  getDepartment() {
    this.msmService.getDepartment().subscribe(
      (data) => (this.departments = data['departmentMasterList']),
      () => this._snackBar.error('Department List is Empty')
    );
  }

  departmentChange(data) {
    this.departmentId = data;
    this.departmentName = this.departments.find((item) => item.id === data)?.department || '';
  }

  getCommittees() {
    this.msmService.getByActiveFlagCommittee().subscribe(
      (data) => (this.committees = data['committeeMasterList']),
      () => this._snackBar.error('Committee List is Empty')
    );
  }

 

  saveMeeting() {
   
    const data = {
      id: this.id,
      isSubject:this.isSubject,
      committeeId: this.committeeId,
      departmentId: this.departmentId,
      departmentName: this.departmentName,
      fromDate: this.fromDate,
      toDate: this.toDate,
      officeId: this.officeId,
      docketNo: this.docketNo,
      docketDate: this.docketDate,
      subject: this.subject,
      subjectSummary: this.subjectSummary,
      verifyUploadedDocuments: this.verifyUploadedDocuments,
      isApprovedAndSubmittedForAgenda: this.isApprovedAndSubmittedForAgenda,
      isRevert: this.isRevert,
      reasonForRevert: this.reasonForRevert,
      remark: this.remark,
      createdUserId:this.createdUserId,
      updateUserId:this.updateUserId,
      activeFlag: 'Y'
    };

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '550px',
      data: {
        mode: this.addedit ? 'Edit' : 'Add',
        component: 'Meeting Agenda'
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'success') {
        this.msmService.savePrepareMeetingAgenda(data).subscribe(
          () => {
            setTimeout(() => {
              this.dialogRef.close({ data: 'Success' });
            }, 1000);
            this._snackBar.success(this.addedit ? 'Docket Entry Edited successfully!' : 'Docket Entry Added successfully!');
          },
          () => this._snackBar.error('Docket Entry not added')
        );
      }
    });
  }
}
