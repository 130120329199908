<h1 mat-dialog-title>{{data.docName}}</h1>
<div mat-dialog-content>
  <!-- Display the image or PDF -->
  <img *ngIf="data.base64Data.startsWith('data:image/')" [src]="safeUrl" alt="Document" style="max-width: 100%; height: 500px;">
  <iframe *ngIf="data.base64Data.startsWith('data:application/pdf;')" [src]="safeUrl" width="100%" height="500px"></iframe>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="downloadDocument()">Download</button>
  <button mat-button (click)="close()">Close</button>
</div>
