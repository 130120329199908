
<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
        close
    </mat-icon>
    <h1 class="text-2xl font-bold mb-8">{{addedit ? 'Edit' : 'Add'}} Asset Valuator</h1>
    <form [formGroup]="assetEvaluatorForm" (ngSubmit)="saveAssetSubCategory()">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-3 gap-8">
          
          
            <div class="relative z-0 w-full mb-5">
                <mat-form-field appearance="fill" class="w-full">
                    <mat-label>Effective From Date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="effectiveFromDate" class="focus:outline-none focus:border-blue-400" [max]="currentDate" required readonly />
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="assetEvaluatorForm.get('effectiveFromDate').invalid && (assetEvaluatorForm.get('effectiveFromDate').dirty || assetEvaluatorForm.get('effectiveFromDate').touched)">
                        <span *ngIf="assetEvaluatorForm.get('effectiveFromDate').errors?.['required']">Effective From Date is required.</span>
                    </mat-error>
                </mat-form-field>
            </div>
            
            <div class="relative z-0 w-full mb-5">
            <mat-form-field class="w-full">
                <mat-label>Enter Organization Name</mat-label>
                <input matInput formControlName="organizationName" maxlength="50" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('organizationName').invalid && (assetEvaluatorForm.get('organizationName').dirty || assetEvaluatorForm.get('organizationName').touched)">
                    <span *ngIf="assetEvaluatorForm.get('organizationName').errors?.['required']">Organization Name is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('organizationName').errors?.['maxlength']">Max length is 50 characters.</span>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="relative z-0 w-full mb-5">
            <mat-form-field class="w-full">
                <mat-label>Enter Organization Address</mat-label>
                <input matInput formControlName="organizationAddress" maxlength="100" required>
                <mat-error *ngIf="assetEvaluatorForm.get('organizationAddress').invalid && (assetEvaluatorForm.get('organizationAddress').dirty || assetEvaluatorForm.get('organizationAddress').touched)">
                    <span *ngIf="assetEvaluatorForm.get('organizationAddress').errors?.['required']">Organization Address is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('organizationAddress').errors?.['maxlength']">Max length is 100 characters.</span>
                </mat-error>
            </mat-form-field>
        </div>

            <mat-form-field class="w-full">
                <mat-label>Enter Organization Number</mat-label>
                <input matInput formControlName="organizationPhone" maxlength="11" required >
                <mat-error *ngIf="assetEvaluatorForm.get('organizationPhone').invalid && (assetEvaluatorForm.get('organizationPhone').dirty || assetEvaluatorForm.get('organizationPhone').touched)">
                    <span *ngIf="assetEvaluatorForm.get('organizationPhone').errors?.['required']">Organization Number is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('organizationPhone').errors?.['maxlength']">Max length is 50 characters.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter First Name</mat-label>
                <input matInput formControlName="firstName" maxlength="30" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('firstName').invalid && (assetEvaluatorForm.get('firstName').dirty || assetEvaluatorForm.get('firstName').touched)">
                    <span *ngIf="assetEvaluatorForm.get('firstName').errors?.['required']">First Name is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('firstName').errors?.['maxlength']">Max length is 30 characters.</span>
                    <span *ngIf="assetEvaluatorForm.get('firstName').errors?.['pattern']">Only alphabets are allowed.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter Middle Name</mat-label>
                <input matInput formControlName="middleName" maxlength="30" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('middleName').invalid && (assetEvaluatorForm.get('middleName').dirty || assetEvaluatorForm.get('middleName').touched)">
                    <span *ngIf="assetEvaluatorForm.get('middleName').errors?.['maxlength']">Max length is 30 characters.</span>
                    <span *ngIf="assetEvaluatorForm.get('middleName').errors?.['pattern']">Only alphabets are allowed.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter Last Name</mat-label>
                <input matInput formControlName="lastName" maxlength="30" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('lastName').invalid && (assetEvaluatorForm.get('lastName').dirty || assetEvaluatorForm.get('lastName').touched)">
                    <span *ngIf="assetEvaluatorForm.get('lastName').errors?.['required']">Last Name is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('lastName').errors?.['maxlength']">Max length is 30 characters.</span>
                    <span *ngIf="assetEvaluatorForm.get('lastName').errors?.['pattern']">Only alphabets are allowed.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label for="gender">Select Gender</mat-label>
                <mat-select formControlName="gender" id="gender" required>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Other">Other</mat-option>
                </mat-select>
                <mat-error *ngIf="assetEvaluatorForm.get('gender').invalid && (assetEvaluatorForm.get('gender').dirty || assetEvaluatorForm.get('gender').touched)">
                    <span *ngIf="assetEvaluatorForm.get('gender').errors?.['required']">Gender is required.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter Contact Number</mat-label>
                <input matInput formControlName="mobile" maxlength="10" required>
                <mat-error *ngIf="assetEvaluatorForm.get('mobile').invalid && (assetEvaluatorForm.get('mobile').dirty || assetEvaluatorForm.get('mobile').touched)">
                    <span *ngIf="assetEvaluatorForm.get('mobile').errors?.['required']">Contact number is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('mobile').errors?.['pattern']">Please enter a valid 10-digit phone number.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter Email</mat-label>
                <input matInput formControlName="email" required>
                <mat-error *ngIf="assetEvaluatorForm.get('email').invalid && (assetEvaluatorForm.get('email').dirty || assetEvaluatorForm.get('email').touched)">
                    <span *ngIf="assetEvaluatorForm.get('email').errors?.['required']">Email is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('email').errors?.['email']">Invalid email format.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter Total Experience</mat-label>
                <input matInput formControlName="totalExperience" maxlength="10" pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('totalExperience').invalid && (assetEvaluatorForm.get('totalExperience').dirty || assetEvaluatorForm.get('totalExperience').touched)">
                    <span *ngIf="assetEvaluatorForm.get('totalExperience').errors?.['required']">Total Experience is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('totalExperience').errors?.['maxlength']">Max length is 30 characters.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>References</mat-label>
                <input matInput formControlName="reference" maxlength="10" pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('reference').invalid && (assetEvaluatorForm.get('reference').dirty || assetEvaluatorForm.get('totalExperience').touched)">
                    <span *ngIf="assetEvaluatorForm.get('reference').errors?.['required']">Reference is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('reference').errors?.['maxlength']">Max length is 30 characters.</span>
                </mat-error>
            </mat-form-field>


            <mat-form-field class="w-full">
                <mat-label>Past Projects</mat-label>
                <input matInput formControlName="pastProjects" maxlength="50" pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('pastProjects').invalid && (assetEvaluatorForm.get('pastProjects').dirty || assetEvaluatorForm.get('totalExperience').touched)">
                    <span *ngIf="assetEvaluatorForm.get('pastProjects').errors?.['required']">past Projects is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('pastProjects').errors?.['maxlength']">Max length is 30 characters.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Enter Relevant Experience</mat-label>
                <input matInput formControlName="relevantExperience" maxlength="50" pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('relevantExperience').invalid && (assetEvaluatorForm.get('relevantExperience').dirty || assetEvaluatorForm.get('relevantExperience').touched)">
                    <span *ngIf="assetEvaluatorForm.get('relevantExperience').errors?.['required']">Relevant Experience is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('relevantExperience').errors?.['maxlength']">Max length is 30 characters.</span>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Remark</mat-label>
                <input matInput formControlName="remarks" maxlength="100" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="assetEvaluatorForm.get('remarks').invalid && (assetEvaluatorForm.get('remarks').dirty || assetEvaluatorForm.get('relevantExperience').touched)">
                    <span *ngIf="assetEvaluatorForm.get('remarks').errors?.['required']">Remark is required.</span>
                    <span *ngIf="assetEvaluatorForm.get('remarks').errors?.['maxlength']">Max length is 30 characters.</span>
                </mat-error>
            </mat-form-field>
            <div class="button-container center">
                <button
                  type="submit"
                  class="btn-save"
                  [disabled]="!assetEvaluatorForm.valid"
                >
                  Save
                </button>
              </div>
              
            
        </div>
    </form>
</div>
