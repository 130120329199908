import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RtsService {
  constructor(private httpClient: HttpClient) { }

  getApplications(data: any) {
    const token = localStorage.getItem('token'); // Retrieve token from localStorage
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    });
  
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getAllApplicationsByCitizen`,
      { headers } // Attach headers to the request
    );
  }
  

  getAllApplications() {
    return this.httpClient.get(`${environment.rtsUrl}/api/application/getAll`);
  }

  saveApplication(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/application/save`,
      data
    );
  }

  getrtsServiceMaster(data: any) {
    return this.httpClient.get(
      `${environment.commonUrl}/api/master/subDepartment/getByDepartment?dptId=${data}`
    );
    // 15.206.219.76:8090/api/master/subDepartment/getByDepartment?dptId=2
  }

  getrtsDepartmentMaster() {
    return this.httpClient.get(
      `${environment.commonUrl}/api/master/department/getAll`
    );
  }

  savertsForm(data: any) {
    return this.httpClient.post(`${environment.rtsUrl}/api/master/save`, data);
  }

  masterFormSave(data: any) {
    return this.httpClient.post(
      `${environment.surveyDB}/api/survey/save`,
      data
    );
  }

  getrtsservice() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/getAllServices`
    );
  }

  getAllServicesWithDepartmentAndCount() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getAllDashboardCount`
    );
  }

  getAllDepartmentWiseCount(){
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getDepartmentWiseCount`
    );
  }
  

  getPropertyServicesApplications() {
    return this.httpClient.get(
      `${environment.taxUrl
      }/api/transaction/trnnewpropertyregistration/getByUserId?userCreatedId=${localStorage.getItem(
        "userId"
      )}`
    );
  }

  getServicesDepartmentWise() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/getServicesDptwiseV1`
    );
  }
  getServicesDptwiseV2() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/getServicesDptwiseV2`
    );
  }

  getServicesBySubDepartmentId(subDeptid) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/getAllServicesBySubDptId?subDptId=${subDeptid}`
    );
  }

  activeInActiveService(id, activeFlag) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/activeInActive?id=${id}&activeFlag=${activeFlag}`,
      null
    );
  }

  getServiceByActiveFlag() {
    return this.httpClient.get(`${environment.rtsUrl}/api/master/getAll`);
  }

  getDeptdata() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getAllApplications`
    );
  }

  getDeptSortData(wardId: any, deptId: any) {
    const params = new HttpParams().set("dptId", deptId).set("wardId", wardId);
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getAllApplicationsBydptAndWard`,
      { params }
    );
  }

  getApplicationByDepartmentUserId(userId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getApplicationByUserId?departmentUserId=${userId}`
    );
  }

  getActionApplicationByDepartmentUserId(userId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getActionApplicationByUserId?departmentUserId=${userId}`
    );
  }

  getApplicationByDepartmentUserIdAndServiceId(userId, serviceId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getByServiceIdAndUserId?serviceId=${serviceId}&departmentUserId=${userId}`
    );
  }


  transferward(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/application/updateward`,
      data
    );
  }

  getdocument() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/document/getAll`
    );
  }

  getBriefCaseDocuments(): Observable<any[]> {
    return this.httpClient
      .get<any[]>(`${environment.rtsUrl}/api/master/document/getAll`)
      .pipe(
        map((response) => {
          return response.map((doc) => ({
            id: doc.id,
            name: doc.documentName,
            description: doc.description || "NA",
            required: doc.required || "N",
          }));
        })
      );
  }

  getdocumentimg(data) {
    const params = new HttpParams().set("filePath", data);
    return this.httpClient.get(`${environment.commonUrl}/api/file/preview?`, {
      params,
    });
  }

  scrutinyForApprove(data) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/application/scrutinyForApprove`,
      data
    );
  }

  scrutinyForReassign(data) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/application/scrutinyForReassign`,
      data
    );
  }

  scrutinyForReject(data) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/application/scrutinyForReject`,
      data
    );
  }

  getapplicationbyid(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getById?id=${id}`
    );
  }

  getDocumentById(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/doc/base64s?id=${id}`
    );
  }

  getapplicationbyApplicationNo(applicationNo) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getByApplicationNumber?appNumber=${applicationNo}`
    );
  }

  rejectTraderScrutiny(data) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/application/scrutinyForTradeLicenseForApprove`,
      data
    );
  }

  getServiceFormbyId(id: any) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/getById?id=${id}`
    );
  }

  uploadBriefCase(data: any) {
    return this.httpClient.post(
      `${environment.commonUrl}/api/userDocuments/save`,
      data
    );
  }

  getPreviousBriefCase(userId: any): Observable<any[]> {
    return this.httpClient
      .get<any[]>(
        `${environment.commonUrl}/api/userDocuments/findAllDocsByUserId?userId=${userId}`
      )
      .pipe(
        map((response) => {
          return response.map((doc) => ({
            id: doc.id,
            documentId: doc.documentId,
            name: doc.documentOriginalName,
            fileName: doc.documentPath,
            description: doc.description || "NA",
            status: doc.activeFlag,
            date: doc.createDtTm,
          }));
        })
      );
  }

  getPreviousBriefCase1(userId: any): Observable<any[]> {
    return this.httpClient.get<any[]>(
      `${environment.commonUrl}/api/userDocuments/findAllDocsByUserId?userId=${userId}`
    );
  }

  //stages
  getAllStages() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflowstage/getAll`
    );
  }

  getAllStagesByServiceDepartmentId(departmentId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflowstage/getStageByDepartmentId?id=${departmentId}`
    );
  }

  getByIdStage(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflowstage/getById?id=${id}`
    );
  }

  getStageDocumentByStageId(stageId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflowstage/getDocumentByStageId?id=${stageId}`
    );
  }

  saveStage(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/workflowstage/save`,
      data
    );
  }

  //workFlow
  getAllWorkFlow() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflow/getAll`
    );
  }

  getWardMapByWardId(wardId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/wardmap/getByWardId?id=${wardId}`
    );
  }

  getWorkflowBySubDepartmentId(subDepartmentId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflow/getBySubdptId?id=${subDepartmentId}`
    );
  }

  getWorkFlowByServiceId(serviceId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflow/getByServiceId?id=${serviceId}`
    );
  }

  getByIdWorkFlow(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/workflow/getById?id=${id}`
    );
  }

  saveWorkFlow(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/workflow/save`,
      data
    );
  }

  // Stage Content Template
  getAllStageContentTemplate() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/template/getAll`
    );
  }
  getByActiveFlagContentTemplate() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/template/getByActiveFlag`
    );
  }

  saveStageContentTemplate(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/template/save`,
      data
    );
  }
  getByIdStageContentTemplate(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/template/getById?id=${id}`
    );
  }

  ///api/master/serviceLevelEscalationMatrix
  // scrutiny
  getScrutiny() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/scrutiny/getAll`
    );
  }
  saveScrutiny(data: any) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
    // console.log(token)
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/scrutiny/save`,
      data, { headers }
    );
  }

  getScrutinyByApplicationId(id) {

    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/scrutiny/getByApplicationId?id=${id}`
    );
  }

  getServiceLevelEscalationMatrix() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/getAll`
    );
  }
  getByIdServiceLevelEscalationMatrix(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/getById?id=${id}`
    );
  }
  saveServiceLevelEscalationMatrix(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/save`,
      data
    );
  }

  getAllWaterApplications() {
    return this.httpClient.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/getAllforRTScitizen`
    );
  }

  // Service Level Escalation
  getServiceLevelEscalation() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/getAll`
    );
  }
  getByIdServiceLevelEscalation(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/getById?id=${id}`
    );
  }
  saveServiceLevelEscalation(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/save`,
      data
    );
  }
  getServiceLevelEscalationByServiceId(serviceId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/getByServiceId?id=${serviceId}`
    );
  }

  getServiceLevelEscalationByServiceIdWardId(serviceId, wardId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/serviceLevelEscalationMatrix/getByServiceIdAndWardId?serviceId=${serviceId}&wardId=${wardId}`
    );
  }

  //storage Business Type
  getStorageBusinessType() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/storagetype/getAll`
    );
  }

  getByActiveFlagStorageBusinessType() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/storagetype/getAllByActiveFlag`
    );
  }

  saveStorageBusinessType(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/storagetype/save`,
      data
    );
  }

  getByIdStorageBusinessType(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/storagetype/getById?id=${id}`
    );
  }

  activeInActiveStorageBusinessType(id, flag) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/storagetype/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  //Factory Business Type
  getFactoryBusinessType() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/factorytype/getAll`
    );
  }

  getByActiveFlagFactoryBusinessType() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/factorytype/getAllByActiveFlag`
    );
  }

  saveFactoryBusinessType(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/factorytype/save`,
      data
    );
  }

  getByIdFactoryBusinessType(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/factorytype/getById?id=${id}`
    );
  }

  activeInActiveFactoryBusinessType(id, flag) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/factorytype/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  //Trade Business Type
  getTradeBusinessType() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/tradetype/getAll`
    );
  }

  getByActiveFlagTradeBusinessType() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/tradetype/getAllByActiveFlag`
    );
  }

  saveTradeBusinessType(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/tradetype/save`,
      data
    );
  }

  getByIdTradeBusinessType(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/tradetype/getById?id=${id}`
    );
  }

  activeInActiveTradeBusinessType(id, flag) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/tradetype/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  //Document
  getDocument() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/document/getAll`
    );
  }

  saveDocument(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/document/save`,
      data
    );
  }

  getByIdDocument(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/document/getById?id=${id}`
    );
  }

  getByActiveFlagDocument() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/document/getByActiveFlag`
    );
  }

  activeInActiveDocument(id, flag) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/document/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  //Ground
  getGround() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/ground/getAll`
    );
  }

  saveGround(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/ground/save`,
      data
    );
  }

  getByIdGround(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/ground/getById?id=${id}`
    );
  }

  getByActiveFlagGround() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/ground/getAllByActiveFlag`
    );
  }

  activeInActiveGround(id, flag) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/ground/activeInactive?id=${id}&activeFlag=${flag}`
    );
  }

  // userallocation
  getUserAllocation() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/userallocation/getAll`
    );
  }
  getUserAllocationByServiceId(serviceId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/userallocation/getByServiceId?serviceId=${serviceId}`
    );
  }

  getUserAllocationByServiceIdWardId(serviceId, wardId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/userallocation/getByServiceIdAndWarId?id=${serviceId}&wardId=${wardId}`
    );
  }

  saveUserAllocation(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/userallocation/save`,
      data
    );
  }

  UpdateUserAllocationByStageId(stageId: any, newUserId: any) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/userallocation/updateByStageId?id=${stageId}&newUserId=${newUserId}`
    )
  }

  getByIdUserAllocation(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/userallocation/getById?id=${id}`
    );
  }

  // Water
  getWaterDetailsByByConnectionNo(connectionNumber) {
    return this.httpClient.get(
      `${environment.wtUrl}/api/transaction/trnNewWaterConnection/getByConsumerId?ConsumerId=${connectionNumber}`
    );
  }

  //Appeal
  getAllAppeal() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appeal/getAll`
    );
  }

  saveAppeal(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/appeal/save`,
      data
    );
  }

  getAppealByApplicationId(applicationId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appeal/getByApplicationId?id=${applicationId}`
    );
  }

  getAppealById(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appeal/getById?id=${id}`
    );
  }

  getAppealByUserId(userId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appeal/getAppealByUserId?userId=${userId}`
    )
  }

  //Hearing On Appeal

  getHearingOnAppeal() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appealHearing/getAll`
    );
  }

  getHearingOnAppealById(id) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appealHearing/getById?id=${id}`
    );
  }

  saveHearingOnAppeal(data: any) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/appealHearing/save`,
      data
    );
  }

  getHearingOnAppealByApplicationId(applicationId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appealHearing/getByApplicationId?id=${applicationId}`
    );
  }

  getHearingOnAppealByUserId(userId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/appealHearing/getHearingByUserId?userId=${userId}`
    )
  }


  //Payment Recept
  generatePaymentReceiptByApplicationNumberAndAmount(
    applicationNumber: string,
    amount: number
  ): Observable<string> {
    const url = `${environment.rtsUrl}/api/reports/generatePaymentReceipt?applicationNumber=${applicationNumber}&amount=${amount}`;

    return this.httpClient.post<string>(url, null, {
      responseType: "text" as "json",
    });
  }


  // Export Excel
  generateExcelReportByServiceId(serviceId): Observable<string> {
    const url = `${environment.rtsUrl}/api/master/dashboard/exportReport?serviceId=${serviceId}`;

    return this.httpClient.post<string>(url, null, {
      responseType: "text" as "json",
    });
  }

  //Payment Service

  makeAPayment(
    applicationId: string,
    transactionAmount: number,
    returnUrl: string,
    moduleId: any,
    paymentDescription,
    serviceId
  ) {
    // const requestData = {
    //   applicationId: applicationId,
    //   transactionAmount: transactionAmount,
    //   returnUrl: returnUrl,
    //   moduleId:moduleId,
    //   paymentDescription:paymentDescription
    // };
    const requestData = {
      applicationId: applicationId,
      transactionAmount: transactionAmount,
      returnUrl: environment.frontEndUrl + returnUrl,
      moduleId: moduleId,
      paymentDescription: paymentDescription,
      paymentAmount: 0, // Number
      pgAmount: 0, // BigDecimal as Number
      firstName: localStorage.getItem('loginName'), // String
      lastName: "x", // String
      emailId:"test@gmail.com",
      address: "x", // String
      mobileNo: localStorage.getItem('mobile'), // String
      ward: "x", // String
      department: "x", // String
      serviceName: "x", // String
      billType: "x", // String
      chargesType: "x", // String
      amount: 0, // BigDecimal as Number
      taxes: 0, // BigDecimal as Number
      demandId: '018fc8ce-44ac-737c-832c-7f4bdc4acd5a', // UUID
      propertyNumber: "x",
      waterConsumerNumber: "x",
      transactionName: "x",
      paymentType: paymentDescription,
      transaction:serviceId,
      extraParam1: paymentDescription,
      extraParam2: "x",
      extraParam3: "x"
    };

    return this.httpClient.post(
      `${environment.commonUrl}/api/eazyPay/pay/makePayment1`,
      requestData
    );
  }

  getPaymentDetailsById(id) {
    return this.httpClient.get(
      `${environment.commonUrl}/api/eazyPay/pay/getById?id=${id}`
    );
  }

  getApplicationDetailsByLicenseNumber(licenseNumber, serviceId,currentServiceId) {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    // return this.httpClient.post(`${environment.rtsUrl}/api/master/fireService/fire`, {}, { headers });
    return this.httpClient.get(`${environment.rtsUrl}/api/application/getByLicenseNumber?licenseNumber=${licenseNumber}&serviceId=${serviceId}&currentServiceId=${currentServiceId}`,{headers})
  }

  generatePaymentReceipt(applicationId, referenceNo) {
    const url = `${environment.rtsUrl}/api/reports/generatePaymentReceipt?applicationId=${applicationId}&referenceNo=${referenceNo}`;

    return this.httpClient.post<string>(url, null);
  }

  generateAllPaymentReceipt(applicationId) {
    const url = `${environment.rtsUrl}/api/reports/generateAllPaymentReceipt?applicationId=${applicationId}`;

    return this.httpClient.post<string>(url, null);
  }





  // getDemandNoteByApplicationIdStageId(applicationId,stageId){
  //   return this.httpClient.post(
  //     `${environment.rtsUrl}/api/reports/generateDemandNote?applicationId=${applicationId}&stageId=${stageId}`,null
  //   )
  // }

  getDemandNoteByApplicationIdStageId(
    applicationId,
    stageId
  ): Observable<string> {
    const url = `${environment.rtsUrl}/api/reports/generateDemandNote?applicationId=${applicationId}&stageId=${stageId}`;

    return this.httpClient.post<string>(url, null, {
      responseType: "text" as "json",
    });
  }

  //Pandal Service
  getGanpatiServices() {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getByServiceId?serviceId=01904e12-fd01-73ec-af71-2afe6b0a2ffb&currentStage=2`
    );
  }

  // Approval Form
  SavePandalApprovalRemark(data) {
    return this.httpClient.post(
      `${environment.rtsUrl}/api/master/verification/save`,
      data
    );
  }

  getPandalApprovalByApplicationId(applicationId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/verification/getByApplicationId?id=${applicationId}`
    );
  }

  getPandalVerificationReport(serviceId, type) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getPandalServiceStatus?serviceId=${serviceId}&type=${type}`
    )
  }

  generatePandalVerificationExcelReport(serviceId, type): Observable<string> {
    const url = `${environment.rtsUrl}/api/ganapatiPandalReport/download?serviceId=${serviceId}&type=${type}`;

    return this.httpClient.get<string>(url, {
      responseType: "text" as "json",
    });
  }
  generatePandalApplicationVerificationExcelReport(serviceId, type): Observable<string> {
    const url = `${environment.rtsUrl}/api/ganapatiPandalReport/ApplicationExcel?serviceId=${serviceId}&type=${type}`;

    return this.httpClient.get<string>(url, {
      responseType: "text" as "json",
    });
  }

  generatePandalApplicationAuthorityReport(serviceId, type): Observable<string> {
    const url = `${environment.rtsUrl}/api/to-excel?serviceId=${serviceId}&type=${type}`;

    return this.httpClient.get<string>(url, {
      responseType: "text" as "json",
    });
  }


  getPandalVerificationApplicationReport(serviceId, type) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/application/getPandalApplicationDetail?serviceId=${serviceId}&type=${type}`
    )
  }


  getPandalApprovalByApplicationIdByActiveFlag(applicationId) {
    return this.httpClient.get(
      `${environment.rtsUrl}/api/master/verification/getActiveApplication?id=${applicationId}`
    )
  }

  // getNoCByApplicationId(applicationId){
  //   return this.httpClient.post(
  //     `${environment.rtsUrl}/api/reports/generatePandalNoc?applicationId=${applicationId}`,null
  //   );
  // }
  getNoCByApplicationId(applicationId): Observable<string> {
    const url = `${environment.rtsUrl}/api/reports/generatePandalNoc?applicationId=${applicationId}`;

    return this.httpClient.post<string>(url, null)
  }
  getDemandNoteByApplicationId(applicationId) {
    const url = `${environment.rtsUrl}/api/reports/generateDemandNote?applicationId=${applicationId}`;

    return this.httpClient.post<string>(url, null)
  }

  //Fire Registration
  // getFireServiceLink() {
  //   return this.httpClient.post(
  //     `${environment.rtsUrl}/api/master/fireService/fire`,null
  //   )
  // }

  getFireServiceLink(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });

    return this.httpClient.post(`${environment.rtsUrl}/api/master/fireService/fire`, {}, { headers });
  }

  validateEmail(email: string) {
    const url = `${environment.rtsUrl}/api/email/checkMail?email=${email}`;
    return this.httpClient.get(url);
  }

}
