<div class="bg-gray-100 p-6">
  <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
    close
  </mat-icon>
  <div class="mx-auto max-w-md">
    <h1 class="text-2xl font-bold mb-8">{{ addedit ? 'Edit' : 'Add' }} Breakdown</h1>
    <form [formGroup]="assetForm" (ngSubmit)="save()">
      <div class="relative z-0 w-full">
        <mat-form-field class="w-full">
          <mat-label>Enter Equipment Breakdown Name</mat-label>
          <input matInput formControlName="equipmentBreakdownTypeName" maxlength="50" required
            pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$" />
          <mat-error *ngIf="assetForm.get('equipmentBreakdownTypeName').hasError('pattern')">
            Enter Breakdown Type Name must start with an alphanumeric character and can include only letters, numbers.
          </mat-error>
          <mat-error *ngIf="assetForm.get('equipmentBreakdownTypeName').hasError('required')">
            Enter Equipment Breakdown Name is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="button-container">
        <button type="submit" class="btn-save" [disabled]="!assetForm.valid">
          Save
        </button>
      </div>
    </form>
  </div>
</div>