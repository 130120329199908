<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
        close
    </mat-icon>

    <div class="mx-auto max-w-md">
        <h1 class="text-2xl font-bold mb-8">{{addedit?'Edit':'Add'}} Asset Category</h1>
        <form (ngSubmit)="saveAssetCategory()" #incidentForm="ngForm">
            <mat-form-field class="w-full">
                <mat-label>Enter Asset Category</mat-label>
                <input matInput [(ngModel)]="assetCategoryName" name="assetCategoryName" maxlength="50" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="incidentForm.controls['assetCategoryName'].hasError('required')">
                    Asset Category is required
                  </mat-error>
                  <mat-error *ngIf="incidentForm.controls['assetCategoryName'].hasError('pattern')">
                    Enter Asset Category must start with an alphanumeric character and can include only letters, numbers.
                  </mat-error>
            </mat-form-field>
            
            <div class="button-container">
                <button 
                  type="submit" 
                  class="btn-save" 
                  [disabled]="!incidentForm.valid"
                >
                  Save
                </button>
              </div>

        </form>
    </div>
</div>