import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-text-field-options-dialog',
  templateUrl: './text-field-options-dialog.component.html',
  styleUrls: ['./text-field-options-dialog.component.scss'],
})
export class TextFieldOptionsDialogComponent {
  fieldOptions: any = {
    field_name: '',
    field_type: 'text',
    field_label: '',
    field_options: {
      placeholder: '',
      required: false,
    },
  };

  constructor(public dialogRef: MatDialogRef<TextFieldOptionsDialogComponent>) {}

  saveFieldOptions(): void {
    this.dialogRef.close(this.fieldOptions);
  }
}
