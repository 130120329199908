<div class="bg-white p-6 rounded-lg shadow-lg max-h-[400px] overflow-y-auto">
    <h2 class="text-xl font-semibold mb-4 text-center">Terms and Conditions</h2>
    <p class="mb-2">This official website of the 'Navi Mumbai Municipal Corporation' (NMMC) has been developed to provide information to the general public...</p>
    <p class="mb-2">The documents and information displayed on this website are for reference purposes only and do not purport to be a legal document.</p>
    <p class="mb-2">NMMC does not warrant the accuracy or completeness of the information, text, graphics, links, or other items contained within the website...</p>
    <p class="mb-2">In case of any variance between what has been stated and that contained in the relevant Act, Rules, Regulations, Policy Statements, etc., the latter shall prevail...</p>
    <p>These terms and conditions shall be governed by and construed in accordance with Indian laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India.</p>
  
    <div class="mt-4 text-center">
      <button (click)="closeDialog()" class="bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-2 px-4 rounded transition duration-200">
        Close
      </button>
    </div>
  </div>
  