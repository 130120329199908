

<div class="bg-gray-100 p-6 scroll-m-0" style="max-height: 90vh; overflow-y: auto;">
  <div class="flex items-center">
     <!-- Back button -->
     <button mat-icon-button (click)="cancel()">
      <mat-icon>arrow_back_ios_new</mat-icon>
  </button>
    <!-- User Manual link moved to the left -->
    <span
        class="flex items-center cursor-pointer text-blue-500 underline hover:text-blue-700 transition-colors duration-200 mr-4"
        (click)="fetchDocumentContent()"
    >
        <img src="assets/question.png" alt="Help Icon" class="w-4 h-4 mr-2" />
        {{ 'userManual' | translate }}
    </span>

   

    <!-- Title aligned in the center -->
    <div class="text-center flex-grow">
        <span class="text-xl font-medium text-indigo-500">{{ 'newLifeCertificateApplication' | translate }}</span>
    </div>

    <!-- Language Toggle -->
<div class="language-toggle-container">
 
  <mat-slide-toggle
    class="language-toggle"
    [checked]="selectedLanguage === 'mr'"
    (change)="changeLanguage($event.checked ? 'mr' : 'en')"
    disableRipple
  >
    <div class="toggle-label">
      <span class="language-en" [class.active]="selectedLanguage === 'en'">En</span>
      <span class="language-mr" [class.active]="selectedLanguage === 'mr'">Mr</span>
    </div>
  </mat-slide-toggle>
</div>
</div>

    <form [formGroup]="pensionerSearchForm" (ngSubmit)="searchEmpByPanNo()"  >
      
      <fieldset class='p-3 border border-black rounded-md'>
          <legend class="text-orange-500 text-lg	"> {{ 'pensionerSearch' | translate }}</legend>
             
          <div class="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4 ">
           
  
            <mat-form-field appearance="fill" >
              <mat-label>Pan No.</mat-label>
              <input matInput formControlName="panNo" placeholder="Pan No." (keypress)="restrictPanInput($event)"/>
              <mat-hint class="text-gray-800">Example: ABCDE1234F</mat-hint>
              <mat-error *ngIf="pensionerSearchForm.get('panNo').hasError('required') && pensionerSearchForm.get('panNo').touched">
                  Pan No. is required
              </mat-error>
              <mat-error *ngIf="pensionerSearchForm.get('panNo').hasError('pattern')">
                Please enter a valid Pan Card Number.
              </mat-error>
          </mat-form-field>
          

          </div>

         <button type="submit" 
          class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-blue-500 hover:bg-blue-600 hover:shadow-lg focus:outline-none">
          Search
      </button>
      
      
        </fieldset>
  </form>

    <form [formGroup]="applicationForm" (ngSubmit)="save()">
    
            <div
            class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0">
            <p class="m-0 px-1 pt-2 font-serif-bold text-lg">{{ 'basicDetailsTitle' | translate }}</p>
          </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">

                <mat-form-field class="w-full">
                    <mat-label>{{ 'firstname' | translate }}</mat-label>
                    <input matInput formControlName="firstName"  maxlength="50"
                    
                    required  />
                    <mat-error *ngIf="applicationForm.get('firstName').hasError('required')">
                      {{ 'firstNameRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('firstName').hasError('pattern')">
                      {{ 'firstNameErrorPattern' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="w-full">
                    <mat-label>{{ 'middlename' | translate }}</mat-label>
                    <input matInput formControlName="middleName"  maxlength="50"    />
                    <mat-error *ngIf="applicationForm.get('middleName').hasError('pattern')">
                      {{ 'middleNameErrorPattern' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="w-full">
                    <mat-label>{{ 'lastname' | translate }}</mat-label>
                    <input matInput formControlName="lastName"  maxlength="50" required   />
                    <mat-error *ngIf="applicationForm.get('lastName').hasError('required')">
                      {{ 'lastNameRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('lastName').hasError('pattern')">
                      {{ 'lastNameErrorPattern' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="w-full">
                    <mat-label>{{ 'pensionername' | translate }}</mat-label>
                    <input matInput formControlName="pensionerName"  maxlength="50" required />
                    <mat-error *ngIf="applicationForm.get('pensionerName').hasError('required')">
                      {{ 'penionerNameRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('pensionerName').hasError('pattern')">
                      {{ 'pensionerNameErrorPattern' | translate }}
                   </mat-error>
                  </mat-form-field>

                    <mat-form-field  class="w-full">
                      <mat-label>{{ 'genderSelection' | translate }}</mat-label>

                      <mat-select formControlName="gender" placeholder="Select Gender" required>
                        <mat-option value="Male">{{ 'maleOption' | translate }}</mat-option>
                        <mat-option value="Female">{{ 'femaleOption' | translate }}</mat-option>
                        <mat-option value="Other">{{ 'otherOption' | translate }}</mat-option>
                      </mat-select>
                      <mat-error *ngIf="applicationForm.get('gender').hasError('required')">
                        {{ 'genderRequired' | translate }}
                      </mat-error>
                    </mat-form-field>
                  
                    <mat-form-field class="w-full">
                        <mat-label>Phone Number</mat-label>
                        <input onKeyPress="if(this.value.length==10) return false;" type="number" (keypress)="keyPress($event)" type="text"  id="phone-number"  matInput formControlName="mobile" required />
                        <mat-error *ngIf="applicationForm.get('mobile').touched && applicationForm.get('mobile').hasError('required')">
                          Phone No. is required.
                      </mat-error>
                      <mat-error *ngIf="applicationForm.get('mobile').touched && applicationForm.get('mobile').hasError('pattern')">
                        Please enter a valid number
                      </mat-error>
                      </mat-form-field>

                      <mat-form-field class="w-full">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email"  maxlength="150"  />
                      
                        <mat-error *ngIf="applicationForm.get('email').hasError('email')">
                          Please enter a valid email address.
                        </mat-error>

                      </mat-form-field>

              
                  <mat-form-field class="w-full" >
                    <mat-label>Aadhar</mat-label>
                    <input matInput type="number" maxlength="14" (keypress)="restrictInput($event)" formControlName="adharNo"/>
                    <mat-error *ngIf="applicationForm.get('adharNo').hasError('required')">
                      {{ 'aadharRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('adharNo').hasError('pattern')">
                      Please enter a valid  Aadhar Number.
                    </mat-error>
                 
                
                  </mat-form-field>

                  <mat-form-field appearance="fill" >
                    <mat-label>Pan No.</mat-label>
                    <input matInput formControlName="panNo" maxlength="10" (keypress)="restrictPanInput($event)"  placeholder="panNo" />
                    <mat-hint class="text-gray-800">Example: ABCDE1234F</mat-hint>
                    <mat-error *ngIf="applicationForm.get('panNo').hasError('required') && applicationForm.get('panNo').touched">
                      {{ 'panRequired' | translate }}
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('panNo').hasError('pattern')">
                      Please enter a valid Pan Card Number.
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field class="w-full">
                    <mat-label>{{ 'address' | translate }}</mat-label>
                    <input matInput formControlName="address" required  />
                    <mat-error *ngIf="applicationForm.get('address').hasError('required')">
                      {{ 'addressRequired' | translate }}
                    </mat-error>
                  </mat-form-field>
              
            </div>

        

            <div
            class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0">
            <p class="m-0 px-1 pt-2 font-serif-bold text-lg">{{ 'bankInfoTitle' | translate }}</p>
          </div>
               
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">

              <mat-form-field class="w-full">
                <mat-label>{{ 'bankName' | translate }}</mat-label>
                <input matInput formControlName="bankName"  maxlength="50"  required  />
                <mat-error *ngIf="applicationForm.get('bankName').hasError('required')">
                  {{ 'bankNameRequired' | translate }}                </mat-error>
                <mat-error *ngIf="applicationForm.get('bankName').hasError('pattern')">
                  {{ 'bankNameErrorPattern' | translate }}
                 </mat-error>

              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>{{ 'branchName' | translate }}</mat-label>
                <input matInput formControlName="branchName"  maxlength="50"  required />
                <mat-error *ngIf="applicationForm.get('branchName').hasError('required')">
                  {{ 'branchNameRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="applicationForm.get('branchName').hasError('pattern')">
                  {{ 'branchNameErrorPattern' | translate }}
               </mat-error>
              </mat-form-field>
               
                <mat-form-field class="w-full">
                    <mat-label>{{ 'accountType' | translate }}</mat-label>
                    <mat-select formControlName="accountType" [disabled]="true" >
                        <mat-option *ngFor="let item of accTypes" [value]="item.accType">{{ item.accType }}</mat-option>
                        <mat-option *ngIf="accTypes?.length === 0" [value]="'noOptions'">No options available</mat-option>

                      </mat-select>
                    <mat-error *ngIf="applicationForm.get('accountType').hasError('required')">
                      Account Type is required.
                  </mat-error>
                </mat-form-field>

                <mat-form-field class="w-full">
                    <mat-label>Account No. / खाते क्रमांक</mat-label>
                    <input matInput formControlName="accountNo" type="number"  required  />
                    <mat-error *ngIf="applicationForm.get('accountNo').hasError('required')">
                      {{ 'accNoRequired' | translate }}
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="w-full">
                    <mat-label>IFSC Code</mat-label>
                    <input matInput formControlName="ifscCode"  required  />
                    <mat-hint class="text-gray-800">Example: SBIN0001234</mat-hint>
                    <mat-error *ngIf="applicationForm.get('ifscCode').hasError('required')">
                      {{ 'ifscRequired' | translate }} 
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('ifscCode').hasError('pattern')">
                      Please enter a valid IFSC code.
                    </mat-error>
                  </mat-form-field>


            </div>

            <div
            class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0">
            <p class="m-0 px-1 pt-2 font-serif-bold text-lg">{{ 'livePhotoUploadTitle' | translate }}</p>
          </div>
    
               
                  <div style="text-align: center; margin-top: 20px; margin-bottom: 20px;" *ngIf="showWebcam || capturedImage">
                    <div class="image-container">
                      <webcam *ngIf="showWebcam"        [videoOptions]="videoOptions" class="webcam-feed no-mirror"
                      (imageCapture)="handleImage($event)" [trigger]="trigger.asObservable()" class="webcam-feed"></webcam>
                      <img *ngIf="!showWebcam && capturedImage" [src]="capturedImage" class="captured-image">
                    </div>
                  </div>
                  
                  <div style="text-align: center;">
                    <button mat-raised-button color="primary" type="button"   (click)="toggleWebcam()">{{ showWebcam ? 'Hide Webcam' : 'Show Webcam' }}</button> &nbsp;&nbsp;&nbsp;
                    <button mat-raised-button color="primary" type="button"   (click)="captureImage()" [disabled]="!showWebcam">Capture Image</button>
                  </div>
                  <div style="text-align: center;">
                    <mat-error *ngIf="showPhoto == false && uploadClicked == true">
                      Please capture live photo
                    </mat-error>
                  </div>
                  <br><br>
                  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2 "> 

             
                    <div class="w-full">
                      <label class="text-sm font-medium text-gray-700" for="reviewDate">{{ 'Date-Time' | translate }}</label>
                      <input  type="datetime-local" id="dateTime" name="dateTime" formControlName="currentDateTime" readonly
                      class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                      required />
                      <p class="mt-1 text-sm text-red-600" *ngIf="applicationForm.get('date').hasError('required') && applicationForm.get('date').touched">
                           Date is required.
                      </p>
                    </div>

                    
  
                    <mat-form-field class="w-full">
                      <mat-label>{{ 'place' | translate }}</mat-label>
                      <input matInput formControlName="place" required readonly />
                      <mat-error *ngIf="applicationForm.get('place').hasError('required')">
                        {{ 'placeRequired' | translate }} 
                      </mat-error>
                    </mat-form-field>
            </div>
                  

        <div
        class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0">
        <p class="m-0 px-1 pt-2 font-serif-bold text-lg">{{ 'instructionsTitle' | translate }}</p>
      </div>

      <div class="notice-container">
        <h3>{{ title }}</h3>
        <p>• {{ points[0] }}</p>
      
        <div *ngIf="showMore">
          <p *ngFor="let point of points.slice(1)" class="pt-2">• {{ point }}</p>
        </div>
      
        <a href="#" (click)="toggleShowMore($event)">{{ showMore ? 'Show Less' : 'Show More' }}</a>
      </div>
      

      <div
      class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0">
      <p class="m-0 px-1 pt-2 font-serif-bold text-lg">{{ 'documentsTitle' | translate }}</p>
    </div>

    

      <!-- <div class="overflow-x-auto mt-2">
        <table class="table-auto border-collapse border border-gray-300 w-full">
          <thead>
            <tr class="bg-indigo-200">
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Sr. No.</th>
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Document Name</th>
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Download (Step 1)</th>
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Upload (Step 2)</th>
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of documentsData; let i = index" >
              <td class="px-4 py-2 border border-gray-300">{{ i + 1 }}</td>
              <td class="px-4 py-2 border border-gray-300" [ngClass]="{ ' text-red-600': doc.mandatory }">{{ doc.mandatory ? doc.docName + '*' : doc.docName }}

              </td>
              <td class="px-4 py-2 border border-gray-300">
                
                <ng-container *ngIf="doc.docName === 'Declaration Certificate'">
                  <button mat-button  (click)="downloadForm(doc)" type="button" class="bg-gray-200 text-gray-700 rounded-lg">
                    <mat-icon>download</mat-icon>
                    <span class="ml-2">{{ 'declarationButtonLabel' | translate }}</span>
                  </button>
                  <br>
                  <mat-hint>Step 1 : {{ 'declarationMessage' | translate }}</mat-hint>
                
                </ng-container>
                <ng-container *ngIf="doc.docName != 'Bank NOC' && doc.docName != 'Declaration Certificate'">
                 -
                </ng-container>
                <ng-container *ngIf="doc.docName === 'Bank NOC'">
                  <button mat-button  (click)="downloadNocForm(doc)" type="button" class="bg-gray-200 text-gray-700 rounded-lg">
                    <mat-icon>download</mat-icon>
                    <span class="ml-2">{{ 'nocButtonLable' | translate }}</span>
                  </button>
                  <br>
                  <mat-hint>Step 1 : {{ 'nocMessage' | translate }}</mat-hint>

                
                </ng-container>
              </td>
              <td class="px-4 py-2 border border-gray-300">
                <div class="flex items-center justify-center" >
                  <div class="w-full relative flex flex-1">
                    <button mat-button (click)="file1.click()" type="button" class="bg-gray-200 text-gray-700 rounded-lg">
                      <mat-icon>attach_file</mat-icon>
                      <span class="ml-2">Upload File</span>
                    </button>
                    <input (change)="uploadDocument($event, doc.docName)" class="hidden" id="file_input" #file1 type="file">
                    <p *ngIf="showDocument[doc.docName]" style="margin-top:9px !important" class="text-sm font-light justify-center mt-2 text-lime-500 ml-2">Document uploaded successfully</p>
                  </div>
                 
                </div>
                <mat-hint *ngIf="doc.docName == 'Declaration Certificate'">Step 2 : {{ 'declarationMessageUpload' | translate }}</mat-hint>
                <mat-hint *ngIf="doc.docName == 'Bank NOC'">Step 2 : {{ 'nocMessageUpload' | translate }}</mat-hint>

              </td>
             
              <td class="px-4 py-2 border border-gray-300 text-center" >
                <mat-icon color="primary" (click)="removeDocument(doc.docName)" *ngIf="showDocument[doc.docName]">
                  delete
                </mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
      
      <div class="mt-2">
        <table class="table-auto border-collapse border border-gray-300 w-full hidden sm:table">
          <thead>
            <tr class="bg-indigo-200">
              <th class="px-2 py-2 font-serif text-sm">Sr. No.</th>
              <th class="px-2 py-2 font-serif text-sm">Document Name</th>
              <th class="px-2 py-2 font-serif text-sm">Download (Step 1)</th>
              <th class="px-2 py-2 font-serif text-sm">Upload (Step 2)</th>
              <th class="px-2 py-2 font-serif text-sm">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of documentsData; let i = index">
              <td class="px-2 py-2 border border-gray-300">{{ i + 1 }}</td>
              <td class="px-2 py-2 border border-gray-300" [ngClass]="{ 'text-red-600': doc.mandatory }">{{ doc.mandatory ? doc.docName + '*' : doc.docName }}</td>
              <td class="px-2 py-2 border border-gray-300">
                <ng-container *ngIf="doc.docName === 'Declaration Certificate'">
                  <button mat-button (click)="downloadForm(doc)" type="button" class="!bg-gray-800 !text-white rounded-lg">
                    <mat-icon>download</mat-icon>
                    <span class="ml-2">{{ 'declarationButtonLabel' | translate }}</span>
                  </button>
                  <br>
                  <mat-hint class="font-bold">Step 1: {{ 'declarationMessage' | translate }}</mat-hint>
                </ng-container>
                <ng-container *ngIf="doc.docName !== 'Bank NOC' && doc.docName !== 'Declaration Certificate'">-</ng-container>
                <ng-container *ngIf="doc.docName === 'Bank NOC'">
                  <button mat-button (click)="downloadNocForm(doc)" type="button" class="!bg-gray-800 !text-white rounded-lg">
                    <mat-icon>download</mat-icon>
                    <span class="ml-2">{{ 'nocButtonLable' | translate }}</span>
                  </button>
                  <br>
                  <mat-hint class="font-bold">Step 1: {{ 'nocMessage' | translate }}</mat-hint>
                </ng-container>
              </td>
              <td class="px-2 py-2 border border-gray-300">
                <button mat-button (click)="file1.click()" type="button" class="!bg-gray-800 !text-white rounded-lg">
                  <mat-icon>attach_file</mat-icon>
                  <span class="ml-2">Upload File</span>
                </button>
                <input (change)="uploadDocument($event, doc.docName)" class="hidden" #file1 type="file">
                <p *ngIf="showDocument[doc.docName]" class="text-xs font-light text-lime-500 mt-2">Document uploaded successfully</p>
                <br>
                <mat-hint class="font-bold" *ngIf="doc.docName === 'Declaration Certificate'">Step 2: {{ 'declarationMessageUpload' | translate }}</mat-hint>
                <mat-hint class="font-bold" *ngIf="doc.docName === 'Bank NOC'">Step 2: {{ 'nocMessageUpload' | translate }}</mat-hint>
              </td>
              <td class="px-2 py-2 border border-gray-300 text-center">
                <mat-icon color="primary" (click)="removeDocument(doc.docName)" *ngIf="showDocument[doc.docName]">delete</mat-icon>
              </td>
            </tr>
          </tbody>
        </table>
      
        <!-- Mobile View -->
        <div *ngFor="let doc of documentsData; let i = index" class="border border-gray-300 rounded-lg mb-2 p-4 sm:hidden">
          <div class="font-bold">Sr. No. {{ i + 1 }}</div>
          <div class="font-semibold" [ngClass]="{ 'text-red-600': doc.mandatory }">{{ doc.mandatory ? doc.docName + '*' : doc.docName }}</div>
          <div class="mt-2">
            <span class="font-semibold">Download (Step 1):</span>
            <ng-container *ngIf="doc.docName === 'Declaration Certificate'">
              <button mat-button (click)="downloadForm(doc)" type="button" class="!bg-gray-800 !text-white rounded-lg">
                <mat-icon>download</mat-icon>
                <span class="ml-2">{{ 'declarationButtonLabel' | translate }}</span>
              </button>
              <mat-hint class="block">Step 1: {{ 'declarationMessage' | translate }}</mat-hint>
            </ng-container>
            <ng-container *ngIf="doc.docName !== 'Bank NOC' && doc.docName !== 'Declaration Certificate'">-</ng-container>
            <ng-container *ngIf="doc.docName === 'Bank NOC'">
              <button mat-button (click)="downloadNocForm(doc)" type="button" class="!bg-gray-800 !text-white rounded-lg">
                <mat-icon>download</mat-icon>
                <span class="ml-2">{{ 'nocButtonLable' | translate }}</span>
              </button>
              <mat-hint class="block">Step 1: {{ 'nocMessage' | translate }}</mat-hint>
            </ng-container>
          </div>
          <div class="mt-2">
            <span class="font-semibold">Upload (Step 2):</span>
            <br>
            <button mat-button (click)="file1.click()" type="button" class="!bg-gray-800 !text-white rounded-lg">
              <mat-icon>attach_file</mat-icon>
              <span class="ml-2">Upload File</span>
            </button>
            <input (change)="uploadDocument($event, doc.docName)" class="hidden" #file1 type="file">
            <p *ngIf="showDocument[doc.docName]" class="text-xs font-light text-lime-500 mt-2">Document uploaded successfully</p>
            <mat-hint class="font-bold" *ngIf="doc.docName === 'Declaration Certificate'" class="block">Step 2: {{ 'declarationMessageUpload' | translate }}</mat-hint>
            <mat-hint class="font-bold" *ngIf="doc.docName === 'Bank NOC'" class="block">Step 2: {{ 'nocMessageUpload' | translate }}</mat-hint>
          </div>
          <div class="mt-2">
            <span class="font-semibold">Action:</span>
            <mat-icon color="primary" (click)="removeDocument(doc.docName)" *ngIf="showDocument[doc.docName]">delete</mat-icon>
          </div>
        </div>
      </div>
      
      
         
        <div class="float-right p-4" >
            <button type="button"  (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                  Go back
                </button>
                <button        
                type="submit" class="text-white disabled:bg-gray-300 bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button>
        </div>

       
    </form>
</div>
<div class="mt-10"></div>


