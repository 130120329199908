<div class="bg-gray-100 p-6">
    <mat-icon id="close-icon" style="float: right; cursor: pointer;" class="close-icon" [mat-dialog-close]="true">
        close
    </mat-icon>
    <div class="mx-auto max-w-md">
        <h1 class="text-2xl font-bold mb-8">{{ addedit ? 'Edit' : 'Add' }} Asset Sub Category</h1>
        <form [formGroup]="form" (ngSubmit)="save()">
            <mat-form-field class="w-full">
                <mat-label>Select Asset Category</mat-label>
                <mat-select formControlName="assetCategoryId" (selectionChange)="onAssetChange($event)" maxlength="50" required>
                    <mat-option *ngFor="let type of assetCategoryTypes" [value]="type.id">
                        {{ type.assetCategoryName }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['assetCategoryId'].hasError('required')">
                    Asset Category is required
                </mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Enter Asset Sub Category</mat-label>
                <input matInput formControlName="assetSubCategoryName"  maxlength="50" required pattern="^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$">
                <mat-error *ngIf="form.controls['assetSubCategoryName'].hasError('required')">
                    Asset Sub Category is required
                </mat-error>
            </mat-form-field>
            <div class="button-container">
                <button type="submit" class="btn-save" [disabled]="!form.valid">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>