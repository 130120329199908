import { Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ActivationInactivationConfirmationComponent } from "src/app/master-data/activation-inactivation-confirmation/activation-inactivation-confirmation.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
import { AddAssetSubTypeComponent } from "./add-asset-sub-type/add-asset-sub-type.component";

@Component({
  selector: "app-asset-sub-type",
  templateUrl: "./asset-sub-type.component.html",
  styleUrls: ["./asset-sub-type.component.scss"],
})
export class AssetSubTypeComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data: any;
  columnNames: any[] = [
    {
      columnDef: "sno",
      header: "Sr.No",
    },
    {
      columnDef: "assetTypeName",
      header: "Asset Type Name",
    },
    {
      columnDef: "assetSubTypeName",
      header: "Asset Sub Type Name",
    },
    {
      columnDef: "actions",
      header: "Actions",
    },
  ];
  assetssubtype = new MatTableDataSource<any>();
  displayedColumns: any[] = [];
  contentLoaded = false;
  statusAction: any = "";
  checked: boolean = false;
  activeFlag: any;
  row: any;
  assetSubTypeName:any

  constructor(
    private assetService: AssetServiceService,
    private dialog: MatDialog,
    private _snackbar: SnackbarMsgService,
    
  ) {}

  ngOnInit() {
    this.displayedColumns = this.displayedColumns.concat(
      this.columnNames.map((x) => x.columnDef)
    );
    this.getAssetSubType();
  }

  getAssetSubType() {
    this.assetService.getAssetSubType().subscribe(
      (data:any) => {
        this.assetssubtype.data = data as any[] ;
        this.assetSubTypeName = this.assetssubtype.data.map(item => item.assetSubTypeName);
        this.assetssubtype.data = data.sort((a: any, b: any) => {
          const aIsActive = a.activeFlag === 'Y' ? 0 : 1;
          const bIsActive = b.activeFlag === 'Y' ? 0 : 1;

          if (aIsActive !== bIsActive) {
            return aIsActive - bIsActive; 
          }
          return a.assetSubTypeName.localeCompare(b.assetSubTypeName);
        });



        setTimeout(() => {

          this.assetssubtype.paginator = this.paginator;
        });

        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      (err) => {
        this.contentLoaded = true;
      }
    );
  }

  addAssetSubType() {
    const dialogRef = this.dialog.open(AddAssetSubTypeComponent, {
      width: "550px",
      data: {
        isEditMode: false,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        this.contentLoaded = false;
        this.getAssetSubType();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetssubtype.filter = filterValue.trim().toLowerCase();
  }

  onSlideToggleChange(row: any, event: any): void {
    const previousState = row.activeFlag;
    if (event.checked == true) {
      this.statusAction = "Activate";
      row.activeFlag = "Y";
    } else {
      this.statusAction = "Deactivate";
      row.activeFlag = "N";
    }
    this.assetSubTypeName = row.assetSubTypeName
    var formData = {
      id: row.id,
      activeFlag: row.activeFlag,
    };
    const dialogRef = this.dialog.open(
      ActivationInactivationConfirmationComponent,
      {
        width: "550px",
        data: {
          mode: this.statusAction,
          component: `${ this.assetSubTypeName }`,
        },
     
        autoFocus: false,
        restoreFocus: false,
        disableClose:true,
      }
    );

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        if (this.statusAction == "Activate") {
          this.assetService.activateInactivateAssetSubType(formData).subscribe(
            (res) => {
              this._snackbar.success(
                ` Record ${this.statusAction}d Successfully`
              );
              this.getAssetSubType();
            },
            (err) => {
              this._snackbar.error("Activation Failed");
              this.getAssetSubType();
            }
          );
        } else {
          this.assetService.activateInactivateAssetSubType(formData).subscribe(
            (res) => {
              this._snackbar.success(
                ` Record ${this.statusAction}d Successfully`
              );
              this.checked = !this.checked;
              this.getAssetSubType();
            },
            (err) => {
              this._snackbar.error("Deactivation Failed");
              this.getAssetSubType();
            }
          );
        }
      } else {
        this.getAssetSubType();
      }
    });
  }

  onEdit(row: any) {
    const dialogRef = this.dialog.open(AddAssetSubTypeComponent, {
      width: "550px",
      data: {
        isEditMode: true,
        row: row,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data == "Success") {
        // this._snackbar.success("Asset Type Edited Successfully");
        this.contentLoaded = true;
        this.getAssetSubType();
      }
    });
  }
}
