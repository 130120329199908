import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from "../../_services/master-data/users.service";
import { SnackbarMsgService } from "../../shared/Toast-service/snackbar-msg.service";
import { CustomValidatorService } from "src/app/_services/custom-validator.service";
import { CommonService } from "src/app/_services/Common-Services/common.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { PropertyTaxService } from "src/app/_services/property-tax/property-tax.service";
import { WaterTaxService } from "src/app/_services/water-tax/water-tax.service";
import { RtsService } from "src/app/_services/rts.service";
import { environment } from "src/environments/environment";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import * as moment from "moment";
import { NGB_DATEPICKER_18N_FACTORY } from "@ng-bootstrap/ng-bootstrap/datepicker/datepicker-i18n";
export interface OutstandingAmount {
  totalOutstanding: number;
  month: string;
  year: number;
}
@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
  styleUrls: ["./user-profile.component.scss", "./primeflex.scss"],
})
export class UserProfileComponent {
  form!: FormGroup;
  userId: string | null = "";
  designations: any[] = [];
  designationName: string = "";
  username: string;
  activeFlag: string;
  showPropertySection: boolean = false;
  showWaterSection: boolean = false;
  showPropertyLegderSection: boolean = false;
  showWaterLegderSection: boolean = false;
  showPropertyBillSection: boolean = false;
  showWaterBillSection: boolean = false;
  receiptButton: boolean = false;
  propertyNumber: string = "";
  propertyNumbers: string[] = [];
  propertyDetails: any = null;
  waterData: any = null;
  currentBill: any;
  previousBill: any;
  propertyId: any;
  waterId: any;
  assessmentDetails: any;
  outstandingAmountsByMonth: { [key: string]: OutstandingAmount } = {};

  authResidential: any;
  unAuthResidential: any;
  nonResidential: any;
  unAuthNonResidential: any;
  consumerNumber: string = "";
  consumerNumbers: string[] = [];
  waterDetails: any = null;
  propertyNo: any;
  ledgerData: any;
  billData: any;
  email: any;
  mobile: any;
  demandAmount: number = 0;
  collection: number = 0;
  penaltyAmount: number = 0;
  billAmount: number = 0;
  outstandingAmount: number = 0;
  advanceAmount: number = 0;
  paymentAmount: any = 0;
  dataLoaded: boolean = false;
  breadcrumbs: any[] = [];
  showApplicationSection: boolean = false;
  showgrievanceSection: boolean = false;
  ugpPrevId = Number(localStorage.getItem("ugpPrevId"));
  resp = {
    essentials: {
      requestId: null,
    },
    id: "652f83749a481c0028e3dbb6",
    patronId: "651ee0f56b624b002a5c08e7",
    url: null,
    requestId: null,
    task: "url",
    result: {
      url: "https://api.digitallocker.gov.in/public/oauth2/1/authorize?client_id=3DF9D55E&code_challenge=8LXkRuOlYU1kUCSUgp5FEE4HcJbHcenofwot6FFnDfw&code_challenge_method=S256&dl_flow&redirect_uri=https%3A%2F%2Fdigilocker.signzy.tech%2Fdigilocker-auth-complete&response_type=code&state=652f8374e59f510012c714bb",
      requestId: "652f8374e59f510012c714bb",
    },
  };
  showErrorproperty: boolean = false;
  showErrorconsumer: boolean = false;
  fileUrlProperty: SafeResourceUrl | null = null;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private dialog: MatDialog,
    private _snackBar: SnackbarMsgService,
    private taxService: PropertyTaxService,
    private wtSErvice: WaterTaxService,
    private rtsService: RtsService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.getBreadcrumbData();
    // window.location.reload();
    this.route.queryParamMap.subscribe((params) => {
      this.userId = localStorage.getItem("userId");
      // if (this.userId) {
      //   this.getPropertyBillReceipt(localStorage.getItem("propertyNo"));
      // }
      // Use the userId as needed
      console.log("User ID:", this.userId);
    });

    this.getDesignationData();
    this.getUsersDetails();
    if (
      localStorage.getItem("paymentStatus") !== null &&
      localStorage.getItem("paymentStatus") === "paymentInitiated"
    ) {
      this.receiptButton = true;
    } else {
      this.receiptButton = false;
    }
    if (
      localStorage.getItem("propertyNo") !== null &&
      localStorage.getItem("propertyNo") !== "null"
    ) {
      this.showPropertyInput();
      this.fetchPropertyData(localStorage.getItem("propertyNo"));
    }
    if (
      localStorage.getItem("consumerNumber") !== null &&
      localStorage.getItem("consumerNumber") !== "null"
    ) {
      this.showWaterInput();
      this.fetchWaterData(localStorage.getItem("consumerNumber"));
    } else {
      this.showWaterSection = false;
    }
    // localStorage.setItem("paymentStatus", "paymentInitiated");

    this.activeFlag = localStorage.getItem("activeFlag") || "N";

    this.form = this.fb.group({
      userFirstname: [""],
      userMiddleName: [""],
      userLastname: [""],
      userName: [""],
      userEmail: [""],
      userMobile: [""],
      // usrPassword: [""],
      dptName: [""],
      gender: [""],
      dob: [""],
      adress: [""],
      state: [""],
      city: [""],
      designationId: [""],
      designationName: [""],
      userActive: [""],
    });

    console.log("ugpPrevId", this.ugpPrevId);

    this.form.disable();
  }
  getPropertyBillReceipt(propertyNo: any) {
    this.taxService
      .getOnlineReceiptPayment(localStorage.getItem("propertyNo"))
      .subscribe((data: any) => {
        let base64Data = data.base64Data;
        const base64Prefix = "data:application/pdf;base64,";
        if (base64Data.startsWith(base64Prefix)) {
          base64Data = base64Data.replace(base64Prefix, "");
        }
        try {
          const byteCharacters = atob(base64Data);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl);
          localStorage.removeItem("paymentStatus");
          this.receiptButton = false;
          // window.location.reload();
        } catch (error) {
          console.error("Error decoding Base64 data:", error);
        }
      });
  }
  getWaterBillReceipt(consumerNumber: any) {
    const format = "pdf";
    this.wtSErvice
      .getReceiptPayment(
        localStorage.getItem("consumerNumber"),
        "Online",
        format
      )
      .subscribe((data: string) => {
        const base64String = data as string;
        const base64Prefix = "data:application/pdf;base64,";

        let base64Data = base64String;
        if (!base64String.startsWith(base64Prefix)) {
          base64Data = base64Prefix + base64String;
        }

        try {
          const byteCharacters = atob(base64Data.replace(base64Prefix, ""));
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: "application/pdf",
          });
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, "_blank");
        } catch (error) {
          console.error("Error opening PDF:", error);
          this._snackBar.error("Failed to open PDF");
        }
      });
  }
  showField(fieldName: string): boolean {
    const ugpPrevId = this.form.get("ugpPrevId").value;
    switch (fieldName) {
      case "userFirstname":
      case "userLastname":
      case "userMobile":
        return true; // Always visible
      case "userEmail":
      case "userMiddleName":
        return ugpPrevId === 3; // Only visible when ugpPrevId is 3
      default:
        return true;
    }
  }

  getBreadcrumbData() {
    this.commonService.breadcrumbs$.subscribe((breadcrumbs) => {
      this.breadcrumbs = breadcrumbs;
    });
  }
  getDesignationData() {
    this.commonService.getDesignation().subscribe((data: any) => {
      this.designations = data["designationMasterList"] as any[];
    });
  }
  getUsersDetails() {
    this.commonService.getUsersById(this.userId).then((data: any) => {
      this.form.patchValue({
        dptName: data.dptName,
        subDepId: data.subDptId,
        adress: data.adress,
        designationId: data.designationId,
        userMiddleName: data.userMiddleName,
        usrId: data.id,
        wardId: data.wardId,
        userFirstname: data.userFirstName,
        firstNameMr: data.userFirstNameMr,
        userLastname: data.userLastName,
        userName: data.userName,
        lastNameMr: data.userLastNameMr,
        gender: data.gender,
        userEmail: data.userEmail,
        userMobile: data.userMobile,

        // 'usrPassword' : data.userPassword,
      });
      this.email = data.userEmail;
      this.mobile = data.userMobile;
      this.setDesignationName(data.designationId);

      this.username = data.userName;
    });
  }

  isActiveFlagY(): boolean {
    return this.activeFlag === "Y";
  }
  onToggleActive(event: any) {
    const newActiveFlag = event.checked ? "Y" : "N";

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: newActiveFlag === "Y" ? "Activate" : "Deactivate",
        component: "User Status",
      },
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // If the user confirms the action
      if (result) {
        this.commonService
          .updateUserStatus(this.username, newActiveFlag)
          .subscribe(
            (response: any) => {
              // Use Observable<any> here
              // Check if the response status is success
              if (response.status === "success") {
                // Log success response and update the local storage
                console.log("Status updated:", response.message);
                localStorage.setItem("activeFlag", newActiveFlag);
                this.activeFlag = newActiveFlag;

                // Show success message
                this._snackBar.success(response.message);
              } else {
                // If not a success, handle the error message
                console.error("Error response:", response.message);
                this._snackBar.error(response.message);

                // Revert the toggle state if the API call fails
                event.source.checked = !event.checked;
              }
            },
            (error) => {
              // Log error response for debugging
              console.error("Error updating status:", error);
              console.error("Error response body:", error.error);

              // Show a generic error message
              this._snackBar.error(
                "Failed to update status. Please try again later."
              );

              // Revert the toggle state if the API call fails
              event.source.checked = !event.checked;
            }
          );
      } else {
        // If the user cancels, revert the toggle state
        event.source.checked = !event.checked;
      }
    });
  }

  setDesignationName(designationId: string) {
    const designation = this.designations.find((d) => d.id === designationId);
    console.log("designation", designation);
    if (designation) {
      this.designationName = designation.designation; // Adjust the property name if necessary
      this.form.get("designationName")?.setValue(this.designationName);
    }
  }

  onScroll(e) {}
  showPropertyInput() {
    this.propertyNumbers.length = 0;
    this.showApplicationSection = false;
    this.showgrievanceSection = false;
    this.taxService
      .getPropertyByUserId(this.userId)
      .subscribe((response: any[]) => {
        response.forEach((item: any) => {
          this.propertyNumbers.push(item.propertyNo);
        });
      });
    this.showPropertySection = true;
    this.showWaterSection = false;
    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = false;
    this.showPropertyBillSection = false;
    this.showWaterBillSection = false;
    this.consumerNumbers.length = 0;
    this.waterData = null;
  }
  showWaterInput() {
    this.consumerNumbers.length = 0;
    this.showApplicationSection = false;
    this.showgrievanceSection = false;
    this.commonService
      .getWaterByUserId(this.userId)
      .subscribe((response: any[]) => {
        response.forEach((item: any) => {
          this.consumerNumbers.push(item.consumerNumber);
        });
      });
    this.showWaterSection = true;
    this.showPropertySection = false;
    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = false;
    this.showPropertyBillSection = false;
    this.showWaterBillSection = false;

    this.propertyNumbers.length = 0;
    this.propertyDetails = null;
  }
  addPropertyNumber(): void {
    if (this.propertyNumber) {
      const requestData = {
        id: null,
        // moduleName: "Property",
        userId: this.userId,
        propertyNo: this.propertyNumber,
        consumerNumber: null,
        activeFlag: "Y",
      };
      this.taxService.profileApplication(requestData).subscribe((data) => {
        console.log("profileApplication", data);

        if (data["status"].toLowerCase() == "success") {
          this.propertyNumbers.length = 0;
          this.taxService
            .getPropertyByUserId(this.userId)
            .subscribe((response: any[]) => {
              response.forEach((item: any) => {
                this.propertyNumbers.push(item.propertyNo);
              });
            });
          // this.propertyNumbers.push(this.propertyNumber);
        } else {
          this._snackBar.error(data["message"]);
        }
      });
      this.propertyNumber = "";
    } else {
      this._snackBar.error("Please Enter Property Number");
    }
  }
  addConsumerNumber(): void {
    if (this.consumerNumber) {
      const requestData = {
        id: null,
        // moduleName: "Property",
        userId: this.userId,
        propertyNo: null,
        consumerNumber: this.consumerNumber,
        activeFlag: "Y",
      };
      this.commonService
        .profileApplicationWater(requestData)
        .subscribe((data) => {
          if (data["status"].toLowerCase() == "success") {
            this.consumerNumbers.length = 0;
            this.commonService
              .getWaterByUserId(this.userId)
              .subscribe((response: any[]) => {
                response.forEach((item: any) => {
                  this.consumerNumbers.push(item.consumerNumber);
                });
              });
            // this.propertyNumbers.push(this.propertyNumber);
          } else {
            this._snackBar.error(data["message"]);
          }
        });
      this.consumerNumber = ""; // Clear input after adding
    } else {
      this._snackBar.error("Please Enter Consumer Number");
    }
  }
  fetchPropertyData(property: string): void {
    this.showPropertyBillSection = false;
    this.showWaterBillSection = false;

    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = false;
    this.taxService.getByPropertyNo(property).subscribe(
      (response: any) => {
        this.propertyDetails = {
          propertyNo: response.propertyNo,
          address: response.postalAddress,
          wardName: response.wardName,
          nodeName: response.nodeName,
          sectorName: response.sectorName,
          usageType: response.usageType,
          subUsageType: response.subUsageType,
        };
        this.propertyId = response.id;
        localStorage.setItem("propertyNo", response.propertyNo);
        localStorage.setItem("consumerNumber", null);
      },
      (error) => {
        console.error("API Error:", error);
      }
    );
  }
  fetchWaterData(water: string): void {
    this.showPropertyBillSection = false;
    this.showWaterBillSection = false;

    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = false;
    this.wtSErvice
      .getWaterMaster(
        "api/transaction/trnNewWaterConnection/getByConsumerId?ConsumerId=" +
          water
      )
      .subscribe(
        (response: any) => {
          this.waterData = {
            consumerNumber: response.consumerNumber,
            address: response.postalAddress,
            wardName: response.wardName,
            nodeName: response.nodeName,
            sectorName: response.sectorName,
            usageType: response.usageType,
            subUsageType: response.subUsageType,
          };
          this.waterId = response.id;
          localStorage.setItem("propertyNo", null);
          localStorage.setItem("consumerNumber", response.consumerNumber);
        },
        (error) => {
          console.error("API Error:", error);
        }
      );
  }
  getPropertyLedgerData(propertyNo: string) {
    this.showPropertyLegderSection = true;
    this.showWaterLegderSection = false;
    this.showPropertyBillSection = false;
    this.showWaterBillSection = false;

    this.showWaterSection = false;
    this.showPropertySection = true;
    if (propertyNo.trim() !== "") {
      this.taxService.getByPropertyCodeGroupByLedgerDate(propertyNo).subscribe(
        (result) => {
          this.ledgerData = result["rptLedgerDaoList"];
          // this.propertyData = result["newPropertyRegistration"];
          this.billData = result["billDao"];
          // //remove values with rec_status != 1
          // this.ledgerData = this.ledgerData.filter((obj) => obj.recStatus == 1);
          this.ledgerData = this.ledgerData.filter(
            (obj) => obj.recStatus !== 0
          );
          // this.dataLoaded = true;
          if (this.billData != null) {
            this.outstandingAmount =
              this.billData.prevosAmt +
              this.billData.billAmt +
              this.billData.dpcAmt2 -
              this.billData.advanceAmt +
              this.billData.dpcWaiveOffAmt;
          }
          // if ((this.propertyData.propertyType = "Residential")) {
          //   this.propertySubType = this.billData.resiUsage;
          // } else if ((this.propertyData.propertyType = "Commercial")) {
          //   this.propertySubType = this.billData.commUsage;
          // } else if ((this.propertyData.propertyType = "Industrial")) {
          //   this.propertySubType = this.billData.indusUsage;
          // }
          // console.log(
          //   "Sub Owners",
          //   this.propertyData.trnJointOwnerDetailsList
          // );
          // if (this.propertyData.trnJointOwnerDetailsList != null) {
          //   this.subOwners = this.propertyData.trnJointOwnerDetailsList;
          //   console.log("Sub Owners", this.subOwners);
          // }
          let demanded = 0;
          let collected = 0;
          let penalty = 0;
          let bill = 0;
          let adnvaceAmt = 0;
          for (let i = 0; i < this.ledgerData.length; i++) {
            if (this.ledgerData[i].transType == 1) {
              demanded += this.ledgerData[i].ledgerAmount;
            } else if (this.ledgerData[i].transType == 2) {
              collected += this.ledgerData[i].ledgerAmount;
              penalty += this.ledgerData[i].dpcAmount;
              adnvaceAmt += this.ledgerData[i].advanceAmount;
              bill += this.ledgerData[i].billAmount;
            }
          }
          this.demandAmount = demanded;
          this.collection = collected;
          this.penaltyAmount = penalty;
          this.billAmount = bill + adnvaceAmt;
          this.advanceAmount = adnvaceAmt;
          if (this.ledgerData == null) {
            this._snackBar.error("No data found");
          }
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.log("Search is not enabled or Property No is empty.");
      this._snackBar.error("Search is not enabled or Property Code is empty.");
    }
  }

  getPropertyBillData(propertyNo: string) {
    this.showPropertyBillSection = true;
    this.showWaterBillSection = false;

    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = false;
    this.showWaterSection = false;
    this.outstandingAmountsByMonth = {};

    if (propertyNo.trim() !== "") {
      this.taxService.searchPropertyBillByPropertyNoPdf(propertyNo).subscribe(
        (data: any) => {
          // const dataUrl = `data:application/pdf;base64,${data}`;
          this.fileUrlProperty =
            this.sanitizer.bypassSecurityTrustResourceUrl(data); // Sanitize URL for iframe
          console.log("Sanitized URL:", this.fileUrlProperty);
        },
        (err) => {
          console.error("Error fetching PDF:", err);
        }
      );
    } else {
      this._snackBar.error("Please enter property number!");
    }
  }
  groupOutstandingAmounts() {
    this.previousBill.forEach((bill) => {
      const date = new Date(bill.billDateFrom);
      const monthYear = `${date.getFullYear()}-${date.getMonth() + 1}`; // Format as "YYYY-Month"

      if (!this.outstandingAmountsByMonth[monthYear]) {
        this.outstandingAmountsByMonth[monthYear] = {
          totalOutstanding: 0,
          month: date.toLocaleString("default", { month: "long" }), // Get the month name
          year: date.getFullYear(),
        };
      }

      // Accumulate the outstanding amount
      this.outstandingAmountsByMonth[monthYear].totalOutstanding +=
        bill.outStandingAmount || 0;
      console.log(this.outstandingAmountsByMonth[monthYear].totalOutstanding);
    });
  }
  getTotalOutstanding(): number {
    return this.previousBill.reduce((total, bill) => {
      return total + (bill.totalTaxAmount || 0); // Handle undefined values
    }, 0);
  }
  calculateTotalAuthorizedResidential(): number {
    return this.authResidential.reduce(
      (sum, item) => sum + item.totalTaxAmount,
      0
    );
  }

  calculateTotalUnauthorizedResidential(): number {
    return this.unAuthResidential.reduce(
      (sum, item) => sum + item.totalTaxAmount,
      0
    );
  }

  calculateTotalNonResidential(): number {
    return this.nonResidential.reduce(
      (sum, item) => sum + item.totalTaxAmount,
      0
    );
  }

  calculateTotalUnauthorizedNonResidential(): number {
    return this.unAuthNonResidential.reduce(
      (sum, item) => sum + item.totalTaxAmount,
      0
    );
  }
  getTaxDataForDisplay() {
    const uniqueTaxNames = new Set<string>();

    // Gather all unique tax names
    [
      ...this.authResidential,
      ...this.unAuthResidential,
      ...this.nonResidential,
      ...this.unAuthNonResidential,
    ].forEach((item) => {
      uniqueTaxNames.add(item.taxName);
    });

    // Create structured data for display
    const taxData = Array.from(uniqueTaxNames).map((taxName) => {
      return {
        taxName,
        authResidentialPercentage:
          this.authResidential.find((item) => item.taxName === taxName)
            ?.taxPercentage || "N/A",
        authResidentialAmount:
          this.authResidential.find((item) => item.taxName === taxName)
            ?.totalTaxAmount || "",
        unAuthResidentialPercentage:
          this.unAuthResidential.find((item) => item.taxName === taxName)
            ?.taxPercentage || "N/A",
        unAuthResidentialAmount:
          this.unAuthResidential.find((item) => item.taxName === taxName)
            ?.totalTaxAmount || "",
        nonResidentialPercentage:
          this.nonResidential.find((item) => item.taxName === taxName)
            ?.taxPercentage || "0",
        nonResidentialAmount:
          this.nonResidential.find((item) => item.taxName === taxName)
            ?.totalTaxAmount || "",
        unAuthNonResidentialPercentage:
          this.unAuthNonResidential.find((item) => item.taxName === taxName)
            ?.taxPercentage || "N/A",
        unAuthNonResidentialAmount:
          this.unAuthNonResidential.find((item) => item.taxName === taxName)
            ?.totalTaxAmount || "",
      };
    });

    return taxData;
  }

  getWaterLedgerData(consumerNo: string) {
    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = true;
    this.showPropertyBillSection = false;
    this.showWaterBillSection = false;

    this.showPropertySection = false;
    if (consumerNo != undefined || consumerNo != null || consumerNo != "") {
      this.wtSErvice.getLedgerReport(consumerNo).subscribe((resp: any) => {
        this.waterDetails = resp.ledgerReport;
      });
    } else {
      this.resetFilter();
    }
  }

  fileUrl1: SafeResourceUrl | null = null;
  showWaterBillsWebPortal(consumerNumber: string) {
    this.showPropertyLegderSection = false;
    this.showWaterLegderSection = false;
    this.showPropertyBillSection = false;
    this.showWaterBillSection = true;

    this.wtSErvice
      .getBackendPDF(
        `/api/reports/showWaterBillsWebPortal?consumerNumber=${consumerNumber}&format=pdf`
      )
      .subscribe((data: string) => {
        const dataUrl = `data:application/pdf;base64,${data}`;
        this.fileUrl1 = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
      });
  }
  isPdfFormat(base64Data: string): boolean {
    // PDF files in base64 usually start with %PDF- in plain text, which is 'JVBER' in base64
    return base64Data.startsWith("JVBER");
  }

  resetFilter() {
    this.form.reset();
  }

  getPropertyBillPay(propertyNo: string) {
    const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
    this.taxService
      .getPendingPropertyBillDetails(propertyNo, currentDate)
      .subscribe((result) => {
        if (result["checkBillDao"] === null) {
          this._snackBar.success(result["message"]);
        } else {
          this.paymentAmount = 0;
          this.paymentAmount = Number(
            result["checkBillDao"]["totalPendingAmount"]
          ).toFixed(2);
          if (
            result["checkBillDao"]["totalAmnestyPenaltyAmount"] !== null &&
            result["checkBillDao"]["totalAmnestyPenaltyAmount"] > 0
          ) {
            this.paymentAmount = Number(
              result["checkBillDao"]["totalPendingAmnestyAmount"]
            ).toFixed(2);
          } else {
            this.paymentAmount = Number(
              result["checkBillDao"]["totalPendingAmount"]
            ).toFixed(2);
          }
          const requestData = {
            applicationId: this.propertyId,
            transactionAmount: this.paymentAmount,
            returnUrl:
              environment.frontEndUrl + "user-profile?userId=" + this.userId,
            moduleId: "01917e3b-afb2-77e7-8f76-383cf6f3a606",
            paymentDescription: "User Payment",
            paymentAmount: 0, // Number
            pgAmount: 0, // BigDecimal as Number
            firstName: "x", // String
            lastName: "x", // String
            emailId: this.email, // String
            address: "x", // String
            mobileNo: this.mobile, // String
            ward: "x", // String
            department: "x", // String
            serviceName: "x", // String
            billType: "x", // String
            chargesType: "x", // String
            amount: 0, // BigDecimal as Number
            taxes: 0, // BigDecimal as Number
            demandId: "018fc8ce-44ac-737c-832c-7f4bdc4acd5a", // UUID Transcation Id
            propertyNumber: propertyNo,
            waterConsumerNumber: "x",
            transactionName: "x",
            extraParam1: "User Payment",
            extraParam2: "x",
            extraParam3: "x",
            paymentType: "USER_TAX_PAYMENT",
            transaction: "PROPERTY_TAX",
          };
          console.log(requestData);

          this.commonService.makeAPaymentUser(requestData).subscribe((data) => {
            if (data["status"].toLowerCase() == "success") {
              localStorage.setItem("paymentStatus", "paymentInitiated");
              const paymentUrl = data["url"];
              window.location.href = paymentUrl;
            }
          });
        }
      });
  }
  getWaterBillPay(consumerNumber: string) {
    const data = {
      consumerNumber: consumerNumber,
    };
    this.wtSErvice.getWaterBillNew(data).subscribe((result) => {
      console.log(result);
      this.paymentAmount = 0;
      this.paymentAmount = Number(result["waterBills"][0]["totalBill"]).toFixed(
        2
      );

      const requestData = {
        applicationId: this.waterId,
        transactionAmount: this.paymentAmount,
        returnUrl:
          environment.frontEndUrl + "user-profile?userId=" + this.userId,
        moduleId: "018f6279-fef9-7c5d-9953-69fefe73fe78",
        paymentDescription: "User Payment",
        paymentAmount: 0, // Number
        pgAmount: 0, // BigDecimal as Number
        firstName: "x", // String
        lastName: "x", // String
        emailId: this.email, // String
        address: "x", // String
        mobileNo: this.mobile, // String
        ward: "x", // String
        department: "x", // String
        serviceName: "x", // String
        billType: "x", // String
        chargesType: "x", // String
        amount: 0, // BigDecimal as Number
        taxes: 0, // BigDecimal as Number
        demandId: "018fc8ce-44ac-737c-832c-7f4bdc4acd5a", // UUID Transcation Id
        propertyNumber: "x",
        waterConsumerNumber: consumerNumber,
        consumerNumber: consumerNumber,
        transactionName: "x",
        extraParam1: "User Payment",
        extraParam2: "x",
        extraParam3: "x",
        paymentType: "USER_TAX_PAYMENT",
        transaction: "WATER_TAX",
      };
      console.log(requestData);

      this.commonService.makeAPaymentUser(requestData).subscribe((data) => {
        if (data["status"].toLowerCase() == "success") {
          localStorage.setItem("paymentStatus", "paymentInitiated");
          const paymentUrl = data["url"];
          window.location.href = paymentUrl;
        }
      });
    });
  }

  validatePropertyNo(value: any): void {
    const propertyNoPattern = /^[A-Z]{2}[0-9]{10}$/;
    console.log(propertyNoPattern.test(this.propertyNumber));
    console.log("log", this.propertyNumber.trim() === "");

    if (!propertyNoPattern.test(this.propertyNumber)) {
      this.showErrorproperty = true;
    } else {
      this.showErrorproperty = false;
    }
    if (this.propertyNumber.trim() === "") {
      this.showErrorproperty = false;
    }
  }

  validateConsumerNo(): void {
    const consumerNoPattern = /^[0-9]{9}$/;
    if (this.consumerNumber === "") {
      this.showErrorconsumer = false;
    } else {
      this.showErrorconsumer = !consumerNoPattern.test(this.consumerNumber);
    }
  }

  showApplication() {
    this.showgrievanceSection = false;
    this.showApplicationSection = !this.showApplicationSection;
    this.showWaterBillSection = false;
    this.showPropertyBillSection = false;
    this.showWaterLegderSection = false;
    this.showPropertyLegderSection = false;
  }
  showGrievance() {
    this.showApplicationSection = false;
    this.showgrievanceSection = !this.showgrievanceSection;
    this.showWaterBillSection = false;
    this.showPropertyBillSection = false;
    this.showWaterLegderSection = false;
    this.showPropertyLegderSection = false;
  }
}
