import { Component } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';
import { CommonService } from 'src/app/_services/Common-Services/common.service';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { HrmsServiceService } from 'src/app/_services/HRMS/hrms-service.service';
import {Location} from '@angular/common';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-add-edit-report-v1',
  templateUrl: './add-edit-report-v1.component.html',
  styleUrls: ['./add-edit-report-v1.component.scss']
})
export class AddEditReportV1Component {

  state : any[] = []
  action : any = ''
  addedit: boolean;

  crForm1 : FormGroup
  crFormData : any[] = []
  section2Form : FormGroup
  section2Data : any[] = []
  section3Form : FormGroup
  section3Data : any[] = []
  section4Form : FormGroup
  section4Data : any[] = []
  departments : any[] = []
  employees : any[] = []
  leaveTypes : any[]=[]
  designations : any[] = []
  ShowdigitalSignature : boolean = false
  userActions : any = ''
  id : any = ''
  userId: string = ''
  questionBank : any[] = []
  questions: Question[] =  []
  section : any = 'Section - 2'
  groupId : any = ''
  groups : any[] = []
  sections : any[] = []
  submitAnswer : any[] = []


  constructor(
    private fb: FormBuilder,
    private hrmsService: HrmsServiceService, 
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    private snackbar : MatSnackBar,
    private uploadService :FileUploadService,
    private designationService : CommonService,
    private location : Location
  ) {}

  ngOnInit() {
    this.userId = localStorage.getItem("userId");
    this.state = history.state;
    this.addedit = this.state['isEditMode']
    this.getDepartments()
    this.getDesignationData()
    this.getLeaveTypes()

    this.crForm1 = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      employeeId: ['', Validators.required],
      oldFirstName: ['', Validators.required],
      oldMiddleName: ['', Validators.required],
      oldLastName: ['', Validators.required],
      newFirstName : ['', Validators.required],
      newMiddleName: ['', Validators.required],
      newLastName: ['', Validators.required],
      departmentId: ['', Validators.required],
      designationId : ['', Validators.required],
      workingPlace: ['', Validators.required],
      phoneNumber :  ["",[Validators.required, Validators.pattern('[0-9]{10}')]],
      reviewDate : ['',Validators.required],
      timePeriodFrom : ['', Validators.required],
      timePeriodTo : ['',Validators.required],
      officerReportedUpon: ['', Validators.required],
      carde: ['', Validators.required],
      dob: ['', Validators.required],
      presentPost : ['', Validators.required],
      dateOfAppintment: ['', Validators.required],
      administrativeOfficer: ['', Validators.required],
      reportingOfficer: ['', Validators.required],
      reportingOfficerDesignationId: ['', Validators.required],
      reportingOfficerPeriod : ['', Validators.required],
      reviewingOfficer: ['', Validators.required],
      reviewinggOfficerDesignationId: ['', Validators.required],
      reviewingOfficerPeriod: ['', Validators.required],
      leavePeriod: ['', Validators.required],
      typeOfLeaveId: ['', Validators.required],
      leaveRemark : ['', Validators.required],
      otherReasonsLeavePeriod: ['', Validators.required],
      otherReasonsTypeOfLeaveId: ['', Validators.required],
      otherReasonsRemark: ['', Validators.required],
      detailsOfTraning:new FormArray([]),
      detailsOfConfidential:new FormArray([]),
      date: ['', Validators.required],
      place: ['', Validators.required],
      digitalSignature: ['', Validators.required],
      activeFlag : ['Y'],
      id:[""],
      createdUserId:[""],
      updateUserId:[""],
      confidentialAnswers:new FormArray([]),
      status:["APPLICATION_CREATED"],

     
    });

    this.section2Form = this.fb.group({
      confidentialAnswers:new FormArray([]),
    });

    this.section3Form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      employeeId: ['', Validators.required],
      oldFirstName: ['', Validators.required],
      oldMiddleName: ['', Validators.required],
      oldLastName: ['', Validators.required],
      newFirstName : ['', Validators.required],
      newMiddleName: ['', Validators.required],
      newLastName: ['', Validators.required],
      departmentId: ['', Validators.required],
      designationId : ['', Validators.required],
      workingPlace: ['', Validators.required],
      phoneNumber :  ["",[Validators.required, Validators.pattern('[0-9]{10}')]],
      reviewDate : ['',Validators.required],
      timePeriodFrom : ['', Validators.required],
      timePeriodTo : ['',Validators.required],
      officerReportedUpon: ['', Validators.required],
      carde: ['', Validators.required],
      dob: ['', Validators.required],
      presentPost : ['', Validators.required],
      dateOfAppintment: ['', Validators.required],
      administrativeOfficer: ['', Validators.required],
      reportingOfficer: ['', Validators.required],
      reportingOfficerDesignationId: ['', Validators.required],
      reportingOfficerPeriod : ['', Validators.required],
      reviewingOfficer: ['', Validators.required],
      reviewinggOfficerDesignationId: ['', Validators.required],
      reviewingOfficerPeriod: ['', Validators.required],
      leavePeriod: ['', Validators.required],
      typeOfLeaveId: ['', Validators.required],
      leaveRemark : ['', Validators.required],
      otherReasonsLeavePeriod: ['', Validators.required],
      otherReasonsTypeOfLeaveId: ['', Validators.required],
      otherReasonsRemark: ['', Validators.required],
      detailsOfTraning:new FormArray([]),
      detailsOfConfidential:new FormArray([]),
      date: ['', Validators.required],
      place: ['', Validators.required],
      digitalSignature: ['', Validators.required],
      activeFlag : ['Y'],
      id:[""],
      createdUserId:[""],
      updateUserId:[""],
      status:["APPLICATION_CREATED"],

     
    });

    this.section4Form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      employeeId: ['', Validators.required],
      oldFirstName: ['', Validators.required],
      oldMiddleName: ['', Validators.required],
      oldLastName: ['', Validators.required],
      newFirstName : [''],
      newMiddleName: [''],
      newLastName: [''],
      departmentId: ['', Validators.required],
      designationId : ['', Validators.required],
      workingPlace: ['', Validators.required],
      phoneNumber :  ["",[Validators.required, Validators.pattern('[0-9]{10}')]],
      reviewDate : ['',Validators.required],
      timePeriodFrom : ['', Validators.required],
      timePeriodTo : ['',Validators.required],
      officerReportedUpon: ['', Validators.required],
      carde: ['', Validators.required],
      dob: ['', Validators.required],
      presentPost : ['', Validators.required],
      dateOfAppintment: ['', Validators.required],
      administrativeOfficer: ['', Validators.required],
      reportingOfficer: ['', Validators.required],
      reportingOfficerDesignationId: ['', Validators.required],
      reportingOfficerPeriod : ['', Validators.required],
      reviewingOfficer: ['', Validators.required],
      reviewinggOfficerDesignationId: ['', Validators.required],
      reviewingOfficerPeriod: ['', Validators.required],
      leavePeriod: ['', Validators.required],
      typeOfLeaveId: ['', Validators.required],
      leaveRemark : ['', Validators.required],
      otherReasonsLeavePeriod: ['', Validators.required],
      otherReasonsTypeOfLeaveId: ['', Validators.required],
      otherReasonsRemark: ['', Validators.required],
      detailsOfTraning:new FormArray([]),
      detailsOfConfidential:new FormArray([]),
      date: ['', Validators.required],
      place: ['', Validators.required],
      digitalSignature: ['', Validators.required],
      activeFlag : ['Y'],
      id:[""],
      createdUserId:[""],
      updateUserId:[""],
      status:["APPLICATION_CREATED"],

     
    });

 

    if (this.addedit) {
      this.action = 'Edit'
      this.userActions = 'Update'
      const confReportData = []
      confReportData.push(this.state['applicationData'])
      this.id = confReportData['id'];
      this.crForm1.patchValue({"updateUserId":this.userId})
    
    }else {
      this.action = 'Add'
      this.userActions = 'Add new'
      this.crForm1.patchValue({"createdUserId":this.userId})

    }
  }

    // convenience getters for easy access to form fields
    get f() { return this.crForm1.controls; }
    get detailsOfTraning() { return this.f['detailsOfTraning'] as FormArray; }
    get detailsOfConfidential() { return this.f['detailsOfConfidential'] as FormArray; }
    get f2() { return this.section2Form.controls; }
    get answersOfSection2()  { return this.f['confidentialAnswers'] as FormArray; }


  getDepartments(){
    this.hrmsService.getDepartment().subscribe(
      (res) => {
        this.departments = res['departmentMasterList']
      },
      (err) => {

      }
    )
  }

  getDesignationData(){
    this.designationService.getDesignation().subscribe((data : any) => {
      this.designations = data['designationMasterList'] as any[]
    
    })
  }

 

  getEmployees(){
    this.hrmsService.getByActiveFlagEmployee().subscribe(
      (res) => {
        this.employees = res as any[]
      },
      (err) => {

      }
    )
  }

  getLeaveTypes(){
    this.hrmsService.getByActiveFlagLeaveType().subscribe(
      (res) => {
        this.leaveTypes = res as any[]
      },
      (err) => {

      }
    )
  }

  numberOfTickets = 0
  onChangeTickets() {
   
    this.numberOfTickets++
    if (this.detailsOfTraning.length < this.numberOfTickets) {
      for (let i = this.detailsOfTraning.length; i < this.numberOfTickets; i++) {
        let count = i + 1
        this.detailsOfTraning.push(
          this.fb.group({
            srNo: [count, Validators.required],
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
            institute: ['', Validators.required],
            subject: ['', Validators.required],
          })
        );
      }
    } else {
      for (let i = this.detailsOfTraning.length; i >= this.numberOfTickets; i--) {
        this.detailsOfTraning.removeAt(i);
        
      }
    }
  }

  numberOfTicketsConf = 0
  onChangeTicketsConf() {
   
    this.numberOfTicketsConf++    
    if (this.detailsOfConfidential.length < this.numberOfTicketsConf) {
      for (let i = this.detailsOfConfidential.length; i < this.numberOfTicketsConf; i++) {
        let count = i + 1
        this.detailsOfConfidential.push(
          this.fb.group({
            srNo: [count, Validators.required],
            fromDate: ['', Validators.required],
            toDate: ['', Validators.required],
            review: ['', Validators.required],
            'name of officer': ['', Validators.required],
            designationId: ['', Validators.required],

          })
        );
      }
    } else {
      for (let i = this.detailsOfConfidential.length; i >= this.numberOfTicketsConf; i--) {
        this.detailsOfConfidential.removeAt(i);
        
      }
    }
  }

  isFormGroup(control: AbstractControl): control is FormGroup {
    return (control instanceof FormGroup);
  }

  uploadDigitalSignature(event : any){
    for(var i = 0; i < event.target.files.length;i++){
      this.uploadService.uploadFile(event.target.files[i]).then((data : any) => {
          
        this.crForm1.patchValue({'digitalSignature':JSON.parse(data).filePath})
        this.ShowdigitalSignature = true

    })
    }
  }

  getQuestions(){
    this.questions = []
   this.hrmsService.getQuestionsByGroupSection('018e1cbc-7b1c-7bb9-8dfc-7499525e492e',this.section).subscribe(
     (res) => {
       this.questionBank = res['questionsListDao']
       for(var i = 0; i < this.questionBank.length; i++){
         if(this.questionBank[i].subQuestions.length == 0){
           // this.questions[i].id = i
           let obj = {
             'questionId' : this.questionBank[i].id,
             'subQuestions' : this.questionBank[i].subQuestions,
             'question' : this.questionBank[i].questions,
             'description' : this.questionBank[i].description,
             'textAnswer' : '',
             'questionType' : this.questionBank[i].questionType,
             'section' : this.section,
             'multipleAns' : []
             
           }
           if(this.questionBank[i].questionType == 'list multiple answers'){
             obj['multipleAns'].push(
               { ansId : 0, ansText : '' },
               { ansId : 1, ansText : '' },
               { ansId : 2, ansText : '' },
               { ansId : 3, ansText : '' },
 
             )
           }
          
         
           this.questions.push(obj)
 
         }else {
           // this.questions[i].id = i
           let obj = {
             'questionId' : this.questionBank[i].id,
             'subQuestions' : [],
             'question' : this.questionBank[i].questions,
             'description' : this.questionBank[i].description,
             'textAnswer' : '',
             'questionType' : this.questionBank[i].questionType,
             'section' : this.section,
             'multipleAns' : []
             
           }
           if(this.questionBank[i].questionType == 'list multiple answers'){
             obj['multipleAns'].push(
               { ansId : 0, ansText : '' },
               { ansId : 1, ansText : '' },
               { ansId : 2, ansText : '' },
               { ansId : 3, ansText : '' },
 
             )
           }
           this.questions.push(obj)
 
           for(var j = 0; j < this.questionBank[i].subQuestions.length; j++){
            
             let subObj = {
               'subQuestionId' : this.questionBank[i].subQuestions[j].id,
               'subQuestion' : this.questionBank[i].subQuestions[j].subQuestion,
               'singleAnswerText' : '',
               'multipleAnswers' : []
             }
             
             this.questions[i].subQuestions.push(subObj)
           }
         }
       }
   
     },
 
     
     (err) => {
 
     }
   )
  }

  addAnswer(ques : any): void {
    if(!ques.subQuestions){
      return;
    }
    ques.subQuestions.forEach(subQuestion => {
      if (!subQuestion.multipleAnswers) {
        subQuestion.answers = []; // Initialize the answers array if it's undefined
      }
      subQuestion.multipleAnswers.push({ ansId: subQuestion.multipleAnswers.length + 1, ansText: '' });
    });    // subQuestion.answers.push({ id: subQuestion.answers.length + 1, text: '' });
    
  }


  saveAnswers(): void {
    const answers = [];   
    for(var i = 0; i < this.questions.length; i++){

      if(this.questions[i].subQuestions.length == 0){
        if(this.questions[i].textAnswer != ''){
          let createAns = {
            'section' : this.section,
            'questionId' : this.questions[i].questionId,
            'question' : this.questions[i].question,
            'description' : this.questions[i].description,
            'subQuestionId' : '',
            'subQuestion' : '',
            'fromSelf' : this.questions[i].textAnswer,
            'fromReportingOfficer' : '',
            'fromReviewOfficer' : ''
          }
          answers.push(createAns)

        }else {
          
        }
       


      }else {
        for(var j = 0; j <  this.questions[i].subQuestions.length; j++){
          if(this.questions[i].questionType == 'multiple answers (sub ques)'){

            for(var k = 0; k < this.questions[i].subQuestions[j].multipleAnswers.length; k++){
              let createAns = {
                'section' : this.section,
                'questionId' : this.questions[i].questionId,
                'question' : this.questions[i].question,
                'description' : this.questions[i].description,
                'subQuestionId' : this.questions[i].subQuestions[j].subQuestionId,
                'subQuestion' : this.questions[i].subQuestions[j].subQuestion,
                'fromSelf' : this.questions[i].subQuestions[j].multipleAnswers[k].ansText,
                'fromReportingOfficer' : '',
                'fromReviewOfficer' : ''
              }
              answers.push(createAns)

            } 
          }else if(this.questions[i].questionType == 'Tabular sub ques'){

            let createAns = {
              'section' : this.section,
              'questionId' : this.questions[i].questionId,
              'question' : this.questions[i].question,
              'description' : this.questions[i].description,
              'subQuestionId' : this.questions[i].subQuestions[j].subQuestionId,
              'subQuestion' : this.questions[i].subQuestions[j].subQuestion,
              'fromSelf' : this.questions[i].subQuestions[j].singleAnswerText,
              'fromReportingOfficer' : '',
              'fromReviewOfficer' : ''
            }
            answers.push(createAns)

          }
        }
      }

    }

    this.submitAnswer.push(answers)
  }

  // Save data and navigate to the next step
  onNext(step: number) {
    this.getQuestions()

    switch (step) {
      case 1:
        this.crFormData = this.crForm1.value;
        this.showSnackBar('CR Form submitted successfully', 'success');
        this.section = 'Section - 2'
        this.getQuestions()
        break;
      case 2:
        this.showSnackBar('Section - 2 submitted successfully', 'success');
        this.section = 'Section - 3'
        this.saveAnswers()
        this.getQuestions()

        break;
      case 3:
        this.section3Data = this.section3Form.value;
        this.showSnackBar('Section - 3 submitted successfully', 'success');
        this.section = 'Section - 4'
        this.saveAnswers()
        this.getQuestions()

        break;
      case 4:
        this.section4Data = this.section3Form.value;
        this.showSnackBar('Section - 4 submitted successfully', 'success');
      this.saveAnswers()
      this.crForm1.patchValue(
        {
          'confidentialAnswers' : this.submitAnswer
        }
      )
        break;
    }
  }

  doSomething(event : StepperSelectionEvent){
    let step = event.selectedIndex
    switch (step) {
      case 1:
        this.crFormData = this.crForm1.value;
        this.showSnackBar('CR Form submitted successfully', 'success');
        this.section = 'Section - 2'
        this.getQuestions()
        break;
      case 2:
        this.saveAnswers()
        this.showSnackBar('Section - 2 submitted successfully', 'success');
        this.section = 'Section - 3'
        this.getQuestions()

        break;
      case 3:
        this.saveAnswers()
        this.section3Data = this.section3Form.value;
        this.showSnackBar('Section - 3 submitted successfully', 'success');
        this.section = 'Section - 4'
        this.getQuestions()

        break;
      case 4:
        this.saveAnswers()
        this.section4Data = this.section3Form.value;
        this.showSnackBar('Section - 4 submitted successfully', 'success');

      this.crForm1.patchValue(
        {
          'confidentialAnswers' : this.submitAnswer
        }
      )
    
        break;
    }
  }

  showSnackBar(message: string, type: 'success' | 'error') {
    this.snackbar.open(message, 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
    });
    }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  cancel(){
    this.location.back()
  }

  
}

interface SubQuestion {
  subQuestionId: number;
  singleAnswerText: string;
  subQuestion : string;
  multipleAnswers: { ansId : number; ansText : string; }[]
}

interface Question {
  section: string;
  questionId: string;
  question : string;
  description : string;
  subQuestions?: SubQuestion[];
  textAnswer : string
  questionType : string;
  multipleAns : { ansId : number; ansText : string; }[]
}
