import { Injectable } from '@angular/core';
// export interface doc{
//   id:any,
//   description:string,
//   fileLocation:string
// }

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private documents: any[] = []; // Initialize as null

  setDocuments(initialDocuments: any[]) {
    // Set the initial list of documents
    if(initialDocuments!=null){
      const str1 = initialDocuments.toString()
      const str2 = JSON.parse(str1)
      this.documents = str2;
     
    }
   
  }

  addDocument(newDocument: any) {
    if (!this.documents) {
      this.documents = this.documents || [];
    }
    const nextId = this.documents.length+1;
    newDocument.id = nextId;
    if (typeof this.documents !== 'undefined'){
      this.documents.push(newDocument);
    }
return this.documents;
  }

  clearData() {
    this.documents = null;
  }

  getDocuments() {
    return this.documents;
  }

}
