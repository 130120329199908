import { ChangeDetectionStrategy, Component, Input, ViewChild, ChangeDetectorRef, AfterViewInit, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuItem } from '@angular/material/menu';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationPopupComponent } from '../confirmation-popup/confirmation-popup.component';
import { AuthService } from '../_services/auth.service';
import { CommonService } from '../_services/Common-Services/common.service';

@Component({
  selector: 'app-mapped-toolbar',
  templateUrl: './mapped-toolbar.component.html',
  styleUrls: ['./mapped-toolbar.component.scss'],
})
export class MappedToolbarComponent implements OnInit, OnDestroy {
  @Input() isOpen = false;
  @ViewChild('sidenav') sidenav: MatSidenav;

  sidenavOpened = false;
  userId: any = '';
  loginName = '';
  designationName = '';
  departmentName = '';
  ugpPrevId: any = '';
  dptId: string = '';
  desId: string = '';
  isAuthenticated = false;
  userMenuAccess: any[] = [];
  masterMenuItems: any[] = [];
  activeMasterMenuItems : any[] = []
  activeTransactionMenuItems : any[] = []
  activeIndividualMenuItmes : any[] = []
  transactionMenuItems: any[] = [];
  individualMenuItmes : any[] = []
  showMasterTransactions = false;
  showIndividualMenus = false

  routerSubscription: Subscription;
  destroy$ = new Subject<void>();
  isDashboardRoute : boolean = false
  loginUser : any = ''
  currentRoute : any = ''

  constructor(
    public authService: AuthService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private commonService: CommonService,
    private dialog : MatDialog
  ) {}

  ngOnInit(): void {
    this.authService.currentUser.subscribe(loggedIn => {
      if (loggedIn) {
        this.authService.isAuthenticated().subscribe(isAuthenticated => {
          this.isAuthenticated = isAuthenticated;
          this.loadUserDetails();
        });
      } else {
        this.resetUserDetails();
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = this.router.url.split("/")[1]; // Extract the current route
        this.showMasterTransactions = !this.currentRoute.includes("mapped-dashboard")
        console.log(this.currentRoute.includes("mapped-dashboard"))
        this.showIndividualMenus = !this.currentRoute.includes("mapped-dashboard")
        console.log(this.showIndividualMenus)
        this.updateActiveMasterMenuItems();

      }
      
    })
    
  }

   loadUserDetails(): void {
    this.ugpPrevId = localStorage.getItem('ugpPrevId') || '';
    this.loginName = localStorage.getItem('loginName') || '';
 
    this.dptId = localStorage.getItem('dptId') || '';
    this.desId = localStorage.getItem('designationId') || '';
    this.userId =  localStorage.getItem('userId') || '';
    this.setLoginUserType();
    this.updateUserDetails();
  }

   setLoginUserType(): void {
    if (this.ugpPrevId === '3') {
      this.loginUser = 'citizen';
    } else if (this.ugpPrevId === '2') {
      this.loginUser = 'department';
    } else if (this.ugpPrevId === '5') {
      this.loginUser = 'Driver';
    }
  }

   updateUserDetails(): void {
    if (this.dptId) {
      this.getDepartmentName(this.dptId);
    }
    if (this.desId) {
      this.getDesignationName(this.desId);
    }
    this.checkUserRole();
  }

   getDepartmentName(dptId: string): void {
    this.commonService.getDepartment().subscribe(
      res => {
        const department = res['departmentMasterList'].find((item: any) => item.id === dptId);
        if (department) {
          this.departmentName = department.department;
          this.cdr.markForCheck();
        }
      },
    );
  }

   getDesignationName(desId: string): void {
    this.commonService.getDesignation().subscribe(
      res => {
        const designation = res['designationMasterList'].find((item: any) => item.id === desId);
        if (designation) {
          this.designationName = designation.designation;
          this.cdr.markForCheck();
        }
      },
    );
  }

   checkUserRole(): void {
    if (!this.userId) {
      return;
    }

    this.commonService.getRolesByUserId(this.userId).subscribe({
      next: () => this.checkUserMenuAccess(),
    });
  }

   checkUserMenuAccess(): void {
    const menuRoleList = JSON.parse(localStorage.getItem('roles') || '[]');
    this.userMenuAccess = menuRoleList.flatMap((element: any) => element.menus);

    this.separateMastersTransactions();
  }

   separateMastersTransactions(): void {
    this.masterMenuItems = [];
    this.transactionMenuItems = [];
    this.individualMenuItmes = []

    const seenMenuUrls = new Set<string>();

    this.userMenuAccess.forEach((menuAccessItem: any) => {
      if (!seenMenuUrls.has(menuAccessItem.url)) {
        seenMenuUrls.add(menuAccessItem.url);
        const menuItem = { label: menuAccessItem.menuNameEng, link: menuAccessItem.url };
        if (menuAccessItem.menuFlag === 'D' || menuAccessItem.menuFlag === 'M') {
          this.masterMenuItems.push(menuItem);
        } else if (menuAccessItem.menuFlag === 'T') {
          this.transactionMenuItems.push(menuItem);
        } else if (menuAccessItem.menuFlag === 'I') {
          this.individualMenuItmes.push(menuItem);
        }
      }
    });


    this.masterMenuItems.sort((a, b) => a.label.localeCompare(b.label));
    this.transactionMenuItems.sort((a, b) => a.label.localeCompare(b.label));
    this.individualMenuItmes.sort((a, b) => a.label.localeCompare(b.label));
    this.updateActiveMasterMenuItems();

  }

  updateActiveMasterMenuItems(){
    this.activeMasterMenuItems = this.masterMenuItems.filter(menuItem =>
      menuItem.link.includes(this.currentRoute)
    );
    this.activeTransactionMenuItems = this.transactionMenuItems.filter(menuItem => 
      menuItem.link.includes(this.currentRoute)
    );
    this.activeIndividualMenuItmes = this.individualMenuItmes.filter(menuItem => 
      menuItem.link.includes(this.currentRoute)
    );
    this.cdr.markForCheck();
  }

  

  toggleSidenav(): void {
    this.sidenav.toggle();
  }

  closeSidenav(): void {
    this.sidenav.close();
  }

  logout(): void {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: "550px",
      data: {
        mode: "logout",
        component: ''
      },
      autoFocus: false,
      restoreFocus: false,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "success") {
        this.resetUserDetails();
        localStorage.clear();
        this.authService.logout();
        this.router.navigate(['/']);
      }
    })
  
  }

  // ngAfterViewInit(): void {
  //   this.ngZone.run(() => {
  //     this.updateRouteFlags(this.router.url);

  //     this.routerSubscription = this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
  //       if (event instanceof NavigationEnd) {
  //         this.updateRouteFlags(event.urlAfterRedirects);
  //       }
  //     });
  //   });
  // }

  //  updateRouteFlags(url: string): void {
  //   this.isDashboardRoute = url.includes('mapped-dashboard');
  //   this.showMasterTransactions = !this.isDashboardRoute;
  //   this.showIndividualMenus = !this.isDashboardRoute;
  //   this.cdr.detectChanges();
  // }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  resetUserDetails(): void {
    this.loginName = '';
    this.designationName = '';
    this.departmentName = '';
    this.ugpPrevId = '';
    this.dptId = '';
    this.desId = '';
    this.userMenuAccess = [];
    this.masterMenuItems = [];
    this.activeMasterMenuItems = []
    this.transactionMenuItems = [];
    this.showMasterTransactions = false;
    this.showIndividualMenus = false
  }

  goToUserProfile(): void {
    const userId = localStorage.getItem("userId");
    if (userId) {
      this.router.navigate(["/core-module/user-profile"], {
        queryParams: { userId },
      });
    } else {
      console.error("User ID not found in localStorage");
    }
  }
}
