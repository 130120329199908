<div class="p-4 sm:p-8 rounded-lg shadow-md w-full">
  <div class="flex items-center"></div>

  <!-- <div class="flex justify-end mt-4">
        <button class="bg-blue-500 text-white py-2 px-4 rounded-lg shadow-md" routerLink="/rtsservicedashboard">
          Service
        </button>
      </div> -->
  <!-- <div class="flex justify-end mt-4" *ngIf="hideService == false">
    <button
      class="text-white py-2 px-3 sm:px-4 rounded-lg shadow-md"
      style="border-color: #015296; background-color: #015296"
      routerLink="/online-rts/rts-services"
    >
      Request Service
    </button>
  </div> -->

  <mat-form-field class="w-full sm:w-1/2 mt-4" appearance="outline">
    <mat-icon matPrefix>search</mat-icon>
    <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input />
  </mat-form-field>

  <div
    class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4"
  >
    <!-- Add flex and spacing classes here -->
    <mat-form-field class="w-full flex-1">
      <!-- Adjust width and flex-1 -->
      <mat-label>Department</mat-label>
      <mat-select
        [(ngModel)]="selectedDepartment"
        (ngModelChange)="onDepartmentChange()"
      >
        <mat-option *ngFor="let item of Departments" [value]="item.id">
          {{ item.department }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full flex-1">
      <!-- Adjust width and flex-1 -->
      <mat-label>Sub-Department</mat-label>
      <mat-select
        [(ngModel)]="selectedSubDept"
        (ngModelChange)="onSubDepartmentChange($event)"
      >
        <mat-option *ngFor="let item of Subdepartments" [value]="item.id">
          {{ item.subDepartment }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="w-full flex-1">
      <!-- Adjust width and flex-1 -->
      <mat-label>Select Service To Filter</mat-label>
      <mat-select
        [(ngModel)]="selectedService"
        (ngModelChange)="serviceChange()"
      >
        <!-- <mat-option [value]=null>None</mat-option> -->
        <mat-option [value]="'all'">All</mat-option>
        <mat-option *ngFor="let option of activeServices" [value]="option.id">{{
          option.serviceName
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="mt-4 overflow-x-auto shadow-md">
    <table
      mat-table
      matSort
      [dataSource]="dataSource"
      *ngIf="dataSource.data.length > 0 && contentLoaded"
      class="min-w-full bg-white"
    >
      <!-- Sr.No Column -->
      <ng-container matColumnDef="srNo">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="px-4 py-2 text-left"
        >
          Sr.No.
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          class="border px-4 py-2"
        >
          {{ i + 1 }}
        </td>
      </ng-container>

      <!-- Application Number Column -->
      <ng-container matColumnDef="applicationNumber">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="px-4 py-2 text-left"
        >
          Application Number (अर्ज क्रमांक)
        </th>
        <td mat-cell *matCellDef="let element" class="border px-4 py-2">
          {{ element.applicationNumber }}
        </td>
      </ng-container>

      <!-- Applicant Name Column -->
      <ng-container matColumnDef="citizenName">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="px-4 py-2 text-left"
        >
          Applicant Name (अर्जदाराचे नाव)
        </th>
        <td mat-cell *matCellDef="let element" class="border px-4 py-2">
          {{ element.applicantName }}
        </td>
      </ng-container>

      <!-- Service Name Column -->
      <ng-container matColumnDef="serviceName">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="px-4 py-2 text-left"
        >
          Service Name (सेवेचे नाव)
        </th>
        <td mat-cell *matCellDef="let element" class="border px-4 py-2">
          {{ element.serviceName }} {{ element?.propertyNo }}
        </td>
      </ng-container>

      <!-- Application Date Column -->
      <ng-container matColumnDef="applicationDate">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="px-4 py-2 text-left"
        >
          Application Date (अर्जाची तारीख)
        </th>
        <td mat-cell *matCellDef="let element" class="border px-4 py-2">
          {{ element.applicationDate | date : "dd-MM-yyyy" }}
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          class="px-4 py-2 text-left"
        >
          Status
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="border px-4 py-2"
          [style.color]="getStatusColor(element.status)"
        >
          {{ element.status }}
        </td>
      </ng-container>

      <!-- View Column -->
      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef class="px-4 py-2 text-left">
          View
        </th>
        <td mat-cell *matCellDef="let element" class="border px-4 py-2">
          <button
            mat-icon-button
            matTooltip="view application"
            (click)="viewApplication(element)"
          >
            <mat-icon>visibility</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Download Column -->
      <ng-container matColumnDef="download">
        <th
          mat-header-cell
          *matHeaderCellDef
          [hidden]="hideService"
          class="px-4 py-2 text-left"
        >
          Download
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [hidden]="hideService"
          class="border px-4 py-2"
        >
          <button
            mat-icon-button
            matTooltip="download application"
            (click)="goToDownload(element)"
          >
            <mat-icon>download</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="download payment receipt"
            (click)="downloadPaymentSlip(element)"
          >
            <mat-icon>receipt</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th
          mat-header-cell
          *matHeaderCellDef
          [hidden]="ugpPrevId == '2'"
          class="px-4 py-2 text-left"
        >
          Action
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [hidden]="ugpPrevId == '2'"
          class="border px-4 py-2"
        >
          <button
            mat-icon-button
            matTooltip="make a payment"
            (click)="paymentproceed(element)"
            *ngIf="
              (element.status == 'SUBMITTED' ||
                element.status == 'PAYMENT_PENDING' ||
                element.status == 'PENDING_FOR_PAYMENT' ||
                element.status == 'DEMAND_PAYMENT') &&
              (element.paymentAmount > 0 || element.additionalPaymentAmount > 0)
            "
          >
            <mat-icon>payment</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="Resubmit"
            (click)="reSumbit(element)"
            *ngIf="element.status.toLowerCase().includes('reject')"
          >
            <mat-icon>loop</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- Table Rows and Paginator -->
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="bg-gray-200"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="hover:bg-gray-50"
      ></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[10, 20, 40, 60]"
      showFirstLastButtons
      class="mt-4"
    ></mat-paginator>
    <ngx-skeleton-loader
      *ngIf="!contentLoaded"
      count="4"
      appearance="line"
      animation="progress-dark"
    ></ngx-skeleton-loader>
    <h3
      *ngIf="dataSource.data.length == 0 && contentLoaded"
      class="text-center text-gray-600"
    >
      No data available.
    </h3>
  </div>
</div>
