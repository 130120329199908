import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-asset-sub-category",
  templateUrl: "./add-asset-sub-category.component.html",
  styleUrls: ["./add-asset-sub-category.component.scss"],
})
export class AddAssetSubCategoryComponent implements OnInit {
  form: FormGroup;
  assetCategoryTypes: any[] = [];
  assetCategoryId:any;
  assetSubCategoryName:any;

  id = null;
  addedit: any;
  selectedValues: boolean = false;
  progressSpinnerDialog: any = null;

  constructor(
    private assetService: AssetServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddAssetSubCategoryComponent>,
    private _snackbar: SnackbarMsgService,
    @Inject(MAT_DIALOG_DATA) public data1: any,
    private fb: FormBuilder
  ) {
    this.addedit = data1["isEditMode"];
    this.form = this.fb.group({
      assetSubCategoryName: ['', [Validators.required, Validators.maxLength(50),Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
      assetCategoryId: [null, [Validators.required, Validators.maxLength(50)]],
      activeFlag: ['Y'],
      createdUserId: [localStorage.getItem('userId')],
      updateUserId: [''],
      id:['']
    });
    if (this.addedit) {
      const data12 = data1["row"];
      this.id = data12["id"];
      this.form.patchValue({
        assetSubCategoryName: data12["assetSubCategoryName"],
        assetCategoryId: data12["assetCategoryId"]
      });
    }
  }
  ngOnInit() {
    this.getAssetCategory();
  }

  getAssetCategory() {
    this.assetService.getActiveAssetCategory().subscribe(
      (data: any) => {
        this.assetCategoryTypes = data["assetCategoryMasterList"];
      },
      (err) => {
        this._snackBar.error("Asset Type not Found");
      }
    );
  }

  onAssetChange(data: any) {
    this.selectedValues = true;
  }

  errorMessage: any;
  save() {

    if (this.form.valid) {
      const data = {
        id: this.id,
        assetCategoryId: this.form.value.assetCategoryId,
        assetSubCategoryName: this.form.value.assetSubCategoryName.trim(),
        activeFlag: "Y"
      };
      
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: this.addedit ? "Edit" : "Add",
          component: "Asset Sub Category",
        },
        disableClose:true,
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          this.displayProgressSpinner(); 
          this.assetService.saveAssetSubCategory(data).subscribe(
            (res) => {
              if (res["status"].toLowerCase() === "success") {
                const message = this.addedit ? "Record Updated Successfully" : "Record Added Successfully";
                this._snackbar.success(message);
                setTimeout(() => {
                  this.closeProgressSpinner();
                  this.dialogRef.close({ data: "Success" });
                }, 1000);
              } else{   
                this.closeProgressSpinner();   
                this._snackbar.error(res['message']);
              }
            },
            (err) => {
              this._snackBar.error("Asset Sub Category not Updated");
            }
          );
        }
      });
    }else{
      this.form.markAsTouched()
    }
  }

  async displayProgressSpinner() {
    this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
        disableClose: true,
        panelClass: "progressSpinner",
    });
  }
  
  async closeProgressSpinner() {
  if(this.progressSpinnerDialog) {
    this.progressSpinnerDialog.close();
  }
  }
}
