import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-document-view-dialog',
  templateUrl: './document-view-dialog.component.html',
  styleUrls: ['./document-view-dialog.component.scss']
})
export class DocumentViewDialogComponent {
  documentUrl: SafeResourceUrl;
  zoomLevel: number = 100; // Initial zoom level
  zoomScale: number = 1;   // Scale factor for the iframe

  constructor(
    public dialogRef: MatDialogRef<DocumentViewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) {
    // Ensure that the document data is not undefined
    if (data.document) {
      this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.document);
    } else {
      console.error('Document data is undefined');
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  zoomIn(): void {
    if (this.zoomLevel < 300) { // Limit maximum zoom level to 300%
      this.zoomLevel += 10;
      this.zoomScale = this.zoomLevel / 100; // Update scale
    }
  }

  zoomOut(): void {
    if (this.zoomLevel > 50) { // Limit minimum zoom level to 50%
      this.zoomLevel -= 10;
      this.zoomScale = this.zoomLevel / 100; // Update scale
    }
  }
}
