import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { MaterialManagementServiceService } from 'src/app/_services/Material-Management/material-management-service.service';

@Component({
  selector: 'app-add-edit-center-type-mm',
  templateUrl: './add-edit-center-type-mm.component.html',
  styleUrls: ['./add-edit-center-type-mm.component.scss']
})
export class AddEditCenterTypeMmComponent {
  // activeFlag : any = 'Y'
  action : any = ''
  rowData : any
  
  form!: FormGroup;
  userId  : any  = localStorage.getItem("userId")
  constructor(private matServices: MaterialManagementServiceService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar : MatSnackBar,
    private dialog : MatDialog,
    public dialogRef: MatDialogRef<AddEditCenterTypeMmComponent>) {
  }
  
  ngOnInit(){
    this.form = this.fb.group({
      centerTypeName: ["",[Validators.required, Validators.maxLength(30)]],
      activeFlag : ["Y"],
   
      id:[""]
      // sequenceNo : [""]
    });
    this.form.patchValue({"updatedUserId" : this.userId})
    if(this.data.isEditMode == true){
      this.rowData = this.data.row
      this.form.patchValue({"centerTypeName" : this.rowData.centerTypeName})
      this.form.patchValue({"id" : this.rowData.id})
      this.form.patchValue({"activeFlag" : this.rowData.activeFlag})
      this.form.patchValue({"createdUserId" : this.rowData.createdUserId})
     
      this.action = 'Edit'
    }else {
     
      this.action = 'Add'
      this.form.patchValue({"createdUserId" : this.userId})
  
    }
  }
  
  
  
  saveCenter() {
    if (this.form.valid) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode : this.action,
          component : 'Center Type'
        },
        autoFocus: false,
        restoreFocus: false,
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          this.form.disable()
          this.matServices.saveCenterType(this.form.value).subscribe(
            (res) => {
              if(this.action == 'Add'){
                this.showSnackBar('Center Type added successfully', 'success');
  
              }else {
                this.showSnackBar('Center Type updated successfully', 'success');
  
              }
                this.dialogRef.close({data : 'Success'});
  
            },
            (err) => {
              if(this.action == 'Add'){
                this.showSnackBar('Error: Center Type addition failed', 'error');
  
              }else {
                this.showSnackBar('Error: Center Type updation failed', 'error');
  
              }
            }
        );
        }
      })
     
    }
  }
  
  showSnackBar(message: string, type: 'success' | 'error') {
  this._snackBar.open(message, 'Close', {
      duration: 3000,
      panelClass: type === 'success' ? ['success-snackbar'] : ['error-snackbar'],
  });
  }
}
  
  