<h2>Add Text Field</h2>
<form>
  <label for="fieldName">Field Name</label>
  <input type="text" id="fieldName" [(ngModel)]="fieldOptions.field_name" />

  <label for="fieldLabel">Field Label</label>
  <input type="text" id="fieldLabel" [(ngModel)]="fieldOptions.field_label" />

  <label for="fieldPlaceholder">Placeholder</label>
  <input type="text" id="fieldPlaceholder" [(ngModel)]="fieldOptions.field_options.placeholder" />

  <label for="fieldRequired">Required</label>
  <input type="checkbox" id="fieldRequired" [(ngModel)]="fieldOptions.field_options.required" />

  <button type="button" (click)="saveFieldOptions()">Save Field</button>
  <button type="button" (click)="dialogRef.close()">Cancel</button>
</form>
