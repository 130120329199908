
// import { Injectable } from '@angular/core';
// import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
// import { Observable } from 'rxjs';
// import { AuthService } from './auth.service'; // This is the service where you manage your JWT token
// import { TokenserviceService } from './tokenservice.service';

// @Injectable()
// export class AuthInterceptor implements HttpInterceptor {

//   constructor(private authService: TokenserviceService) {}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     const authToken = this.authService.getToken(); // Assuming this method retrieves your JWT token from storage

//     if (authToken) {
//       // Clone the request and add the authorization header
//       const authReq = req.clone({
//         setHeaders: {
//           Authorization: `Bearer ${authToken}`
//         }
//       });
//       return next.handle(authReq);
//     }

//     // If no token exists, just forward the request as is
//     return next.handle(req);
//   }
// }

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators'; 
import { TokenserviceService } from './tokenservice.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private authService: TokenserviceService, 
    private authService1: AuthService,

    private router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken(); 

    let authReq = req;

    if (authToken) {
      authReq = this.addTokenHeader(req, authToken);
    }

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // alert(JSON.stringify(error));
        if (error.status === 500 && !authReq.url.includes('refreshToken')) {
          console.log("500", error)
          return this.handle500Error(authReq, next, error);
        }
        return throwError(() => error);
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle500Error(request: HttpRequest<any>, next: HttpHandler, error: any) {
    // if (!this.isRefreshing) {
    console.log("in 500")
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const refreshTokan = localStorage.getItem('refrshToken')
      if(error?.error?.message?.startsWith("JWT expired")){
        return this.authService.refreshToken(refreshTokan).pipe(
          switchMap((response: any) => {
            this.isRefreshing = false;
  
            const res = response as any;
            console.log(res)
            // if (res["status"] == "Success") {
            localStorage.setItem("ugpId", res.ugpId);
            localStorage.setItem("ugpPrevId", res.ugpPrevId);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userId", res.id);
            localStorage.setItem("dptId", res.dptId);
            localStorage.setItem("wardId", res.wardId);
            localStorage.setItem("loginName", res.fullName);
            localStorage.setItem("previousDocument", res.previousDocument);
            localStorage.setItem("designationId", res.designationId);
            localStorage.setItem("firstName", res.firstName);
            localStorage.setItem("lastName", res.lastName);
            localStorage.setItem("mobile", res.mobile);
            localStorage.setItem("pinCode", res.pinCode);
            localStorage.setItem("workflow", JSON.stringify(res.workflow));
            localStorage.setItem("activeFlag", res.activeFlag);
            localStorage.setItem("issuedAt", res.issuedAt);
            localStorage.setItem("expiration", res.expiration);
            localStorage.setItem("IsDepartmentHead", res.isDepartmentHead)
            localStorage.setItem("refrshToken", res.refreshToken)
    
            const token = response.refreshToken;
            // this.authService.storeToken(token);
            this.refreshTokenSubject.next(token); 
    
            return next.handle(this.addTokenHeader(request, token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
  
            
            alert(JSON.stringify(err));
            console.log(err.message);
            this.authService1.logout(); 
            this.router.navigate(['/']);
            return throwError(() => new Error(err.message || 'Failed to refresh token'));
          })
          
        );
        
      } else {
        return  throwError(error);
      }
  
      
    // } else {
    //   return this.refreshTokenSubject.pipe(
    //     filter(token => token != null),
    //     take(1),
    //     switchMap(token => next.handle(this.addTokenHeader(request, token))),
    //     catchError((err) => {
    //       alert('An error occurred while retrying the request.');
    //       return throwError(() => new Error(err.message || 'Error during retry'));
    //     })
    //   );
    // }
  }
  
}
