import { Component } from '@angular/core';

@Component({
  selector: 'app-asset-dashboard',
  templateUrl: './asset-dashboard.component.html',
  styleUrls: ['./asset-dashboard.component.scss']
})
export class AssetDashboardComponent {
  masterItems = [
    { title: 'Asset Type', routerLink: '/asset-management/asset-type-master' },
    { title: 'Asset Sub Type', routerLink: '/asset-management/asset-sub-type-master' },
    { title: 'Asset Category', routerLink: '/asset-management/asset-category' },
    { title: 'Asset Sub Category', routerLink: '/asset-management/asset-sub-category' },
    { title: 'Asset Valuator', routerLink: '/asset-management/asset-evaluator' },
    { title: 'Asset Equipment Breakdown', routerLink: '/asset-management/asset-eqpBreakdown' },
    { title: 'Asset Maintenance CheckList', routerLink: '/asset-management/asset-checklist' },
    { title: 'Asset Checklist Items', routerLink: '/asset-management/asset-checklist-Item' },
    { title: 'Asset Status', routerLink: '/asset-management/asset-status' },
    { title: 'Asset Document List', routerLink: '/asset-management/document-list' },
  ];
}
