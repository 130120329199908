
import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";

@Component({
  selector: "app-add-asset-sub-type",
  templateUrl: "./add-asset-sub-type.component.html",
  styleUrls: ["./add-asset-sub-type.component.scss"],
})
export class AddAssetSubTypeComponent {
  form: FormGroup;
  assetSubTypeName: string = "";
  assetTypeId: any;
  assetTypes: any[] = [];
  id = null;
  addedit:any;
  selectedValues: boolean = false;
  progressSpinnerDialog: any = null;

  constructor(
    private assetService: AssetServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddAssetSubTypeComponent>,
    private fb: FormBuilder,
    private _snackbar: SnackbarMsgService,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {
    this.addedit = data1["isEditMode"];
    this.form = this.fb.group({
      assetSubTypeName: ['',[Validators.required, Validators.maxLength(50),Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)]],
      assetTypeId: [null, [Validators.required, Validators.maxLength(50)]],
      activeFlag: ['Y'],
      createdUserId: [localStorage.getItem('userId')],
      updateUserId: [''],
      id: ['']
    });

    if (this.addedit) {
      const data12 = data1["row"];
      this.id = data12["id"];
      this.form.patchValue({
        assetTypeId:data12["assetTypeId"],
        assetSubTypeName:data12["assetSubTypeName"],
        
      });
    
  
    } 
  }

  ngOnInit() {
    this.getAssetType();
  }

  getAssetType() {
    this.assetService.getActiveAssetType().subscribe(
      (data: any) => {
        this.assetTypes = data["assetTypeMasterList"];
      },
      (err) => {
        this._snackBar.error("Asset Type not Found");
      }
    );
  }
 
  onAssetChange(data: any) {
this.selectedValues = true;
  }
  errorMessage: any;
  saveAssetSubType() {
    if (this.form.valid) {
      const data = {
        id: this.id,
        assetTypeId: this.form.value.assetTypeId,
        assetSubTypeName: this.form.value.assetSubTypeName.trim(),
        activeFlag: "Y",
      };

      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: this.addedit ? "Edit" : "Add",
          component: "Asset Sub Type",
        },
      
        autoFocus: false,
        restoreFocus: false,
        disableClose: true,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          this.displayProgressSpinner(); 
          this.assetService.saveAssetSubType(data).subscribe(
            (res) => {
              if (res["status"].toLowerCase() === "success") {
                const message = this.addedit ? "Record Updated Successfully" : "Record Added Successfully";
                this._snackbar.success(message);
                setTimeout(() => {
                  this.closeProgressSpinner();
                  this.dialogRef.close({ data: "Success" });
                }, 1000);
              }  else{   
                this.closeProgressSpinner();   
                this._snackbar.error(res['message']);
              }
            },
          );
        }
      });
    }
    else {
      this.form.markAllAsTouched();
    }
  }

  async displayProgressSpinner() {
    this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
        disableClose: true,
        panelClass: "progressSpinner",
    });
  }
  
  async closeProgressSpinner() {
  if(this.progressSpinnerDialog) {
    this.progressSpinnerDialog.close();
  }
  }
}
