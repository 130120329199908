<div
  class="m-0 p-6 bg-[#F8F8F8] h-full"
  style="height: calc(100% - 100px); overflow: auto"
  (scroll)="onScroll($event)"
  #divToScroll
>
  <h1 class="main-title">User Profile</h1>

  <!-- Breadcrumb  -->
  <div class="mb-4 breadcrumbsDiv">
    <!-- breadcrumb.component.html -->
    <nav class="text-sm text-gray-600 mb-1">
      <a href="/" class="hover:underline">All Services</a>
      <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
        <span class="breadcrumb-separator"> &gt; </span>
        <ng-container *ngIf="!last">
          <a [routerLink]="breadcrumb.url" class="hover:underline">{{
            breadcrumb.label
          }}</a>
        </ng-container>
        <ng-container *ngIf="last">
          <span class="font-semibold text-gray-800 underline">{{
            breadcrumb.label
          }}</span>
        </ng-container>
      </ng-container>
    </nav>
  </div>
  <div
    class="col-12 lg:col-12 xl:col-12 md:col-12 pl-3 pr-3 pb-0"
    #target
    [ngClass]="'mb-6'"
  >
    <div class="grid m-0 p-0">
      <div class="col-12 xl:col-2 lg:col-2"></div>
      <div class="col-12 xl:col-8 lg:col-8 border-2">
        <div class="grid m-0 p-0">
          <div class="col-12 text-xl text-center">User Profile</div>
          <div class="col-4 xl:col-12 lg:col-12 text-center m-0 p-0"></div>
          <div class="col-12 flex">
            <div class="col-3">
              <div class="col-12 xl:col-12 lg:col-12 text-center">
                <img
                  src="https://via.placeholder.com/150"
                  alt="User Photo"
                  class="person shadow-3"
                />
              </div>
            </div>
            <div class="col-9">
              <div class="col-12 xl:col-10 lg:col-9">
                <div class="grid">
                  <div class="col-12">
                    <label class="profile-label"> Name </label>
                    <label>
                      :{{ form.get("userFirstname").value }}
                      {{ form.get("userLastname").value }}</label
                    >
                  </div>
                  <div class="col-12">
                    <label class="profile-label"> Mobile Number </label>
                    <label> : +91 {{ form.get("userMobile").value }} </label>
                  </div>

                  <div class="col-12">
                    <label class="profile-label"> Email Id </label>
                    <label> : {{ form.get("userEmail").value }} </label>
                  </div>

                  <div class="col-12">
                    <div class="grid p-2">
                      <div class="col-12 p-0">
                        <label class="profile-label"> Address </label>
                        <label> : {{ form.get("adress").value }} </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 xl:col-12 lg:col-12 text-center m-0 p-0"></div>
        </div>
      </div>
      <div class="col-12 xl:col-3 lg:col-2"></div>
    </div>
    <div class="container">
      <div class="row flex">
        <!-- First Card with col-3 and margin-end -->
        <div class="col-3 border-2 p-3 me-3 h-fit">
          <div>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="showPropertyInput()"
            >
              My Property
            </button>
          </div>
          <div>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="showWaterInput()"
            >
              My Water Connection
            </button>
          </div>
          <div>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="showApplication()"
            >
              My Application
            </button>
          </div>
          <div>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="showGrievance()"
            >
              My Grievances
            </button>
          </div>
          <div>
            <button type="button" class="addnewButton w-fit p-3 m-1 rounded">
              Change Password
            </button>
          </div>
        </div>

        <!-- Second Card with col-9 -->
        <div
          *ngIf="!showApplicationSection && !showgrievanceSection"
          class="col-9 border-2 p-3"
        >
          <div *ngIf="showPropertySection">
            <mat-form-field class="col-4">
              <mat-label>Property Number</mat-label>
              <input
                matInput
                id="propertyNumber"
                (input)="validatePropertyNo($event)"
                [(ngModel)]="propertyNumber"
                maxlength="14"
              />
              <span *ngIf="showErrorproperty" class="text-red-600">
                Property number must start with alphabets and be followed by
                Minimum 12 digits.
              </span>
            </mat-form-field>

            <button
              mat-fab
              class="addnewButton w-fit p-3 m-1 rounded"
              type="button"
              (click)="addPropertyNumber()"
              aria-label="Add Property Number"
              [ngClass]="{ disabled: showErrorproperty }"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div
            class="property-button-container"
            *ngIf="propertyNumbers.length > 0 && showPropertySection"
          >
            <div
              *ngFor="let property of propertyNumbers"
              class="property-button-item"
            >
              <!-- color="accent" -->
              <button
                mat-raised-button
                class="light-green-button"
                (click)="fetchPropertyData(property)"
              >
                {{ property }}
              </button>
            </div>
          </div>
          <br />
          <br />
          <div *ngIf="propertyDetails">
            <mat-card-content>
              <p>
                <strong>Property Number:</strong>
                {{ propertyDetails.propertyNo }}
              </p>
              <p><strong>Address:</strong> {{ propertyDetails.address }}</p>
              <p>
                <strong>Ward Name:</strong>
                {{ propertyDetails.wardName }} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Node Name:</strong>
                {{ propertyDetails.nodeName }} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Sector Name:</strong> {{ propertyDetails.sectorName }}
              </p>
              <p>
                <strong>Usage Type:</strong>
                {{ propertyDetails.usageType }} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Sub Usage Type:</strong>
                {{ propertyDetails.subUsageType }}
              </p>
            </mat-card-content>

            <!-- Buttons with spacing -->
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getPropertyLedgerData(propertyDetails.propertyNo)"
            >
              Ledger
            </button>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getPropertyBillData(propertyDetails.propertyNo)"
            >
              Bill
            </button>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getPropertyBillPay(propertyDetails.propertyNo)"
            >
              Pay Now
            </button>
            <button type="button" class="addnewButton w-fit p-3 m-1 rounded">
              Update info
            </button>
            <button
              *ngIf="receiptButton"
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getPropertyBillReceipt(propertyDetails.propertyNo)"
            >
              Receipt
            </button>
          </div>

          <!-- water-->
          <div *ngIf="showWaterSection">
            <mat-form-field class="col-4">
              <mat-label>Consumer Number</mat-label>
              <input
                matInput
                id="consumerNumber"
                [(ngModel)]="consumerNumber"
                (input)="validateConsumerNo()"
                maxlength="9"
                type="number"
              />
              <span *ngIf="showErrorconsumer" class="text-red-600">
                Consumer number must be followed by exactly 9 digits.
              </span>
            </mat-form-field>

            <button
              mat-fab
              color="primary"
              type="button"
              (click)="addConsumerNumber()"
              [ngClass]="{ disabled: showErrorconsumer }"
              aria-label="Add Consumer Number"
            >
              <mat-icon>add</mat-icon>
            </button>
          </div>

          <div
            class="property-button-container"
            *ngIf="consumerNumbers.length > 0"
          >
            <div
              *ngFor="let water of consumerNumbers"
              class="property-button-item"
            >
              <!-- color="accent" -->
              <button
                mat-raised-button
                class="light-green-button"
                (click)="fetchWaterData(water)"
              >
                {{ water }}
              </button>
            </div>
          </div>
          <br />
          <br />
          <div *ngIf="waterData">
            <mat-card-content>
              <p>
                <strong>Consumer Number:</strong>
                {{ waterData.consumerNumber }}
              </p>
              <p><strong>Address:</strong> {{ waterData.address }}</p>
              <p>
                <strong>Ward Name:</strong>
                {{ waterData.wardName }} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Node Name:</strong>
                {{ waterData.nodeName }} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Sector Name:</strong> {{ waterData.sectorName }}
              </p>
              <p>
                <strong>Usage Type:</strong>
                {{ waterData.usageType }} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                <strong>Sub Usage Type:</strong>
                {{ waterData.subUsageType }}
              </p>
            </mat-card-content>

            <!-- Buttons with spacing -->
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getWaterLedgerData(waterData.consumerNumber)"
            >
              Ledger
            </button>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="showWaterBillsWebPortal(waterData.consumerNumber)"
            >
              Bill
            </button>
            <button
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getWaterBillPay(waterData.consumerNumber)"
            >
              Pay Now
            </button>
            <button type="button" class="addnewButton w-fit p-3 m-1 rounded">
              Update info
            </button>
            <button
              *ngIf="receiptButton"
              type="button"
              class="addnewButton w-fit p-3 m-1 rounded"
              (click)="getWaterBillReceipt(waterData.consumerNumber)"
            >
              Receipt
            </button>
          </div>
        </div>
        <div
          class="col-9 border-2 p-3"
          *ngIf="showApplicationSection && !showgrievanceSection"
        >
          <app-my-aaplication></app-my-aaplication>
        </div>
        <div
          class="col-9 border-2 p-3"
          *ngIf="showgrievanceSection && !showApplicationSection"
        >
          <app-grievance-application></app-grievance-application>
        </div>
      </div>
    </div>

    <!--Property ledger-->
    <div
      *ngIf="showPropertyLegderSection"
      class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0"
    >
      <span class="text-lg font-medium text-indigo-500"
        >Details of Demand & Collection</span
      >
    </div>
    <div
      class="w-full lg:w-1/2 bg-gray-50 p-5 rounded-lg shadow-md"
      *ngIf="showPropertyLegderSection"
    >
      <div class="text-base md:text-lg text-gray-700">
        <div class="flex justify-between items-center mb-2">
          <div class="font-semibold text-gray-600">Demand</div>
          <div class="text-base md:text-lg text-gray-800">
            {{ demandAmount | currency : "INR" : "symbol" : "1.2-2" : "en-IN" }}
          </div>
        </div>

        <div class="flex justify-between items-center mb-2">
          <div class="font-semibold text-gray-600">Total Paid</div>
          <div class="text-base md:text-lg text-gray-800">
            {{ collection | currency : "INR" : "symbol" : "1.2-2" : "en-IN" }}
          </div>
        </div>

        <div class="flex justify-between items-center mb-2">
          <div class="font-semibold text-gray-600">Collection + Penalty</div>
          <div class="text-base md:text-lg text-gray-800">
            Bill: {{ billAmount | currency : "INR" : "symbol" : "1.2-2" }} +
            Penalty:
            {{
              penaltyAmount | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
            }}
          </div>
        </div>

        <div
          class="flex justify-between items-center border-t border-gray-300 pt-4"
        >
          <div class="font-semibold text-gray-600">Outstanding</div>
          <div class="text-base md:text-lg">
            {{
              outstandingAmount
                | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-x-auto" *ngIf="showPropertyLegderSection">
      <table class="w-full border-collapse border border-gray-300">
        <thead>
          <tr>
            <th class="p-3 border bg-indigo-200">Serial No</th>
            <th class="p-3 border bg-indigo-200">Ledger Date</th>
            <th class="p-3 border bg-indigo-200">Reference</th>
            <th class="p-3 border bg-indigo-200">Demand ⟨₹⟩</th>
            <th class="p-3 border bg-indigo-200">Collection ⟨₹⟩</th>
            <th class="p-3 border bg-indigo-200">Penalty ⟨₹⟩</th>
            <th class="p-3 border bg-indigo-200">Total ⟨₹⟩</th>
            <th class="p-3 border bg-indigo-200">Remarks</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="ledgerData">
            <tr
              *ngFor="let ledgerDetail of ledgerData; let i = index"
              [ngClass]="{ 'font-bold': ledgerDetail?.transType == 1 }"
            >
              <td class="p-3 border border-gray-300">{{ i + 1 }}</td>
              <td class="p-3 border border-gray-300">
                {{ ledgerDetail?.ledgerDate | date : "dd/MM/yyyy" }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail?.transType == 1
                    ? "Demand - " + ledgerDetail?.demandCycleName
                    : "Collection - " + ledgerDetail?.collectionCenterName
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail?.transType == 1
                    ? (ledgerDetail?.ledgerAmount
                      | currency : "INR" : "symbol" : "1.2-2" : "en-IN")
                    : "0.00"
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail?.transType == 2
                    ? (ledgerDetail?.billAmount + ledgerDetail?.advanceAmount
                      | currency : "INR" : "symbol" : "1.2-2" : "en-IN")
                    : "0.00"
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail?.dpcAmount
                    | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail?.ledgerAmount
                    | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{ ledgerDetail?.remarks }}
              </td>
            </tr>
          </ng-container>
        </tbody>
        <tfoot>
          <tr class="font-bold">
            <td class="p-3 border border-gray-300" colspan="3">Total</td>
            <td class="p-3 border border-gray-300">
              {{
                demandAmount | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
              }}
            </td>
            <td class="p-3 border border-gray-300">
              {{ billAmount | currency : "INR" : "symbol" : "1.2-2" : "en-IN" }}
            </td>
            <td class="p-3 border border-gray-300">
              {{
                penaltyAmount | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
              }}
            </td>
            <td class="p-3 border border-gray-300" colspan="2"></td>
          </tr>
        </tfoot>
      </table>
    </div>

    <!--water ledger-->
    <div *ngIf="this.waterDetails && showWaterLegderSection">
      <div class="w-full rounded-lg overflow-hidden">
        <div
          class="flex flex-col lg:flex-row justify-between p-5 space-y-4 lg:space-y-0 lg:space-x-4"
        >
          <div class="w-full lg:w-1/1 p-5 rounded-lg shadow-md">
            <div class="text-base md:text-lg text-gray-700">
              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600"
                  >Consumer Number:</span
                >
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.consumerNumber
                }}</span>
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600">Name:</span>
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.applicantName
                }}</span>
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600"
                  >Conncetion Address:</span
                >
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.applicantAddress
                }}</span>
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600">Meter Number:</span>
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.meterNumber
                }}</span>
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600">Outstanding:</span>
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.totalOutStanding
                }}</span>
              </div>
              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600">Due Date:</span>
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.billDueDate
                }}</span>
              </div>

              <div
                class="flex flex-col md:flex-row items-start md:items-center mb-2"
              >
                <span class="font-semibold text-gray-600"
                  >Connection Size:</span
                >
                <span class="mt-1 md:mt-0 md:ml-2">{{
                  waterDetails.connectionSizeMM
                }}</span>
              </div>
            </div>
          </div>

          <div
            class="w-full lg:w-1/2 bg-gray-50 p-5 rounded-lg shadow-md"
            *ngIf="false"
          >
            <div class="text-base md:text-lg text-gray-700">
              <div class="flex justify-between items-center mb-2">
                <div class="font-semibold text-gray-600">Demand Amount</div>
                <div class="text-base md:text-lg text-gray-800">
                  {{ 100 | currency : "INR" : "symbol" : "1.2-2" : "en-IN" }}
                </div>
              </div>

              <div class="flex justify-between items-center mb-2">
                <div class="font-semibold text-gray-600">Collection Amount</div>
                <div class="text-base md:text-lg text-gray-800">
                  {{ 200 | currency : "INR" : "symbol" : "1.2-2" : "en-IN" }}
                </div>
              </div>

              <div class="flex justify-between items-center mb-2">
                <div class="font-semibold text-gray-600">Bill</div>
                <div class="text-base md:text-lg text-gray-800">
                  Bill: {{ 500 | currency : "INR" : "symbol" : "1.2-2" }} +
                  Penalty:
                  {{ 600 | currency : "INR" : "symbol" : "1.2-2" : "en-IN" }}
                </div>
              </div>

              <div
                class="flex justify-between items-center border-t border-gray-300 pt-4"
              >
                <div class="font-semibold text-gray-600">Outstanding</div>
                <div class="text-base md:text-lg">
                  {{
                    billData.prevosAmt +
                      billData.billAmt +
                      billData.dpcAmt1 -
                      billData.advanceAmt
                      | currency : "INR" : "symbol" : "1.2-2" : "en-IN"
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="flex items-center justify-center border-blue-600 bg-indigo-200 rounded p-1 text-center w-full h-auto m-4 ml-0"
      >
        <p class="m-0 px-1 pt-2 font-arial text-sm font-bold">
          Details of Demand & Collection
        </p>
      </div>
      <div class="overflow-x-auto">
        <table class="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th class="p-3 border bg-indigo-200">Sr.No</th>
              <th class="p-3 border bg-indigo-200">Date</th>
              <th class="p-3 border bg-indigo-200">Reference</th>
              <th class="p-3 border bg-indigo-200">Demand ⟨RS⟩</th>
              <th class="p-3 border bg-indigo-200">Collection⟨RS⟩</th>
              <th class="p-3 border bg-indigo-200">Dpc⟨₹⟩</th>
              <th class="p-3 border bg-indigo-200">Penality⟨RS⟩</th>
              <th class="p-3 border bg-indigo-200">Total⟨RS⟩</th>
              <th class="p-3 border bg-indigo-200">Bill Print</th>
              <!-- <th class="p-3 border bg-indigo-200">Penalty ⟨₹⟩</th>
                    <th class="p-3 border bg-indigo-200">Adv Amt ⟨₹⟩</th>
                    <th class="p-3 border bg-indigo-200">Total ⟨₹⟩</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ledgerDetail of waterDetails.ledger; let i = index">
              <td class="p-3 border border-gray-300">{{ i + 1 }}</td>
              <td class="p-3 border border-gray-300">
                {{ ledgerDetail.ledgerDate | date : "dd/MM/yyyy" }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail.collectionCenterId != null
                    ? ledgerDetail.collectionCenterName
                    : ledgerDetail.demandCycleName
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail.collectionCenterId != null
                    ? "0"
                    : ledgerDetail.amount
                }}
              </td>
              <td class="p-3 border border-gray-300">
                {{
                  ledgerDetail.collectionCenterId != null
                    ? ledgerDetail.amount
                    : "0"
                }}
              </td>
              <td class="p-3 border border-gray-300">{{ ledgerDetail.dpc }}</td>
              <td class="p-3 border border-gray-300">
                {{ ledgerDetail.penalty + ledgerDetail.dpcApplicablePenalty }}
              </td>
              <td class="p-3 border border-gray-300">
                {{ ledgerDetail.ledgerAmount }}
              </td>
              <td class="p-3 border border-gray-300"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="font-bold">
              <td class="p-3 border border-gray-300" colspan="3">
                Total(in Rs.):
              </td>
              <td class="p-3 border border-gray-300">
                {{ waterDetails.totalDemand }}
              </td>
              <td class="p-3 border border-gray-300">
                {{ waterDetails.totalCollection }}
              </td>
              <td class="p-3 border border-gray-300">
                {{ waterDetails.totalDpc }}
              </td>
              <td class="p-3 border border-gray-300">
                {{ waterDetails.totalPenalty }}
              </td>
              <td class="p-3 border border-gray-300"></td>
            </tr>

            <tr class="font-bold">
              <td class="p-3 border border-gray-300" colspan="3">
                Outstanding (in Rs.):
              </td>
              <td class="p-3 border border-gray-300" colspan="5">
                {{ waterDetails.totalOutStanding }}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <!-- property Bill-->

    <!-- <div
      *ngIf="dataLoaded && showPropertyBillSection"
      class="bg-white shadow-md rounded-lg p-6"
    >
      <div class="text-center mb-6">
        <h2 class="text-gray-900 text-3xl font-bold">
          Property No: {{ propertyDetails.propertyNo }}
        </h2>
      </div>

      <div class="flex justify-between text-gray-800 text-base mb-6">
        <p class="mr-4">Bill From: {{ currentBill.billDateFrom }}</p>
        <p>Bill To: {{ currentBill.billDateTo }}</p>
      </div>

      <table class="min-w-full border border-gray-300 mb-6">
        <thead>
          <tr class="bg-gray-100 text-gray-700">
            <th class="px-4 py-2 border">Tax Name</th>
            <th class="px-4 py-2 border">Tax Percentage</th>
            <th class="px-4 py-2 border">Authorized Residential</th>
            <th class="px-4 py-2 border">Unauthorized Residential</th>
            <th class="px-4 py-2 border">Tax Percentage</th>
            <th class="px-4 py-2 border">Non-Residential</th>
            <th class="px-4 py-2 border">Unauthorized Non-Residential</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let tax of getTaxDataForDisplay()"
            class="hover:bg-gray-50 transition duration-200"
          >
            <td class="px-4 py-2 border">{{ tax.taxName }}</td>
            <td class="px-4 py-2 border">
              {{ tax.authResidentialPercentage }}%
            </td>
            <td class="px-4 py-2 border">
              {{
                tax.authResidentialAmount
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
            <td class="px-4 py-2 border">
              {{
                tax.unAuthResidentialAmount
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
            <td class="px-4 py-2 border">
              {{ tax.nonResidentialPercentage }}%
            </td>
            <td class="px-4 py-2 border">
              {{
                tax.nonResidentialAmount | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
            <td class="px-4 py-2 border">
              {{
                tax.unAuthNonResidentialAmount
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="bg-gray-200 text-gray-800 font-semibold">
            <td class="px-4 py-2 border" colspan="2">Total</td>
            <td class="px-4 py-2 border">
              {{
                calculateTotalAuthorizedResidential()
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
            <td class="px-4 py-2 border">
              {{
                calculateTotalUnauthorizedResidential()
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
            <td class="px-4 py-2 border"></td>
            <td class="px-4 py-2 border">
              {{
                calculateTotalNonResidential()
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
            <td class="px-4 py-2 border">
              {{
                calculateTotalUnauthorizedNonResidential()
                  | currency : "INR" : "symbol" : "1.2-2"
              }}
            </td>
          </tr>
        </tfoot>
      </table>

      <div class="bg-gray-100 p-4 rounded-lg mb-6">
        <h2 class="text-lg font-semibold">
          Total Outstanding:
          {{ getTotalOutstanding() | currency : "INR" : "symbol" : "1.2-2" }}
        </h2>
      </div>

      <div class="bg-gray-100 p-4 rounded-lg">
        <h2 class="text-lg font-semibold">
          Outstanding Amounts by Month and Year
        </h2>
        <table class="min-w-full border border-gray-300 mt-4">
          <thead>
            <tr class="bg-gray-100 text-gray-700">
              <th class="px-4 py-2 border">Month</th>
              <th class="px-4 py-2 border">Year</th>
              <th class="px-4 py-2 border">Total Outstanding Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let monthYear of outstandingAmountsByMonth | keyvalue"
              class="hover:bg-gray-50 transition duration-200"
            >
              <td class="px-4 py-2 border">{{ monthYear.value.month }}</td>
              <td class="px-4 py-2 border">{{ monthYear.value.year }}</td>
              <td class="px-4 py-2 border">
                {{
                  monthYear.value.totalOutstanding
                    | currency : "INR" : "symbol" : "1.2-2"
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div> -->
    <div
      *ngIf="showPropertyBillSection"
      class="container mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div class="bg-blue-200 text-center text-lg font-semibold py-2 mb-4">
        Property No: {{ propertyDetails.propertyNo }}
      </div>
      <div *ngIf="fileUrlProperty; else loadingTemplate">
        <iframe [src]="fileUrlProperty" width="100%" height="600px"></iframe>
      </div>
      <ng-template #loadingTemplate>
        <div>Loading...</div>
      </ng-template>
    </div>

    <!-- water bill-->
    <div
      *ngIf="showWaterBillSection"
      class="container mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div class="bg-blue-200 text-center text-lg font-semibold py-2 mb-4">
        Consumer No: {{ waterDetails.consumerNumber }}
      </div>
      <div *ngIf="fileUrl1">
        <iframe [src]="fileUrl1" width="100%" height="600px"></iframe>
      </div>
      <div *ngIf="!fileUrl1">Loading...</div>
    </div>

    <!-- My Applications  -->
  </div>
  <div
    class="col-12 lg:col-1 xl:col-1 md:col-1 file-upload-container text-center"
  ></div>
</div>
