import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/_services/DisasterServices/data.service';
import { MsmamServiceService } from 'src/app/_services/MSMAM/msmam-service.service';
import { FileUploadService } from 'src/app/_services/file-upload.service';
import { MasterService } from 'src/app/_services/master.service';
import { ConfirmationPopupComponent } from 'src/app/confirmation-popup/confirmation-popup.component';
import { SnackbarMsgService } from 'src/app/shared/Toast-service/snackbar-msg.service';

@Component({
  selector: 'app-add-new-docketentry',
  templateUrl: './add-new-docketentry.component.html',
  styleUrls: ['./add-new-docketentry.component.scss']
})
export class AddNewDocketentryComponent {

  departments: any;
  departmentId: any;
  docketNo: number;
  subject: any;
  departmentName:any;
  subjectSummary: any;
  subjectDate: any;
  committeeId: number;
  committee: string;
  committees: any;
  financialYear: any;
  meetingTypeId: any;
  meetingTypes: any;
  meetingTypeName: any;
  fileLocation: any;
  fileUplaod: boolean;
  fileName: any;
  ammount: number;
  date: any;
  time: any;
  status: string;
  remark: string;
  uploadDocument:any;
  documents: any[] = [];  
  prvdocuments:any;
  addedit: any;
  createdUserId:any;
  updateUserId:any;
  userId: string;
  id = null;
  constructor(
    private msmService: MsmamServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddNewDocketentryComponent>,
    private uploadService: FileUploadService,
    private dataService: DataService,
    private masterService: MasterService,
    @Inject(MAT_DIALOG_DATA) public data1: any
  ) {

  }

  ngOnInit() {
    this.getCommittees();
    this.getDepartment();
    this.getMetype();
    this.initializeFormData();
  }

  initializeFormData() {
    this.userId = localStorage.getItem("userId");
    this.createdUserId=this.userId;
    this.updateUserId=this.userId;
    this.addedit = this.data1['isEditMode'];
    if (this.addedit) {
      const data12 = this.data1['row'];
      this.createdUserId=data12['createdUserId'];
      this.updateUserId=this.userId;
      Object.assign(this, data12);
      this.departmentName = data12['departmentName'];
      this.meetingTypeName = data12['meetingTypeName'];
      // const documentsString = data12['uploadDocument'];
      // this.prvdocuments = JSON.parse(documentsString);
      this.dataService.clearData();
        this.dataService.setDocuments(data12['uploadDocument']);

      
    }
  }

  getMetype() {
    this.msmService.getByActiveFlagMeetingType().subscribe(
      (data) => (this.meetingTypes = data['meetingTypeMasterList']),
      () => this._snackBar.error('Meeting type List is Empty')
    );
  }

  meetingTypeChange(data) {
    this.meetingTypeId = data;
    this.meetingTypeName = this.meetingTypes.find((item) => item.id === data)?.meetingTypeName || '';
  }

  getDepartment() {
    this.masterService.getdepartment().subscribe(
      (data) => (this.departments = data['department']),
      () => this._snackBar.error('Department List is Empty')
    );
  }

  departmentChange(data) {
    this.departmentId = data;
    this.departmentName = this.departments.find((item) => item.id === data)?.departmentName || '';
  }
  

  onSelectYear(year: number): void {
    this.financialYear = year;
  }
  getCommittees() {
    this.msmService.getByActiveFlagCommittee().subscribe(
      (data) => (this.committees = data['committeeMasterList']),
      () => this._snackBar.error('Committee List is Empty')
    );
  }

  committeeChange(data) {
    this.committeeId = data;
    this.committee = this.committees.find((item) => item.id === data)?.committeeName || '';
  }

 

onFileChange(event: Event) {
  const inputElement: HTMLInputElement = event.target as HTMLInputElement;

  if (inputElement.files) {
    const files = inputElement.files;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

    for (let i = 0; i < Math.min(files.length, 2); i++) {
      const file = files[i];

      if (allowedTypes.includes(file.type)) {
        this.uploadService.uploadFile(file).then((data: any) => {
          const uploadedDocument = {
            filePath: JSON.parse(data).filePath,
            fileName: JSON.parse(data).fileName
          };
          this.documents.push(uploadedDocument);
          this._snackBar.success(`Document ${i + 1} Uploaded Successfully`);
        });
      } else {
        this._snackBar.error('Only upload pdf, word, excel');
      }
    }
  }
}


  saveMeeting() {
    if (!this.documents || this.documents.length === 0) {
      this._snackBar.error('No file selected');
      return;
    }
    
    
    for (const document of this.documents) {
      const jsonData: any = {
        id: null,
        fileLocation: document.filePath 
      };
      this.dataService.addDocument(jsonData);
    }
    
    // const newJsonDataArray = this.dataService.addDocument(jsonDataArray);

    const jsonString: string = JSON.stringify(this.dataService.getDocuments());

    
    this.documents = [];
    this.dataService.clearData();
    

    const data = {
      id: this.id,
      committeeId: this.committeeId,
      committee: this.committee,
      departmentId: this.departmentId,
      departmentName: this.departmentName,
      docketNo: this.docketNo,
      subject: this.subject,
      subjectSummary: this.subjectSummary,
      subjectDate: this.subjectDate,
      financialYear: this.financialYear,
      meetingTypeId: this.meetingTypeId,
      meetingTypes: this.meetingTypes,
      meetingTypeName: this.meetingTypeName,
      fileLocation: this.fileLocation,
      fileUplaod: this.fileUplaod,
      fileName: this.fileName,
      ammount: this.ammount,
      status: this.status,
      remark: this.remark,
      createdUserId:this.createdUserId,
      updateUserId:this.updateUserId,
      uploadDocument: jsonString,
      activeFlag: 'Y'
    };

    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      width: '550px',
      data: {
        mode: this.addedit ? 'Edit' : 'Add',
        component: 'Docket Entry'
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'success') {
        this.msmService.saveNewDocketentry(data).subscribe(
          () => {
            setTimeout(() => {
              this.dialogRef.close({ data: 'Success' });
            }, 1000);
            this._snackBar.success(this.addedit ? 'Docket Entry Edited successfully!' : 'Docket Entry Added successfully!');
          },
          () => this._snackBar.error('Docket Entry not added')
        );
      }
    });
  }
}