
  <div class="bg-gray-100 p-6 scroll-m-0" style="max-height: 90vh; overflow-y: auto;">

    <div class="flex items-center">

     
  
        <div>
          <button mat-icon-button (click)="cancel()">
            <mat-icon>arrow_back_ios_new</mat-icon>
            </button> <span class="text-lg font-medium">Life Certificate</span>
        </div>
      </div>

      <div class="flex justify-center">
        <h3 class="text-indigo-800 text-2xl font-bold mb-8">Life Certificate {{action}} {{userActions}}</h3>
    </div>
   
    <form [formGroup]="applicationForm">
     
    <fieldset class='border-solid border-2 border-zinc-700 p-6'>
        <legend class="text-xl-500 text-indigo-800 text-lg mb-4 p-2"> Basic Details of Pensioner </legend>
           
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">

            <mat-form-field class="w-full">
                <mat-label>Firstname</mat-label>
                <input matInput formControlName="firstName" required  />
                <mat-error *ngIf="applicationForm.get('firstName').hasError('required')">
                    firstName is required.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>Middlename</mat-label>
                <input matInput formControlName="middleName" required  />
                <mat-error *ngIf="applicationForm.get('middleName').hasError('required')">
                    middleName is required.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>Lastname</mat-label>
                <input matInput formControlName="lastName" required  />
                <mat-error *ngIf="applicationForm.get('lastName').hasError('required')">
                    lastName is required.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>Pensioner Name</mat-label>
                <input matInput formControlName="pensionerName" required  />
                <mat-error *ngIf="applicationForm.get('pensionerName').hasError('required')">
                    pensionerName is required.
                </mat-error>
              </mat-form-field>

                <mat-form-field  class="w-full">
                  <mat-select formControlName="gender" placeholder="Select Gender">
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Other">Other</mat-option>
                  </mat-select>
                </mat-form-field>
              
                <mat-form-field class="w-full">
                    <mat-label>Phone Number</mat-label>
                    <input onKeyPress="if(this.value.length==10) return false;" type="number" (keypress)="keyPress($event)" type="text"  id="phone-number"  matInput formControlName="mobile" required />
                    <mat-error *ngIf="applicationForm.get('mobile').touched && applicationForm.get('mobile').hasError('required')">
                        phone No. is required.
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('mobile').touched && applicationForm.get('mobile').errors">
                      Please enter a valid number
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field class="w-full">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" required />
                    <mat-error *ngIf="applicationForm.get('email').hasError('required')">
                      Email is required.
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('email').hasError('email')">
                      Please enter a valid email address.
                    </mat-error>
                    <mat-error *ngIf="applicationForm.get('email').touched && applicationForm.get('email').errors?.['invalidTLD']" class="text-red-500">
                      Invalid top-level domain. Please use .com, .in, .ai, etc.
                  </mat-error>
                  </mat-form-field>

          
              <mat-form-field class="w-full" >
                <mat-label>Aadhar</mat-label>
                <input matInput formControlName="adharNo" placeholder="Enter Aadhar Number" />
                <!-- <mat-error *ngIf="applicationForm.get('adharNo').hasError('required')">
                   Aadhar is required.
                </mat-error>
                <mat-error *ngIf="applicationForm.get('adharNo').hasError('pattern')">
                  Aadhar number should be 12 digits.
                </mat-error> -->
              </mat-form-field>

              <mat-form-field appearance="fill" >
                <mat-label>Pan No</mat-label>
                <input matInput formControlName="panNo" placeholder="panNo" />
                <mat-error *ngIf="applicationForm.get('panNo').hasError('required') && applicationForm.get('panNo').touched">
                    Pan No is required
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Address</mat-label>
                <input matInput formControlName="address" required  />
                <mat-error *ngIf="applicationForm.get('address').hasError('required')">
                    address is required.
                </mat-error>
              </mat-form-field>
          

             

        </div>
    </fieldset>

  
    <fieldset class='border-solid border-2 border-zinc-700 p-6'>
      <legend class="text-xl-500 text-indigo-800 text-lg mb-4 p-2"> Bank Details </legend>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">

            <!-- <mat-form-field class="w-full">
                <mat-label>Select Bank </mat-label>
                <mat-select formControlName="bankId">
                    <mat-option *ngFor="let item of bankData" [value]="item.id">{{ item.bankName }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Select Branch </mat-label>
                <mat-select formControlName="branchId">
                    <mat-option *ngFor="let item of branchData" [value]="item.id">{{ item.bankName }}</mat-option>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field class="w-full">
              <mat-label>Bank Name</mat-label>
              <input matInput formControlName="bankName"  required  />
              <mat-error *ngIf="applicationForm.get('bankName').hasError('required')">
                 Bank Name is required.
              </mat-error>

            </mat-form-field>

            <mat-form-field class="w-full">
              <mat-label>Branch Name</mat-label>
              <input matInput formControlName="branchName"  required  />
              <mat-error *ngIf="applicationForm.get('branchName').hasError('required')">
                 Branch Name is required.
              </mat-error>
           
            </mat-form-field>
            
            <mat-form-field class="w-full">
                <mat-label>Account Type</mat-label>
                <mat-select formControlName="accountType" >
                    <mat-option *ngFor="let item of accTypes" [value]="item.accType">{{ item.accType }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Account No.</mat-label>
                <input matInput formControlName="accountNo" type="number" required  />
                <mat-error *ngIf="applicationForm.get('accountNo').hasError('required')">
                    Account No. is required.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="w-full">
                <mat-label>IFSC Code</mat-label>
                <input matInput formControlName="ifscCode"  required  />
                <mat-error *ngIf="applicationForm.get('ifscCode').hasError('required')">
                   IFSC is required.
                </mat-error>
              </mat-form-field>


        </div>
    </fieldset>

    <fieldset class='border-solid border-2 border-zinc-700 p-6'>
        <legend class="text-xl-500 text-indigo-800 text-lg mb-4 p-2"> Live Details </legend>
           
     

              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2 "> 

         

                <div class="w-full">
                  <label class="text-sm font-medium text-gray-700" for="reviewDate">Date</label>
                  <input type="date" id="date" name="date" formControlName="date"
                  class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                  required />
                  <p class="mt-1 text-sm text-red-600" *ngIf="applicationForm.get('date').hasError('required') && applicationForm.get('date').touched">
                       Date is required.
                  </p>
                </div>

                <mat-form-field class="w-full">
                  <mat-label>Place</mat-label>
                  <input matInput formControlName="place" required  />
                  <mat-error *ngIf="applicationForm.get('place').hasError('required')">
                      place is required.
                  </mat-error>
                </mat-form-field>
           </div>

           <!-- <div class="w-full" *ngIf="showPhoto">
            <button type="button"  (click)="openLivePhoto()" class="text-blue-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
              <mat-icon>visibility</mat-icon>&nbsp;Live Photo
            </button>
          </div> -->
              
         
    </fieldset>
    <fieldset class='border-solid border-2 border-zinc-700 p-6'>
      <legend class="text-xl-500 text-indigo-800 text-lg mb-4 p-2"> Uploaded Documents </legend>
      <div class="overflow-x-auto mt-2" *ngIf="documentList.length != 0">
        <table class="table-auto border-collapse border border-gray-300 w-full">
          <thead>
            <tr class="bg-indigo-200">
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Sr. No.</th>
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">Document Name</th>
              <th class="m-0 px-1 pt-2 font-serif text-sm py-2">view</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of documentList; let i = index">
              <td class="px-4 py-2 border border-gray-300">{{ i + 1 }}</td>
              <td class="px-4 py-2 border border-gray-300">{{ doc.documentName }}</td>
              <td class="px-4 py-2 border border-gray-300 text-center">
                <mat-icon color="primary" (click)="viewDocuments(doc.filePath,doc.documentName)">
                  remove_red_eye  
                </mat-icon>
              </td>
           
            </tr>
          </tbody>
        </table>
      </div>
    </fieldset>
 

  

  <fieldset class='border-solid border-2 border-zinc-700 p-6' *ngIf="appStatus != 'APPLICATION_CREATED' && !applicationForm.get('remark').value">
    <legend class="text-xl-500 text-indigo-800 text-lg mb-4 p-2"> Previous remarks </legend>
    <mat-form-field class="w-full"  *ngIf="applicationForm.get('remark').value">
      <mat-label>Remark</mat-label>
      <textarea matInput placeholder="scrutiny remark" formControlName="remark" readonly></textarea>
    </mat-form-field>


  </fieldset>

 
          <div class="flex justify-center" *ngIf="assignStage.includes(appStatus) && (appStatus != 'REJECTED_BY_CLEARK' && appStatus != 'ADMINISTRATIVE_OFFICER_APPROVED')">
            
            <button id="button" type="button" (click)="approve()"
            *ngIf="appStatus != 'ADMINISTRATIVE_OFFICER_APPROVED'"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-green-500 hover:bg-green-600 hover:shadow-lg focus:outline-none">
                Approve
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button id="button" type="button" (click)="reject()"
            *ngIf="appStatus == 'APPLICATION_CREATED' || appStatus == 'APPROVED_BY_CLEARK' || appStatus == 'ADMINISTRATIVE_OFFICER_APPROVED' || appStatus == 'REJECTED_BY_CLEARK' || appStatus == 'ADMINISTRATIVE_OFFICER_REJECTED'"
                class="w-50 disabled:bg-gray-300 px-6 py-3 mt-3 text-lg text-white transition-all duration-150 ease-linear rounded-lg shadow outline-none bg-red-500 hover:bg-red-600 hover:shadow-lg focus:outline-none">
                Reject
            </button>
        </div>

       
    </form>
    
</div>
<div class="mt-10"></div>