import { Component, Output, EventEmitter } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-year-picker',
  template: `
    <mat-form-field class="w-full">
      <mat-label>Financial Year</mat-label>
      <mat-select (selectionChange)="selectYear.emit($event.value)">
        <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
      </mat-select>
    </mat-form-field>
  `,
  // styleUrls: ['./year-picker.component.css'],
})
export class YearPickerComponent {
  @Output() selectYear = new EventEmitter<number>();
  years: number[] = [];

  constructor() {
    this.populateYears();
  }

  private populateYears(): void {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear; year >= currentYear - 10; year--) {
      this.years.push(year);
    }
  }
}
