import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class GrievanceManagementServiceService {
  constructor(private http: HttpClient) {}

  getdocumentimg(data: string): Observable<Blob> {
    const params = new HttpParams().set("filePath", data);
    return this.http.get(`${environment.commonUrl}/api/file/preview`, {
      params,
      responseType: "blob", // Add response type here
    });
  }

  //Keywords
  getKeywords() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceKeyword/getAll`
    );
  }

  getKeywordsById(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceKeyword/getById?id=${id}`
    );
  }
  getByActiveFlagKeywords() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceKeyword/getAllByActiveFlag`
    );
  }

  getAllByKewordsName(keyword: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getByKeywordName/?keyword=${keyword}`
    );
  }

  activeInactiveItemKeywords(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceKeyword/activeInActive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveKeywords(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceKeyword/save`,
      data
    );
  }

  // Apartment
  getApartment() {
    return this.http.get(`${environment.gmUrl}/api/master/apartment/getAll`);
  }

  getByActiveFlagApartment() {
    return this.http.get(
      `${environment.gmUrl}/api/master/apartment/getAllByActiveFlag`
    );
  }

  activeInactiveItemApartment(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/apartment/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveApartment(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/apartment/save`,
      data
    );
  }

  // Applicant Type
  getApplicantType() {
    return this.http.get(
      `${environment.gmUrl}/api/master/applicantType/getAll`
    );
  }
  getByActiveFlagApplicantType() {
    return this.http.get(
      `${environment.gmUrl}/api/master/applicantType/getAllByActiveFlag`
    );
  }
  activeInactiveItemApplicantType(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/applicantType/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveApplicantType(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/applicantType/save`,
      data
    );
  }

  // Channel
  getChannel() {
    return this.http.get(`${environment.gmUrl}/api/master/channel/getAll`);
  }
  getByActiveFlagChannel() {
    return this.http.get(
      `${environment.gmUrl}/api/master/channel/getAllByActiveFlag`
    );
  }
  activeInactiveItemChannel(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/channel/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  saveChannel(data: any) {
    return this.http.post(`${environment.gmUrl}/api/master/channel/save`, data);
  }

  // Grievance Type
  getGrievanceType() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceType/getAll`
    );
  }
  getByActiveFlagGrievanceType() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceType/getAllByActiveFlag`
    );
  }
  activeInactiveItemGrievanceType(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceType/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveGrievanceType(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceType/save`,
      data
    );
  }

  // Grievnace Sub Type
  getGrievanceSubType() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceSubType/getAll`
    );
  }
  getGrievanceSubTypeById(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceSubType/getByGrievanceTypeId?gtId=${id}`
    );
  }

  getByActiveFlagGrievanceSubType() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceSubType/getAllByActiveFlag`
    );
  }
  activeInactiveItemGrievanceSubType(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceSubType/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveGrievanceSubType(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceSubType/save`,
      data
    );
  }

  // Ward Officer
  getWardOfficer() {
    return this.http.get(
      `${environment.gmUrl}/api/master/wardOfficer/getAll`
    );
  }
  getWardOfficerById(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/master/wardOfficer/getByGrievanceTypeId?gtId=${id}`
    );
  }

  activeInactiveWardOfficer(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceWorkflowOrEscalation/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }

  getByActiveFlagWardOfficer() {
    return this.http.get(
      `${environment.gmUrl}/api/master/wardOfficer/getAllByActiveFlag`
    );
  }

  saveWardOfficer(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/wardOfficer/save`,
      data
    );
  }
  // Ward Officer

  // Grievance Flow Escalation
  getGrievanceFlowEscalation() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceWorkflowOrEscalation/getAll`
    );
  }

  getGrievanceFlowEscalationById(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceWorkflowOrEscalation/getById?id=${id}`
    );
  }

  getByActiveFlagGrievanceFlowEscalation() {
    return this.http.get(
      `${environment.gmUrl}/api/master/grievanceWorkflowOrEscalation/getAllByActiveFlag`
    );
  }

  activeInactiveItemGrievanceFlowEscalation(id, flag) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceWorkflowOrEscalation/activeInactive?id=${id}&activeFlag=${flag}`,
      null
    );
  }
  saveGrievanceFlowEscalation(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/master/grievanceWorkflowOrEscalation/saveV1`,
      data
    );
  }
  getDesignationFromDept(id: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/mstDepartmentDesignationMapping/getByDepartmentIdV1?departmentId=${id}`
    );
  }

  // common

  getLocation() {
    return this.http.get(`${environment.commonUrl}/api/master/location/getAll`);
  }
  getByActiveFlagLocation() {
    return this.http.get(
      `${environment.commonUrl}/api/master/location/getAllByActiveFlag`
    );
  }
  getZone() {
    var url = `${environment.commonUrl}` + "/api/master/zone/getAllDashBoard";
    return this.http.get(url);
  }
  getWardByZoneId(zoneId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/ward/getWardByZoneId?zoneId=${zoneId}`
    );
  }
  getNodeByZoneId(zoneId: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/node/getNodeByZoneId?zoneId=${zoneId}`
    );
  }
  getsectorbyward(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByWardId?wardId=${data}`
    );
  }
  getsectorbyNode(data: any) {
    return this.http.get(
      `${environment.commonUrl}/api/master/sector/getSectorByNodeId?nodeId=${data}`
    );
  }
  getArea() {
    var url = `${environment.commonUrl}` + "/api/master/area/getAllDashboard";
    return this.http.get(url);
  }
  getByActiveFlagArea() {
    return this.http.get(`${environment.commonUrl}/api/master/area/getAll`);
  }
  getDepartment() {
    var url =
      `${environment.commonUrl}` + "/api/master/department/getAllDashboard";
    return this.http.get(url);
  }

  getByActiveFlagDepartment() {
    var url = `${environment.commonUrl}` + "/api/master/department/getAll";
    return this.http.get(url);
  }

  getSortDepartment() {
    const departmentIds = [
      "ac1f1f79-8c81-193b-818c-817804f7000c", // Property Tax
      "ac1f1f79-8c91-1b82-818c-a4ba3caf0009", // License
      "0190db33-5dbb-7fb2-b73a-dd139a1685bb", // Water
      "018f523c-2434-7bd4-8c8d-6853d0b938e3", // Sewerage
      "018f523c-bd54-7391-b369-ac489708f963", // Estate
      "018ee692-648c-773c-8a44-58425950b7c5", // Vehicle & Mechanical
      "0190cf5b-aaa3-7708-b292-86f4394ca73c", // LBT and CESS
      "018f6104-842a-791f-b63f-60af3ff8df56", // Town Planning
      "0190f563-c0db-7871-82d2-e9e16a1ae324", // Electrical
      "018ee68f-db51-75bc-9a64-ace798eccf53", // Fire
      "018e7ec8-b7d1-7728-b932-f0e0f417852a", // City Engineer
      "018ee695-6854-7eab-a47e-6d8a59256dae", // Audit
      "0190a58e-9fe4-7b53-baf5-454b3747e119", // Environment
      "0190ceb3-5a02-7e7a-a6eb-c3b705d25e8c", // ETC
      "0190cf56-4163-76ab-9af6-a5dafc5cf8bc", // Samaj Vikas
      "0190cf5a-51b1-72ec-8f84-5ea5f9123bac", // Sports & Culture
      "018ee694-3d66-7bab-b2b6-03853d648d10", // Library
      "0190cf5b-f25c-7ea0-98e2-d9edaee2546b", // Legal and Law
      "ac1f1f79-8c7d-1d2f-818c-80a986b30012", // Health
      "018ee694-6e92-738d-8758-1570f0b271f5", // Election
      "0190f573-3f86-7056-bd03-bf1253eeba4e", // Education
      "0190dba3-61a1-7faf-a98e-14327618e3fa", // Computer
      "0190cf55-b646-7b7c-b086-ca26b07ef202", // Municipal Secretary
      "018ee696-11f6-7148-bb29-5c186cebcf80", // Encroachment
      "018f809d-fd60-7b5c-9cf0-36b233c48023", // Garden Department
      "0190c996-e636-7f5f-9169-3b4b7345b71a", // Solid Waste Management
      "01927657-a12f-7779-af7d-79f5bca5f8f8"  // NMMT(Transport)
    ];
    
    const url = `${environment.commonUrl}/api/master/department/getAll`;
  
    return this.http.get(url).pipe(
      map((data: any) => {
        // Check if the departmentMasterList exists to avoid potential errors.
        return data.departmentMasterList?.filter(department => departmentIds.includes(department.id)) || [];
      }),
      catchError(error => {
        // Handle error appropriately
        console.error("Error fetching departments:", error);
        return [];
      })
    );
  }
  

  getSubDepartment() {
    var url =
      `${environment.commonUrl}` + "/api/master/subDepartment/getAllDashboard";
    return this.http.get(url);
  }
  getSubDepartmentByDepartment(deptId: any) {
    var url =
      `${environment.commonUrl}` +
      "/api/master/subDepartment/getByDepartment?dptId=" +
      deptId;
    return this.http.get(url);
  }
  getEmployee() {
    return this.http.get(
      `${environment.hrmsUrl}/api/master/mstEmployee/getAll`
    );
  }

  getByActiveFlagEmployee() {
    return this.http.get(
      `${environment.hrmsUrl}/api/master/mstEmployee/getAllActiveList`
    );
  }

  getByActiveFlagDesignation() {
    return this.http.get(
      `${environment.commonUrl}/api/master/designation/getAll`
    );
  }

  getLevelOfEmp() {
    return this.http.get(
      `${environment.hrmsUrl}/api/master/mstClass/getAllActive`
    );
  }

  getEmpDetailsByDesIdandClassId(desId: any, classId: any) {
    return this.http.get<any>(
      `${environment.hrmsUrl}/api/master/mstEmployee/getEmpDetailsByDesIdandClassId?desId=${desId}&classId=${classId}`
    );
  }

  // Register
  getGrievanceRegister() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getAll`
    );
  }

  getGrievanceRegisterByUserDeptUgpPriv(
    userId: any,
    dptId: any,
    ugpPrevId: any
  ) {
    if (dptId == null || dptId == "null") {
      console.log(
        `${environment.gmUrl}/api/trn/registerGrievance/getAll?userId=${userId}&ugpPrevId=${ugpPrevId}`
      );

      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAll?userId=${userId}&ugpPrevId=${ugpPrevId}`
      );
    } else {
      console.log(
        `${environment.gmUrl}/api/trn/registerGrievance/getAll?userId=${userId}&dptId=${dptId}&ugpPrevId=${ugpPrevId}`
      );

      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAll?userId=${userId}&dptId=${dptId}&ugpPrevId=${ugpPrevId}`
      );
    }
  }

  getAllDptUserGrievances(userId: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getAllDptUserGrievances?userId=${userId}`
    );
  }

  getGrievanceRegisterById(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getById?id=${id}`
    );
  }

  saveGrievanceRegister(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/trn/registerGrievance/saveV1`,
      data
    );
  }

  // Grievance Handling
  getRegisterGrievance() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/grievanceHandling/getAll`
    );
  }

  getRegisterGrievanceById(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getByGrievanceCode?grievanceCode=${id}`
    );
  }

  getRegisterGrievanceByTrgId(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getByTrgId?id=${id}`
    );
  }

  // closeOrReopenGrievance(
  //   id: any,
  //   status: any,
  //   documents: File[],
  //   citizenComment: any,
  //   deptComment: any,
  //   createdUsrId: any
  // ) {
  //   return this.http.post(
  //     `${environment.gmUrl}/api/trn/registerGrievance/closeOrReopenGrievance?id=${id}&status=${status}&citizenComment=${citizenComment}&deptComment=${deptComment}&createdUsrId=${createdUsrId}&citizenCommentMr=${citizenComment}&deptCommentMr=${deptComment}`,
  //     null
  //   );
  // }

  closeOrReopenGrievance(
    id: any,
    status: any,
    documents: File[],
    images: File[],
    citizenComment: string,
    deptComment: string,
    createdUserId: any
  ) {
    const formData = new FormData();

    const grievanceData = {
      id,
      status,
      deptComment,
      createdUserId,
    };
    formData.append(
      "trnRegisterGrievanceDaoString",
      JSON.stringify(grievanceData)
    );
    if (documents) {
      for (let i = 0; i < documents.length; i++) {
        formData.append("doc", documents[i]);
      }
    }

    if (images) {
      for (let i = 0; i < images.length; i++) {
        formData.append("img", images[i]);
      }
    }

    console.log("formData1", formData);
    formData.forEach((value, key) => {
      console.log(`${key}:`, value);
    });

    return this.http.post(
      `${environment.gmUrl}/api/trn/registerGrievance/closeOrReopenGrievanceV1`,
      formData
    );
  }

  saveGrievanceHandling(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/trn/grievanceHandling/save`,
      data
    );
  }

  // Grievance Forwarding
  getGrievanceForwarding() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/grievanceForwarding/getAll`
    );
  }

  getForwardingStatus(grievanceCode: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getByGrievanceCode?grievanceCode=${grievanceCode}`
    );
  }

  getGrievanceForwardingByTrgId(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/grievanceForwarding/getByGfId?id=${id}`
    );
  }

  getGrievanceForwardingByUserDeptUgpPriv(
    userId: any,
    dptId: any,
    ugpPrevId: any
  ) {
    if (dptId == null || dptId == "null") {
      console.log(
        `${environment.gmUrl}/api/trn/grievanceForwarding/getAll?userId=${userId}&ugpPrevId=${ugpPrevId}`
      );

      return this.http.get(
        `${environment.gmUrl}/api/trn/grievanceForwarding/getAll?userId=${userId}&ugpPrevId=${ugpPrevId}`
      );
    } else {
      console.log(
        `${environment.gmUrl}/api/trn/grievanceForwarding/getAll?userId=${userId}&dptId=${dptId}&ugpPrevId=${ugpPrevId}`
      );

      return this.http.get(
        `${environment.gmUrl}/api/trn/grievanceForwarding/getAll?userId=${userId}&dptId=${dptId}&ugpPrevId=${ugpPrevId}`
      );
    }
  }

  getGrievanceForwardingByGrCode(id: any) {
    return this.http.get(
      `${environment.gmUrl}/api/trn/grievanceHistory/getBygrievanceCode?grievanceCode=${id}`
    );
  }

  saveGrievanceForwarding(data: any) {
    return this.http.post(
      `${environment.gmUrl}/api/trn/grievanceForwarding/saveV1`,
      data
    );
  }

  // Dashboard
  getTotalGrievance() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getTotalGrievance`
    );
  }

  getDeparmentGrievanceCount() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/countByDepartments`
    );
  }

  getTotalGrievanceCountByMonth() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getTotalGrievanceCountByMonth`
    );
  }
  getTotalGrievanceCountByWeek() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getTotalGrievanceCountByWeek`
    );
  }
  getTotalGrievanceCountByDay() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getTotalGrievanceCountByDay`
    );
  }

  getGrievanceByWard() {
    return this.http.get(
      `${environment.gmUrl}/api/report/getGrievanceCountByWard`
    );
  }

  getGrievanceCountStatuswiseForReport(id?: any) {
    const url = id 
      ? `${environment.gmUrl}/api/report/getGrievanceCountStatuswiseForReport?wardId=${id}` 
      : `${environment.gmUrl}/api/report/getGrievanceCountStatuswiseForReport`;
      
    return this.http.get(url);
  }
  
  getGrievanceByDepartment(departmentId: any) {
    if (departmentId != "null") {
      return this.http.get(
        // `${environment.gmUrl}/api/report/getGrievanceCountForDepartment?departmentId=${departmentId}`
        `${environment.gmUrl}/api/report/getGrievanceCountForDepartmentV1?departmentId=${departmentId}`
      );
    } else {
      return this.http.get(
        // `${environment.gmUrl}/api/report/getGrievanceCountByDepartmentV1` //old
        `${environment.gmUrl}/api/report/getGrievanceCountByDepartmentV2`
      );
    }
  }

  getGrievanceCountByDepartmentWard() {
    return this.http.get(
      `${environment.gmUrl}/api/report/wardAndDepartmentWiseReport`
    );
  }

  getGrievanceCountByDepartment() {
    return this.http.get(
      `${environment.gmUrl}/api/report/getGrievanceCountByDepartment`
    );
  }

  getGrievanceCountByOverallWardReportsDashboard(){
    return this.http.get(
      `${environment.gmUrl}/api/report/getGrievanceCountByWard`
    );
  }

  getCitizenSatisfactionReportByDepartmentWise(){
    return this.http.get(
      `${environment.gmUrl}/api/report/getCitizenFeedbackDepartmentWise`
    );
  }

  getCitizenFeedbackDepartmentWisePieChart(){
    return this.http.get(
      `${environment.gmUrl}/api/report/getCitizenFeedbackDepartmentWiseForPieChart`
    );
  }

  getCitizenFeedbackWardWisePieChart(){
    return this.http.get(
      `${environment.gmUrl}/api/report/getCitizenFeedbackWardWiseForPieChart`
    );
  }

  getCitizenSatisfactionReportByWardWise(){
    return this.http.get(
      `${environment.gmUrl}/api/report/getCitizenFeedbackWardWise`
    );
  }

  getTopTenGrievanceCount() {
    return this.http.get(
      `${environment.gmUrl}/api/report/getTopTenGrievanceTypeWise`
    );
  }

  getOpenGrievanceCount() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getOpenGrievances`
    );
  }

  getUnAssignedOpenGrievanceCount() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getUnassignedOpenGrievances`
    );
  }

  getGrievanceByDepartmentIdAndStage(id: any, stage: any) {
    return this.http.get(
      // `${environment.gmUrl}/api/trn/registerGrievance/getGrievancesLstForDashboard?dptId=${id}&stage=${stage}` //old
      `${environment.gmUrl}/api/trn/registerGrievance/getGrievancesLstForDashboardV1?dptId=${id}&stage=${stage}`
    );
  }

  getClosedGrievanceCount() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getClosedGrievances`
    );
  }

  getEscalatedGrievanceCount() {
    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getDelayedGrievances`
    );
  }

  //Reports
  getTotalMonthlyGrievance(duration: number, departmentId: any) {
    return this.http.get(
      `${environment.gmUrl}/api/report/getGrievanceCountByMonthForReport?duration=${duration}&departmentId=${departmentId}`
    );
  }

  getGrievanceCountByDepartmentForReport(duration: number) {
    return this.http.get(
      `${environment.gmUrl}/api/report/getGrievanceCountByDepartmentForReport?duration=${duration}`
    );
  }

  getAllByDateForReport(
    fromDate: any, 
    toDate: any, 
    departmentId?: any, 
    wardId?: any, 
    status?: any
  ) {
    let url = `${environment.gmUrl}/api/report/getAllByDateForReport`;
  
    let queryParams: string[] = [];
  
    if (fromDate) {
      queryParams.push(`fromDate=${fromDate}`);
    }
  
    if (toDate) {
      queryParams.push(`toDate=${toDate}`);
    }
  
    if (departmentId) {
      queryParams.push(`departmentId=${departmentId}`);
    }
  
    if (wardId) {
      queryParams.push(`wardId=${wardId}`);
    }
  
    if (status) {
      queryParams.push(`status=${status}`);
    }
  
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
  
    return this.http.get(url);
  }  

  getAllByDateForChannelWiseReport(
    fromDate: any, 
    toDate: any, 
    channelId: any, 
    wardId?: any, 
    status?: any,
    dptId?:any
  ) {
    let url = `${environment.gmUrl}/api/report/getChannelWiseReport`;
  
    let queryParams: string[] = [];
  
    if (fromDate) {
      queryParams.push(`fromDate=${fromDate}`);
    }
  
    if (toDate) {
      queryParams.push(`toDate=${toDate}`);
    }
  
    if (channelId) {
      queryParams.push(`channelId=${channelId}`);
    }
  
    if (wardId) {
      queryParams.push(`wardId=${wardId}`);
    }
  
    if (status) {
      queryParams.push(`status=${status}`);
    }
    if (dptId) {
      queryParams.push(`departmentId=${dptId}`);
    }
    
  
    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }
  
    return this.http.get(url);
  }
  
  

  postFeedback(
    rating: any,
    isSatisfied: any,
    isUnsatisfied: any,
    isNeutral: any,
    remark: any,
    remarkM: any,
    id: any
  ) {
    const trnRegisterGrievanceDao = {
      rating: rating,
      isSatisfied: isSatisfied,
      isUnsatisfied: isUnsatisfied,
      isNeutral: isNeutral,
      remark: remark,
      remarkM: remark,
      id: id,
    };

    return this.http.post(
      `${environment.gmUrl}/api/trn/registerGrievance/postFeedback`,
      trnRegisterGrievanceDao
    );
  }

  getMyOpenGrievances(
    userId: any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);

    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }

    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getAllDptUserOpenGrievances?userId=${userId}`,
      { params }
    );
  }

  getMyClosedGrievances(
    userId: any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);

    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }

    return this.http.get(
      `${environment.gmUrl}/api/trn/registerGrievance/getAllDptUserClosedGrievances?userId=${userId}`,
      { params }
    );
  }

  getAllOpenGrievances(
    userId: any,
    dptId: any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);

    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }
    console.log(dptId);
    if (dptId != null && dptId != "null") {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllAssignedOpenGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}`,
        { params }
      );
    } else {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllAssignedOpenGrievances?userId=${userId}&ugpPrevId=2`,
        { params }
      );
    }
    // return this.http.get(`${environment.gmUrl}/api/trn/registerGrievance/getAllAssignedOpenGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}`, { params });
  }

  getAllClosedGrievances(
    userId: any,
    dptId: any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);

    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }

    if (dptId != null && dptId != "null") {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllClosedGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}`,
        { params }
      );
    } else {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllClosedGrievances?userId=${userId}&ugpPrevId=2`,
        { params }
      );
    }
    // return this.http.get(`${environment.gmUrl}/api/trn/registerGrievance/getAllClosedGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}`, { params });
  }

  getAllOpenWardGrievances(
    userId: any,
    dptId: any,
    wardId: any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);
  
    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }
  
    console.log(dptId);
    if (dptId != null && dptId != "null") {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllAssignedOpenGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}&wardId=${wardId}`,
        { params }
      );
    } else {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllAssignedOpenGrievances?userId=${userId}&ugpPrevId=2&wardId=${wardId}`,
        { params }
      );
    }
  }
  
  getAllClosedWardGrievances(
    userId: any,
    dptId: any,
    wardId:any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);

    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }

    if (dptId != null && dptId != "null") {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllClosedGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}&wardId=${wardId}`,
        { params }
      );
    } else {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllClosedGrievances?userId=${userId}&ugpPrevId=2&wardId=${wardId}`,
        { params }
      );
    }
  }



  getAllUnassignedGrievances(
    userId: any,
    dptId: any,
    pageNo: number,
    pageSize: number,
    sortBy: string,
    sortDir: string,
    searchParam?: string
  ): Observable<any> {
    let params = new HttpParams()
      .set("pageNo", pageNo.toString())
      .set("pageSize", pageSize.toString())
      .set("sortBy", sortBy)
      .set("sortDir", sortDir);

    if (searchParam) {
      params = params.set("searchParam", searchParam);
    }

    if (dptId != null && dptId != "null") {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllNonAssignedOpenGrievances?userId=${userId}&ugpPrevId=2&dptId=${dptId}`,
        { params }
      );
    } else {
      return this.http.get(
        `${environment.gmUrl}/api/trn/registerGrievance/getAllNonAssignedOpenGrievances?userId=${userId}&ugpPrevId=2`,
        { params }
      );
    }
  }

  checkCurrentEmpForwarding(
    departmentId: any,
    wardId:any,
    empId: any,
    subDepartmentId: any
  ) {
    if (subDepartmentId == null) {
      return this.http.get(
        `${environment.gmUrl}/api/mst/mstGrievanceEmployeeMapping/checkCurrentEmpForForwardingV1?departmentId=${departmentId}&wardId=${wardId}&empId=${empId}`
      );
    } else {
      return this.http.get(
        `${environment.gmUrl}/api/mst/mstGrievanceEmployeeMapping/checkCurrentEmpForForwardingV1?departmentId=${departmentId}&wardId=${wardId}&empId=${empId}&subDepartmentId=${subDepartmentId}`
      );
    }
  }

  getGrievanceCountForBarGraph() {
    return this.http.get(
      `${environment.gmUrl}/api/report/getGrievanceCountForBarGraph`
    );
  }

  getChannelWiseForBarGraph(){
    return this.http.get(
      `${environment.gmUrl}/api/report/getChannelWiseCountForBarGraph`
    );
  }
}
