
<div *ngIf="isImageType(data)" style="width: 700px; min-height: 300px">
  <img [src]="data" alt="Image" />
</div>

<div *ngIf="isPdfType(data)">
  <ngx-doc-viewer
    [url]="data"
    viewer="google"
    style="width: 700px; height: 500px"
  >
  </ngx-doc-viewer>
</div>

<button mat-button 
          (click)="closeDialog()" 
          style="position: absolute; top: 10px; right: 10px; background: red; color: white;">
    Close
</button>
