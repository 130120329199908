import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { RtsService } from "src/app/_services/rts.service";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { TransferWardComponent } from "src/app/RTS/transfer-ward/transfer-ward.component";
import { environment } from "src/environments/environment";
import { PaymentService } from "src/app/shared/services/payment.service";
import { HttpClient } from "@angular/common/http";
import { CommonService } from "src/app/_services/Common-Services/common.service";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
@Component({
  selector: "app-my-aaplication",
  templateUrl: "./my-aaplication.component.html",
  styleUrls: ["./my-aaplication.component.scss"],
})
export class MyAaplicationComponent {
  displayedColumns: string[] = [
    "srNo",
    "applicationNumber",
    "citizenName",
    "serviceName",
    "applicationDate",
    "status",
    "view",
    "download",
    "action",
  ];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  contentLoaded: boolean = false;
  department: any;
  Departdata: any;
  applications: any;
  userId = localStorage.getItem("userId");
  dataSource = new MatTableDataSource<ApplicationData>();
  hideService: boolean = false;
  ugpId = localStorage.getItem("ugpId");
  wardId = localStorage.getItem("wardId");
  deptId = localStorage.getItem("dptId");
  ugpPrevId = localStorage.getItem("ugpPrevId");
  designationId = localStorage.getItem("designationId");
  activeServices: any;
  Subdepartments: any;
  Departments: any;
  selectedService: any = "all";
  selectedDepartment: any;
  selectedSubDept: any;
  showPayment: boolean = false;
  amount: any = {
    amount: "",
    applicationId: "",
    showForm: false,
  };
  constructor(
    private httpClient: HttpClient,
    private _snackbar: SnackbarMsgService,
    private router: Router,
    private commonService: CommonService,
    private rtsService: RtsService,
    public dialog: MatDialog,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.getDepartment();
    this.getActiveServices();
    this.loadServices();
  }
  loadServices() {
    if (this.ugpPrevId == "3") {
      this.getCitizenData();
    } else if (this.ugpPrevId == "2") {
      const userId = localStorage.getItem("userId");
      var userDepartmentId = localStorage.getItem("dptId");
      this.commonService.getUserById(userId).subscribe((data) => {
        userDepartmentId = data["dptId"];
      });
      if (userId == "018d8776-b566-7d3c-9904-47166ad9dc0a") {
        this.getAllApplication();
      } else {
        // if (userDepartmentId == '018ee68f-db51-75bc-9a64-ace798eccf53') {
        //   this.rtsService.getGanpatiServices().subscribe(res => {
        //     this.applications = res['application'];
        //     this.dataSource.data = this.applications;
        // this.contentLoaded =true;
        //   })
        // } else {
        this.getDeptsortdata();

        // }
      }
    }

    if (
      localStorage.getItem("ugpPrevId") === "2" ||
      localStorage.getItem("ugpPrevId") === "1"
    ) {
      this.hideService = false;
    }
  }
  getActiveServices() {
    this.rtsService.getServiceByActiveFlag().subscribe((res) => {
      if (res["service"].length > 0) {
        this.activeServices = res["service"];
        // this.selectedService = '0192045a-8bc3-7c74-8fe9-a2d180bd3300'
        this.loadServices();
      }
    });
  }
  getDepartment() {
    this.rtsService.getrtsDepartmentMaster().subscribe((res) => {
      this.Departments = res["departmentMasterList"];
    });
  }
  onDepartmentChange() {
    this.rtsService
      .getrtsServiceMaster(this.selectedDepartment)
      .subscribe((res) => {
        this.Subdepartments = res;
        if (this.Subdepartments.length > 0) {
        }
        if (this.Subdepartments.length === 0) {
          this._snackbar.error("No Sub Department Available !");
        }
      });
    this.contentLoaded = false;
    this.selectedSubDept = null;
    this.selectedService = "all";
    this.loadServices();
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
  }

  onSubDepartmentChange(subDptId) {
    this.rtsService.getServicesBySubDepartmentId(subDptId).subscribe((res) => {
      if (res["service"].length > 0) {
        this.activeServices = res["service"];
      } else {
        this.activeServices = [];
      }
    });

    this.contentLoaded = false;
    this.loadServices();
    this.selectedService = "all";
    setTimeout(() => {
      this.contentLoaded = true;
    }, 2000);
  }

  serviceChange() {
    this.loadServices();
  }

  goToDownload(row) {
    this.router.navigateByUrl(`/online-rts/download-application/${row.id}`);
  }
  reSumbit(row) {
    this.router.navigateByUrl(
      "/online-rts/application-service-form/" + row.serviceId,
      {
        state: { applicationId: row.id }, // Passing the data through state
      }
    );
  }
  downloadPaymentSlip(element) {
    const applicationId = element.id;
    if (applicationId) {
      this.rtsService.generateAllPaymentReceipt(applicationId).subscribe(
        (data: any) => {
          const isZip = data.message.includes("Multiple"); // Check if the file is a ZIP
          const contentType = isZip ? "application/zip" : "application/pdf"; // Set MIME type dynamically
          const base64String = data["data"];

          if (base64String) {
            // Convert base64 string to a Blob
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: contentType });

            // Create a download link
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            // Set the appropriate file name based on content type
            link.download = isZip ? "payment-slip.zip" : "payment-slip.pdf";

            // Append the link to the DOM and trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up and remove the link
            document.body.removeChild(link);
          } else {
            this._snackbar.info("No Payment Slip Available");
          }
        },
        (error) => {
          this._snackbar.info("No Payment Slip Available");
        }
      );
    }
  }

  getAllApplication() {
    this.rtsService.getAllApplications().subscribe((res) => {
      this.applications = res["application"];
      if (this.selectedService !== "all") {
        // Filter applications by serviceId
        this.applications = this.applications.filter(
          (application) => application.serviceId === this.selectedService
        );
      } else {
        if (this.selectedDepartment) {
          this.applications = this.applications.filter(
            (application) =>
              application.departmentId === this.selectedDepartment
          );
        }
        if (this.selectedSubDept) {
          this.applications = this.applications.filter(
            (application) =>
              application.subDepartmentId === this.selectedSubDept
          );
        }
      }
      this.dataSource.data = this.applications;

      this.contentLoaded = true;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  getDeptsortdata() {
    // if(this.wardId && this.deptId){
    if (this.selectedService.includes("all")) {
      this.rtsService
        .getApplicationByDepartmentUserId(localStorage.getItem("userId"))
        .subscribe((res) => {
          this.applications = res["application"];
          if (this.selectedDepartment) {
            this.applications = this.applications.filter(
              (application) =>
                application.departmentId === this.selectedDepartment
            );
          }
          if (this.selectedSubDept) {
            this.applications = this.applications.filter(
              (application) =>
                application.subDepartmentId === this.selectedSubDept
            );
          }

          this.dataSource.data = this.applications;
          this.contentLoaded = true;
        });
    } else {
      this.rtsService
        .getApplicationByDepartmentUserIdAndServiceId(
          localStorage.getItem("userId"),
          this.selectedService
        )
        .subscribe((res) => {
          this.applications = res["application"];

          if (this.selectedDepartment) {
            this.applications = this.applications.filter(
              (application) =>
                application.departmentId === this.selectedDepartment
            );
          }
          if (this.selectedSubDept) {
            this.applications = this.applications.filter(
              (application) =>
                application.subDepartmentId === this.selectedSubDept
            );
          }

          this.dataSource.data = this.applications;
          this.contentLoaded = true;
        });
    }
    // }
    // else{
    //   this.rtsService.getAllApplications().subscribe(res => {
    //   });
    // }
  }

  // getDeptData() {
  //   this.rtsService.getDeptdata().subscribe(res => {
  //     this.applications = res['application'];
  //     this.dataSource.data = this.applications;
  //     this.contentLoaded = true;
  //   })

  // }
  getCitizenData() {
    this.rtsService.getApplications(this.userId).subscribe((res) => {
      this.applications = res["application"];

      // Check if a specific service is selected
      if (this.selectedService !== "all") {
        // Filter applications by serviceId
        this.applications = this.applications.filter(
          (application) => application.serviceId === this.selectedService
        );
      } else {
        if (this.selectedDepartment) {
          this.applications = this.applications.filter(
            (application) =>
              application.departmentId === this.selectedDepartment
          );
        }
        if (this.selectedSubDept) {
          this.applications = this.applications.filter(
            (application) =>
              application.subDepartmentId === this.selectedSubDept
          );
        }
      }

      // Update the data source with the filtered applications
      this.dataSource.data = this.applications;

      this.contentLoaded = true;
    });
  }
  viewApplication(data) {
    if (data.moduleName == "property" || data.moduleName == "water") {
      this.router.navigate(["/online-rts/water-new-connection-view"], {
        queryParams: { id: data.id },
      });
    } else {
      this.router.navigateByUrl(
        `/online-rts/view-form-dept-actions/${data.id}`
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  getPropertyServices() {
    this.rtsService.getAllWaterApplications().subscribe((res) => {
      if (res["trnNewWaterConnection"]) {
        res["trnNewWaterConnection"].forEach((element, index) => {
          this.applications.push({
            serviceName: element.serviceNameEn,
            moduleName: "water",
            citizenName: element.firstName + " " + element.lastName,
            applicationNumber: element.applicationNumber,
            applicationDate: element.applicationDate,
            id: element.id,
            status: element.applicationStatus,
            view: "view",
          });
        });
      }
      this.dataSource.data = this.applications;
      this.contentLoaded = true;
      // this.applications = res['application'];
      // this.dataSource.data = this.applications;
    });

    this.rtsService.getPropertyServicesApplications().subscribe((res) => {
      if (res && Array.isArray(res)) {
        res.forEach((element, index) => {
          this.applications.push({
            serviceName: "Property Registration",
            propertyNo: element.propertyNo,
            citizenName: element.firstName,
            applicationNumber: element.applicationNo,
            applicationDate: element.applicationDate,
            id: element.id,
            moduleName: "property",
            status: "SUBMITTED",
            view: "view",
          });
        });
      }
      this.dataSource.data = this.applications;
    });

    setTimeout(() => {
      this.dataSource.sort = this.sort;
    });
  }

  show(data: any) {
    this.router.navigateByUrl("view-form-dept-actions", data);
    try {
      const appli = JSON.parse(data.formDetails);
      const documents = data.documents;
      const dept = {
        Status: data.status,
        Date: data.applicationDate,
        Approved_Remark: data.approveRemark,
        Rejected_Remark: data.rejectRemark,
        Amount: data.paymentAmount,
        docPath: data.departmentDocument,
      };

      const applicationDetails = {
        applicationId: data,
      };

      const concatdeptappli = {
        document: documents,
        applicationData: appli,
        departmentData: dept,
        data,
      };
      this.router.navigate(["/viewform"], {
        state: { applicationData: concatdeptappli },
      });
    } catch (error) {
      console.error("Invalid JSON:", error);
    }
  }

  applyScrutiny(element: any) {
    this.router.navigate(["/appScrutiny"], {
      state: { applicationData: element },
    });
  }

  getStatusColor(status: string): string {
    const lowerStatus = status.toLowerCase();
    if (
      lowerStatus === "completed" ||
      status === "PAYMENT_SUCCESSFUL" ||
      lowerStatus.includes("completed_") ||
      lowerStatus.includes("_completed")
    ) {
      return "green";
    } else if (
      lowerStatus === "rejected" ||
      status === "APPLICATION REJECTED" ||
      lowerStatus.includes("rejected_") ||
      lowerStatus.includes("_rejected")
    ) {
      return "red";
    } else if (
      lowerStatus === "generated" ||
      lowerStatus === "approved" ||
      lowerStatus.includes("_approved") ||
      lowerStatus.includes("_approved") ||
      lowerStatus.includes("generated")
    ) {
      return "blue";
    } else if (
      lowerStatus === "demand_payment" ||
      lowerStatus === "payment_pending" ||
      lowerStatus.includes("resubmitted") ||
      lowerStatus.includes("payment")
    ) {
      return "orange";
    } else if (lowerStatus === "submitted") {
      return "purple";
    }
    return "black"; // Default color if status doesn't match
  }

  transferWard(item) {
    const dialogRef = this.dialog.open(TransferWardComponent, {
      height: "400px",
      width: "600px",
      data: item,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.getDeptsortdata();
    });
  }

  downloadfile(item: string) {
    const img = environment.commonUrl + "/api/file/preview?filePath=" + item;
    const imgName = item.substr(item.lastIndexOf("/") + 1);

    this.httpClient.get(img, { responseType: "blob" }).subscribe((res) => {
      const url = window.URL.createObjectURL(res);
      const link = document.createElement("a");
      link.href = url;
      link.download = imgName;

      // Simulate a click to trigger the download
      const clickEvent = new MouseEvent("click");
      link.dispatchEvent(clickEvent);

      // Clean up
      window.URL.revokeObjectURL(url);
    });
  }

  paymentproceed(item: any) {
    if (
      item.status == "DEMAND_PAYMENT" ||
      item.status == "PENDING_FOR_PAYMENT"
    ) {
      this.amount.amount = Number(item.additionalPaymentAmount).toFixed(2);
    } else if (item.status == "SUBMITTED" || item.status == "PAYMENT_PENDING") {
      this.amount.amount = Number(item.paymentAmount).toFixed(2);
    }
    this.amount.applicationId = item.id;
    // this.showPayment = true
    // payment-recept
    let extraParam1;
    if (item.paymentAmount > 0) {
      extraParam1 = "SERVICE_CHARGE";
    } else if (item.additionalPaymentAmount > 0) {
      extraParam1 = "DEMAND_CHARGE";
    }
    this.rtsService
      .makeAPayment(
        this.amount.applicationId,
        this.amount.amount,
        "/online-rts/payment-recept",
        "0190da40-156d-7303-976d-478bbe734440",
        extraParam1,
        item.serviceId
      )
      .subscribe((data) => {
        if (data["status"].toLowerCase() == "success") {
          const paymentUrl = data["url"];
          window.location.href = paymentUrl;
        }
      });
    // this.paymentService.setAmountData(this.amount)
  }
}
interface ApplicationData {
  srNo: number;
  citizenName: string;
  serviceName: string;
  status: string;
}
