import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationEnd } from "@angular/router";
import { filter, Observable } from "rxjs";
import { AuthService } from "../_services/auth.service";
import * as moment from "moment";
import { TokenserviceService } from "../_services/tokenservice.service";

@Injectable({
  providedIn: "root",
})
export class TokenAuthGuard implements CanActivate {

  private history: string[] = [];

  constructor(private router: Router,private authService : TokenserviceService,private snackBar: MatSnackBar) {
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
    
      this.history.push(event.urlAfterRedirects);
    });
  }

  getHistory(): string[] {
    return this.history;
  }

  getPreviousUrl(): string | null {
    if (this.history.length > 1) {
      return this.history[this.history.length - 2];
    }
    return null;
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("token");
    const expiration = localStorage.getItem("expiration");
    const issuedAt = localStorage.getItem("issuedAt");
    const urlToRoute = state.url;

    console.log("Token:", token);
    console.log("Refresh token:", refreshToken);
    console.log("Expiration:", expiration);
    console.log("Issued at:", issuedAt);
    console.log("URL to route:", urlToRoute);
  
    // if (urlToRoute === '/') {
    //   return true; 
    // }


    if (token) {

      // const expirationDate = moment(expiration);
      // const currentTime = moment();

      // console.log("Expiration date:", expirationDate);
      // console.log("Current time:", currentTime);
      // console.log("Expiration date - current time:", expirationDate.diff(currentTime, "minutes"));

      // if (expirationDate.diff(currentTime, "minutes") <= 1) {
      //   return new Observable<boolean>((observer) => {
      //     this.authService.refreshToken(refreshToken).subscribe(
      //       (response: any) => {

      //         if(response === 'Invalid refresh token'){
      //           this.router.navigate(["/"], { queryParams: { returnUrl: urlToRoute } });
      //           localStorage.clear();
      //           observer.next(false);
      //           observer.complete();
      //         } else if (response === 'An error occurred'){
      //           this.router.navigate(["/"], { queryParams: { returnUrl: urlToRoute } });
      //           localStorage.clear();
      //           observer.next(false);
      //           observer.complete();
      //         } else {
      //           const newToken = response.token;
      //           const newExpiration = response.expiration;
      //           const newIssuedAt = response.issuedAt;
      //           const newRefreshToken = response.refreshToken;

      //           // Update local storage with new values
      //           localStorage.setItem("token", newToken);
      //           localStorage.setItem("expiration", newExpiration);
      //           localStorage.setItem("issuedAt", newIssuedAt);
      //           localStorage.setItem("refreshToken", newRefreshToken);
    
      //           console.log("Token refreshed successfully");
    
      //           // Allow navigation after token refresh
      //           observer.next(true);
      //           observer.complete();
      //         }
              
      //       },
      //       (error: any) => {
      //         // If token refresh fails, redirect to login page
      //         this.router.navigate(["/"], { queryParams: { returnUrl: urlToRoute } });
      //         localStorage.clear();
      //         observer.next(false);
      //         observer.complete();
      //       }
      //     );
      //   });
      // }

      return true;
    }
  
   
  
    // If any required information is missing, redirect to login page
    this.router.navigate(["/login"], { queryParams: { returnUrl: urlToRoute } });
    return false;
  }
  
  
  

}
