import { CurrencyPipe } from "@angular/common";
import { Component, ChangeDetectorRef } from "@angular/core";
import { PropertyTaxService } from "src/app/_services/property-tax/property-tax.service";
import { ApexChart, ApexXAxis, ApexDataLabels, ApexYAxis, ApexTitleSubtitle, ApexPlotOptions } from "ng-apexcharts";

// Define interfaces for API responses
interface WardPropertyCount {
  TotalCount: number;
  WardName: string;
}

interface NodeWisePropertyCount {
  nodeName: string;
  TotalCount: number;
}

interface SectorWisePropertyCount {
  sectorName: string;
  TotalCount: number;
}

interface PropertyTypeCount {
  propertyTypeName: string;
  TotalCount: number;
}

interface PropertySubTypeCount {
  propertySubTypeName: string;
  TotalCount: number;
}

interface PropertyStatusCounts {
  propertRegistration: number;
  propertyTransfer: number;
  propertyAssessmentCount: number;
  propertyTaxCancellationCount: number;
  propertySubDivisionCount: number;
  propertyObjectionRaisedCount: number;
  propertyTaxRectificationCount: number;
  propertyReconstructionCount: number;
}

// Define the chart options interface
// export type ChartOptions = {
//   // series: { name: string; data: number[] }[] | number[]; // Allow array of numbers for pie chart data
//   series: 
//     | { name: string; data: { name: string; value: number }[] }[]  // For Treemaps
//     | { name: string; data: number[] }[] | number[];
//   chart: ApexChart;
//   xaxis?: ApexXAxis;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   yaxis?: ApexYAxis;
//   title: ApexTitleSubtitle;
//   colors?: string[];
//   labels?: string[]; // Add labels for pie chart categories
//   tooltip?: {  // Add tooltip configuration here
//     enabled: boolean;
//     custom: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: any; dataPointIndex: any; w: any }) => string;
//   };
// };
// export type ChartOptions = {
//   series:
//     | { name: string; data: { x: string; y: number }[] }[]  // Treemap format
//     | { name: string; data: number[] }[];                  // Bar chart format
//   chart: ApexChart;
//   xaxis?: ApexXAxis;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   yaxis?: ApexYAxis;
//   title: ApexTitleSubtitle;
//   colors?: string[];
//   labels?: string[];
//   tooltip?: {
//     enabled: boolean;
//     custom: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: any; dataPointIndex: any; w: any }) => string;
//   };
// };

// export type ChartOptions = {
//   series:
//     | { name: string; data: { x: string; y: number }[] }[]  // Treemap format
//     | { name: string; data: number[] }[]                    // Bar chart format
//     | number[];                                             // Polar area format
//   chart: ApexChart;
//   xaxis?: ApexXAxis;
//   yaxis?: ApexYAxis;
//   dataLabels: ApexDataLabels;
//   plotOptions: ApexPlotOptions;
//   title: ApexTitleSubtitle;
//   colors?: string[];
//   labels?: string[];                                        // Labels for polar area chart
//   tooltip?: {
//     enabled: boolean;
//     custom?: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: any; dataPointIndex: any; w: any }) => string;
//   };
// };

export type ChartOptions = {
  series:
    | { name: string; data: { x: string; y: number }[] }[]  // Treemap format
    | { name: string; data: number[] }[]                    // Bar chart format
    | number[];                                             // Polar area and radialBar format
  chart: ApexChart;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  title: ApexTitleSubtitle;
  colors?: string[];
  labels?: string[];                                        // Labels for polar area chart
  tooltip?: {
    enabled: boolean;
    custom?: ({ seriesIndex, dataPointIndex, w }: { seriesIndex: any; dataPointIndex: any; w: any }) => string;
  };
};




@Component({
  selector: "app-property-tax-dashboard",
  templateUrl: "./property-tax-dashboard.component.html",
  styleUrls: ["./property-tax-dashboard.component.scss"],
  providers: [CurrencyPipe],
})
export class PropertyTaxDashboardComponent {
  chartOptions: ChartOptions;
  selectedFilter: string = 'wardWise'; // Default filter is 'wardWise'
  propertyStatusCounts: PropertyStatusCounts;
  public propertyStatusItems: { label: string; count: number }[] = [];
  // chartType: 'treemap' | 'bar' = 'treemap';
  // chartType: 'treemap' | 'bar' | 'polarArea' = 'polarArea'; 
  chartType: 'treemap' | 'bar' | 'polarArea' | 'radialBar' = 'polarArea';

  constructor(
    private propertyService: PropertyTaxService,
    private currencyPipe: CurrencyPipe,
    private cdr: ChangeDetectorRef
  ) {
    this.getWardWisePropertyCounts(); 
    this.getPropertyStatusCounts();
    this.chartOptions = this.getInitialChartOptions();
    
  }

  getPropertyStatusCounts() {
    this.propertyService.getPropertyStatusCounts().subscribe((data: any) => {
      this.propertyStatusCounts = data as PropertyStatusCounts;

      this.propertyStatusItems = [
        { label: 'Registration', count: this.propertyStatusCounts.propertRegistration },
        { label: 'Transfer', count: this.propertyStatusCounts.propertyTransfer },
        { label: 'Assessment', count: this.propertyStatusCounts.propertyAssessmentCount },
        { label: 'Cancellation', count: this.propertyStatusCounts.propertyTaxCancellationCount },
        { label: 'Sub Division', count: this.propertyStatusCounts.propertySubDivisionCount },
        { label: 'Objection Raised', count: this.propertyStatusCounts.propertyObjectionRaisedCount },
        { label: 'Tax Rectification', count: this.propertyStatusCounts.propertyTaxRectificationCount },
        { label: 'Reconstruction', count: this.propertyStatusCounts.propertyReconstructionCount }
      ];
    });
  }
  

  // Function to get initial chart options
  getInitialChartOptions(): ChartOptions {
    return {
      series: [],
      chart: {
        type: 'bar',
        height: 350
      },
      title: {
        text: 'Property Tax Overview',
        align: 'center'
      },
      colors: ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FF8633'],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '80%'
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          colors: ['#fff']
        },
        offsetY: -10,
        formatter: function (val) {
          return val.toString();
        }
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: 'Number of Properties'
        }
      }
    };
  }

  // Fetching ward-wise property counts and updating chart options
  getWardWisePropertyCounts() {
    this.propertyService.getWardWisePropertyCounts().subscribe((res: WardPropertyCount[]) => {
      const wardNames = res.map(item => item.WardName);
      const propertyCounts = res.map(item => item.TotalCount);
  
      // Set up chart options for a bar chart with data labels visible
      this.chartOptions = {
        ...this.chartOptions,
        chart: {
          type: 'bar',  // Specifies the chart type as 'bar'
        },
        series: [{
          name: 'Property Count',
          data: propertyCounts  // Data for each ward's property count
        }],
        xaxis: {
          categories: wardNames,  // Wards as categories along the x-axis
          title: {
            text: 'Wards'  // Label for the x-axis
          }
        },
        title: {
          text: 'Ward Wise Property Counts',  // Title for the chart
          align: 'center'
        },
        dataLabels: {
          enabled: true,  // Enable data labels to display on the bars
          style: {
            colors: ['#fff'],  // Set the color of the data labels (e.g., white text on dark bars)
            fontSize: '14px',
            fontWeight: 'bold'
          },
          formatter: (val: string | number) => {
            // Ensure that the value returned is either a string or number
            return typeof val === 'number' ? val.toString() : val;
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,  // Keep bars vertical (set to true for horizontal bars)
            borderRadius: 5  // Optionally set rounded corners for the bars
          }
        }
      };
  
      this.cdr.detectChanges();  // Detect changes to update the view
    });
  }
  

  getNodeWisePropertyCounts() {
    this.propertyService.getNodeWisePropertyCounts().subscribe((data: NodeWisePropertyCount[]) => {
      const nodeNames = data.map(item => item.nodeName);
      const nodeCounts = data.map(item => item.TotalCount);
  
      // Set up chart options for a bar chart with data labels visible
      this.chartOptions = {
        ...this.chartOptions,
        chart: {
          type: 'bar',  // Specifies the chart type as 'bar'
        },
        series: [{
          name: 'Property Count',
          data: nodeCounts  // Data for each node count
        }],
        xaxis: {
          categories: nodeNames,  // Nodes as categories along the x-axis
          title: {
            text: 'Nodes'  // Label for the x-axis
          }
        },
        title: {
          text: 'Node Wise Property Counts',  // Title for the chart
          align: 'center'
        },
        dataLabels: {
          enabled: true,  // Enable data labels to display on the bars
          style: {
            colors: ['#fff'],  // Set the color of the data labels (e.g., white text on dark bars)
            fontSize: '14px',
            fontWeight: 'bold'
          },
          formatter: (val: string | number) => {
            // Ensure that the value returned is either a string or number
            return typeof val === 'number' ? val.toString() : val;
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,  // Keep bars vertical (set to true for horizontal bars)
            borderRadius: 5  // Optionally set rounded corners for the bars
          }
        }
      };
  
      this.cdr.detectChanges();  // Detect changes to update the view
    });
  }
  


  // getPropertyTypeCounts() {
  //   this.propertyService.getPropertyTypeCounts().subscribe((data: PropertyTypeCount[]) => {
  //     const propertyTypes = data.map(item => item.propertyTypeName);
  //     const typeCounts = data.map(item => item.TotalCount);
  
  //     // Set up chart options for a bar chart with data labels visible
  //     this.chartOptions = {
  //       chart: {
  //         type: 'polarArea',  // Specifies that the chart type is 'bar'
  //       },
  //       series: [{
  //         name: 'Property Count',
  //         data: typeCounts  // Data for each property type count
  //       }],
  //       xaxis: {
  //         categories: propertyTypes,  // Property types as categories along the x-axis
  //         title: {
  //           text: 'Property Types'  // Label for the x-axis
  //         }
  //       },
  //       title: {
  //         text: 'Property Type Counts',  // Title for the chart
  //         align: 'center'
  //       },
  //       dataLabels: {
  //         enabled: true,  // Enable data labels to display on the bars
  //         style: {
  //           colors: ['#fff'],  // Set the color of the data labels (e.g., white text on dark bars)
  //           fontSize: '14px',
  //           fontWeight: 'bold'
  //         },
  //         formatter: (val: string | number) => {
  //           // Ensure that the value returned is always a string or number
  //           return typeof val === 'number' ? val.toString() : val;
  //         }
  //       },
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,  // Keep bars vertical (set to true for horizontal bars)
  //           borderRadius: 5  // Optionally set rounded corners for the bars
  //         }
  //       }
  //     };
  
  //     this.cdr.detectChanges();  // Detect changes to update the view
  //   });
  // }

  getPropertyTypeCounts() {
    this.propertyService.getPropertyTypeCounts().subscribe((data: PropertyTypeCount[]) => {
      const propertyTypes = data.map(item => item.propertyTypeName);
      const typeCounts = data.map(item => item.TotalCount);
  
      // Set up chart options for a polar area chart with data labels visible
      this.chartOptions = {
        chart: {
          type: 'polarArea',
          height: 400,
        },
        series: typeCounts,               // Array of counts for polar area chart
        labels: propertyTypes,            // Labels for each property type
        title: {
          text: 'Property Type Counts',
          align: 'center'
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333'],
            fontSize: '14px',
            fontWeight: 'bold'
          },
          formatter: (val: number) => {
            return `${val} Properties`;
          }
        },
        plotOptions: {
          polarArea: {
            rings: {
              strokeWidth: 0,
            },
            spokes: {
              strokeWidth: 1
            }
          }
        },
        colors: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        tooltip: {
          enabled: true,
          custom: ({ seriesIndex, w }) => {
            return `<div class="tooltip-text">${propertyTypes[seriesIndex]}: ${typeCounts[seriesIndex]} Properties</div>`;
          }
        }
      };
      
  
      this.cdr.detectChanges();  // Detect changes to update the view
    });
  }

  
  // getSectorWisePropertyCounts() {
  //   this.propertyService.getSectorWisePropertyCounts().subscribe((data: SectorWisePropertyCount[]) => {
  //     const sectorNames = data.map(item => item.sectorName);
  //     const sectorCounts = data.map(item => item.TotalCount);
  
  //     // Update chart options to enhance visualization
  //     this.chartOptions = {
  //       ...this.chartOptions,
  //       chart: {
  //         type: 'donut',  // Keeps donut chart for sector-wise counts
  //         height: '400',  // Adjusts the height of the donut chart
  //       },
  //       series: sectorCounts,  // Provides the data for each sector
  //       labels: sectorNames,    // Sets the labels for each donut segment
  //       title: {
  //         text: 'Sector Wise Property Counts',  // Title for the chart
  //         align: 'center'
  //       },
  //       dataLabels: {
  //         enabled: true,  // Enables data labels on the chart
  //         style: {
  //           fontSize: '14px',  // Sets font size for the labels
  //           fontWeight: 'bold', // Makes the label text bold
  //           colors: ['#fff'], // Color for the text (white text on dark segments)
  //         },
  //         formatter: (val, opts) => {
  //           // Formats the value to display the sector name and percentage
  //           const percentage = (typeof val === 'number') ? val.toFixed(1) : val;
  //           return `${opts.w.config.labels[opts.seriesIndex]}: ${percentage}%`;
  //         }
  //       },
  //       plotOptions: {
  //         pie: {
  //           donut: {
  //             size: '65%'  // Adjusts the size of the donut hole
  //           },
  //           dataLabels: {
  //             offset: -10  // Slightly adjusts the data label position
  //           }
  //         }
  //       }
  //     };
  
  //     // Ensure that Angular change detection picks up the updated chart options
  //     this.cdr.detectChanges();
  //   });
  // }

  // getSectorWisePropertyCounts() {
  //   this.propertyService.getSectorWisePropertyCounts().subscribe((data: SectorWisePropertyCount[]) => {
  //     // Check if data is valid and not empty
  //     if (!Array.isArray(data) || data.length === 0) {
  //       console.error('Received invalid or empty data from API');
  //       return;
  //     }

  //     // Transform the data into Treemap format if it's a Treemap chart
  //     let treemapData = [];
  //     let barData = [];
  //     if (this.chartType === 'treemap') {
  //       treemapData = this.transformToTreemapFormat(data);
  //     } else if (this.chartType === 'bar') {
  //       barData = this.transformToBarChartFormat(data);
  //     }

  //     // Update chart options to render the selected chart type (Treemap or Bar)
  //     this.chartOptions = {
  //       series: this.chartType === 'treemap'
  //         ? [{
  //             name: 'Sector Property Count',
  //             data: treemapData
  //           }]
  //         : [{
  //             name: 'Sector Property Count',
  //             data: barData
  //           }],
  //       chart: {
  //         type: this.chartType,  // This will switch between 'treemap' or 'bar'
  //         height: 400,
  //       },
  //       title: {
  //         text: 'Sector Wise Property Counts',
  //         align: 'center',
  //       },
  //       plotOptions: {
  //         treemap: {
  //           distributed: true,  // Only applicable for Treemaps
  //         },
  //       },
  //       tooltip: {
  //         enabled: true,
  //         custom: function({ seriesIndex, dataPointIndex, w }) {
  //           const data = w.config.series[seriesIndex].data[dataPointIndex];
  //           return this.chartType === 'treemap'
  //             ? `
  //                 <div style="padding: 10px;">
  //                   <strong>Sector: ${data.name}</strong><br>
  //                   Property Count: ${data.value}
  //                 </div>
  //               `
  //             : `
  //                 <div style="padding: 10px;">
  //                   <strong>Sector: ${w.config.labels[dataPointIndex]}</strong><br>
  //                   Property Count: ${data}
  //                 </div>
  //               `;
  //         },
  //       },
  //       dataLabels: {
  //         enabled: true,
  //         style: {
  //           fontSize: '12px',
  //           fontWeight: 'bold',
  //           colors: ['#fff'],
  //         },
  //       },
  //     };

  //     // Ensure that Angular change detection picks up the updated chart options
  //     this.cdr.detectChanges();
  //   });
  // }

  
  // // Transform data into Treemap chart format
  // transformToTreemapFormat(data: SectorWisePropertyCount[]): { x: string, y: number }[] {
  //   return data.map(item => ({
  //     x: item.sectorName, // Sector name for the Treemap
  //     y: item.TotalCount, // Property count for the Treemap
  //   }));
  // }

  // // Transform data into Bar chart format
  // transformToBarChartFormat(data: SectorWisePropertyCount[]): number[] {
  //   return data.map(item => item.TotalCount); // Just return the property count numbers for Bar chart
  // }

  getSectorWisePropertyCounts() {
    this.propertyService.getSectorWisePropertyCounts().subscribe((data: SectorWisePropertyCount[]) => {
      // Check if data is valid and not empty
      if (!Array.isArray(data) || data.length === 0) {
        console.error('Received invalid or empty data from API');
        return;
      }
  
      // Transform data into Treemap chart format
      const treemapData = data.map(item => ({
        x: item.sectorName,  // Sector name for the Treemap
        y: item.TotalCount   // Property count for the Treemap
      }));
  
      // Update chart options specifically for Treemap chart
      this.chartOptions = {
        series: [{
          name: 'Sector Property Count',
          data: treemapData  // Treemap data with sector names and counts
        }],
        chart: {
          type: 'treemap',  // Set chart type to treemap
          height: 400,
        },
        title: {
          text: 'Sector Wise Property Counts',
          align: 'center',
        },
        plotOptions: {
          treemap: {
            distributed: true,  // Distribute colors across the treemap blocks
            enableShades: true,
            shadeIntensity: 0.5,  // Control intensity of shades for better contrast
          }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],  // Customize colors for sectors
        tooltip: {
          enabled: true,
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            const data = w.config.series[seriesIndex].data[dataPointIndex];
            return `
              <div style="padding: 10px;">
                <strong>Sector: ${data.x}</strong><br>
                Property Count: ${data.y}
              </div>
            `;
          },
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            colors: ['#fff'],
          },
          formatter: (val, opts) => {
            return `${opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex]} Properties`;
          }
        },
      };
  
      // Ensure that Angular change detection picks up the updated chart options
      this.cdr.detectChanges();
    });
  }
  
  
  
  // // Transform the data to a hierarchical structure
  // transformToTreemapFormat(data: SectorWisePropertyCount[]): any[] {
  //   return data.map(item => ({
  //     name: item.sectorName, // Sector Name
  //     value: item.TotalCount, // Total Count as the value
  //   }));
  // }
  
  

  // Fetching property subtype counts and updating chart options
  // getPropertySubTypeCounts() {
  //   this.propertyService.getPropertySubTypeCounts().subscribe((data: PropertySubTypeCount[]) => {
  //     const propertySubTypes = data.map(item => item.propertySubTypeName);
  //     const subTypeCounts = data.map(item => item.TotalCount);
  
  //     // Set up chart options for a bar chart with data labels visible
  //     this.chartOptions = {
  //       ...this.chartOptions,
  //       chart: {
  //         type: 'bar',  // Specifies the chart type as 'bar'
  //       },
  //       series: [{
  //         name: 'Property Count',
  //         data: subTypeCounts  // Data for each property subtype's count
  //       }],
  //       xaxis: {
  //         categories: propertySubTypes,  // Property subtypes as categories along the x-axis
  //         title: {
  //           text: 'Property SubTypes'  // Label for the x-axis
  //         }
  //       },
  //       title: {
  //         text: 'Property SubType Counts',  // Title for the chart
  //         align: 'center'
  //       },
  //       dataLabels: {
  //         enabled: true,  // Enable data labels to display on the bars
  //         style: {
  //           colors: ['#fff'],  // Set the color of the data labels (e.g., white text on dark bars)
  //           fontSize: '14px',
  //           fontWeight: 'bold'
  //         },
  //         formatter: (val: string | number) => {
  //           // Ensure that the value returned is either a string or number
  //           return typeof val === 'number' ? val.toString() : val;
  //         }
  //       },
  //       plotOptions: {
  //         bar: {
  //           horizontal: false,  // Keep bars vertical (set to true for horizontal bars)
  //           borderRadius: 5  // Optionally set rounded corners for the bars
  //         }
  //       }
  //     };
  
  //     this.cdr.detectChanges();  // Detect changes to update the view
  //   });
  // }

  getPropertySubTypeCounts() {
    this.propertyService.getPropertySubTypeCounts().subscribe((data: PropertySubTypeCount[]) => {
      const propertySubTypes = data.map(item => item.propertySubTypeName);
      const subTypeCounts = data.map(item => item.TotalCount);
  
      // Set up chart options for radialBar chart
      this.chartOptions = {
        chart: {
          type: 'radialBar',
          height: 400
        },
        series: subTypeCounts,
        labels: propertySubTypes,
        title: {
          text: 'Property SubType Counts',
          align: 'center'
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333'],
            fontSize: '14px',
            fontWeight: 'bold'
          },
          formatter: (val: number) => `${val} Properties`
        },
        plotOptions: {
          radialBar: {
            dataLabels: {
              name: {
                fontSize: '16px'
              },
              value: {
                fontSize: '14px',
                formatter: (val: number) => `${val} Properties`
              },
              total: {
                show: true,
                label: 'Total',
                formatter: () => `${subTypeCounts.reduce((a, b) => a + b, 0)} Properties`
              }
            }
          }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
        tooltip: {
          enabled: true,
          custom: ({ seriesIndex, w }) => {
            return `<div class="tooltip-text">${propertySubTypes[seriesIndex]}: ${subTypeCounts[seriesIndex]} Properties</div>`;
          }
        }
      };
      this.cdr.detectChanges();
    });
  }
  

  

  // Handle filter change
  onFilterChange() {
    switch (this.selectedFilter) {
      case 'wardWise':
        this.getWardWisePropertyCounts();
        break;
      case 'nodeWise':
        this.getNodeWisePropertyCounts();
        break;
      case 'sectorWise':
        this.getSectorWisePropertyCounts();
        break;
      case 'propertyType':
        this.getPropertyTypeCounts();
        break;
      case 'propertySubType':
        this.getPropertySubTypeCounts();
        break;
      default:
        this.getWardWisePropertyCounts();
    }
  }
}
