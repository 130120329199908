<!-- <div class="ticker-container bg-blue-600 text-white py-2">
  <div class="ticker-content whitespace-nowrap overflow-x-hidden">
    <div
      class="ticker-item inline-block px-4 animate-marquee"
      *ngFor="let service of services"
    >
      {{ service.serviceName }}
    </div>
  </div>
</div>

<div class="flex flex-col md:flex-row h-screen overflow-hidden">
  <div
    class="hidden md:flex w-full h-1/3 md:h-full bg-cover bg-center md:w-3/5"
    style="background-image: url('assets/NMMC-Banner.jpg')"
  >
  </div>

  <div
    class="flex items-center justify-center w-full md:w-2/5 p-4 h-2/3 md:h-full"
  >
    <div class="w-full max-w-md">
      <img
        src="../../assets/nmmc.jpg"
        alt="NMMC Logo"
        class="mx-auto w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 mb-4"
      />
      <div
        class="text-xl md:text-2xl font-semibold mb-2 md:mb-4 text-center"
      >
        Navi Mumbai Municipal Corporation
      </div>
      <h1
        class="text-lg sm:text-xl md:text-2xl text-center mb-4 md:mb-6"
      >
        Sign In
      </h1>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <mat-form-field appearance="fill" class="w-full mb-3 md:mb-4">
          <mat-label>Username</mat-label>
          <input
            matInput
            type="text"
            formControlName="usrName"
            placeholder="Enter Mobile No as Username"
            class="text-sm sm:text-base"
          />
          <mat-error *ngIf="loginForm.get('usrName').hasError('required')">
            Username is required.
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full mb-3 md:mb-4">
          <mat-label>Password</mat-label>
          <div class="relative flex items-center">
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="usrPassword"
              placeholder="Enter Password"
              class="flex-1 text-sm sm:text-base pr-10"
            />
            <mat-icon
              (click)="togglePasswordVisibility()"
              aria-label="Toggle password visibility"
              class="absolute right-0 cursor-pointer text-gray-500"
            >
              {{ hide ? 'visibility_off' : 'visibility' }}
            </mat-icon>
          </div>
          <mat-error
            *ngIf="loginForm.get('usrPassword').hasError('required')"
          >
            Password is required.
          </mat-error>
          <mat-error
            *ngIf="loginForm.get('usrPassword').hasError('minlength')"
          >
            Password must be at least 6 characters long.
          </mat-error>
        </mat-form-field>

        <button
          type="submit"
          class="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded transition-transform transform hover:scale-105 mb-4 md:mb-6"
          [disabled]="!loginForm.valid"
        >
          Login
        </button>

        <div
          class="text-center text-xs sm:text-sm font-light text-gray-500"
        >
          <p class="mt-2">
            Forgot Password?
            <a
              routerLink="/forgot-password"
              class="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >Forgot Password</a
            >
          </p>
          <p>
            Don’t have an account?
            <a
              routerLink="/register"
              class="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >Sign Up</a
            >
            here
          </p>
        </div>
      </form>
    </div>
  </div>
</div> -->

<div class="ticker-container bg-blue-600 text-white py-2">
  <div class="ticker-content whitespace-nowrap overflow-x-hidden">
    <div
      class="ticker-item inline-block px-4 animate-marquee"
      *ngFor="let service of services"
    >
      {{ service.serviceName }}
    </div>
  </div>
</div>

<div class="flex flex-col md:flex-row h-screen overflow-hidden">
  <div
    class="hidden md:flex w-full h-1/3 md:h-full bg-cover bg-center md:w-3/5"
    style="background-image: url('assets/NMMC-Banner.jpg')"
  ></div>

  <div
    class="flex items-center justify-center w-full md:w-2/5 p-4 h-2/3 md:h-full"
  >
    <div class="w-full max-w-md">
      <img
        src="../../assets/nmmc.jpg"
        alt="NMMC Logo"
        class="mx-auto w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 mb-4"
      />
      <div class="text-xl md:text-2xl font-semibold mb-2 md:mb-4 text-center">
        Navi Mumbai Municipal Corporation
      </div>
      <h1 class="text-lg sm:text-xl md:text-2xl text-center mb-4 md:mb-6">
        Sign In
      </h1>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <!-- Username Field -->
        <mat-form-field appearance="fill" class="w-full mb-3 md:mb-4">
          <mat-label>Mobile No</mat-label>
          <input
            matInput
            type="text"
            formControlName="usrName"
            placeholder="Enter Mobile No"
            maxlength="10"
            class="text-sm sm:text-base"
            autocomplete="off"
          />
          <mat-error *ngIf="loginForm.get('usrName').hasError('required')">
            Mobile No is required.
          </mat-error>
        </mat-form-field>

        <!-- (keypress)="allowOnlyNumbers($event)"
          (copy)="allowPaste($event)"
          (paste)="allowPaste($event)"
          (cut)="allowPaste($event)" -->
        <!-- Password Field -->
        <mat-form-field
          *ngIf="isPasswordMode"
          appearance="fill"
          class="w-full mb-3 md:mb-4"
        >
          <mat-label>Password</mat-label>
          <div class="relative flex items-center">
            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              formControlName="usrPassword"
              placeholder="Enter Password"
              class="flex-1 text-sm sm:text-base pr-10"
            />
            <!-- (copy)="allowPaste($event)"
            (paste)="allowPaste($event)"
            (cut)="allowPaste($event)" -->
            <mat-icon
              (click)="togglePasswordVisibility()"
              aria-label="Toggle password visibility"
              class="absolute right-0 cursor-pointer text-gray-500"
            >
              {{ hide ? "visibility_off" : "visibility" }}
            </mat-icon>
          </div>
          <mat-error *ngIf="loginForm.get('usrPassword').hasError('required')">
            Password is required.
          </mat-error>
          <mat-error *ngIf="loginForm.get('usrPassword').hasError('minlength')">
            Password must be at least 6 characters long.
          </mat-error>
        </mat-form-field>

        <!-- OTP Field -->
        <mat-form-field *ngIf="isOtpMode" appearance="fill" class="w-full mb-4">
          <mat-label>Enter OTP</mat-label>
          <input
            matInput
            type="text"
            formControlName="otp"
            maxlength="6"
            placeholder="Enter the OTP"
            class="text-sm sm:text-base"
            (copy)="allowPaste($event)"
            (paste)="allowPaste($event)"
            (cut)="allowPaste($event)"
            (input)="onOtpInput($event)"
          />
          <mat-error *ngIf="loginForm.get('otp').hasError('required')">
            OTP is required.
          </mat-error>
          <mat-error *ngIf="loginForm.get('otp').hasError('minlength')">
            OTP must be 6 digits long.
          </mat-error>
          <mat-error *ngIf="loginForm.get('otp').hasError('pattern')">
            OTP must be numeric and exactly 6 digits.
          </mat-error>
        </mat-form-field>

        <!-- Login Button -->
        <button
          *ngIf="isPasswordMode || isOtpMode"
          type="submit"
          class="w-full bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded mb-4 md:mb-6"
          [disabled]="!loginForm.valid"
        >
          Login
        </button>

        <button
          *ngIf="isOtpMode && !isTimerActive"
          type="submit"
          class="w-full bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded mb-4 md:mb-6"
          (click)="onLoginWithOtp()"
        >
          Resend OTP
        </button>

        <div *ngIf="isTimerActive" class="w-full text-center">
          <span class="text-gray-600"
            >You can resend OTP in {{ countdown }} seconds</span
          >
        </div>

        <!-- Divider for alternate login methods -->
        <div *ngIf="isOtpMode || isPasswordMode" class="flex items-center my-4">
          <div class="flex-grow h-px bg-gray-400"></div>
          <span class="px-4 text-gray-500">or</span>
          <div class="flex-grow h-px bg-gray-400"></div>
        </div>

        <!-- Login with Password and OTP Buttons -->
        <div
          class="flex flex-col md:flex-row justify-center items-center space-y-2 md:space-y-0 md:space-x-1 w-full"
        >
          <!-- Login with Password Button -->
          <button
            *ngIf="!isPasswordMode"
            type="button"
            class="w-full md:w-auto bg-green-500 hover:bg-green-600 text-white font-medium py-2 px-4 rounded mb-2 md:mb-0"
            (click)="onLoginWithPassword()"
          >
            Login with Password
          </button>

          <!-- Login with OTP Button -->
          <button
            *ngIf="!isOtpMode"
            type="button"
            class="w-full md:w-auto bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded mb-2 md:mb-0"
            (click)="onLoginWithOtp()"
            [disabled]="isLoading"
            style="min-width: 200px"
          >
            <ng-container *ngIf="!isLoading; else loading">
              Login with OTP
            </ng-container>

            <ng-template #loading>
              <div class="flex items-center justify-center">
                <span
                  class="flex items-center justify-center"
                  style="width: 22px; height: 22px"
                >
                  <mat-spinner
                    diameter="20"
                    class="text-white"
                    style="color: white"
                  ></mat-spinner>
                </span>
              </div>
            </ng-template>
          </button>
        </div>

        <!-- Forgot Password and Signup Links -->
        <div
          class="text-center text-xs sm:text-sm font-light text-gray-500 mt-4"
        >
          <p class="mt-2">
            Forgot Password?
            <a
              routerLink="/forgot-password"
              class="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >Forgot Password</a
            >
          </p>
          <p>
            Don’t have an account?
            <a
              routerLink="/register"
              class="font-medium text-blue-600 hover:underline dark:text-blue-500"
              >Sign Up</a
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
