import { Component } from '@angular/core';

@Component({
  selector: 'app-textarea-field-options-dialog',
  templateUrl: './textarea-field-options-dialog.component.html',
  styleUrls: ['./textarea-field-options-dialog.component.scss']
})
export class TextareaFieldOptionsDialogComponent {

}
