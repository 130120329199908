import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PropertyTaxService } from 'src/app/_services/property-tax/property-tax.service';
import { AddEditPropertyTaxComponent } from './add-edit-property-tax/add-edit-property-tax.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-property-master',
  templateUrl: './property-master.component.html',
  styleUrls: ['./property-master.component.scss']
})
export class PropertyMasterComponent {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  // departments: any[] = [];
  columnNames : any[] = [
    {
      columnDef : "id",
      header : '#'
    },
    {
      columnDef : "firstName",
      header : 'Name'
    },
    {
      columnDef : "mobile",
      header : 'Mobile'
    },
    
    {
      columnDef : "email",
      header : 'Email'
    },
    {
      columnDef : "survey",
      header : 'Survey'
    },
    
    {
      columnDef : "postalAddress",
      header : 'Postal Address'
    },
    {
      columnDef : "waterConnectionType",
      header : 'Water Connection Type'
    },
    
    {
      columnDef : "usageType",
      header : 'Usage'
    },
    {
      columnDef : "propertyType",
      header : 'Property Type'
    },
    {
      columnDef : "propertyStatus",
      header : 'Property Status'
    },
    // {
    //   columnDef : "nodeName",
    //   header : 'Node'
    // },
    // {
    //   columnDef : "wardName",
    //   header : 'Ward'
    // }
  ]
  propertyTaxes = new MatTableDataSource<any>();
  displayedColumns : any[] = []
  contentLoaded = false;


  constructor(private taxService: PropertyTaxService,
    private dialog : MatDialog,
    private router:Router
   ) {
  }

  

  ngOnInit(){
    //call this method on component load
    this.displayedColumns = this.displayedColumns.concat(this.columnNames.map(x => x.columnDef));    // pre-fix static
    this.getTaxData();
  }

  ngAfterViewInit() {
    this.propertyTaxes.sort = this.sort;
  }

  getTaxData(){
    this.taxService.getPropertyMaster().subscribe(
      res=>{
        console.log(res)
        this.propertyTaxes.data = res['trnNewPropertyRegistrationList']
        this.propertyTaxes.data = this.propertyTaxes.data.sort((a, b) => a.id - b.id);
        setTimeout(
          () => {
            this.propertyTaxes.sort = this.sort;
            this.propertyTaxes.paginator = this.paginator


           });
        setTimeout(() => {
          this.contentLoaded = true;
        }, 2000);
      },
      err=>{
        console.log(err)
      }
    )
  
  }

  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.propertyTaxes.filter = filterValue.trim().toLowerCase();
  }

  addDept(){
    this.router.navigateByUrl('property-tax-registration')
    // const dialogRef = this.dialog.open(AddEditPropertyTaxComponent, {
    //   width: "1250px",
    //   // height:"800px",
    //   data: {
    //     isEditMode: false,
    //   },
    // });

    // dialogRef.afterClosed().subscribe((result: any) => {
    //   if (result.data == "Success") {
    //     this.contentLoaded = false;
    //       this.getTaxData()
    //   }
    // });

  }

 

  

}





