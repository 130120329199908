

<div class="bg-gray-100 p-6 scroll-m-0" style="max-height: 90vh; overflow-y: auto;">
    <div class="flex items-center">
  
        <button mat-icon-button (click)="cancel()">
            <mat-icon>arrow_back_ios_new</mat-icon>
        </button>
  
    <div>
        <span class="text-lg font-medium">Confidential Report</span>
    </div>
    </div>
   
    <mat-horizontal-stepper  [linear]="true" #stepper (selectionChange)="doSomething($event)">
        <!-- <mat-step [stepControl]="crForm1" (stepControl)="onNext(1)"> -->
          <mat-step  (stepControl)="onNext(1)">

          <ng-template matStepLabel></ng-template>

         
          <form [formGroup]="crForm1">
            <div class="flex justify-center">
                <h1 class="text-2xl mb-8">CR Form-1</h1>
              </div>
            <fieldset class='p-3 border border-black rounded-md'>
                <legend class="text-orange-500 text-lg	"> Employee Details - form CR forms </legend>
                <label class="heading">Appraisal Report for the period</label>
                <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 ">
                <div class="w-full">
                    <label class="text-sm font-medium text-gray-700" for="fromDate">From Date</label>
                    <input type="date" id="fromDate" name="fromDate" formControlName="fromDate"
                    class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                    required />
                    <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('fromDate').hasError('required') && crForm1.get('fromDate').touched">
                         Date is required.
                    </p>
                  </div>
    
                  <div class="w-full">
                    <label class="text-sm font-medium text-gray-700" for="reviewDate">To Date</label>
                    <input type="date" id="toDate" name="toDate" formControlName="toDate"
                    class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                    required />
                    <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('toDate').hasError('required') && crForm1.get('toDate').touched">
                         Date is required.
                    </p>
                  </div>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
              
                    <mat-form-field class="w-full">
                        <mat-label>Employee ID</mat-label>
                        <input matInput formControlName="employeeId" required />
                        <mat-error *ngIf="crForm1.get('employeeId').hasError('required')">
                            Employee Id is required.
                        </mat-error>
                      </mat-form-field>
    
                  <mat-form-field class="w-full">
                      <mat-label>Firstname (old)</mat-label>
                      <input matInput formControlName="oldFirstName" required />
                      <mat-error *ngIf="crForm1.get('oldFirstName').hasError('required')">
                           Firstname is required.
                      </mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="w-full">
                        <mat-label>Middlename (old)</mat-label>
                        <input matInput formControlName="oldMiddleName" required />
                        <mat-error *ngIf="crForm1.get('oldMiddleName').hasError('required')">
                            Middlename is required.
                        </mat-error>
                      </mat-form-field>
    
                      <mat-form-field class="w-full">
                        <mat-label>Lastname (old)</mat-label>
                        <input matInput formControlName="oldLastName" required />
                        <mat-error *ngIf="crForm1.get('oldLastName').hasError('required')">
                            Lastname is required.
                        </mat-error>
                      </mat-form-field>
    
                      <mat-form-field class="w-full">
                        <mat-label>Firstname (new)</mat-label>
                        <input matInput formControlName="newFirstName"  />
                       
                      </mat-form-field>
      
                      <mat-form-field class="w-full">
                          <mat-label>Middlename (new)</mat-label>
                          <input matInput formControlName="newMiddleName"  />
                          
                        </mat-form-field>
      
                        <mat-form-field class="w-full">
                          <mat-label>Lastname (new)</mat-label>
                          <input matInput formControlName="newLastName"  />
                         
                        </mat-form-field>
      
                    <mat-form-field class="w-full">
                      <mat-label>Select Department</mat-label>
                      <mat-select formControlName="departmentId">
                          <mat-option *ngFor="let item of departments" [value]="item.id">{{ item.department }}</mat-option>
                      </mat-select>
                  </mat-form-field>
    
                  <mat-form-field class="w-full">
                    <mat-label>Select Designation</mat-label>
                    <mat-select formControlName="designationId">
                        <mat-option *ngFor="let item of designations" [value]="item.id">{{ item.designation }}</mat-option>
                    </mat-select>
                </mat-form-field>
    
                  <mat-form-field class="w-full">
                    <mat-label>Working Place</mat-label>
                    <input matInput formControlName="workingPlace" required />
                    <mat-error *ngIf="crForm1.get('workingPlace').hasError('required')">
                        working place is required.
                    </mat-error>
                  </mat-form-field>
      
                  
                  <mat-form-field class="w-full">
                    <mat-label>Phone Number</mat-label>
                    <input onKeyPress="if(this.value.length==10) return false;" type="number" (keypress)="keyPress($event)" type="text"  id="phone-number"  matInput formControlName="phoneNumber" required />
                    <mat-error *ngIf="crForm1.get('phoneNumber').touched && crForm1.get('phoneNumber').hasError('required')">
                        phone No. is required.
                    </mat-error>
                    <mat-error *ngIf="crForm1.get('phoneNumber').touched && crForm1.get('phoneNumber').errors">
                      Please enter a valid number
                    </mat-error>
                  </mat-form-field>
    
                  <div class="w-full">
                    <label class="text-sm font-medium text-gray-700" for="reviewDate">Review Date</label>
                    <input type="date" id="reviewDate" name="reviewDate" formControlName="reviewDate"
                    class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                    required />
                    <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('reviewDate').hasError('required') && crForm1.get('reviewDate').touched">
                         Date is required.
                    </p>
                  </div>
    
                </div>
                <label class="heading">Time period</label>
                <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-2 ">
                    <div class="w-full">
                        <label class="text-sm font-medium text-gray-700" for="timePeriodFrom">From Date</label>
                        <input type="date" id="timePeriodFrom" name="timePeriodFrom" formControlName="timePeriodFrom"
                        class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                        required />
                        <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('timePeriodFrom').hasError('required') && crForm1.get('timePeriodFrom').touched">
                             Date is required.
                        </p>
                      </div>
        
                      <div class="w-full">
                        <label class="text-sm font-medium text-gray-700" for="timePeriodTo">To Date</label>
                        <input type="date" id="timePeriodTo" name="timePeriodTo" formControlName="timePeriodTo"
                        class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                        required />
                        <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('timePeriodTo').hasError('required') && crForm1.get('timePeriodTo').touched">
                             Date is required.
                        </p>
                      </div>
                    </div>
            </fieldset>

            <fieldset class='p-3 border border-black rounded-md'>
              <legend class="text-orange-500 text-lg	"> To be filled in by Establishment section </legend>
             
              <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
            
                  <mat-form-field class="w-full">
                      <mat-label>Name of officer reported upon</mat-label>
                      <input matInput formControlName="officerReportedUpon" required />
                      <mat-error *ngIf="crForm1.get('officerReportedUpon').hasError('required')">
                         Name is required.
                      </mat-error>
                    </mat-form-field>
  
                <mat-form-field class="w-full">
                    <mat-label>Carde</mat-label>
                    <input matInput formControlName="carde" required />
                    <mat-error *ngIf="crForm1.get('carde').hasError('required')">
                      carde is required.
                    </mat-error>
                  </mat-form-field>
  
  
                <div class="w-full">
                  <label class="text-sm font-medium text-gray-700" for="dob">Birth Date</label>
                  <input type="date" id="dob" name="dob" formControlName="dob"
                  class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                  required />
                  <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('dob').hasError('required') && crForm1.get('dob').touched">
                       Date is required.
                  </p>
                </div>
  
                <mat-form-field class="w-full">
                  <mat-label>Present Post</mat-label>
                  <input matInput formControlName="presentPost" required />
                  <mat-error *ngIf="crForm1.get('presentPost').hasError('required')">
                    Post is required.
                  </mat-error>
                </mat-form-field>
  
                <div class="w-full">
                  <label class="text-sm font-medium text-gray-700" for="dateOfAppintment">Date of appointment to present post</label>
                  <input type="date" id="dateOfAppintment" name="dateOfAppintment" formControlName="dateOfAppintment"
                  class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                  required />
                  <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('dateOfAppintment').hasError('required') && crForm1.get('dateOfAppintment').touched">
                       Date is required.
                  </p>
                </div>
  
                <mat-form-field class="w-full">
                  <mat-label>Administrative Officer</mat-label>
                  <input matInput formControlName="administrativeOfficer" required />
                  <mat-error *ngIf="crForm1.get('administrativeOfficer').hasError('required')">
                    officer is required.
                  </mat-error>
                </mat-form-field>
  
              </div>
          </fieldset>
  
          <fieldset class='p-3 border border-black rounded-md'>
            <legend class="text-orange-500 text-lg	"> Details of Reporting and Reviewing Officer </legend>
            <label class="heading">1. Reporting Officer</label>
            <div class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3 ">
          
                          <mat-form-field class="w-full">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="reportingOfficer" required />
                            <mat-error *ngIf="crForm1.get('reportingOfficer').hasError('required')">
                               Name is required.
                            </mat-error>
                          </mat-form-field>
  
                          <mat-form-field class="w-full">
                            <mat-label>Select Designation</mat-label>
                            <mat-select formControlName="reportingOfficerDesignationId">
                                <mat-option *ngFor="let item of designations" [value]="item.id">{{ item.designation }}</mat-option>
                            </mat-select>
                        </mat-form-field>
  
                        <mat-form-field class="w-full">
                          <mat-label>Period</mat-label>
                          <input matInput formControlName="reportingOfficerPeriod" required />
                          <mat-error *ngIf="crForm1.get('reportingOfficerPeriod').hasError('required')">
                             Period is required.
                          </mat-error>
                        </mat-form-field>
  
                           
            
                         
  
            </div>
  
            <label class="heading">2. Reviewing Officer</label>
            <div class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3 ">
          
                          <mat-form-field class="w-full">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="reviewingOfficer" required />
                            <mat-error *ngIf="crForm1.get('reviewingOfficer').hasError('required')">
                               Name is required.
                            </mat-error>
                          </mat-form-field>
  
                          <mat-form-field class="w-full">
                            <mat-label>Select Designation</mat-label>
                            <mat-select formControlName="reviewinggOfficerDesignationId">
                                <mat-option *ngFor="let item of designations" [value]="item.id">{{ item.designation }}</mat-option>
                            </mat-select>
                        </mat-form-field>
  
                        <mat-form-field class="w-full">
                          <mat-label>Period</mat-label>
                          <input matInput formControlName="reviewingOfficerPeriod" required />
                          <mat-error *ngIf="crForm1.get('reviewingOfficerPeriod').hasError('required')">
                             Period is required.
                          </mat-error>
                        </mat-form-field>
  
                           
            
                         
  
            </div>
        </fieldset>
  
        <fieldset class='p-3 border border-black rounded-md'>
          <legend class="text-orange-500 text-lg	"> Details of Leave and Absence due to other reasons during period under report</legend>
          <label class="heading">1. Leave</label>
          <div class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3 ">
        
                        <mat-form-field class="w-full">
                          <mat-label>Period</mat-label>
                          <input matInput formControlName="leavePeriod" required />
                          <mat-error *ngIf="crForm1.get('leavePeriod').hasError('required')">
                             Period is required.
                          </mat-error>
                        </mat-form-field>
  
                        <mat-form-field class="w-full">
                          <mat-label>Select Leave Type</mat-label>
                          <mat-select formControlName="typeOfLeaveId">
                              <mat-option *ngFor="let item of leaveTypes" [value]="item.id">{{ item.leaveTypeName }}</mat-option>
                          </mat-select>
                      </mat-form-field>
  
                      <mat-form-field class="w-full">
                        <mat-label>Remark</mat-label>
                        <input matInput formControlName="leaveRemark" required />
                        <mat-error *ngIf="crForm1.get('leaveRemark').hasError('required')">
                          Remark is required.
                        </mat-error>
                      </mat-form-field>
  
                         
          
                       
  
          </div>
  
          <label class="heading">2. Other reasons (specify) such as absconding,unauthorized absence</label>
          <div class="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 gap-3 ">
        
            <mat-form-field class="w-full">
              <mat-label>Period</mat-label>
              <input matInput formControlName="otherReasonsLeavePeriod" required />
              <mat-error *ngIf="crForm1.get('otherReasonsLeavePeriod').hasError('required')">
                 Period is required.
              </mat-error>
            </mat-form-field>
  
            <mat-form-field class="w-full">
              <mat-label>Select Leave Type</mat-label>
              <mat-select formControlName="otherReasonsTypeOfLeaveId">
                  <mat-option *ngFor="let item of leaveTypes" [value]="item.id">{{ item.leaveTypeName }}</mat-option>
              </mat-select>
          </mat-form-field>
  
          <mat-form-field class="w-full">
            <mat-label>Remark</mat-label>
            <input matInput formControlName="otherReasonsRemark" required />
            <mat-error *ngIf="crForm1.get('otherReasonsRemark').hasError('required')">
              Remark is required.
            </mat-error>
          </mat-form-field>
  
             
  
           
  
  </div>
        </fieldset>
  
        <fieldset class='p-3 mt-4 border border-black rounded-md'>
          <legend class="text-orange-500 text-lg	">Details of Training Undergone during Period Under Report</legend>
     
          <div *ngFor="let ticket of detailsOfTraning.controls; let i = index" class="list-group list-group-flush">
            <div class="list-group-item">
                <div *ngIf="isFormGroup(ticket)" [formGroup]="ticket" class="form-row">
                    <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4 ">
                       
                      <div class="w-full">
                        <label class="text-sm font-medium text-gray-700" for="fromDate">From Date</label>
                        <input type="date" id="fromDate" name="fromDate" formControlName="fromDate"
                        class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                        required />
                        <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('fromDate').hasError('required') && crForm1.get('fromDate').touched">
                             Date is required.
                        </p>
                      </div>
  
                      <div class="w-full">
                        <label class="text-sm font-medium text-gray-700" for="toDate">To Date</label>
                        <input type="date" id="toDate" name="toDate" formControlName="toDate"
                        class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                        required />
                        <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('toDate').hasError('required') && crForm1.get('toDate').touched">
                             Date is required.
                        </p>
                      </div>
  
                          <mat-form-field class="w-full">
                            <mat-label>Institute</mat-label>
                            <input matInput formControlName="institute" required />
                            <mat-error *ngIf="ticket.get('institute').hasError('required')">
                              Institute is required.
                            </mat-error>
                          </mat-form-field>
  
                          <mat-form-field class="w-full">
                            <mat-label>Subject</mat-label>
                            <input matInput formControlName="subject" required />
                            <mat-error *ngIf="ticket.get('subject').hasError('required')">
                              Subject is required.
                            </mat-error>
                          </mat-form-field>
            
                    </div>
                     
                </div>
            </div>
            </div>
  
            <div class="pt-4">
              <button type="button" type="button" (click)="onChangeTickets()" class="text-blue-600 bg-blue-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                  Add
                </button>
            
            </div>
  
           
  
  
  
  
  
       </fieldset>
  
       <fieldset class='p-3 mt-4 border border-black rounded-md'>
        <legend class="text-orange-500 text-lg	">Details of Confidential Report not Written/Reviewd, as Reporting/Reviewing Officer by officer under report up to previous assessment year </legend>
   
        <div *ngFor="let ticket of detailsOfConfidential.controls; let i = index" class="list-group list-group-flush">
          <div class="list-group-item">
              <div *ngIf="isFormGroup(ticket)" [formGroup]="ticket" class="form-row">
                  <div class="grid grid-cols-5 sm:grid-cols-5 md:grid-cols-5 lg:grid-cols-5 gap-5 ">
                     
                    <div class="w-full">
                      <label class="text-sm font-medium text-gray-700" for="fromDate">From Date</label>
                      <input type="date" id="fromDate" name="fromDate" formControlName="fromDate"
                      class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                      required />
                      <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('fromDate').hasError('required') && crForm1.get('fromDate').touched">
                           Date is required.
                      </p>
                    </div>
  
                    <div class="w-full">
                      <label class="text-sm font-medium text-gray-700" for="toDate">To Date</label>
                      <input type="date" id="toDate" name="toDate" formControlName="toDate"
                      class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                      required />
                      <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('toDate').hasError('required') && crForm1.get('toDate').touched">
                           Date is required.
                      </p>
                    </div>
  
                        <mat-form-field class="w-full">
                          <mat-label>Reporting/Review</mat-label>
                          <input matInput formControlName="review" required />
                          <mat-error *ngIf="ticket.get('review').hasError('required')">
                            Details are required.
                          </mat-error>
                        </mat-form-field>
  
                        <mat-form-field class="w-full">
                          <mat-label>Name</mat-label>
                          <input matInput formControlName="name of officer" required />
                          <mat-error *ngIf="ticket.get('name of officer').hasError('required')">
                            Name is required.
                          </mat-error>
                        </mat-form-field>
  
                        <mat-form-field class="w-full">
                          <mat-label>Select Designation</mat-label>
                          <mat-select formControlName="designationId">
                              <mat-option *ngFor="let item of designations" [value]="item.id">{{ item.designation }}</mat-option>
                          </mat-select>
                      </mat-form-field>
          
                  </div>
                   
              </div>
          </div>
          </div>
  
          <div class="pt-4">
            <button type="button" type="button" (click)="onChangeTicketsConf()" class="text-blue-600 bg-blue-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                Add
              </button>
          
          </div>
  
         
  
  
  
  
  
     </fieldset>
      
        <fieldset class='p-3 mt-4 border border-black rounded-md'>
          <legend class="text-orange-500 text-lg	">Date of filling Assets and Liability statement of previous year to concerned authority</legend>
     
          <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 ">
            <div class="w-full">
                <label class="text-sm font-medium text-gray-700" for="date">Date</label>
                <input type="date" id="date" name="date" formControlName="date"
                class="mt-1 p-2 border rounded-md w-full focus:outline-none  focus:border-blue-700"
                required />
                <p class="mt-1 text-sm text-red-600" *ngIf="crForm1.get('date').hasError('required') && crForm1.get('date').touched">
                     Date is required.
                </p>
              </div>
  
              <mat-form-field class="w-full">
                <mat-label>Place</mat-label>
                <input matInput formControlName="place" required />
                <mat-error *ngIf="crForm1.get('place').hasError('required')">
                    Place is required.
                </mat-error>
              </mat-form-field>
  
              <div class="w-full">
  
                <label class="text-sm font-medium text-gray-700" for="uploadDigitalSignature">Digital Signature Upload</label>
                <input (change)="uploadDigitalSignature($event)" class="block w-full file:bg-gray-800 file:text-white text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="file_input" type="file">
                <p *ngIf="ShowdigitalSignature == true" class="text-sm font-light text-lime-500 dark:text-gray-400">
                    Digital Signature uploaded successfully 
                </p>
            </div>
  
            </div>
    
      </fieldset>
       
      <div class="float-right p-4" >
        <button type="button" matStepperNext  class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Next</button>
          <!-- <button type="button" type="button" (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                Go back
              </button>
              <button type="submit" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button> -->
      </div>
      
     
           
          </form>
       
        </mat-step>
        
        <mat-step (stepControl)="onNext(2)">
          <ng-template matStepLabel></ng-template>
          <fieldset class='p-3 border border-black rounded-md'>
            <legend class="text-orange-500 text-lg	"> Self Appraisal Report </legend>
           
          
          <div *ngFor="let question of questions;let q = index" >
            <h3>{{q+1}}] {{ question.question }}</h3>
            <ng-container *ngIf="question.questionType === 'text'">
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.textAnswer" required></textarea>
            </mat-form-field>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'Y/N/NA radio'">
              <select [(ngModel)]="question.textAnswer">
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="not_applicable">Not Applicable</option>
              </select>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'Y/N radio'">
              <select [(ngModel)]="question.textAnswer">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'list multiple answers'">
                
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[0].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[1].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[2].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[3].ansText" required></textarea>
              </mat-form-field>
             
      
          </ng-container>
            <ng-container *ngIf="question.questionType === 'Tabular sub ques'">
              <div *ngFor="let subQuestion of question.subQuestions;let i = index">
                <p>{{i + 1}}. {{ subQuestion.subQuestion }}</p>
                <mat-form-field class="w-full">
                  <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="subQuestion.singleAnswerText" required></textarea>
              </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'multiple answers (sub ques)'">
              <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4 ">
      
              <div *ngFor="let subQuestion of question.subQuestions; let i = index">
                           
                          <div class="w-full">
                           
      
                            <div *ngFor="let answer of subQuestion.multipleAnswers">
                              <label class="text-sm font-medium text-gray-700 " for="fromDate">{{subQuestion.subQuestion}} 
                              </label>
                              <hr>
                              <mat-form-field appearance="outline" class="w-full">
                                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="answer.ansText" required></textarea>
                            </mat-form-field>
                            </div>
      
                          </div>
      
                       
              </div>
      
                         
                </div>
      
                <div class="pt-4">
                  <button type="button" type="button" (click)="addAnswer(question)" class="text-blue-600 bg-blue-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                      Add
                    </button>
                
                </div>
           
            </ng-container> 
         
          </div>
          
        </fieldset>
        <div class="float-right p-4" >
          <button type="button" matStepperNext  class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Next</button>
            <!-- <button type="button" type="button" (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                  Go back
                </button>
                <button type="submit" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button> -->
        </div>
        </mat-step>
        <mat-step  (stepControl)="onNext(3)">
          <ng-template matStepLabel></ng-template>
          <fieldset class='p-3 border border-black rounded-md'>
            <legend class="text-orange-500 text-lg	"> Self Appraisal Report </legend>
           
          
          <div *ngFor="let question of questions;let q = index" >
            <h3>{{q+1}}] {{ question.question }}</h3>
            <ng-container *ngIf="question.questionType === 'text'">
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.textAnswer" required></textarea>
            </mat-form-field>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'Y/N/NA radio'">
              <select [(ngModel)]="question.textAnswer">
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="not_applicable">Not Applicable</option>
              </select>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'Y/N radio'">
              <select [(ngModel)]="question.textAnswer">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'list multiple answers'">
                
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[0].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[1].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[2].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[3].ansText" required></textarea>
              </mat-form-field>
             
      
          </ng-container>
            <ng-container *ngIf="question.questionType === 'Tabular sub ques'">
              <div *ngFor="let subQuestion of question.subQuestions;let i = index">
                <p>{{i + 1}}. {{ subQuestion.subQuestion }}</p>
                <mat-form-field class="w-full">
                  <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="subQuestion.singleAnswerText" required></textarea>
              </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'multiple answers (sub ques)'">
              <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4 ">
      
              <div *ngFor="let subQuestion of question.subQuestions; let i = index">
                           
                          <div class="w-full">
                           
      
                            <div *ngFor="let answer of subQuestion.multipleAnswers">
                              <label class="text-sm font-medium text-gray-700 " for="fromDate">{{subQuestion.subQuestion}} 
                              </label>
                              <hr>
                              <mat-form-field appearance="outline" class="w-full">
                                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="answer.ansText" required></textarea>
                            </mat-form-field>
                            </div>
      
                          </div>
      
                       
              </div>
      
                         
                </div>
      
                <div class="pt-4">
                  <button type="button" type="button" (click)="addAnswer(question)" class="text-blue-600 bg-blue-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                      Add
                    </button>
                
                </div>
           
            </ng-container> 
         
          </div>
          
        </fieldset>
        <div class="float-right p-4" >
          <button type="button" matStepperNext  class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Next</button>
            <!-- <button type="button" type="button" (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                  Go back
                </button>
                <button type="submit" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button> -->
        </div>
        </mat-step>
        <mat-step  (stepControl)="onNext(4)">
          <ng-template matStepLabel></ng-template>
          <fieldset class='p-3 border border-black rounded-md'>
            <legend class="text-orange-500 text-lg	"> Self Appraisal Report </legend>
           
          
          <div *ngFor="let question of questions;let q = index" >
            <h3>{{q+1}}] {{ question.question }}</h3>
            <ng-container *ngIf="question.questionType === 'text'">
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.textAnswer" required></textarea>
            </mat-form-field>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'Y/N/NA radio'">
              <select [(ngModel)]="question.textAnswer">
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="not_applicable">Not Applicable</option>
              </select>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'Y/N radio'">
              <select [(ngModel)]="question.textAnswer">
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'list multiple answers'">
                
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[0].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[1].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[2].ansText" required></textarea>
              </mat-form-field>
              <mat-form-field class="w-full">
                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="question.multipleAns[3].ansText" required></textarea>
              </mat-form-field>
             
      
          </ng-container>
            <ng-container *ngIf="question.questionType === 'Tabular sub ques'">
              <div *ngFor="let subQuestion of question.subQuestions;let i = index">
                <p>{{i + 1}}. {{ subQuestion.subQuestion }}</p>
                <mat-form-field class="w-full">
                  <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="subQuestion.singleAnswerText" required></textarea>
              </mat-form-field>
              </div>
            </ng-container>
            <ng-container *ngIf="question.questionType === 'multiple answers (sub ques)'">
              <div class="grid grid-cols-4 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-4 gap-4 ">
      
              <div *ngFor="let subQuestion of question.subQuestions; let i = index">
                           
                          <div class="w-full">
                           
      
                            <div *ngFor="let answer of subQuestion.multipleAnswers">
                              <label class="text-sm font-medium text-gray-700 " for="fromDate">{{subQuestion.subQuestion}} 
                              </label>
                              <hr>
                              <mat-form-field appearance="outline" class="w-full">
                                <textarea matInput name="subQuestion" cols="35" rows="2" [(ngModel)]="answer.ansText" required></textarea>
                            </mat-form-field>
                            </div>
      
                          </div>
      
                       
              </div>
      
                         
                </div>
      
                <div class="pt-4">
                  <button type="button" type="button" (click)="addAnswer(question)" class="text-blue-600 bg-blue-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                      Add
                    </button>
                
                </div>
           
            </ng-container> 
         
          </div>
          
        </fieldset>
        <div class="float-right p-4" >
          <button type="button" matStepperNext  (click)="saveAnswers()" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button>
            <!-- <button type="button" type="button" (click)="cancel()" class="text-sky-600 bg-sky-100 hover:bg-gray-300 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2">
                  Go back
                </button>
                <button type="submit" class="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">Submit</button> -->
        </div>
        </mat-step>

         
    </mat-horizontal-stepper>
    
</div>