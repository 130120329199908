<!-- Close Button -->
<div class="flex justify-between items-center w-full px-3 py-3">
  <!-- Text aligned to the left -->
  <div class="text-left pt-1">
    <p class="text-lg no-underline">Uploaded Document</p>
  </div>

  <!-- Close button aligned to the right -->
  <button mat-icon-button (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<!-- Image Display -->
<div *ngIf="isImageType(data)" style="width: 700px; min-height: 300px">
  <img [src]="data" alt="Image" />
</div>

<!-- PDF Viewer for Android -->
<div *ngIf="isPdfType(data)">
  <pdf-viewer
    [src]="data"
    [render-text]="true"
    [original-size]="false"
    style="width: 90vw; height: 90vh"
  ></pdf-viewer>
</div>

<!-- Add styles to handle the close button positioning -->
