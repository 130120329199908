const serverUrl = "https://waterapp.probitysoftware.in/backend/";
const serverFrontEndUrl = "https://waterapp.probitysoftware.in/";

export const environment = {
  production: true,
  frontEndUrl:serverFrontEndUrl,
  commonUrl: `${serverUrl}common`,
  rtsUrl: `${serverUrl}rts`,
  surveyDB: `${serverUrl}sdc`,
  gmUrl: `${serverUrl}gm`,
  cmsUrl: `${serverUrl}cms`,
  wtUrl: `${serverUrl}wt`,
  ewmUrl: `${serverUrl}ewm`,
  taxUrl: `${serverUrl}pt`,
  hrmsUrl: `${serverUrl}hrms`,
  wmUrl: `${serverUrl}wm`,
  faUrl: `${serverUrl}fm`,
  cwmUrl: `${serverUrl}cwm`,
  disasterUrl: `${serverUrl}dm`,
  assetUrl: `${serverUrl}am`,
  msmamUrl: `${serverUrl}msm`,
  mmUrl: `${serverUrl}mat`,
  swmUrl: `${serverUrl}swm`,

  apiUrl: "https://15-206-219-76.nip.io:9002",
  landUrl: "https://15-206-219-76.nip.io:9006",
  // swmUrl: "https://15-206-219-76.nip.io:9014",

};
