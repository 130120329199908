import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NewLoaderComponent } from "src/app/WATER-TAX/REPORT/loader-new/new-loader.component";
import { AssetServiceService } from "src/app/_services/assetService/asset-service.service";
import { ConfirmationPopupComponent } from "src/app/confirmation-popup/confirmation-popup.component";
import { SnackbarMsgService } from "src/app/shared/Toast-service/snackbar-msg.service";
@Component({
  selector: "app-add-edit-eqp-breakdown-type",
  templateUrl: "./add-edit-eqp-breakdown-type.component.html",
  styleUrls: ["./add-edit-eqp-breakdown-type.component.scss"],
})
export class AddEditEqpBreakdownTypeComponent {
  assetForm: FormGroup;
  id = null;
  addedit: any;
  progressSpinnerDialog: any = null;

  constructor(
    private fb: FormBuilder,
    private assetService: AssetServiceService,
    private _snackBar: SnackbarMsgService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditEqpBreakdownTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any,
  ) {
    this.addedit = data1["isEditMode"];
    this.assetForm = this.fb.group({
      id: [""],
      equipmentBreakdownTypeName: ["",[Validators.required, Validators.pattern(/^(?![\s,;:.]*$)[a-zA-Z0-9]+(?:[\s,;:.]{0,2}[a-zA-Z0-9]+)*$/)],],
      activeFlag: ["Y", Validators.required],
    });

    if (this.addedit) {
      const data12 = data1["row"];
      this.id = data12["id"];
      this.assetForm.patchValue({
        id: data12["id"],
        equipmentBreakdownTypeName: data12["equipmentBreakdownTypeName"],
        activeFlag: data12["activeFlag"],
      });
    }
  }
  ngOnInit() {}

  save() {
    if (this.assetForm.valid) {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        width: "550px",
        data: {
          mode: this.addedit ? "Edit" : "Add",
          component: "Breakdown Type",
        },
        autoFocus: false,
        restoreFocus: false,
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == "success") {
          this.displayProgressSpinner();
          this.assetService.saveEquipmentBreakdownType(this.assetForm.value).subscribe((res) => {
            if (res["status"] === "success") {
              const message = this.addedit ? "Record Updated Successfully" : "Record Added Successfully";
              this._snackBar.success(message);

              setTimeout(() => {
                this.closeProgressSpinner();
                this.dialogRef.close({ data: "Success" });
              }, 1000);
            } else {
              this.closeProgressSpinner();
              this._snackBar.error(res["message"]);
            }
          });
        }
      });
    }
  }

  async displayProgressSpinner() {
    this.progressSpinnerDialog = this.dialog.open(NewLoaderComponent, {
      disableClose: true,
      panelClass: "progressSpinner",
    });
  }

  async closeProgressSpinner() {
    if (this.progressSpinnerDialog) {
      this.progressSpinnerDialog.close();
    }
  }
}
